export const it = {
  dpiPurchase: {
    addDpi: "Aggiungi DPI",
    code: "Identificativo",
    codeError: "Inserire un identificativo valido e univoco",
    content: "Contenuto",
    date: "Data",
    dateError: "Selezionare una data",
    dpiPurchase: "Acquisto DPI",
    dpiPurchaseShort: "Acquisto",
    dpiPurchases: "Acquisti DPI",
    isStored: "Immagazzinato",
    notes: "Note",
    totalPrice: "Prezzo totale",
  },
  navigation: {
    dpiPurchase: "Acquisti DPI",
  },
};
