export const it = {
  dpi: {
    category: "Categoria",
    categoryError: "Selezionare una categoria",
    description: "Descrizione",
    dpi: "DPI",
    dpis: "DPI",
    name: "Nome",
    nameError: "Inserire un nome valido",
    productCode: "Codice prodotto",
    quantity: "Quantità",
    quantityError: "Inserire una quantità valida",
    size: "Taglia",
    sizeError: "Selezionare una taglia",
    sizes: "Taglie",
    sizesError: "Inserire almeno una taglia",
    totalPrice: "Prezzo totale",
    unitPrice: "Prezzo unitario",
    unitPriceError: "Inserire un prezzo valido",
  },
  navigation: {
    dpi: "DPI",
  },
};
