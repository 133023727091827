import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { NumberField, StringField } from "@/core/fields";

export class ParcelItem {
  public id: string = DataHelpers.uniqueId();
  public code: string | undefined = undefined;
  public quantity = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): ParcelItem {
    this.id = DataHelpers.uniqueId();

    this.code = StringField.fromFirestore(data.code);
    this.quantity = NumberField.fromFirestore(data.quantity);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.quantity = NumberField.toFirestore(this.quantity);

    return firestoreData;
  }
}
