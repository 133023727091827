import { RouteRecordRaw } from "vue-router";

export const employeeContractRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/contracts/:employeeContractId/edit",
    name: "EmployeeContractEdit",
    component: () => import("../views/EmployeeContractEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeContract",
      requiredRight: "viewUI",
    },
  },
];
