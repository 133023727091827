import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class BranchHelpers {
  public static getDocFolderPath(branchId: string | undefined): string {
    try {
      if (branchId === undefined) throw new Error("branchId is undefined");
      return `branches/${branchId}/docs/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("BranchHelpers.getDocFolderPath", { branchId }, error);
      throw error;
    }
  }
}
