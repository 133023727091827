export const it = {
  invoice: {
    company: "Azienda",
    companyError: "Selezionare una azienda",
    details: "Dettagli",
    importItem: "Aggiungi elemento",
    importItems: "Calcola elementi",
    isConfirmed: "Confermato",
    itemName: "Descrizione",
    itemNameError: "Inserire una descrizione",
    items: "Elementi",
    month: "Periodo di riferimento",
    monthDate: "Periodo",
    monthError: "Selezionare un periodo di riferimento",
    notes: "Note",
    paid: "Pagati",
    paymentAmount: "Importo",
    paymentAmountError: "Inserire un importo",
    paymentBank: "Conto corrente",
    paymentBankError: "Selezionare un conto corrente",
    paymentDate: "Data",
    paymentDateError: "Inserire una data valida",
    payments: "Pagamenti",
    price: "Prezzo",
    priceError: "Inserire un prezzo",
    quantity: "Quantità",
    quantityError: "Inserire una quantità",
    state: "Stato",
    states: {
      completed: "Completata",
      draft: "Bozza",
      paid: "Pagata",
    },
    total: "Totale",
    invoice: "Fattura",
    invoices: "Fatture",
  },
  navigation: {
    invoice: "Fatture",
  },
  buttons: {
    markAsCompleted: "Segna completata",
  },
  toast: {
    error: {
      companyUndefined: "Selezionare una azienda",
      monthDateUndefined: "Selezionare un periodo di riferimento",
      priceListItemUndefined: "Selezionare un elemento",
      priceListUndefined: "Nessun listino prezzi associato",
      unconfirmedItems: "Ci sono elementi non confermati",
    },
    success: {},
    warning: {},
  },
};
