export const it = {
  aedCompany: {
    address: "Indirizzo",
    addressFields: {
      country: "Paese",
      province: "Provincia",
      street: "Indirizzo",
      town: "Comune",
      zipCode: "CAP",
    },
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserisci un indirizzo e-mail valido",
    aedCompany: "Azienda",
    aedCompanies: "Aziende",
    email: "Indirizzo e-mail",
    emailError: "Indirizzo e-mail non valido o già presente",
    fax: "Fax",
    filters: {
      archived: "Aziende archiviate",
      archivedExcluded: "Archiviate escluse",
      archivedIncluded: "Archiviate incluse",
    },
    fiscalCode: "Codice fiscale",
    fiscalData: "Dati fiscali",
    general: "Generali",
    iban: "IBAN",
    info: "Informazioni",
    mobile: "Cellulare",
    name: "Ragione sociale",
    nameError: "Inserisci la ragione sociale",
    notes: "Note",
    phone: "Telefono",
    position: "Posizione",
    positionError: "Inserire una posizione valida",
    recipientCode: "Codice destinatario",
    referentName: "Nome",
    referentNameError: "Inserire un nome valido",
    referents: "Referenti",
    state: "Stato",
    states: {
      active: "Attiva",
      archived: "Archiviata",
    },
    vatCode: "Partita IVA",
    vatCodeEmpty: "Inserisci la partita IVA",
    vatCodeError: "La partita IVA inserita è già presente",
    vatCodePlaceholder: "Inserisci una partita IVA",
  },
  navigation: {
    aedCompany: "Aziende",
  },
  buttons: {
    archiveAedCompany: "Metti in archivio",
    restoreAedCompany: "Togli dall'archivio",
  },
};
