export const it = {
  statHandleCode: {
    action: "Azione",
    company: "Azienda",
    handleCodeCount: "Saldo materiale",
    handleCodePaper: "Resoconto su movimentazione per codice materiale",
    inboundShipment: "Spedizione in arrivo",
    itemCode: "Codice materiale",
    name: "Movimentazione per codice materiale",
    outboundShipment: "Spedizione in partenza",
    parcel: "UDC",
    parcelStates: {
      created: "Creato",
      deleted: "Eliminato",
      loaded: "Caricato",
      loosened: "Libero",
      sent: "Spedito",
      stored: "Immagazzinato",
      unknown: "Sconosciuto",
    },
    period: "Periodo di riferimento",
    periodShort: "Periodo",
    quantity: "Quantità",
    shipmentDetail: "Spedizione {code} del {date} da {companyName}",
  },
};
