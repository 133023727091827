import { format } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "../ParcelModel";

export const getParcelsByCompanyAndPeriodAndItemCode = async (
  companyId: string,
  startDate: Date,
  endDate: Date,
  itemCode: string
): Promise<Parcel[]> => {
  try {
    const snapshotQuery: Query<Parcel> = query(
      parcelModel.getPathReference().withConverter(parcelModel.firestoreConverter),
      where("inboundShipment.company.id", "==", companyId),
      where("lifeEndSort", ">=", format(startDate, "yyyy-MM-dd")),
      where("itemsCodes", "array-contains", itemCode)
    );

    const snapshot: QuerySnapshot<Parcel> = await getDocs(parcelModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data()).filter((parcel: Parcel) => parcel.lifeStartSort <= format(endDate, "yyyy-MM-dd"));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.getParcelsByCompanyAndPeriodAndItemCode", { companyId, startDate, endDate, itemCode }, error);
    return [];
  }
};
