import { RouteRecordRaw } from "vue-router";

export const vehicleExpenseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/vehicles/:vehicleId/expenses/:vehicleExpenseId/edit",
    name: "VehicleExpenseEdit",
    component: () => import("../views/VehicleExpenseEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vehicleExpense",
      requiredRight: "viewUI",
    },
  },
];
