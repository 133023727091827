import { DpiPurchase } from "../objects/DpiPurchase";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { isCodeUnique } from "./methods";

export class DpiPurchaseModel extends FirestoreModel<DpiPurchase> {
  public constructor() {
    super(() => new DpiPurchase(), "dpiPurchases", LockPolicy.DiscardUnsyncedChanges, "dpiPurchase");
  }

  public async getDocuments(): Promise<DpiPurchase[]> {
    return super.getDocuments([new SortCriteria("date", "desc", "date")]);
  }

  public async getDocument(dpiPurchaseId: string): Promise<DpiPurchase> {
    return super.getDocument(dpiPurchaseId);
  }

  public async createDocument(dpiPurchase: DpiPurchase): Promise<string> {
    return super.createDocument(dpiPurchase);
  }

  public async updateDocument(dpiPurchase: DpiPurchase): Promise<void> {
    return super.updateDocument(dpiPurchase);
  }

  public async deleteDocument(dpiPurchase: DpiPurchase): Promise<boolean> {
    return super.deleteDocument(dpiPurchase);
  }

  public async isCodeUnique(code: string, dpiPurchaseId?: string): Promise<boolean> {
    return isCodeUnique(code, dpiPurchaseId);
  }
}

export const dpiPurchaseModel: DpiPurchaseModel = new DpiPurchaseModel();
