export const it = {
  statHandleChart: {
    chartHandlesByDetail: "Movimentazioni per dettagli",
    chartHandlesByType: "Movimentazioni per formato UDC",
    chartStockByDetailStart: "Giacenza iniziale per dettagli",
    chartStockByTypeStart: "Giacenza iniziale per formato UDC",
    chartStockByDetailEnd: "Giacenza finale per dettagli",
    chartStockByTypeEnd: "Giacenza finale per formato UDC",
    company: "Azienda",
    inbounds: "Arrivi",
    parcel: "UDC",
    period: "Periodo di riferimento",
    name: "Grafici di movimentazione",
    noDetail: "Nessun dettaglio",
    noType: "Sconosciuto",
    outbounds: "Partenze",
  },
};
