import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { dpiHandlingModel } from "@/features/modules/dpiHandling/models/DpiHandlingModel";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { TargetType } from "@/features/modules/dpiHandling/objects/TargetType";

export class DpiDeliveredModel {
  public async calcDpiDelivered(startDate: Date, endDate: Date): Promise<StoredDpi[]> {
    try {
      const dpisDelivered: StoredDpi[] = [];

      const dpiHandlings: DpiHandling[] = await dpiHandlingModel.getDpiHandlingsByPeriod(startDate, endDate);

      for (const dpiHandling of dpiHandlings) {
        if (dpiHandling.targetType !== TargetType.Employee) continue;

        for (const handledDpi of dpiHandling.getHandledDpis()) {
          const storedDpi: StoredDpi | undefined = dpisDelivered.find(
            (storedDpi: StoredDpi) => storedDpi.dpi?.id === handledDpi.dpi?.id && storedDpi.size === handledDpi.size && handledDpi.size !== undefined
          );

          if (storedDpi === undefined) {
            const newStoredDpi: StoredDpi = new StoredDpi();
            newStoredDpi.dpi = handledDpi.dpi;
            newStoredDpi.quantity = handledDpi.quantity;
            newStoredDpi.size = handledDpi.size;
            dpisDelivered.push(newStoredDpi);
          } else {
            storedDpi.quantity += handledDpi.quantity;
          }
        }
      }

      return dpisDelivered;
    } catch (error: unknown) {
      appFaultModel.catchAppError("DpiDeliveredModel.calcDpiDelivered", { startDate, endDate }, error);
      return [];
    }
  }
}

export const dpiDeliveredModel: DpiDeliveredModel = new DpiDeliveredModel();
