import { Doc } from "../objects/Doc";
import { docModel } from "../models/DocModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedDocType } from "@/features/modules/docType/objects/LinkedDocType";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const docSearch: SearchModule = new SearchModule(
  "doc.doc",
  (firestoreDocument: FirestoreDocument) =>
    Promise.resolve(`${(firestoreDocument as Doc).name} (${((firestoreDocument as Doc).type as LinkedDocType).name})`),
  (firestoreDocument: FirestoreDocument) => `/docs/${firestoreDocument.id}`,
  "cog",
  docModel,
  [new SortCriteria("name", "asc", "string")]
);
