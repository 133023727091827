import { startOfMonth, endOfMonth } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";
import { StockChartData } from "../objects/StockChartData";

import { chartMonthlyStock, chartMonthlyStockByDetail, chartMonthlyStockByType, calcMonthlyStockPeakValue } from "./methods";

export class StockChartModel {
  public async calcStockChart(companyId: string, monthDate: Date, t: (entry: string) => string): Promise<StockChartData> {
    try {
      if (companyId == undefined) throw new Error("companyIdUndefined");
      if (monthDate == undefined) throw new Error("monthDateUndefined");

      const startDate: Date = startOfMonth(monthDate);
      const endDate: Date = endOfMonth(monthDate);

      const parcels: Parcel[] = await parcelModel.getParcelsByCompanyAndPeriod(companyId, startDate, endDate);

      const chartsData: StockChartData = {
        chartMonthlyStock: chartMonthlyStock(parcels, monthDate, t),
        chartMonthlyStockByDetail: chartMonthlyStockByDetail(parcels, monthDate, t),
        chartMonthlyStockByType: chartMonthlyStockByType(parcels, monthDate, t),
        monthlyStockPeakValue: calcMonthlyStockPeakValue(parcels, monthDate),
      };

      return chartsData;
    } catch (error: unknown) {
      appFaultModel.catchAppError("StockChartModel.calcStockChart", { companyId, monthDate, t }, error);
      return {
        chartMonthlyStock: {},
        chartMonthlyStockByDetail: {},
        chartMonthlyStockByType: {},
        monthlyStockPeakValue: 0,
      };
    }
  }
}

export const stockChartModel: StockChartModel = new StockChartModel();
