import { RouteRecordRaw } from "vue-router";

export const employeeCourseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/courses/:employeeCourseId/edit",
    name: "EmployeeCourseEdit",
    component: () => import("../views/EmployeeCourseEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeCourse",
      requiredRight: "viewUI",
    },
  },
];
