import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedParcelType } from "@/features/modules/parcelType/objects/LinkedParcelType";
import { Parcel } from "./Parcel";
import { ParcelItem } from "./ParcelItem";
import { ParcelState } from "./ParcelState";

import { ArrayField, EnumField, NumberWithDefaultField, ObjectField, StringArrayField, StringField } from "@/core/fields";

export class LinkedParcel extends LinkedFirestoreDocument {
  public type: LinkedParcelType | undefined = undefined;
  public code: string | undefined = undefined;
  public items: ParcelItem[] = [];
  public itemsCodes: string[] = [];
  public state: ParcelState = ParcelState.Loosened;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromParcel(parcel: Parcel): LinkedParcel {
    const linkedParcel: LinkedParcel = new LinkedParcel();
    linkedParcel.id = parcel.id;
    linkedParcel.type = parcel.type;
    linkedParcel.code = parcel.code;
    linkedParcel.items = parcel.items;
    linkedParcel.itemsCodes = parcel.itemsCodes;
    linkedParcel.state = parcel.state;

    return linkedParcel;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedParcel {
    super.fromFirestore(data);

    this.type = ObjectField.fromFirestore<LinkedParcelType>(data.type, (value) => new LinkedParcelType(value));
    this.code = StringField.fromFirestore(data.code);
    this.items = ArrayField.fromFirestore<ParcelItem>(data.items, (value) => new ParcelItem(value));
    this.itemsCodes = StringArrayField.fromFirestore(data.itemsCodes);
    this.state = EnumField.fromFirestore<ParcelState>(data.state, Object.values(ParcelState), ParcelState.Loosened);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.type = ObjectField.toFirestore<LinkedParcelType>(this.type, (value) => value.toFirestore());
    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.items = ArrayField.toFirestore<ParcelItem>(this.items, (value) => value.toFirestore());
    firestoreData.itemsCodes = ArrayField.toFirestore<ParcelItem>(this.items, (value) => value.code);
    firestoreData.state = EnumField.toFirestore<ParcelState>(this.state, ParcelState.Loosened);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }
}
