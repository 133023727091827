import { Doc } from "../objects/Doc";
import { DocOwner } from "../objects/DocOwner";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { EmployeeExpiration } from "@/features/modules/employee/objects/EmployeeExpiration";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import {
  getDocsByOwner,
  getDocsByPeriod,
  getDocsByPeriodAndOwner,
  getDocsInForceByOwner,
  getExpiredSourceDocs,
  getExpiringSourceDocs,
  getMissingSourceDocs,
} from "./methods";

export class DocModel extends FirestoreModel<Doc> {
  public constructor() {
    super(() => new Doc(), "docs", LockPolicy.DiscardUnsyncedChanges, "doc");
  }

  public async getDocuments(): Promise<Doc[]> {
    return super.getDocuments();
  }

  public async getDocument(docId: string): Promise<Doc> {
    return super.getDocument(docId);
  }

  public async createDocument(doc: Doc): Promise<string> {
    return super.createDocument(doc);
  }

  public async updateDocument(doc: Doc): Promise<void> {
    return super.updateDocument(doc);
  }

  public async deleteDocument(doc: Doc): Promise<boolean> {
    return super.deleteDocument(doc);
  }

  public async getDocsByOwner(owner: DocOwner): Promise<Doc[]> {
    return getDocsByOwner(owner);
  }

  public async getDocsByPeriod(startDate: Date, endDate: Date): Promise<Doc[]> {
    return getDocsByPeriod(startDate, endDate);
  }

  public async getDocsByPeriodAndOwner(startDate: Date, endDate: Date, owner: DocOwner): Promise<Doc[]> {
    return getDocsByPeriodAndOwner(startDate, endDate, owner);
  }

  public async getDocsInForceByOwner(owner: DocOwner): Promise<Doc[]> {
    return getDocsInForceByOwner(owner);
  }

  public async getExpiredSourceDocs(source: DocTypeSource): Promise<EmployeeExpiration[]> {
    return getExpiredSourceDocs(source);
  }

  public async getExpiringSourceDocs(source: DocTypeSource): Promise<EmployeeExpiration[]> {
    return getExpiringSourceDocs(source);
  }

  public async getMissingSourceDocs(source: DocTypeSource): Promise<EmployeeExpiration[]> {
    return getMissingSourceDocs(source);
  }
}

export const docModel: DocModel = new DocModel();
