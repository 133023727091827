export const it = {
  statCompanyStorage: {
    code: "Codice",
    company: "Azienda",
    companyStoragePaper: "Resoconto su conteggio materiale",
    date: "Data",
    name: "Conteggio materiale",
    totalQuantity: "Totale",
  },
};
