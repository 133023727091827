import { DocumentReference } from "firebase/firestore";
import * as _ from "lodash";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "../InboundShipmentModel";
import { LinkedInboundShipment } from "@/features/modules/inboundShipment/objects/LinkedInboundShipment";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { ParcelData } from "@/features/modules/parcel/objects/ParcelData";
import { ParcelItem } from "@/features/modules/parcel/objects/ParcelItem";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";

export const generateParcels = async (inboundShipment: InboundShipment): Promise<InboundShipment> => {
  try {
    const previousParcels: Parcel[] = await parcelModel.getParcelsByInboundShipment(inboundShipment.id);
    const codeSet: string[] = [];

    for (const parcelData of inboundShipment.parcelsData) {
      const parcelWithData: Parcel | undefined = checkIfParcelDataExists(parcelData, previousParcels);

      if (parcelWithData !== undefined) {
        // remove found parcel
        const index = previousParcels.indexOf(parcelWithData, 0);
        if (index > -1) {
          previousParcels.splice(index, 1);
        }
      } else {
        // create new parcel
        const newCode: string = await parcelModel.generateCode(codeSet);
        const parcel: Parcel = createParcelWithData(parcelData, inboundShipment, newCode);

        codeSet.push(newCode);
        const linkedParcel: LinkedParcel = LinkedParcel.createFromParcel(parcel);
        linkedParcel.order = inboundShipment.getLinkedParcels().length + 1;
        inboundShipment.addLinkedParcel(linkedParcel);
      }
    }

    batch.set(inboundShipmentModel.getDocumentReference(inboundShipment.id), inboundShipment.toFirestore());

    await batch.commit();

    return inboundShipment;
  } catch (error: unknown) {
    appFaultModel.catchAppError("InboundShipmentModel.generateParcels", { inboundShipment }, error);
    return new InboundShipment();
  }
};

const checkIfParcelDataExists = (parcelData: ParcelData, previousParcels: Parcel[]): Parcel | undefined => {
  for (const previousParcel of previousParcels) {
    if (parcelData.type?.id === previousParcel.type?.id) {
      const parcelDataItems: Record<string, number> = {};
      for (const parcelDataItem of parcelData.items) {
        parcelDataItems[parcelDataItem.code as string] = parcelDataItem.quantity;
      }

      const previousParcelItems: Record<string, unknown> = {};
      for (const previousParcelItem of previousParcel.items) {
        previousParcelItems[previousParcelItem.code as string] = previousParcelItem.quantity;
      }

      if (_.isEqual(parcelDataItems, previousParcelItems)) return previousParcel;
    }
  }
  return undefined;
};

const createParcelWithData = (parcelData: ParcelData, inboundShipment: InboundShipment, newCode: string): Parcel => {
  const linkedInboundShipment: LinkedInboundShipment = LinkedInboundShipment.createFromInboundShipment(inboundShipment);

  const parcelRef: DocumentReference = parcelModel.getDocumentReference();
  const parcel: Parcel = new Parcel(undefined, parcelRef.id);
  parcel.code = newCode;
  parcel.inboundShipment = linkedInboundShipment;
  parcel.type = parcelData.type;
  parcel.detail = inboundShipment.detail;

  for (const parcelDataItem of parcelData.items) {
    const parcelItem: ParcelItem = new ParcelItem();
    parcelItem.code = parcelDataItem.code;
    parcelItem.quantity = parcelDataItem.quantity;
    parcel.items.push(parcelItem);
    if (parcelItem.code != undefined && !parcel.itemsCodes.includes(parcelItem.code)) {
      parcel.itemsCodes.push(parcelItem.code);
    }
  }

  parcel.addAction(ParcelActionType.Created, inboundShipment.code);

  parcel.setSearchKeys();
  parcel.setTimestampFields("create");
  batch.set(parcelRef, parcel.toFirestore());

  return parcel;
};
