import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "../InboundShipmentModel";

export const getInboundShipmentByCode = async (code: string): Promise<InboundShipment | undefined> => {
  try {
    const snapshotQuery: Query<InboundShipment> = query(
      inboundShipmentModel.getPathReference().withConverter(inboundShipmentModel.firestoreConverter),
      where("code", "==", code)
    );

    const snapshot: QuerySnapshot<InboundShipment> = await getDocs(inboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("InboundShipmentModel.getInboundShipmentByCode", { code }, error);
    return new InboundShipment();
  }
};
