import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class CompanyHelpers {
  public static getDocFolderPath(companyId: string | undefined): string {
    try {
      if (companyId === undefined) throw new Error("companyId is undefined");
      return `companies/${companyId}/docs/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyHelpers.getDocFolderPath", { companyId }, error);
      throw error;
    }
  }
}
