import { DocumentReference } from "firebase/firestore";
import { getMonth, getYear, startOfMonth, subMonths } from "date-fns";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { InvoiceItem } from "./InvoiceItem";
import { InvoicePayment } from "./InvoicePayment";
import { InvoiceState } from "./InvoiceState";

import { ArrayField, DateStrictField, EnumField, NumberField, ObjectField, StringField } from "@/core/fields";

export class Invoice extends FirestoreDocument {
  public monthDate: Date = startOfMonth(subMonths(new Date(), 1));
  public month: number = getMonth(this.monthDate) + 1;
  public year: number = getYear(this.monthDate);
  public company: LinkedCompany | undefined = undefined;
  public items: InvoiceItem[] = [];
  public total = 0;
  public paid = 0;
  public payments: InvoicePayment[] = [];
  public state: InvoiceState = InvoiceState.Draft;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Invoice {
    super.fromFirestore(data, id, firestoreRef);

    this.monthDate = DateStrictField.fromFirestore(data.monthDate, startOfMonth(new Date()));
    this.month = NumberField.fromFirestore(data.month);
    this.year = NumberField.fromFirestore(data.year);
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.items = ArrayField.fromFirestore<InvoiceItem>(data.items, (value) => new InvoiceItem(value));
    this.total = NumberField.fromFirestore(data.total);
    this.paid = NumberField.fromFirestore(data.paid);
    this.payments = ArrayField.fromFirestore<InvoicePayment>(data.payments, (value) => new InvoicePayment(value));
    this.state = EnumField.fromFirestore<InvoiceState>(data.state, Object.values(InvoiceState), InvoiceState.Draft);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.monthDate = DateStrictField.toFirestore(this.monthDate);
    firestoreData.month = getMonth(this.monthDate) + 1;
    firestoreData.year = getYear(this.monthDate);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.items = ArrayField.toFirestore<InvoiceItem>(this.items, (value) => value.toFirestore());
    firestoreData.total = NumberField.toFirestore(this.total);
    firestoreData.paid = NumberField.toFirestore(this.paid);
    firestoreData.payments = ArrayField.toFirestore<InvoicePayment>(this.payments, (value) => value.toFirestore());
    firestoreData.state = EnumField.toFirestore<InvoiceState>(this.state, InvoiceState.Draft);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }
}
