export const it = {
  employeeWarning: {
    closingDate: "Data chiusura",
    date: "Data richiamo",
    dateError: "Selezionare una data valida",
    employeeWarning: "Richiamo",
    employeeWarningOf: "Richiamo di {parent}",
    employeeWarnings: "Richiami",
    employeeWarningsOf: "Richiami di {parent}",
    isSuspended: "Sospeso",
    justificationDate: "Data giustificazione",
    meanType: "Tipo di notifica",
    meanTypeError: "Selezionare un tipo di notifica valido",
    meanTypes: {
      certifiedMail: "Posta certificata",
      handDelivered: "Consegna a mano",
      registeredLetter: "Posta raccomandata A.R.",
    },
    notes: "Note",
    openEmployeeWarnings: "Richiami aperti",
    penaltyDetail: "Dettaglio sanzione",
    penaltyType: "Sanzione",
    penaltyTypeError: "Selezionare un tipo sanzione valido",
    preview: "Anteprima",
    reason: "Motivo",
    receptionDate: "Data ricezione",
    state: "Stato",
    states: {
      closed: "Chiuso",
      open: "Aperto",
    },
    suspensionEnd: "Fine sospensione",
    suspensionEndError: "Periodo non valido o sospensione già presente",
    suspensionStart: "Inizio sospensione",
    suspensionStartError: "Periodo non valido o sospensione già presente",
    type: "Tipo richiamo",
    typeError: "Selezionare un tipo richiamo valido",
  },
  buttons: {
    goToEmployeeWarnings: "Vai ai richiami dell'operatore",
  },
};
