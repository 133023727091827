import { RouteRecordRaw } from "vue-router";

export const storedDpiRoutes: Array<RouteRecordRaw> = [
  {
    path: "/stored-dpis",
    name: "StoredDpiList",
    component: () => import("../views/StoredDpiList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "storedDpi",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/stored-dpis/:id",
    name: "StoredDpiView",
    component: () => import("../views/StoredDpiView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "storedDpi",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/stored-dpis/:id/edit",
    name: "StoredDpiEdit",
    component: () => import("../views/StoredDpiEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "storedDpi",
      requiredRight: "viewUI",
    },
  },
];
