import { BranchPosition } from "../objects/BranchPosition";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class BranchPositionModel extends FirestoreModel<BranchPosition> {
  public constructor() {
    super(() => new BranchPosition(), "branchPositions", LockPolicy.DiscardUnsyncedChanges, "branchPosition");
    this.parentCollectionName = "branches";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<BranchPosition[]> {
    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(branchPositionId: string, parentId?: string): Promise<BranchPosition> {
    return super.getDocument(branchPositionId, parentId);
  }

  public async createDocument(branchPosition: BranchPosition, parentId?: string): Promise<string> {
    return super.createDocument(branchPosition, parentId);
  }

  public async updateDocument(branchPosition: BranchPosition, parentId?: string): Promise<void> {
    return super.updateDocument(branchPosition, parentId);
  }

  public async deleteDocument(branchPosition: BranchPosition, parentId?: string): Promise<boolean> {
    return super.deleteDocument(branchPosition, parentId);
  }
}

export const branchPositionModel: BranchPositionModel = new BranchPositionModel();
