import { DocType } from "./DocType";
import { DocTypeSource } from "./DocTypeSource";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { ArrayField, StringField } from "@/core/fields";

export class LinkedDocType extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;
  public availableFor: DocTypeSource[] = [];
  public mandatoryFor: DocTypeSource[] = [];
  public visibleFor: DocTypeSource[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromDocType(docType: DocType): LinkedDocType {
    const linkedDocType: LinkedDocType = new LinkedDocType();
    linkedDocType.id = docType.id;
    linkedDocType.name = docType.name;
    linkedDocType.availableFor = docType.availableFor;
    linkedDocType.mandatoryFor = docType.mandatoryFor;
    linkedDocType.visibleFor = docType.visibleFor;

    return linkedDocType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedDocType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.availableFor = ArrayField.fromFirestore<DocTypeSource>(data.availableFor, (value) => value as unknown as DocTypeSource);
    this.mandatoryFor = ArrayField.fromFirestore<DocTypeSource>(data.mandatoryFor, (value) => value as unknown as DocTypeSource);
    this.visibleFor = ArrayField.fromFirestore<DocTypeSource>(data.visibleFor, (value) => value as unknown as DocTypeSource);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.availableFor = ArrayField.toFirestore<DocTypeSource>(this.availableFor, (value) => value as string);
    firestoreData.mandatoryFor = ArrayField.toFirestore<DocTypeSource>(this.mandatoryFor, (value) => value as string);
    firestoreData.visibleFor = ArrayField.toFirestore<DocTypeSource>(this.visibleFor, (value) => value as string);

    return firestoreData;
  }
}
