export const it = {
  leaveType: {
    leaveType: "Tipo di assenza",
    leaveTypes: "Tipi di assenza",
    name: "Nome",
    nameError: "Inserire un nome valido",
  },
  navigation: {
    leaveType: "Tipi di assenza",
  },
};
