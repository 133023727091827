import { RouteRecordRaw } from "vue-router";

export const aedProductRoutes: Array<RouteRecordRaw> = [
  {
    path: "/aed-products",
    name: "AedProductList",
    component: () => import("../views/AedProductList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedProduct",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-products/:id/edit",
    name: "AedProductEdit",
    component: () => import("../views/AedProductEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedProduct",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-products/:id",
    name: "AedProductView",
    component: () => import("../views/AedProductView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedProduct",
      requiredRight: "viewUI",
    },
  },
];
