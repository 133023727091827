import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { StringField } from "@/core/fields";

export class Referent {
  public id: string = DataHelpers.uniqueId();
  public position: string | undefined = undefined;
  public name: string | undefined = undefined;
  public phone: string | undefined = undefined;
  public mobile: string | undefined = undefined;
  public email: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): Referent {
    this.id = DataHelpers.uniqueId();

    this.position = StringField.fromFirestore(data.position);
    this.name = StringField.fromFirestore(data.name);
    this.phone = StringField.fromFirestore(data.phone);
    this.mobile = StringField.fromFirestore(data.mobile);
    this.email = StringField.fromFirestore(data.email);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.position = StringField.toFirestore(this.position);
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.mobile = StringField.toFirestore(this.mobile);
    firestoreData.email = StringField.toFirestore(this.email);

    return firestoreData;
  }
}
