import { RouteRecordRaw } from "vue-router";

export const vehicleRoutes: Array<RouteRecordRaw> = [
  {
    path: "/vehicles",
    name: "VehicleList",
    component: () => import("../views/VehicleList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vehicle",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/vehicles/:id/:section?",
    name: "VehicleView",
    component: () => import("../views/VehicleView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vehicle",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/vehicles/:id/edit",
    name: "VehicleEdit",
    component: () => import("../views/VehicleEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vehicle",
      requiredRight: "viewUI",
    },
  },
];
