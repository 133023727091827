export const it = {
  employeeDpi: {
    employeeDpi: "DPI",
    employeeDpiOf: "DPI di {parent}",
    employeeDpis: "DPI",
    employeeDpisOf: "DPI di {parent}",
    name: "Nome",
    quantity: "Quantità",
    size: "Taglia",
  },
};
