import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";
import { OutboundShipment } from "../objects/OutboundShipment";
import { OutboundShipmentItem } from "../objects/OutboundShipmentItem";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";
import { ParcelState } from "@/features/modules/parcel/objects/ParcelState";

export class OutboundShipmentHelpers {
  public static calcQuantities(
    outboundShipment: OutboundShipment,
    companyParcels: Parcel[],
    candidateParcels: LinkedParcel[]
  ): OutboundShipmentItem[] {
    try {
      return outboundShipment.items.map((item: OutboundShipmentItem) => {
        item.selectedQuantity = 0;
        item.availableQuantity = 0;

        for (const parcel of outboundShipment.getLinkedParcels()) {
          for (const parcelItem of parcel.items) {
            if (parcelItem.code === item.code) item.selectedQuantity += parcelItem.quantity;
          }
        }
        for (const parcel of candidateParcels) {
          for (const parcelItem of parcel.items) {
            if (parcelItem.code === item.code) item.selectedQuantity += parcelItem.quantity;
          }
        }

        const selectedParcelsCodes: string[] = outboundShipment.getLinkedParcels().map((parcel: LinkedParcel) => parcel.code as string);

        const availableParcels: Parcel[] = companyParcels.filter(
          (parcel: Parcel) => parcel.itemsCodes.includes(item.code as string) && !selectedParcelsCodes.includes(parcel.code as string)
        );

        for (const availableParcel of availableParcels) {
          for (const parcelItem of availableParcel.items) {
            if (parcelItem.code === item.code) item.availableQuantity += parcelItem.quantity;
          }
        }

        return item;
      });
    } catch (error: unknown) {
      appFaultModel.catchAppError("OutboundShipmentHelpers.calcQuantities", { outboundShipment, companyParcels, candidateParcels }, error);
      return [];
    }
  }

  public static async checkParcels(outboundShipment: OutboundShipment): Promise<boolean> {
    try {
      for (const linkedParcel of outboundShipment.getLinkedParcels()) {
        const parcel: Parcel = await parcelModel.getDocument(linkedParcel.id);
        if (parcel === undefined) {
          console.log(`Parcel #${linkedParcel.id} not found`);
          return false;
        }
        if (parcel.outboundShipment === undefined) {
          if (parcel.state !== ParcelState.Loosened && parcel.state !== ParcelState.Stored) {
            console.log(`Parcel state not valid (${parcel.state}) for parcel #${parcel.id} (${parcel.code})`);
            return false;
          }
        } else if (outboundShipment.id === "new" || parcel.outboundShipment.id !== outboundShipment.id) {
          console.log(`Parcel outboundShipment not valid for parcel #${parcel.id} (${parcel.code})`);
          return false;
        }
      }
      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("OutboundShipmentHelpers.checkParcels", { outboundShipment }, error);
      return false;
    }
  }
}
