import { Branch } from "@/features/modules/branch/objects/Branch";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";

export class DpiStockPaper extends DocumentPaper {
  private branches: Branch[];
  private results: StoredDpi[];

  constructor(
    results: StoredDpi[],
    branches: Branch[],
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.results = results;
    this.branches = branches;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      dpiStockDetail: { fontSize: 10 },
      dpiStockDpiCell: { alignment: "center", fontSize: 8 },
      dpiStockDpiCellLeft: { alignment: "left", fontSize: 8 },
      dpiStockDpiHead: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(): Promise<Record<string, unknown>[]> {
    await this.setStructure(this.t("statDpiStock.name").toLocaleUpperCase());

    this.writeDetails();

    this.writeDpiTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeDetails(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: DataHelpers.mmToPoints(40),
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "dpiStockDetail",
              text: `${this.t("statDpiStock.includedBranches").toLocaleUpperCase()}:`,
            },
          ],
        },
        {
          width: "*",
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "dpiStockDetail",
              text: this.branches.length > 0 ? this.branches.map((branch) => branch.name ?? "-").join(", ") : "-",
            },
          ],
        },
      ],
    });
  }

  private writeDpiTable(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "dpiStockDpiHead", text: this.t("dpi.name").toLocaleUpperCase() },
        { style: "dpiStockDpiHead", text: this.t("storedDpi.size").toLocaleUpperCase() },
        { style: "dpiStockDpiHead", text: this.t("storedDpi.quantity").toLocaleUpperCase() },
      ],
    ];

    for (const result of this.results) {
      const row: unknown[] = [
        { style: "dpiStockDpiCellLeft", text: result.dpi?.name ?? "-" },
        { style: "dpiStockDpiCell", text: result.size ?? "-" },
        { style: "dpiStockDpiCell", text: result.quantity ? this.n(result.quantity, "number0") : "-" },
      ];

      dataTableBody.push(row);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "dpiStockDpiCell", text: this.t("gen.noResults") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(25)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
