import { Device } from "zebra-print";

import { Parcel } from "@/features/modules/parcel/objects/Parcel";

import { connect, createZplLabelFromParcel, getState, printLabel, setState } from "./methods";

export class ZebraPrintModel {
  public device: Device | undefined = undefined;

  public connect(): void {
    return connect();
  }

  public createZplLabelFromParcel(parcel: Parcel, d: (date: Date, format: string) => string, withBorders = false): string {
    return createZplLabelFromParcel(parcel, d, withBorders);
  }
  public getState(): "connected" | "disconnected" {
    return getState();
  }

  public printLabel(zplLabel: string): void {
    return printLabel(zplLabel);
  }

  public setState(state: "connected" | "disconnected"): void {
    return setState(state);
  }
}

export const zebraPrintModel: ZebraPrintModel = new ZebraPrintModel();
