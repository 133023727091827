import { activityLocaleModule } from "@/features/modules/activity/locales/module";
import { aedCompanyLocaleModule } from "@/features/modules/aedCompany/locales/module";
import { aedProductLocaleModule } from "@/features/modules/aedProduct/locales/module";
import { aedPurchaseLocaleModule } from "@/features/modules/aedPurchase/locales/module";
import { aedSaleLocaleModule } from "@/features/modules/aedSale/locales/module";
import { assignmentLocaleModule } from "@/features/modules/assignment/locales/module";
import { bankLocaleModule } from "@/features/modules/bank/locales/module";
import { branchDpiLocaleModule } from "@/features/modules/branchDpi/locales/module";
import { branchLocaleModule } from "@/features/modules/branch/locales/module";
import { branchPositionLocaleModule } from "@/features/modules/branchPosition/locales/module";
import { branchShiftTypeLocaleModule } from "@/features/modules/branchShiftType/locales/module";
import { companyLocaleModule } from "@/features/modules/company/locales/module";
import { configurationLocaleModule } from "@/features/modules/configuration/locales/module";
import { courseTypeLocaleModule } from "@/features/modules/courseType/locales/module";
import { docFolderLocaleModule } from "@/features/modules/docFolder/locales/module";
import { docLocaleModule } from "@/features/modules/doc/locales/module";
import { docTypeLocaleModule } from "@/features/modules/docType/locales/module";
import { dpiAlertLocaleModule } from "@/features/modules/dpiAlert/locales/module";
import { dpiCategoryLocaleModule } from "@/features/modules/dpiCategory/locales/module";
import { dpiHandlingLocaleModule } from "@/features/modules/dpiHandling/locales/module";
import { dpiLocaleModule } from "@/features/modules/dpi/locales/module";
import { dpiPurchaseLocaleModule } from "@/features/modules/dpiPurchase/locales/module";
import { employeeContractLocaleModule } from "@/features/modules/employeeContract/locales/module";
import { employeeCourseLocaleModule } from "@/features/modules/employeeCourse/locales/module";
import { employeeDpiLocaleModule } from "@/features/modules/employeeDpi/locales/module";
import { employeeLeaveLocaleModule } from "@/features/modules/employeeLeave/locales/module";
import { employeeLocaleModule } from "@/features/modules/employee/locales/module";
import { employeeMedicalExamLocaleModule } from "@/features/modules/employeeMedicalExam/locales/module";
import { employeePositionLocaleModule } from "@/features/modules/employeePosition/locales/module";
import { employeeRosterLocaleModule } from "@/features/modules/employeeRoster/locales/module";
import { employeeTransactionLocaleModule } from "@/features/modules/employeeTransaction/locales/module";
import { employeeWarningLocaleModule } from "@/features/modules/employeeWarning/locales/module";
import { expenseTypeLocaleModule } from "@/features/modules/expenseType/locales/module";
import { forkliftLocaleModule } from "@/features/modules/forklift/locales/module";
import { inboundShipmentLocaleModule } from "@/features/modules/inboundShipment/locales/module";
import { invoiceLocaleModule } from "@/features/modules/invoice/locales/module";
import { leaveTypeLocaleModule } from "@/features/modules/leaveType/locales/module";
import { medicalExamTypeLocaleModule } from "@/features/modules/medicalExamType/locales/module";
import { outboundShipmentLocaleModule } from "@/features/modules/outboundShipment/locales/module";
import { parcelLocaleModule } from "@/features/modules/parcel/locales/module";
import { parcelDetailLocaleModule } from "@/features/modules/parcelDetail/locales/module";
import { parcelTypeLocaleModule } from "@/features/modules/parcelType/locales/module";
import { penaltyTypeLocaleModule } from "@/features/modules/penaltyType/locales/module";
import { priceListLocaleModule } from "@/features/modules/priceList/locales/module";
import { rawClockingLocaleModule } from "@/features/modules/rawClocking/locales/module";
import { referentLocaleModule } from "@/features/modules/referent/locales/module";
import { storedDpiLocaleModule } from "@/features/modules/storedDpi/locales/module";
import { timekeeperLocaleModule } from "@/features/modules/timekeeper/locales/module";
import { transactionTypeLocaleModule } from "@/features/modules/transactionType/locales/module";
import { transportTypeLocaleModule } from "@/features/modules/transportType/locales/module";
import { vehicleExpenseLocaleModule } from "@/features/modules/vehicleExpense/locales/module";
import { vehicleLocaleModule } from "@/features/modules/vehicle/locales/module";
import { vehiclePositionLocaleModule } from "@/features/modules/vehiclePosition/locales/module";
import { vehicleTypeLocaleModule } from "@/features/modules/vehicleType/locales/module";
import { warehouseLocationLocaleModule } from "@/features/modules/warehouseLocation/locales/module";
import { warningTypeLocaleModule } from "@/features/modules/warningType/locales/module";
import { zebraPrintLocaleModule } from "@/features/modules/zebraPrint/locales/module";

// stat
import { statModuleLocaleModule } from "@/features/modules/stat/locales/module";
import { statBranchMonthReportLocaleModule } from "@/features/modules/stat/modules/branchMonthReport/locales/module";
import { statBranchWithEmployeeLocaleModule } from "@/features/modules/stat/modules/branchWithEmployee/locales/module";
import { statCompanyStockLocaleModule } from "@/features/modules/stat/modules/companyStock/locales/module";
import { statCompanyStorageLocaleModule } from "@/features/modules/stat/modules/companyStorage/locales/module";
import { statDpiDeliveredLocaleModule } from "@/features/modules/stat/modules/dpiDelivered/locales/module";
import { statDpiStockLocaleModule } from "@/features/modules/stat/modules/dpiStock/locales/module";
import { statEmployeeMonthReportLocaleModule } from "@/features/modules/stat/modules/employeeMonthReport/locales/module";
import { statHandleChartLocaleModule } from "@/features/modules/stat/modules/handleChart/locales/module";
import { statHandleCodeLocaleModule } from "@/features/modules/stat/modules/handleCode/locales/module";
import { statInvoiceByYearLocaleModule } from "@/features/modules/stat/modules/invoiceByYear/locales/module";
import { statItemCodeLocaleModule } from "@/features/modules/stat/modules/itemCode/locales/module";
import { statStockChartLocaleModule } from "@/features/modules/stat/modules/stockChart/locales/module";

// utility
import { utilityModuleLocaleModule } from "@/features/modules/utility/locales/module";
import { utilityItemCodeLocaleModule } from "@/features/modules/utility/modules/itemCode/locales/module";
import { utilityMoveWarehouseLocationLocaleModule } from "@/features/modules/utility/modules/moveWarehouseLocation/locales/module";

export const featuresLocales = [
  activityLocaleModule,
  aedCompanyLocaleModule,
  aedProductLocaleModule,
  aedPurchaseLocaleModule,
  aedSaleLocaleModule,
  assignmentLocaleModule,
  bankLocaleModule,
  branchDpiLocaleModule,
  branchLocaleModule,
  branchPositionLocaleModule,
  branchShiftTypeLocaleModule,
  companyLocaleModule,
  configurationLocaleModule,
  courseTypeLocaleModule,
  docFolderLocaleModule,
  docLocaleModule,
  docTypeLocaleModule,
  dpiAlertLocaleModule,
  dpiCategoryLocaleModule,
  dpiHandlingLocaleModule,
  dpiLocaleModule,
  dpiPurchaseLocaleModule,
  employeeContractLocaleModule,
  employeeCourseLocaleModule,
  employeeDpiLocaleModule,
  employeeLeaveLocaleModule,
  employeeLocaleModule,
  employeeMedicalExamLocaleModule,
  employeePositionLocaleModule,
  employeeRosterLocaleModule,
  employeeTransactionLocaleModule,
  employeeWarningLocaleModule,
  expenseTypeLocaleModule,
  forkliftLocaleModule,
  inboundShipmentLocaleModule,
  invoiceLocaleModule,
  leaveTypeLocaleModule,
  medicalExamTypeLocaleModule,
  outboundShipmentLocaleModule,
  parcelLocaleModule,
  parcelDetailLocaleModule,
  parcelTypeLocaleModule,
  penaltyTypeLocaleModule,
  priceListLocaleModule,
  rawClockingLocaleModule,
  referentLocaleModule,
  storedDpiLocaleModule,
  timekeeperLocaleModule,
  transactionTypeLocaleModule,
  transportTypeLocaleModule,
  vehicleLocaleModule,
  vehicleExpenseLocaleModule,
  vehiclePositionLocaleModule,
  vehicleTypeLocaleModule,
  warehouseLocationLocaleModule,
  warningTypeLocaleModule,
  zebraPrintLocaleModule,

  statModuleLocaleModule,
  statBranchMonthReportLocaleModule,
  statBranchWithEmployeeLocaleModule,
  statCompanyStockLocaleModule,
  statCompanyStorageLocaleModule,
  statDpiDeliveredLocaleModule,
  statDpiStockLocaleModule,
  statEmployeeMonthReportLocaleModule,
  statHandleChartLocaleModule,
  statHandleCodeLocaleModule,
  statInvoiceByYearLocaleModule,
  statItemCodeLocaleModule,
  statStockChartLocaleModule,

  utilityModuleLocaleModule,
  utilityItemCodeLocaleModule,
  utilityMoveWarehouseLocationLocaleModule,
];
