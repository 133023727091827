import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doc } from "@/features/modules/doc/objects/Doc";
import { docModel } from "../DocModel";

export const getDocsByPeriod = async (startDate: Date, endDate: Date): Promise<Doc[]> => {
  try {
    if (startDate === undefined || endDate === undefined) throw new Error("invalid parameters");

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const snapshotQuery: Query<Doc> = query(
      docModel.getPathReference().withConverter(docModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate))
    );

    const snapshot: QuerySnapshot<Doc> = await getDocs(docModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("DocModel.getDocsByPeriod", { startDate, endDate }, error);
    return [];
  }
};
