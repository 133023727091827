import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { dpiHandlingModel } from "../DpiHandlingModel";

export const getDpiHandlingsByPeriod = async (startDate: Date, endDate: Date): Promise<DpiHandling[]> => {
  try {
    if (startDate === undefined || endDate === undefined) throw new Error("Invalid period");

    const snapshotQuery: Query<DpiHandling> = query(
      dpiHandlingModel.getPathReference().withConverter(dpiHandlingModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate))
    );

    const snapshot: QuerySnapshot<DpiHandling> = await getDocs(dpiHandlingModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.getDpiHandlingsByPeriod", { startDate, endDate }, error);
    return [];
  }
};
