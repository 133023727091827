import { addDays, compareAsc, endOfMonth, format, getDate, startOfMonth } from "date-fns";

import { BranchCounter } from "../objects/BranchCounter";
import { BranchEmployeeReport } from "../objects/BranchEmployeeReport";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { employeePositionModel } from "@/features/modules/employeePosition/models/EmployeePositionModel";
import { EmployeeRoster } from "@/features/modules/employeeRoster/objects/EmployeeRoster";
import { employeeRosterModel } from "@/features/modules/employeeRoster/models/EmployeeRosterModel";
import { Shift } from "@/features/modules/employeeRoster/objects/Shift";

export class BranchMonthReportModel {
  public async createReport(branchId: string, monthDate: Date): Promise<{ report: BranchEmployeeReport[]; counters: BranchCounter }> {
    try {
      if (branchId === undefined) throw new Error("branchUndefined");
      if (monthDate === undefined) throw new Error("monthUndefined");

      const branchEmployeeReports: BranchEmployeeReport[] = [];
      const branchCounter: BranchCounter = new BranchCounter();

      // find all possible employee positions
      const employeePositions: EmployeePosition[] = await employeePositionModel.getEmployeePositionsByBranch(branchId);

      // find all the employees working there in that month at least one day
      const employees: Employee[] = [];
      let startDate: Date = startOfMonth(monthDate);
      const endDate: Date = endOfMonth(monthDate);
      while (compareAsc(startDate, endDate) <= 0) {
        const dateSort: string = format(startDate, "yyyy-MM-dd");
        for (const employeePosition of employeePositions) {
          if (employeePosition.parentId === undefined) continue;

          // employee already present
          if (employees.find((employee) => employee.id === employeePosition.parentId) !== undefined) continue;

          if (dateSort >= employeePosition.fromSort && dateSort <= employeePosition.toSort) {
            employees.push(await employeeModel.getDocument(employeePosition.parentId));
          }
        }
        startDate = addDays(startDate, 1);
      }

      for (const employee of employees) {
        const branchEmployeeReport: BranchEmployeeReport = new BranchEmployeeReport(employee);

        const employeeRoster: EmployeeRoster | undefined = await employeeRosterModel.getEmployeeRosterByEmployeeAndMonthAndYear(
          employee.id,
          monthDate.getMonth() + 1,
          monthDate.getFullYear()
        );

        if (employeeRoster === undefined) continue;

        startDate = startOfMonth(monthDate);
        const endDate: Date = endOfMonth(monthDate);
        while (compareAsc(startDate, endDate) <= 0) {
          const day: string = getDate(startDate).toFixed();
          if (day in employeeRoster.shifts) {
            const shift: Shift = employeeRoster.shifts[day];
            shift.counters.calculateCounters(shift, startDate, branchId);
          }

          startDate = addDays(startDate, 1);
        }

        // TODOM3: farlo sempre nella funzione, qui è superfluo
        // employeeRoster.counters.calculateCounters(getYear(monthDate), getMonth(monthDate) + 1, employeeRoster.shifts);

        branchEmployeeReport.counters = employeeRoster.counters;
        branchEmployeeReports.push(branchEmployeeReport);

        branchCounter.totalHours += employeeRoster.counters.totalHours;
        branchCounter.overtimeHours += employeeRoster.counters.overtimeHours;
      }

      return { report: branchEmployeeReports, counters: branchCounter };
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}

export const branchMonthReportModel: BranchMonthReportModel = new BranchMonthReportModel();
