export const it = {
  branchDpi: {
    branchDpi: "DPI",
    branchDpiOf: "DPI di {parent}",
    branchDpis: "DPI",
    branchDpisOf: "DPI di {parent}",
    name: "Nome",
    quantity: "Quantità",
    size: "Taglia",
  },
};
