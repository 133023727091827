import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { InboundShipment } from "../objects/InboundShipment";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import {
  generateCode,
  generateParcels,
  getInboundShipmentByCode,
  getInboundShipmentsByCompanyAndPeriod,
  getInboundShipmentsByPeriod,
  getLastInboundShipments,
} from "./methods";

export class InboundShipmentModel extends FirestoreModel<InboundShipment> {
  public constructor() {
    super(() => new InboundShipment(), "inboundShipments", LockPolicy.DiscardUnsyncedChanges, "inboundShipment");
    this.beforeDeleteFunction = "featuresInboundShipmentBeforeDelete";
  }

  public async getDocuments(): Promise<InboundShipment[]> {
    return super.getDocuments([new SortCriteria("date", "desc", "date")]);
  }

  public async getDocument(inboundShipmentId: string): Promise<InboundShipment> {
    return super.getDocument(inboundShipmentId);
  }

  public async createDocument(inboundShipment: InboundShipment): Promise<string> {
    const duplicatedInboundShipment: InboundShipment | undefined = await this.getInboundShipmentByCode(inboundShipment.code as string);
    if (duplicatedInboundShipment != undefined) throw new Error("duplicatedCode");

    return super.createDocument(inboundShipment);
  }

  public async updateDocument(inboundShipment: InboundShipment): Promise<void> {
    const duplicatedInboundShipment: InboundShipment | undefined = await this.getInboundShipmentByCode(inboundShipment.code as string);
    if (duplicatedInboundShipment != undefined && inboundShipment.id !== duplicatedInboundShipment.id) throw new Error("duplicatedCode");

    return super.updateDocument(inboundShipment);
  }

  public async deleteDocument(inboundShipment: InboundShipment): Promise<boolean> {
    return super.deleteDocument(inboundShipment);
  }

  public async generateCode(): Promise<string> {
    return generateCode();
  }

  public async generateParcels(inboundShipment: InboundShipment): Promise<InboundShipment> {
    return generateParcels(inboundShipment);
  }

  public async getInboundShipmentByCode(code: string): Promise<InboundShipment | undefined> {
    return getInboundShipmentByCode(code);
  }

  public async getInboundShipmentsByCompanyAndPeriod(companyId: string, startDate: Date, endDate: Date): Promise<InboundShipment[]> {
    return getInboundShipmentsByCompanyAndPeriod(companyId, startDate, endDate);
  }

  public async getInboundShipmentsByPeriod(startDate: Date, endDate: Date): Promise<InboundShipment[]> {
    return getInboundShipmentsByPeriod(startDate, endDate);
  }

  public async getLastInboundShipments(): Promise<InboundShipment[]> {
    return getLastInboundShipments();
  }
}

export const inboundShipmentModel: InboundShipmentModel = new InboundShipmentModel();
