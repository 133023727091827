import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";

export const chartHandlesByDetail = (parcels: Parcel[], startDate: Date, endDate: Date, t: (entry: string) => string): Record<string, unknown> => {
  try {
    if (parcels.length == 0) return {};

    const sets: Record<string, number[]> = {};
    for (const parcel of parcels) {
      const detail: string = parcel.detail || t("statHandleChart.noDetail");

      for (const action of parcel.actions) {
        const actionStart: Date = new Date(action.date.getTime());
        actionStart.setHours(0, 0, 0, 0);
        const actionEnd: Date = new Date(action.date.getTime());
        actionEnd.setHours(23, 59, 59, 999);

        if (action.action == ParcelActionType.Created) {
          if (compareAsc(actionEnd, startDate) >= 0 && compareAsc(actionStart, endDate) <= 0) {
            if (Object.keys(sets).includes(detail)) {
              sets[detail][0] += 1;
            } else {
              sets[detail] = [1, 0];
            }
          }
        } else if ([ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) {
          if (compareAsc(actionEnd, startDate) >= 0 && compareAsc(actionStart, endDate) <= 0) {
            if (Object.keys(sets).includes(detail)) {
              sets[detail][1] += 1;
            } else {
              sets[detail] = [0, 1];
            }
          }
        }
      }
    }

    const orderedSets: Record<string, number[]> = DataHelpers.sortObjectKeys(sets);

    const chartData: Record<string, unknown> = {
      labels: [t("statHandleChart.inbounds"), t("statHandleChart.outbounds")],
      datasets: Object.keys(orderedSets).map((detail: string, index: number) => {
        return {
          backgroundColor: ChartHelpers.getChartBackgroundColor(index),
          borderColor: ChartHelpers.getChartBorderColor(index),
          borderWidth: 2,
          data: [orderedSets[detail][0], orderedSets[detail][1]],
          label: detail,
        };
      }),
    };

    return chartData;
  } catch (error: unknown) {
    appFaultModel.catchAppError("HandleChartModel.chartHandlesByDetail", { parcels, startDate, endDate, t }, error);
    return {};
  }
};
