import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { compareAsc } from "date-fns";

export class ItemCodePaper extends DocumentPaper {
  private companyName: string | undefined;
  private itemCode: string | undefined;
  private results: Parcel[];

  constructor(
    companyName: string | undefined,
    itemCode: string | undefined,
    results: Parcel[],
    sortField: string,
    sortOrder: number,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.companyName = companyName;
    this.itemCode = itemCode;
    this.results = results;

    if (sortField === "code") {
      if (sortOrder === 1) {
        this.results = this.results.sort((a, b) => (a.code && b.code ? (a.code > b.code ? 1 : a.code < b.code ? -1 : 0) : 0));
      } else {
        this.results = this.results.sort((a, b) => (a.code && b.code ? (a.code < b.code ? 1 : a.code > b.code ? -1 : 0) : 0));
      }
    } else if (sortField === "inboundShipment.date") {
      if (sortOrder === 1) {
        this.results = this.results.sort((a, b) =>
          a.inboundShipment && b.inboundShipment && a.inboundShipment.date && b.inboundShipment.date
            ? compareAsc(a.inboundShipment.date, b.inboundShipment.date) > 0
              ? 1
              : compareAsc(a.inboundShipment.date, b.inboundShipment.date) < 0
              ? -1
              : 0
            : 0
        );
      } else {
        this.results = this.results.sort((a, b) =>
          a.inboundShipment && b.inboundShipment && a.inboundShipment.date && b.inboundShipment.date
            ? compareAsc(a.inboundShipment.date, b.inboundShipment.date) < 0
              ? 1
              : compareAsc(a.inboundShipment.date, b.inboundShipment.date) > 0
              ? -1
              : 0
            : 0
        );
      }
    } else if (sortField === "warehouseLocation.code") {
      if (sortOrder === 1) {
        this.results = this.results.sort((a, b) =>
          a.warehouseLocation?.code && b.warehouseLocation?.code
            ? a.warehouseLocation?.code > b.warehouseLocation?.code
              ? 1
              : a.warehouseLocation?.code < b.warehouseLocation?.code
              ? -1
              : 0
            : 0
        );
      } else {
        this.results = this.results.sort((a, b) =>
          a.warehouseLocation?.code && b.warehouseLocation?.code
            ? a.warehouseLocation?.code < b.warehouseLocation?.code
              ? 1
              : a.warehouseLocation?.code > b.warehouseLocation?.code
              ? -1
              : 0
            : 0
        );
      }
    }
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      itemCodeDetail: { fontSize: 10 },
      itemCodeParcelCell: { alignment: "center", fontSize: 8 },
      itemCodeParcelFoot: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 8 },
      itemCodeParcelHead: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(): Promise<Record<string, unknown>[]> {
    await this.setStructure(this.t("statItemCode.itemCodePaper").toLocaleUpperCase());

    this.writeDetails();

    this.writeItemCodeTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeDetails(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: DataHelpers.mmToPoints(40),
          stack: [
            { margin: [0, DataHelpers.mmToPoints(4), 0, 0], style: "itemCodeDetail", text: `${this.t("statItemCode.company").toLocaleUpperCase()}:` },
            {
              margin: [0, DataHelpers.mmToPoints(2), 0, 0],
              style: "itemCodeDetail",
              text: `${this.t("statItemCode.itemCode").toLocaleUpperCase()}:`,
            },
          ],
        },
        {
          width: "*",
          stack: [
            { margin: [0, DataHelpers.mmToPoints(4), 0, 0], style: "itemCodeDetail", text: this.companyName ?? "-" },
            { margin: [0, DataHelpers.mmToPoints(2), 0, 0], style: "itemCodeDetail", text: this.itemCode?.toLocaleUpperCase() ?? "-" },
          ],
        },
      ],
    });
  }

  private writeItemCodeTable(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "itemCodeParcelHead", text: this.t("statItemCode.code").toLocaleUpperCase() },
        { style: "itemCodeParcelHead", text: this.t("statItemCode.inboundShipment").toLocaleUpperCase() },
        { style: "itemCodeParcelHead", text: this.t("statItemCode.items").toLocaleUpperCase() },
        { style: "itemCodeParcelHead", text: this.t("statItemCode.parcelState").toLocaleUpperCase() },
        { style: "itemCodeParcelHead", text: this.t("statItemCode.warehouseLocation").toLocaleUpperCase() },
      ],
    ];

    for (const result of this.results) {
      dataTableBody.push([
        { style: "itemCodeParcelCell", text: result.code ?? "-" },
        {
          style: "itemCodeParcelCell",
          text: result.inboundShipment
            ? this.t("statItemCode.shipmentDetail", {
                code: result.inboundShipment.code,
                date: this.d(result.inboundShipment.date || new Date(), "shortDate"),
                companyName: result.inboundShipment.company?.name ?? "-",
              })
            : "-",
        },
        { style: "itemCodeParcelCell", text: result.items.map((item) => `${item.code}: ${this.n(item.quantity, "number0")}`).join(", ") },
        { style: "itemCodeParcelCell", text: this.t(`statItemCode.parcelStates.${result.state}`) },
        { style: "itemCodeParcelCell", text: result.warehouseLocation?.code ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 5, style: "itemCodeParcelCell", text: this.t("gen.noResults") }]);
    } else {
      let itemCodeCount = 0;
      for (const parcel of this.results) {
        for (const parcelItem of parcel.items) {
          if (parcelItem.code == this.itemCode?.trim().toUpperCase()) {
            itemCodeCount += parcelItem.quantity;
          }
        }
      }

      dataTableBody.push([
        { style: "itemCodeParcelFoot", text: "" },
        { style: "itemCodeParcelFoot", text: this.t("statItemCode.itemCodeCount").toLocaleUpperCase() },
        { style: "itemCodeParcelFoot", text: this.n(itemCodeCount, "number0") },
        { style: "itemCodeParcelFoot", text: "" },
        { style: "itemCodeParcelFoot", text: "" },
      ]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(18), "*", DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(20)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
