import { RouteRecordRaw } from "vue-router";

export const warehouseLocationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/warehouse-locations",
    name: "WarehouseLocationList",
    component: () => import("../views/WarehouseLocationList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "warehouseLocation",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/warehouse-locations/:id/edit",
    name: "WarehouseLocationEdit",
    component: () => import("../views/WarehouseLocationEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "warehouseLocation",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/warehouse-locations/:id/:section?",
    name: "WarehouseLocationView",
    component: () => import("../views/WarehouseLocationView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "warehouseLocation",
      requiredRight: "viewUI",
    },
  },
];
