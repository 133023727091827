import { ClockingSide } from "./ClockingSide";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { DateTimeStrictField, EnumField, NumberWithDefaultField, StringField } from "@/core/fields";

export class Clocking {
  public id: string = DataHelpers.uniqueId();
  public branchId: string | undefined = undefined;
  public positionId: string | undefined = undefined;
  public side: ClockingSide = ClockingSide.In;
  public timestamp: Date = new Date();
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): Clocking {
    this.branchId = StringField.fromFirestore(data.branchId);
    this.positionId = StringField.fromFirestore(data.positionId);
    this.side = EnumField.fromFirestore<ClockingSide>(data.side, Object.values(ClockingSide), ClockingSide.In);
    this.timestamp = DateTimeStrictField.fromFirestore(data.timestamp, new Date());
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.branchId = StringField.toFirestore(this.branchId);
    firestoreData.positionId = StringField.toFirestore(this.positionId);
    firestoreData.side = EnumField.toFirestore<ClockingSide>(this.side, ClockingSide.In);
    firestoreData.timestamp = DateTimeStrictField.toFirestore(this.timestamp);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }
}
