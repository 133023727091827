import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isFiscalCodeUnique = async (fiscalCode: string, employeeId = "empty"): Promise<boolean> => {
  try {
    if (fiscalCode === undefined) return false;

    return CloudHelpers.runValidatorFunction("featuresEmployeeFiscalCodeValidator", fiscalCode, employeeId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.isFiscalCodeUnique", { fiscalCode, employeeId }, error);
    return false;
  }
};
