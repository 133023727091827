import { Branch } from "../objects/Branch";
import { branchModel } from "../models/BranchModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const branchSearch: SearchModule = new SearchModule(
  "branch.branch",
  (firestoreDocument: FirestoreDocument) =>
    Promise.resolve(`${(firestoreDocument as Branch).name} (${((firestoreDocument as Branch).company as LinkedCompany).name})`),
  (firestoreDocument: FirestoreDocument) => `/branches/${firestoreDocument.id}`,
  "cog",
  branchModel,
  [new SortCriteria("name", "asc", "string")]
);
