
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { itemCodeModel } from "../models/ItemCodeModel";
import { ItemCodePaper } from "../paper/ItemCodePaper";
import { LinkedWarehouseLocation } from "@/features/modules/warehouseLocation/objects/LinkedWarehouseLocation";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "ItemCodeView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<Parcel[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId = ref();
    const itemCode = ref();
    const itemCodeCount = ref(0);
    const companies: Ref<Company[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value == undefined) throw new Error("companyIdUndefined");
      if (itemCode.value == undefined) throw new Error("itemCodeUndefined");

      results.value = await itemCodeModel.calcItemCode(companyId.value, itemCode.value);

      itemCodeCount.value = 0;
      for (const parcel of results.value) {
        for (const parcelItem of parcel.items) {
          if (parcelItem.code == itemCode.value.trim().toUpperCase()) {
            itemCodeCount.value += parcelItem.quantity;
          }
        }
      }

      showResults.value = true;
    };

    const showWarehouseLocationDetails = (warehouseLocation: LinkedWarehouseLocation) => {
      eventBus.emit("showWarehouseLocationDetail", warehouseLocation.code);
    };

    const printReport = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const company: Company = companies.value.find((c) => c.id == companyId.value) as Company;

          const itemCodePaper: ItemCodePaper = new ItemCodePaper(
            company.name,
            itemCode.value,
            results.value,
            sortField.value,
            sortOrder.value,
            d,
            n,
            t
          );
          await itemCodePaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const sortField: Ref<string> = ref("code");
    const sortOrder: Ref<number> = ref(1);

    const onSortFieldUpdate = (value: string) => {
      sortField.value = value;
    };

    const onSortOrderUpdate = (value: number | undefined) => {
      sortOrder.value = value ?? 1;
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companyId,
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      itemCode,
      itemCodeCount,
      n,
      onSortFieldUpdate,
      onSortOrderUpdate,
      printReport,
      results,
      showResults,
      showWarehouseLocationDetails,
      sortField,
      sortOrder,
      t,
    };
  },
});
