import { RouteRecordRaw } from "vue-router";

export const employeeLeaveRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/leaves/:employeeLeaveId/edit",
    name: "EmployeeLeaveEdit",
    component: () => import("../views/EmployeeLeaveEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeLeave",
      requiredRight: "viewUI",
    },
  },
];
