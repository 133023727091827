import { BranchShiftType } from "./BranchShiftType";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringArrayField, StringField } from "@/core/fields";

export class LinkedBranchShiftType extends LinkedFirestoreDocument {
  public code: string | undefined = undefined;
  public name: string | undefined = undefined;
  public times: string[] = [];
  public branchId: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromBranchShiftType(branchShiftType: BranchShiftType): LinkedBranchShiftType {
    const linkedBranchShiftType: LinkedBranchShiftType = new LinkedBranchShiftType();
    linkedBranchShiftType.id = branchShiftType.id;
    linkedBranchShiftType.code = branchShiftType.code;
    linkedBranchShiftType.name = branchShiftType.name;
    linkedBranchShiftType.times = branchShiftType.times;

    return linkedBranchShiftType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedBranchShiftType {
    super.fromFirestore(data);

    this.code = StringField.fromFirestore(data.code);
    this.name = StringField.fromFirestore(data.name);
    this.times = StringArrayField.fromFirestore(data.times);
    this.branchId = StringField.fromFirestore(data.branchId);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.times = StringArrayField.toFirestore(this.times);
    firestoreData.branchId = StringField.toFirestore(this.branchId);

    return firestoreData;
  }
}
