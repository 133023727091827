import { RouteRecordRaw } from "vue-router";

export const dpiHandlingRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dpi-handlings",
    name: "DpiHandlingList",
    component: () => import("../views/DpiHandlingList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiHandling",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/dpi-handlings/:id/edit",
    name: "DpiHandlingEdit",
    component: () => import("../views/DpiHandlingEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiHandling",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/dpi-handlings/:id",
    name: "DpiHandlingView",
    component: () => import("../views/DpiHandlingView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiHandling",
      requiredRight: "viewUI",
    },
  },
];
