import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isCodeUnique = async (code: string, dpiPurchaseId = "empty"): Promise<boolean> => {
  try {
    if (code === undefined) return false;

    return CloudHelpers.runValidatorFunction("featuresDpiPurchaseCodeValidator", code, dpiPurchaseId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiPurchaseModel.isCodeUnique", { code, dpiPurchaseId }, error);
    return false;
  }
};
