import { LinkedUser } from "@/core/modules/user/objects/LinkedUser";

import { ObjectField, StringField } from "@/core/fields";

export class DocDownload {
  public source: string | undefined = undefined;
  public user: LinkedUser | undefined = undefined;
  public ipAddress: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): DocDownload {
    this.source = StringField.fromFirestore(data.source);
    this.user = ObjectField.fromFirestore<LinkedUser>(data.user, (value: Record<string, unknown>) => new LinkedUser(value));
    this.ipAddress = StringField.fromFirestore(data.ipAddress);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.source = StringField.toFirestore(this.source);
    firestoreData.user = ObjectField.toFirestore<LinkedUser>(this.user, (value: LinkedUser) => value.toFirestore());
    firestoreData.ipAddress = StringField.toFirestore(this.ipAddress);

    return firestoreData;
  }
}
