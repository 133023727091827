import { RouteRecordRaw } from "vue-router";

export const dpiRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dpis/:id/edit",
    name: "DpiEdit",
    component: () => import("../views/DpiEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpi",
      requiredRight: "viewUI",
    },
  },
];
