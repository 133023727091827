export const it = {
  statModule: {
    groups: {
      dpi: "DPI",
      employeeRoster: "Turni operatori",
      invoice: "Fatturazione",
      warehouse: "Magazzino",
    },
  },
};
