export const it = {
  aedSale: {
    aedCompanyError: "Selezionare un'azienda valida",
    aedSale: "Vendita",
    aedSales: "Vendite",
    code: "Identificativo",
    date: "Data",
    dateError: "Selezionare una data valida",
    detailsError: "Verificare di aver inserito tutti i dettagli necessari",
    emailPrefix: "E-mail",
    fiscalCodePrefix: "C.F.",
    general: "Generali",
    heading: "Intestazione",
    name: "Ragione sociale",
    nameError: "Inserire una ragione sociale valida",
    notes: "Note",
    orderTitle: "Ordine n. {code} del {date}",
    quoteTitle: "Preventivo n. {code} del {date}",
    signature: "Firma per accettazione",
    state: "Stato",
    states: {
      completed: "Completata",
      order: "Ordine",
      quote: "Preventivo",
    },
    subtotal: "Subtotale",
    total: "Totale",
    vat: "IVA 22%",
    vatCodePrefix: "P.IVA",
  },
  navigation: {
    aedSale: "Vendite",
  },
  buttons: {
    aedSaleMarkAsOrder: "Segna come ordine",
    aedSaleMarkAsCompleted: "Segna come completato",
  },
};
