import { LeaveType } from "./LeaveType";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedLeaveType extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromLeaveType(leaveType: LeaveType): LinkedLeaveType {
    const linkedLeaveType: LinkedLeaveType = new LinkedLeaveType();
    linkedLeaveType.id = leaveType.id;
    linkedLeaveType.name = leaveType.name;

    return linkedLeaveType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedLeaveType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
