import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isRegistrationNumberUnique = async (registrationNumber: number, employeeId = "empty"): Promise<boolean> => {
  try {
    if (registrationNumber === undefined) return false;

    return CloudHelpers.runValidatorFunction("featuresEmployeeRegistrationNumberValidator", registrationNumber.toFixed(0), employeeId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.isRegistrationNumberUnique", { registrationNumber, employeeId }, error);
    return false;
  }
};
