import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeRosterCounter } from "@/features/modules/employeeRoster/objects/EmployeeRosterCounter";

export class BranchEmployeeReport {
  public employee: Employee;
  public counters: EmployeeRosterCounter;

  public constructor(employee: Employee) {
    this.employee = employee;
    this.counters = new EmployeeRosterCounter();
  }
}
