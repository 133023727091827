import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { TransportType } from "./TransportType";

import { StringField } from "@/core/fields";

export class LinkedTransportType extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromTransportType(transportType: TransportType): LinkedTransportType {
    const linkedTransportType: LinkedTransportType = new LinkedTransportType();
    linkedTransportType.id = transportType.id;
    linkedTransportType.name = transportType.name;

    return linkedTransportType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedTransportType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
