export const it = {
  statCompanyStock: {
    company: "Azienda",
    companyStockPaper: "Resoconto su giacenza UDC",
    date: "Data",
    name: "Giacenza UDC",
    detail: "Dettagli",
    parcelType: "Formato UDC",
    quantity: "Quantità",
    stockCount: "Conteggio totale UDC",
  },
};
