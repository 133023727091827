import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeeDpi } from "@/features/modules/employeeDpi/objects/EmployeeDpi";
import { employeeDpiModel } from "../EmployeeDpiModel";

export const getEmployeeDpiByDpiAndSize = async (dpiId: string, size: string, parentId: string): Promise<EmployeeDpi | undefined> => {
  try {
    const snapshotQuery: Query<EmployeeDpi> = query(
      employeeDpiModel.getPathReference(parentId).withConverter(employeeDpiModel.firestoreConverter),
      where("dpi.id", "==", dpiId),
      where("size", "==", size)
    );

    const snapshot: QuerySnapshot<EmployeeDpi> = await getDocs(employeeDpiModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeDpiModel.getEmployeeDpiByDpiAndSize", { dpiId, size, parentId }, error);
    return undefined;
  }
};
