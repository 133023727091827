
import { defineComponent, onMounted, Ref, ref } from "vue";
import { subDays } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { HandleChartData } from "../objects/HandleChartData";
import { handleChartModel } from "../models/HandleChartModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "HandleChartView",
  setup() {
    const { d, t } = useLocale();

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId = ref();
    const period: Ref<Date[]> = ref([subDays(new Date(), 30), new Date()]);
    const companies: Ref<Company[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const warehouseLocationValue: Ref<string | undefined> = ref(undefined);
    const warehouseLocationVisible: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value == undefined) throw new Error("companyIdUndefined");
      if (period.value == undefined || period.value.length != 2) throw new Error("periodUndefined");

      const startDate: Date = period.value[0];
      const endDate: Date = period.value[1];
      if (startDate == undefined || endDate == undefined) throw new Error("periodUndefined");

      const chartsData: HandleChartData = await handleChartModel.calcHandleChart(companyId.value, startDate, endDate, t);

      chartHandlesByDetail.value = chartsData.chartHandlesByDetail;
      chartHandlesByType.value = chartsData.chartHandlesByType;
      chartStockByDetailStart.value = chartsData.chartStockByDetailStart;
      chartStockByTypeStart.value = chartsData.chartStockByTypeStart;
      chartStockByDetailEnd.value = chartsData.chartStockByDetailEnd;
      chartStockByTypeEnd.value = chartsData.chartStockByTypeEnd;

      showResults.value = true;
    };

    const chartHandlesByDetail: Ref<Record<string, unknown>> = ref({});
    const chartHandlesByType: Ref<Record<string, unknown>> = ref({});
    const chartStockByDetailStart: Ref<Record<string, unknown>> = ref({});
    const chartStockByTypeStart: Ref<Record<string, unknown>> = ref({});
    const chartStockByDetailEnd: Ref<Record<string, unknown>> = ref({});
    const chartStockByTypeEnd: Ref<Record<string, unknown>> = ref({});

    onMounted(() => {
      handleLoad();
    });

    return {
      chartHandlesByDetail,
      chartHandlesByType,
      ChartHelpers,
      chartStockByDetailStart,
      chartStockByTypeStart,
      chartStockByDetailEnd,
      chartStockByTypeEnd,
      companies,
      companyId,
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      period,
      showResults,
      t,
      warehouseLocationValue,
      warehouseLocationVisible,
    };
  },
});
