
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { companyStockModel } from "../models/CompanyStockModel";
import { CompanyStockPaper } from "../paper/CompanyStockPaper";
import { CompanyStockRow } from "../objects/CompanyStockRow";
import { LinkedWarehouseLocation } from "@/features/modules/warehouseLocation/objects/LinkedWarehouseLocation";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";
import { eventBus } from "@/core/modules/eventBus/EventBus";

export default defineComponent({
  name: "CompanyStockView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<CompanyStockRow[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId = ref();
    const date: Ref<Date> = ref(new Date());
    const stockCount: Ref<number> = ref(0);

    const companies: Ref<Company[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (date.value == undefined) throw new Error("dateUndefined");
      if (companyId.value == undefined) throw new Error("companyIdUndefined");

      results.value = await companyStockModel.calcCompanyStock(companyId.value, date.value);

      stockCount.value = 0;
      for (const stock of results.value) {
        stockCount.value += stock.quantity;
      }

      showResults.value = true;
    };

    const showWarehouseLocationDetails = (warehouseLocation: LinkedWarehouseLocation) => {
      eventBus.emit("showWarehouseLocationDetails", warehouseLocation.code);
    };

    const printReport = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const company: Company = companies.value.find((c) => c.id == companyId.value) as Company;

          const companyStockPaper: CompanyStockPaper = new CompanyStockPaper(company.name, date.value, results.value, d, n, t);
          await companyStockPaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companyId,
      dataChanged,
      date,
      handleKeydown,
      handleSubmit,
      n,
      printReport,
      results,
      showResults,
      showWarehouseLocationDetails,
      stockCount,
      t,
    };
  },
});
