import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { BranchDpi } from "@/features/modules/branchDpi/objects/BranchDpi";
import { branchDpiModel } from "../BranchDpiModel";

export const getBranchDpiByDpiAndSize = async (dpiId: string, size: string, parentId: string): Promise<BranchDpi | undefined> => {
  try {
    const snapshotQuery: Query<BranchDpi> = query(
      branchDpiModel.getPathReference(parentId).withConverter(branchDpiModel.firestoreConverter),
      where("dpi.id", "==", dpiId),
      where("size", "==", size)
    );

    const snapshot: QuerySnapshot<BranchDpi> = await getDocs(branchDpiModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("BranchDpiModel.getBranchDpiByDpiAndSize", { dpiId, size, parentId }, error);
    return undefined;
  }
};
