import { format } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "../ParcelModel";
import { ParcelState } from "@/features/modules/parcel/objects/ParcelState";

export const getParcelsByCompanyAndPeriod = async (companyId: string, startDate: Date, endDate: Date): Promise<Parcel[]> => {
  try {
    const snapshotQuery: Query<Parcel> = query(
      parcelModel.getPathReference().withConverter(parcelModel.firestoreConverter),
      where("inboundShipment.company.id", "==", companyId),
      where("lifeEndSort", ">=", format(startDate, "yyyy-MM-dd"))
    );

    const snapshot: QuerySnapshot<Parcel> = await getDocs(parcelModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    const parcels: Parcel[] = sortDocuments(
      snapshot.docs
        .map((doc) => doc.data())
        .filter((parcel: Parcel) => parcel.lifeStartSort <= format(endDate, "yyyy-MM-dd") && parcel.state !== ParcelState.Deleted)
    );

    return parcels;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.getParcelsByCompanyAndPeriod", { companyId, startDate, endDate }, error);
    return [];
  }
};

function sortDocuments(documents: Parcel[]): Parcel[] {
  const sorter: FirestoreSorter<Parcel> = new FirestoreSorter(documents);
  sorter.addSortCriteria("createdAt", "asc", "date");
  return sorter.sort();
}
