import { RouteRecordRaw } from "vue-router";

export const employeeWarningRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/warnings/:employeeWarningId/edit",
    name: "EmployeeWarningEdit",
    component: () => import("../views/EmployeeWarningEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeWarning",
      requiredRight: "viewUI",
    },
  },
];
