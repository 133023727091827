export const it = {
  aedPurchase: {
    aedPurchase: "Acquisto",
    aedPurchases: "Acquisti",
    code: "Identificativo",
    date: "Data",
    dateError: "Selezionare una data valida",
    general: "Generali",
    notes: "Note",
    paperTitle: "Acquisto n. {code} del {date}",
    state: "Stato",
    states: {
      created: "Creato",
      stocked: "In magazzino",
    },
    subtotal: "Subtotale",
    total: "Totale",
    vat: "IVA 22%",
  },
  navigation: {
    aedPurchase: "Acquisti",
  },
  buttons: {
    aedPurchaseMarkAsStocked: "Immagazzina",
  },
};
