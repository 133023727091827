
import { defineComponent, onMounted, Ref, ref } from "vue";

import { addMonths, compareAsc, startOfMonth } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";
import { StockChartData } from "../objects/StockChartData";
import { stockChartModel } from "../models/StockChartModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "StockChartView",
  setup() {
    const { d, n, t } = useLocale();

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId = ref();
    const monthDate: Ref<Date> = ref(startOfMonth(new Date()));
    monthDate.value.setHours(0, 0, 0, 0);
    const companies: Ref<Company[]> = ref([]);
    const months: Ref<{ label: string; date: Date }[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const warehouseLocationValue: Ref<string | undefined> = ref(undefined);
    const warehouseLocationVisible: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();

      let startDate: Date = new Date("2022-01-01");
      startDate.setHours(0, 0, 0, 0);
      const endDate: Date = startOfMonth(new Date());
      endDate.setHours(0, 0, 0, 0);

      while (compareAsc(startDate, endDate) <= 0) {
        months.value.push({
          label: FormatHelpers.capitalizeFirstLetter(d(startDate, "longMonth")),
          date: startDate,
        });
        startDate = addMonths(startDate, 1);
      }
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value == undefined) throw new Error("companyIdUndefined");
      if (monthDate.value == undefined) throw new Error("monthDateUndefined");
      monthDate.value.setHours(0, 0, 0, 0);

      const chartsData: StockChartData = await stockChartModel.calcStockChart(companyId.value, monthDate.value, t);

      chartMonthlyStock.value = chartsData.chartMonthlyStock;
      chartMonthlyStockByDetail.value = chartsData.chartMonthlyStockByDetail;
      chartMonthlyStockByType.value = chartsData.chartMonthlyStockByType;
      monthlyStockPeakValue.value = chartsData.monthlyStockPeakValue;

      showResults.value = true;
    };

    const chartMonthlyStock: Ref<Record<string, unknown>> = ref({});
    const chartMonthlyStockByDetail: Ref<Record<string, unknown>> = ref({});
    const chartMonthlyStockByType: Ref<Record<string, unknown>> = ref({});
    const monthlyStockPeakValue: Ref<number> = ref(0);

    onMounted(() => {
      handleLoad();
    });

    return {
      ChartHelpers,
      chartMonthlyStock,
      chartMonthlyStockByDetail,
      chartMonthlyStockByType,
      companies,
      companyId,
      d,
      dataChanged,
      FormatHelpers,
      handleKeydown,
      handleSubmit,
      monthDate,
      monthlyStockPeakValue,
      months,
      n,
      showResults,
      t,
      warehouseLocationValue,
      warehouseLocationVisible,
    };
  },
});
