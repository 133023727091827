import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { BranchDpi } from "@/features/modules/branchDpi/objects/BranchDpi";
import { branchDpiModel } from "@/features/modules/branchDpi/models/BranchDpiModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { DpiHandlingHelpers } from "@/features/modules/dpiHandling/helpers/DpiHandlingHelpers";
import { EmployeeDpi } from "@/features/modules/employeeDpi/objects/EmployeeDpi";
import { employeeDpiModel } from "@/features/modules/employeeDpi/models/EmployeeDpiModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";

export const deliverDpisFromEmployeeToBranch = async (dpiHandling: DpiHandling): Promise<string> => {
  try {
    // check if dpis are available
    if (dpiHandling.sourceEmployee === undefined) throw new Error("sourceEmployee is undefined");
    const result: string = await DpiHandlingHelpers.checkDpisAvailabilityInEmployee(dpiHandling, dpiHandling.sourceEmployee.id);
    if (result !== "OK") return result;

    await FirestoreModel.runTransaction(async () => {
      if (dpiHandling.sourceEmployee === undefined) throw new Error("sourceEmployee is undefined");

      for (const handledDpi of dpiHandling.getHandledDpis()) {
        if (dpiHandling.targetBranch === undefined) throw new Error("targetBranch is undefined");
        if (handledDpi.dpi === undefined) throw new Error("handledDpi dpi is undefined");
        if (handledDpi.size === undefined) throw new Error("handledDpi size is undefined");

        // check if employee dpi is available and if quantity is enough
        const employeeDpi: EmployeeDpi | undefined = await employeeDpiModel.getEmployeeDpiByDpiAndSize(
          handledDpi.dpi.id,
          handledDpi.size,
          dpiHandling.sourceEmployee.id
        );
        if (employeeDpi === undefined) throw new Error(`Dpi #${handledDpi.dpi.id} with size ${handledDpi.size} not available`);
        if (employeeDpi.quantity < handledDpi.quantity) {
          throw new Error(
            `Dpi #${handledDpi.dpi.id} with size ${handledDpi.size} not available (requested quantity: ${handledDpi.quantity}, available quantity: ${employeeDpi.quantity})`
          );
        }

        // update the employee dpi quantity or delete it if quantity is 0
        if (employeeDpi.firestoreRef === undefined) throw new Error("employeeDpi firestoreRef is undefined");
        if (employeeDpi.quantity - handledDpi.quantity > 0) {
          batch.update(employeeDpi.firestoreRef, { quantity: employeeDpi.quantity - handledDpi.quantity });
        } else {
          batch.delete(employeeDpi.firestoreRef);
        }

        // check if branch dpi already exists and update the quantity or create a new one
        const branchDpi: BranchDpi | undefined = await branchDpiModel.getBranchDpiByDpiAndSize(
          handledDpi.dpi.id,
          handledDpi.size,
          dpiHandling.targetBranch.id
        );
        if (branchDpi === undefined) {
          const newBranchDpi: BranchDpi = new BranchDpi();
          newBranchDpi.dpi = handledDpi.dpi;
          newBranchDpi.size = handledDpi.size;
          newBranchDpi.quantity = handledDpi.quantity;
          batch.set(branchDpiModel.getDocumentReference(undefined, dpiHandling.targetBranch.id), newBranchDpi.toFirestore());
        } else {
          if (branchDpi.firestoreRef === undefined) throw new Error("branchDpi firestoreRef is undefined");
          batch.update(branchDpi.firestoreRef, { quantity: branchDpi.quantity + handledDpi.quantity });
        }
      }
      await batch.commit();
    });

    return "OK";
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.deliverDpisFromEmployeeToBranch", { dpiHandling }, error);
    return "ERROR";
  }
};
