
import { defineComponent, onMounted, Ref, ref } from "vue";

import { addMonths, compareAsc, endOfYear, getYear, startOfYear } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { CompanyInvoiceYear } from "../objects/CompanyInvoiceYear";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceByYearModel } from "../models/InvoiceByYearModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "InvoiceByYearView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<CompanyInvoiceYear[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const year: Ref<number> = ref(getYear(new Date()));
    const years: Ref<number[]> = ref(DataHelpers.createRange(2022, getYear(new Date())));

    const monthDates: Ref<Date[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      let startDate: Date = startOfYear(new Date());
      const endDate: Date = endOfYear(new Date());
      while (compareAsc(startDate, endDate) <= 0) {
        monthDates.value.push(startDate);
        startDate = addMonths(startDate, 1);
      }
    };

    const submitAction = async () => {
      showResults.value = false;

      results.value = await invoiceByYearModel.calcInvoices(year.value);

      showResults.value = true;
    };

    const renderInvoiceValue = (invoices: Invoice[], monthDate: Date): string => {
      const month: number = monthDate.getMonth() + 1;
      const filteredInvoices: Invoice[] = invoices.filter((invoice) => invoice.month == month);
      if (filteredInvoices.length === 0) return "-";

      let monthTotal = 0;
      for (const invoice of filteredInvoices) {
        monthTotal += invoice.total;
      }
      return n(monthTotal, "currencyEUR");
    };

    onMounted(() => handleLoad());

    return {
      d,
      dataChanged,
      FormatHelpers,
      handleKeydown,
      handleSubmit,
      monthDates,
      n,
      renderInvoiceValue,
      results,
      showResults,
      t,
      year,
      years,
    };
  },
});
