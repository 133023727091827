import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { LinkedBank } from "@/features/modules/bank/objects/LinkedBank";

import { DateStrictField, NumberField, ObjectField } from "@/core/fields";

export class InvoicePayment {
  public id: string = DataHelpers.uniqueId();
  public date: Date = new Date();
  public bank: LinkedBank | undefined = undefined;
  public amount = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): InvoicePayment {
    this.id = DataHelpers.uniqueId();

    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.bank = ObjectField.fromFirestore<LinkedBank>(data.bank, (value) => new LinkedBank(value));
    this.amount = NumberField.fromFirestore(data.amount);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.bank = ObjectField.toFirestore<LinkedBank>(this.bank, (value) => value.toFirestore());
    firestoreData.amount = NumberField.toFirestore(this.amount);

    return firestoreData;
  }
}
