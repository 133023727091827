export const it = {
  parcelDetail: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    parcelDetail: "Dettaglio UDC",
    parcelDetails: "Dettagli UDC",
  },
  navigation: {
    parcelDetail: "Dettagli UDC",
  },
};
