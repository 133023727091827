import { RouteRecordRaw } from "vue-router";

export const vehicleTypeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/vehicle-types/:id/edit",
    name: "VehicleTypeEdit",
    component: () => import("../views/VehicleTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vehicleType",
      requiredRight: "viewUI",
    },
  },
];
