import { ParcelActionType } from "./ParcelActionType";

import { DateTimeStrictField, EnumField, NumberWithDefaultField, StringField } from "@/core/fields";

export class ParcelAction {
  public order = 999;
  public date: Date = new Date();
  public action: ParcelActionType = ParcelActionType.Created;
  public reference: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): ParcelAction {
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);
    this.date = DateTimeStrictField.fromFirestore(data.date, new Date());
    this.action = EnumField.fromFirestore<ParcelActionType>(data.action, Object.values(ParcelActionType), ParcelActionType.Created);
    this.reference = StringField.fromFirestore(data.reference);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);
    firestoreData.date = DateTimeStrictField.toFirestore(this.date);
    firestoreData.action = EnumField.toFirestore<ParcelActionType>(this.action, ParcelActionType.Created);
    firestoreData.reference = StringField.toFirestore(this.reference);

    return firestoreData;
  }
}
