import { addDays, compareAsc, endOfMonth, getDaysInMonth, startOfMonth } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";

export const chartMonthlyStockByDetail = (parcels: Parcel[], monthDate: Date, t: (entry: string) => string): Record<string, unknown> => {
  try {
    let startDate: Date = startOfMonth(monthDate);
    const endDate: Date = endOfMonth(monthDate);

    const availableDays: string[] = DataHelpers.createRange(1, getDaysInMonth(monthDate)).map((day) => day.toString());
    const availableDetails: string[] = [];

    const sets: Record<string, Record<string, number>> = {};
    while (compareAsc(startDate, endDate) <= 0) {
      const dayOfMonth: string = startDate.getDate().toString();

      for (const parcel of parcels) {
        const detail: string = parcel.detail || t("statStockChart.noDetail");

        let isStored = false;
        for (const action of parcel.actions) {
          action.date.setHours(0, 0, 0, 0);
          if (compareAsc(action.date, startDate) > 0) continue;

          if (!isStored) {
            if (action.action == ParcelActionType.Created) isStored = true;
          } else {
            if ([ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) isStored = false;
          }
        }
        if (isStored) {
          if (Object.keys(sets).includes(detail)) {
            if (Object.keys(sets[detail]).includes(dayOfMonth)) {
              sets[detail][dayOfMonth] += 1;
            } else {
              sets[detail][dayOfMonth] = 1;
            }
          } else {
            sets[detail] = {};
            sets[detail][dayOfMonth] = 1;
          }
          if (!availableDetails.includes(detail)) availableDetails.push(detail);
        }
      }

      startDate = addDays(startDate, 1);
    }

    for (const detail of availableDetails) {
      for (const dayOfMonth of availableDays) {
        if (!Object.keys(sets).includes(detail)) {
          sets[detail][dayOfMonth] = 0;
        } else if (!Object.keys(sets[detail]).includes(dayOfMonth)) {
          sets[detail][dayOfMonth] = 0;
        }
      }
    }

    const orderedSets: Record<string, Record<string, number>> = DataHelpers.sortObjectKeys(sets);

    const chartData: Record<string, unknown> = {
      labels: availableDays,
      datasets: Object.keys(orderedSets).map((detail: string, index: number) => {
        return {
          backgroundColor: ChartHelpers.getChartBackgroundColor(index),
          borderColor: ChartHelpers.getChartBorderColor(index),
          borderWidth: 2,
          data: orderedSets[detail],
          label: detail,
        };
      }),
    };

    return chartData;
  } catch (error: unknown) {
    appFaultModel.catchAppError("StockChartModel.chartMonthlyStockByDetail", { parcels, monthDate, t }, error);
    return {};
  }
};
