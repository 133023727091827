
import { defineComponent, onMounted, Ref, ref } from "vue";

import { subDays } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { dpiDeliveredModel } from "../models/DpiDeliveredModel";
import { DpiDeliveredPaper } from "../paper/DpiDeliveredPaper";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "DpiDeliveredView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<StoredDpi[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          // do nothing here
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const period: Ref<Date[]> = ref([subDays(new Date(), 30), new Date()]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const submitAction = async () => {
      showResults.value = false;

      const startDate: Date = period.value[0];
      const endDate: Date = period.value[1];
      if (startDate == undefined || endDate == undefined) throw new Error("periodUndefined");

      results.value = await dpiDeliveredModel.calcDpiDelivered(startDate, endDate);

      showResults.value = true;
    };

    const printReport = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const startDate: Date = period.value[0];
          const endDate: Date = period.value[1];
          if (startDate == undefined || endDate == undefined) throw new Error("periodUndefined");

          const dpiDeliveredPaper: DpiDeliveredPaper = new DpiDeliveredPaper(startDate, endDate, results.value, d, n, t);
          await dpiDeliveredPaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => handleLoad());

    return {
      dataChanged,
      handleKeydown,
      handleSubmit,
      n,
      period,
      printReport,
      results,
      showResults,
      t,
    };
  },
});
