import { format } from "date-fns";
import { getDocs, Query, query, QueryDocumentSnapshot, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { employeePositionModel } from "../EmployeePositionModel";

export const getEmployeePositionsByDate = async (date: Date): Promise<EmployeePosition[]> => {
  try {
    const snapshotQuery: Query<EmployeePosition> = query(
      employeePositionModel.getGroupPathReference().withConverter(employeePositionModel.firestoreConverter),
      where("toSort", ">=", format(date, "yyyy-MM-dd"))
    );

    const snapshot: QuerySnapshot<EmployeePosition> = await getDocs(employeePositionModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.reduce((result: EmployeePosition[], doc: QueryDocumentSnapshot<EmployeePosition>) => {
      if (doc.data().fromSort <= format(date, "yyyy-MM-dd")) result.push(doc.data());
      return result;
    }, []);
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeePositionModel.getEmployeePositionsByDate", { date }, error);
    return [];
  }
};
