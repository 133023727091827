import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createBlock(_component_OverlayPanel, {
    ref: "overlayPanel",
    style: {"max-width":"50vw","max-height":"40vh"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        innerHTML: _ctx.FormatHelpers.nl2br(_ctx.textRef)
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 512))
}