import { EmployeeWarning } from "./EmployeeWarning";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedWarningType } from "@/features/modules/warningType/objects/LinkedWarningType";

import { DateField, ObjectField } from "@/core/fields";

export class LinkedEmployeeWarning extends LinkedFirestoreDocument {
  public date: Date | undefined = new Date();
  public type: LinkedWarningType | undefined = undefined;
  public suspensionStart: Date | undefined = undefined;
  public suspensionEnd: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromEmployeeWarning(employeeWarning: EmployeeWarning): LinkedEmployeeWarning {
    const linkedEmployeeWarning: LinkedEmployeeWarning = new LinkedEmployeeWarning();
    linkedEmployeeWarning.id = employeeWarning.id;
    linkedEmployeeWarning.date = employeeWarning.date;
    linkedEmployeeWarning.type = employeeWarning.type;
    linkedEmployeeWarning.suspensionStart = employeeWarning.suspensionStart;
    linkedEmployeeWarning.suspensionEnd = employeeWarning.suspensionEnd;

    return linkedEmployeeWarning;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedEmployeeWarning {
    super.fromFirestore(data);

    this.date = DateField.fromFirestore(data.date);
    this.type = ObjectField.fromFirestore<LinkedWarningType>(data.type, (value) => new LinkedWarningType(value));
    this.suspensionStart = DateField.fromFirestore(data.suspensionStart);
    this.suspensionEnd = DateField.fromFirestore(data.suspensionEnd);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.type = ObjectField.toFirestore<LinkedWarningType>(this.type, (value) => value.toFirestore());
    firestoreData.suspensionStart = DateField.toFirestore(this.suspensionStart);
    firestoreData.suspensionEnd = DateField.toFirestore(this.suspensionEnd);

    return firestoreData;
  }
}
