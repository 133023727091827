import { Assignment } from "./Assignment";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedAssignment extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromAssignment(assignment: Assignment): LinkedAssignment {
    const linkedAssignment: LinkedAssignment = new LinkedAssignment();
    linkedAssignment.id = assignment.id;
    linkedAssignment.name = assignment.name;

    return linkedAssignment;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedAssignment {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
