import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { BranchWithEmployee } from "../objects/BranchWithEmployee";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeLeave } from "@/features/modules/employeeLeave/objects/EmployeeLeave";
import { employeeLeaveModel } from "@/features/modules/employeeLeave/models/EmployeeLeaveModel";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { employeePositionModel } from "@/features/modules/employeePosition/models/EmployeePositionModel";

export class BranchWithEmployeeModel {
  public async countForDate(date: Date): Promise<BranchWithEmployee[]> {
    try {
      if (date === undefined) throw new Error("dateUndefined");
      date.setHours(0, 0, 0, 0);

      const branchesWithEmployees: BranchWithEmployee[] = [];

      // populate branches
      const branches: Branch[] = await branchModel.getDocuments();
      for (const branch of branches) {
        const branchWithEmployee: BranchWithEmployee = new BranchWithEmployee(branch);
        branchesWithEmployees.push(branchWithEmployee);
      }

      const allEmployeeLeaves: EmployeeLeave[] = await employeeLeaveModel.getEmployeeLeavesByDate(date);
      const allEmployeePositions: EmployeePosition[] = await employeePositionModel.getEmployeePositionsByDate(date);

      const employees: Employee[] = await employeeModel.getDocuments();
      for (const employee of employees) {
        const employeeLeaves: EmployeeLeave[] = allEmployeeLeaves.filter((employeeLeave: EmployeeLeave) => employeeLeave.parentId === employee.id);
        const employeePositions: EmployeePosition[] = allEmployeePositions.filter(
          (employeePosition: EmployeePosition) => employeePosition.parentId === employee.id
        );

        for (const employeePosition of employeePositions) {
          if (employeePosition.branch === undefined) continue;
          if (employeePosition.position === undefined) continue;

          const branchWithEmployee: BranchWithEmployee | undefined = branchesWithEmployees.find(
            (branchWithEmployee: BranchWithEmployee) => branchWithEmployee.branch.id === employeePosition.branch?.id
          );
          if (branchWithEmployee === undefined) continue;

          branchWithEmployee.employeesWithPosition.push({
            employee: employee,
            position: employeePosition.position,
            leave: employeeLeaves.length > 0 ? employeeLeaves[0].type : undefined,
          });
        }
      }

      return branchesWithEmployees;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}

export const branchWithEmployeeModel: BranchWithEmployeeModel = new BranchWithEmployeeModel();
