import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Employee } from "./Employee";

export class EmployeeExpiration {
  public id: string = DataHelpers.uniqueId();
  public employee: Employee;
  public expiration: Date | undefined;
  public typeId: string | undefined;

  public constructor(employee: Employee, expiration: Date | undefined, typeId?: string) {
    this.employee = employee;
    this.expiration = expiration;
    this.typeId = typeId;
  }
}
