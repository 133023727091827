import { HandleChartData } from "../objects/HandleChartData";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";

import { chartHandlesByDetail, chartHandlesByType, chartStockByDetail, chartStockByType } from "./methods";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class HandleChartModel {
  public async calcHandleChart(companyId: string, startDate: Date, endDate: Date, t: (entry: string) => string): Promise<HandleChartData> {
    try {
      if (companyId === undefined) throw new Error("companyIdUndefined");
      if (startDate === undefined) throw new Error("startDateUndefined");
      if (endDate === undefined) throw new Error("endDateUndefined");

      const parcels: Parcel[] = await parcelModel.getParcelsByCompanyAndPeriod(companyId, startDate, endDate);

      const chartsData: HandleChartData = {
        chartHandlesByDetail: chartHandlesByDetail(parcels, startDate, endDate, t),
        chartHandlesByType: chartHandlesByType(parcels, startDate, endDate, t),
        chartStockByDetailStart: chartStockByDetail(parcels, startDate, t),
        chartStockByTypeStart: chartStockByType(parcels, startDate, t),
        chartStockByDetailEnd: chartStockByDetail(parcels, endDate, t),
        chartStockByTypeEnd: chartStockByType(parcels, endDate, t),
      };

      return chartsData;
    } catch (error: unknown) {
      appFaultModel.catchAppError("HandleChartModel.calcHandleChart", { companyId, startDate, endDate, t }, error);
      return {
        chartHandlesByDetail: {},
        chartHandlesByType: {},
        chartStockByDetailStart: {},
        chartStockByTypeStart: {},
        chartStockByDetailEnd: {},
        chartStockByTypeEnd: {},
      };
    }
  }
}

export const handleChartModel: HandleChartModel = new HandleChartModel();
