import { RouteRecordRaw } from "vue-router";

export const transportTypeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/transport-types/:id/edit",
    name: "TransportTypeEdit",
    component: () => import("../views/TransportTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "transportType",
      requiredRight: "viewUI",
    },
  },
];
