import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";

export const chartHandlesByType = (parcels: Parcel[], startDate: Date, endDate: Date, t: (entry: string) => string): Record<string, unknown> => {
  try {
    if (parcels.length == 0) return {};

    const sets: Record<string, number[]> = {};
    for (const parcel of parcels) {
      const type: string = parcel.type?.name ?? t("statHandleChart.noType");

      for (const action of parcel.actions) {
        const actionStart: Date = new Date(action.date.getTime());
        actionStart.setHours(0, 0, 0, 0);
        const actionEnd: Date = new Date(action.date.getTime());
        actionEnd.setHours(23, 59, 59, 999);

        if (action.action == ParcelActionType.Created) {
          if (compareAsc(actionEnd, startDate) >= 0 && compareAsc(actionStart, endDate) <= 0) {
            if (Object.keys(sets).includes(type)) {
              sets[type][0] += 1;
            } else {
              sets[type] = [1, 0];
            }
          }
        } else if ([ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) {
          if (compareAsc(actionEnd, startDate) >= 0 && compareAsc(actionStart, endDate) <= 0) {
            if (Object.keys(sets).includes(type)) {
              sets[type][1] += 1;
            } else {
              sets[type] = [0, 1];
            }
          }
        }
      }
    }

    const orderedSets: Record<string, number[]> = DataHelpers.sortObjectKeys(sets) as Record<string, number[]>;

    const chartData: Record<string, unknown> = {
      labels: [t("statHandleChart.inbounds"), t("statHandleChart.outbounds")],
      datasets: Object.keys(orderedSets).map((type: string, index: number) => {
        return {
          backgroundColor: ChartHelpers.getChartBackgroundColor(index),
          borderColor: ChartHelpers.getChartBorderColor(index),
          borderWidth: 2,
          data: [orderedSets[type][0], orderedSets[type][1]],
          label: type,
        };
      }),
    };

    return chartData;
  } catch (error: unknown) {
    appFaultModel.catchAppError("HandleChartModel.chartHandlesByType", { parcels, startDate, endDate, t }, error);
    return {};
  }
};
