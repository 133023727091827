import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Invoice } from "../objects/Invoice";

import { getPreviousInvoiceByCompany, getInvoicesByYear, markAsCompleted } from "./methods";

export class InvoiceModel extends FirestoreModel<Invoice> {
  public constructor() {
    super(() => new Invoice(), "invoices", LockPolicy.DiscardUnsyncedChanges, "invoice");
  }

  public async getDocuments(): Promise<Invoice[]> {
    return super.getDocuments();
  }

  public async getDocument(invoiceId: string): Promise<Invoice> {
    return super.getDocument(invoiceId);
  }

  public async createDocument(invoice: Invoice): Promise<string> {
    return super.createDocument(invoice);
  }

  public async updateDocument(invoice: Invoice): Promise<void> {
    return super.updateDocument(invoice);
  }

  public async deleteDocument(invoice: Invoice): Promise<boolean> {
    return super.deleteDocument(invoice);
  }

  /* custom methods */

  public getPreviousInvoiceByCompany(companyId: string, monthDate: Date): Promise<Invoice | undefined> {
    return getPreviousInvoiceByCompany(companyId, monthDate);
  }

  public getInvoicesByYear(year: number): Promise<Invoice[]> {
    return getInvoicesByYear(year);
  }

  public markAsCompleted(invoice: Invoice): Promise<void> {
    return markAsCompleted(invoice);
  }
}

export const invoiceModel: InvoiceModel = new InvoiceModel();
