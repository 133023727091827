import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { WarehouseLocation } from "../objects/WarehouseLocation";
import { warehouseLocationModel } from "../models/WarehouseLocationModel";

export const warehouseLocationSearch: SearchModule = new SearchModule(
  "warehouseLocation.warehouseLocation",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as WarehouseLocation).code as string),
  (firestoreDocument: FirestoreDocument) => `/warehouseLocations/${firestoreDocument.id}/edit`,
  "inbox",
  warehouseLocationModel,
  [new SortCriteria("code", "asc", "string")]
);
