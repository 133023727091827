import { RouteRecordRaw } from "vue-router";

export const dpiPurchaseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dpi-purchases",
    name: "DpiPurchaseList",
    component: () => import("../views/DpiPurchaseList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiPurchase",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/dpi-purchases/:id/edit",
    name: "DpiPurchaseEdit",
    component: () => import("../views/DpiPurchaseEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiPurchase",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/dpi-purchases/:id",
    name: "DpiPurchaseView",
    component: () => import("../views/DpiPurchaseView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiPurchase",
      requiredRight: "viewUI",
    },
  },
];
