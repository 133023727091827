import { DpiPurchase } from "@/features/modules/dpiPurchase/objects/DpiPurchase";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { StoredDpi } from "../objects/StoredDpi";

import { getStoredDpiByDpiAndSize, storeFromDpiPurchase } from "./methods";

export class StoredDpiModel extends FirestoreModel<StoredDpi> {
  public constructor() {
    super(() => new StoredDpi(), "storedDpis", LockPolicy.DiscardUnsyncedChanges, "storedDpi");
  }

  public async getDocuments(): Promise<StoredDpi[]> {
    return super.getDocuments();
  }

  public async getDocument(storedDpiId: string): Promise<StoredDpi> {
    return super.getDocument(storedDpiId);
  }

  public async createDocument(storedDpi: StoredDpi): Promise<string> {
    return super.createDocument(storedDpi);
  }

  public async updateDocument(storedDpi: StoredDpi): Promise<void> {
    return super.updateDocument(storedDpi);
  }

  public async deleteDocument(storedDpi: StoredDpi): Promise<boolean> {
    return super.deleteDocument(storedDpi);
  }

  /* custom methods */

  public async getStoredDpiByDpiAndSize(dpiId: string, size: string): Promise<StoredDpi | undefined> {
    return getStoredDpiByDpiAndSize(dpiId, size);
  }

  public async storeFromDpiPurchase(dpiPurchase: DpiPurchase): Promise<void> {
    return storeFromDpiPurchase(dpiPurchase);
  }
}

export const storedDpiModel: StoredDpiModel = new StoredDpiModel();
