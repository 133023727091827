import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OutboundShipment } from "../objects/OutboundShipment";
import { OutboundShipmentHelpers } from "../helpers/OutboundShipmentHelpers";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import {
  generateCode,
  getOutboundShipmentByCode,
  getOutboundShipmentsByCompanyAndPeriod,
  getOutboundShipmentsByPeriod,
  getWorkingOutboundShipments,
  markAsSent,
  updateLinkedParcels,
} from "./methods";

export class OutboundShipmentModel extends FirestoreModel<OutboundShipment> {
  public constructor() {
    super(() => new OutboundShipment(), "outboundShipments", LockPolicy.DiscardUnsyncedChanges, "outboundShipment");
    this.beforeDeleteFunction = "featuresOutboundShipmentBeforeDelete";
  }

  public async getDocuments(): Promise<OutboundShipment[]> {
    return super.getDocuments([new SortCriteria("date", "desc", "date")]);
  }

  public async getDocument(outboundShipmentId: string): Promise<OutboundShipment> {
    return super.getDocument(outboundShipmentId);
  }

  public async createDocument(outboundShipment: OutboundShipment): Promise<string> {
    try {
      const duplicatedOutboundShipment: OutboundShipment | undefined = await this.getOutboundShipmentByCode(outboundShipment.code as string);
      if (duplicatedOutboundShipment !== undefined) throw new Error("duplicatedCode");

      const checkResult: boolean = await OutboundShipmentHelpers.checkParcels(outboundShipment);
      if (checkResult === false) throw new Error("checkParcels failed");

      const outboundShipmentId: string = await super.createDocument(outboundShipment);
      outboundShipment.id = outboundShipmentId;

      await updateLinkedParcels(outboundShipment);

      return outboundShipmentId;
    } catch (error: unknown) {
      appFaultModel.catchAppError("OutboundShipmentModel.createDocument", { outboundShipment }, error);
      return "ERROR";
    }
  }

  public async updateDocument(outboundShipment: OutboundShipment): Promise<void> {
    try {
      const duplicatedOutboundShipment: OutboundShipment | undefined = await outboundShipmentModel.getOutboundShipmentByCode(
        outboundShipment.code as string
      );
      if (duplicatedOutboundShipment != undefined && duplicatedOutboundShipment.id != outboundShipment.id) throw new Error("duplicatedCode");

      const checkResult: boolean = await OutboundShipmentHelpers.checkParcels(outboundShipment);
      if (checkResult === false) throw new Error("checkParcels failed");

      await updateLinkedParcels(outboundShipment);

      outboundShipment.setState();

      return super.updateDocument(outboundShipment);
    } catch (error: unknown) {
      appFaultModel.catchAppError("OutboundShipmentModel.updateDocument", { outboundShipment }, error);
    }
  }

  public async deleteDocument(outboundShipment: OutboundShipment): Promise<boolean> {
    return super.deleteDocument(outboundShipment);
  }

  public async generateCode(): Promise<string> {
    return generateCode();
  }

  public async getOutboundShipmentByCode(code: string): Promise<OutboundShipment | undefined> {
    return getOutboundShipmentByCode(code);
  }

  public async getOutboundShipmentsByCompanyAndPeriod(companyId: string, startDate: Date, endDate: Date): Promise<OutboundShipment[]> {
    return getOutboundShipmentsByCompanyAndPeriod(companyId, startDate, endDate);
  }

  public async getOutboundShipmentsByPeriod(startDate: Date, endDate: Date): Promise<OutboundShipment[]> {
    return getOutboundShipmentsByPeriod(startDate, endDate);
  }

  public async getWorkingOutboundShipments(): Promise<OutboundShipment[]> {
    return getWorkingOutboundShipments();
  }

  public async markAsSent(outboundShipment: OutboundShipment): Promise<void> {
    return markAsSent(outboundShipment);
  }
}

export const outboundShipmentModel: OutboundShipmentModel = new OutboundShipmentModel();
