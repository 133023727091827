import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";

import { ArrayByKeyField, NumberField, StringArrayField, StringField } from "@/core/fields";

export class WarehouseLocation extends FirestoreDocument {
  public warehouse: string | undefined = undefined;
  public type: string | undefined = undefined;
  public zone: string | undefined = undefined;
  public lane: string | undefined = undefined;
  public span: string | undefined = undefined;
  public position: string | undefined = undefined;
  public floor: string | undefined = undefined;
  public code: string | undefined = undefined;
  public capacity = 0;
  public parcels: Record<string, LinkedParcel> = {};
  public parcelTypesIds: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): WarehouseLocation {
    super.fromFirestore(data, id, firestoreRef);

    this.warehouse = StringField.fromFirestore(data.warehouse);
    this.type = StringField.fromFirestore(data.type);
    this.zone = StringField.fromFirestore(data.zone);
    this.lane = StringField.fromFirestore(data.lane);
    this.span = StringField.fromFirestore(data.span);
    this.position = StringField.fromFirestore(data.position);
    this.floor = StringField.fromFirestore(data.floor);
    this.code = StringField.fromFirestore(data.code);
    this.capacity = NumberField.fromFirestore(data.capacity);
    this.parcels = ArrayByKeyField.fromFirestore<LinkedParcel>(data.parcels, (value) => new LinkedParcel(value));
    this.parcelTypesIds = StringArrayField.fromFirestore(data.parcelTypesIds);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.warehouse = StringField.toFirestore(this.warehouse);
    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.zone = StringField.toFirestore(this.zone);
    firestoreData.lane = StringField.toFirestore(this.lane);
    firestoreData.span = StringField.toFirestore(this.span);
    firestoreData.position = StringField.toFirestore(this.position);
    firestoreData.floor = StringField.toFirestore(this.floor);
    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.capacity = NumberField.toFirestore(this.capacity);
    firestoreData.parcels = ArrayByKeyField.toFirestore<LinkedParcel>(this.parcels, (value) => value.toFirestore());
    firestoreData.parcelTypesIds = StringArrayField.toFirestore(this.parcelTypesIds);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.code !== undefined) this.searchKeys.push(this.code.toLowerCase());
  }

  public getLinkedParcels(): LinkedParcel[] {
    return DataHelpers.objectToSortedArray<LinkedParcel>(this.parcels);
  }

  public setLinkedParcels(linkedParcels: LinkedParcel[]): void {
    this.parcels = DataHelpers.sortedArrayToObject<LinkedParcel>(linkedParcels);
    const parcelTypes: string[] = Object.values(this.parcels).map((linkedParcel) => linkedParcel.type?.id ?? "EMPTY");
    this.parcelTypesIds = parcelTypes.filter(function (linkedParcelTypeId: string, index: number) {
      return linkedParcelTypeId !== "EMPTY" && parcelTypes.indexOf(linkedParcelTypeId) === index;
    });
  }

  public addLinkedParcel(linkedParcel: LinkedParcel): void {
    this.parcels[linkedParcel.id] = linkedParcel;
    const parcelTypes: string[] = Object.values(this.parcels).map((linkedParcel) => linkedParcel.type?.id ?? "EMPTY");
    this.parcelTypesIds = parcelTypes.filter(function (linkedParcelTypeId: string, index: number) {
      return linkedParcelTypeId !== "EMPTY" && parcelTypes.indexOf(linkedParcelTypeId) === index;
    });
  }

  public removeLinkedParcel(linkedParcel: LinkedParcel): void {
    delete this.parcels[linkedParcel.id];
    if (linkedParcel.type === undefined) return;
    const parcelTypes: string[] = Object.values(this.parcels).map((linkedParcel) => linkedParcel.type?.id ?? "EMPTY");
    this.parcelTypesIds = parcelTypes.filter(function (linkedParcelTypeId: string, index: number) {
      return linkedParcelTypeId !== "EMPTY" && parcelTypes.indexOf(linkedParcelTypeId) === index;
    });
  }

  public emptyLinkedParcels(): void {
    this.parcels = {};
    this.parcelTypesIds = [];
  }
}
