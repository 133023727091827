import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export const getOfflineState = (): "online" | "offline" => {
  try {
    const localStorageNetworkState: string | null = localStorage.getItem("koruOfflineState");

    return localStorageNetworkState !== null && localStorageNetworkState.trim().length > 0
      ? (localStorageNetworkState as "online" | "offline")
      : "online";
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.getOfflineState", {}, error);
    return "online";
  }
};
