export const it = {
  aedProduct: {
    addAedProduct: "Aggiungi prodotto collegato",
    aedProduct: "Prodotto",
    aedProducts: "Prodotti",
    code: "Codice",
    codeError: "Inserire un codice valido e univoco",
    description: "Descrizione",
    discountType: "Sconto",
    discountTypeError: "Selezionare un tipo di sconto",
    discountTypes: {
      amount: "Importo",
      none: "Nessuno",
      percentage: "Percentuale",
    },
    discountValue: "Valore sconto",
    discountValueError: "Inserire un valore sconto valido",
    expirationDate: "Data di scadenza",
    general: "Generali",
    hasExpirationDate: "Data di scadenza",
    hasExpirationDateError: "Selezionare un valore",
    hasSerialNumber: "Numero seriale",
    hasSerialNumberError: "Selezionare un valore",
    info: "Informazioni",
    image: "Immagine",
    linkedAedProducts: "Prodotti collegati",
    name: "Nome",
    nameError: "Inserire un nome valido",
    notes: "Note",
    price: "Prezzo",
    priceError: "Inserire un prezzo valido",
    quantity: "Quantità",
    quantityError: "Inserire una quantità valida",
    serialNumber: "Numero seriale",
    stockQuantity: "Quantità in magazzino",
    stockQuantityError: "Inserire una quantità in magazzino valida",
  },
  navigation: {
    aedProduct: "Prodotti",
  },
};
