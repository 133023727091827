export const it = {
  branch: {
    address: "Indirizzo",
    addressFields: {
      country: "Paese",
      province: "Provincia",
      street: "Indirizzo",
      town: "Comune",
      zipCode: "CAP",
    },
    branch: "Cantiere",
    branchOf: "Cantiere di {parent}",
    branches: "Cantieri",
    branchesOf: "Cantieri di {parent}",
    company: "Azienda",
    companyError: "Selezionare una azienda",
    docFolder: "Fascicolo",
    editablePastDays: "Giorni passati modificabili",
    filters: {
      archived: "Cantieri archiviati",
      archivedExcluded: "Archiviati esclusi",
      archivedIncluded: "Archiviati inclusi",
    },
    name: "Nome",
    nameError: "Inserire un nome valido",
    province: "Provincia",
    notes: "Note",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attivo",
      archived: "Archiviato",
    },
    timemotoId: "ID Timemoto",
    timemotoIdError: "Inserire un ID Timemoto valido",
    town: "Comune",
    weekDays: "Giorni lavorativi della settimana",
    zipCode: "CAP",
  },
  navigation: {
    branch: "Cantieri",
  },
  buttons: {
    archiveBranch: "Metti in archivio",
    goToEmployeeRoster: "Vai ai turni",
    restoreBranch: "Togli dall'archivio",
  },
};
