import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeRoster } from "./EmployeeRoster";

export class EmployeeWithRoster {
  public employee: Employee;
  public roster: EmployeeRoster;
  public allowedDays: number[];

  public constructor(employee: Employee, roster: EmployeeRoster, allowedDays: number[]) {
    this.employee = employee;
    this.roster = roster;
    this.allowedDays = allowedDays;
  }
}
