import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "../ParcelModel";

export const generateCode = async (newSet: string[] | undefined): Promise<string> => {
  try {
    let newCode = "ERROR";
    let duplicatedParcel: Parcel | undefined = new Parcel();

    while (duplicatedParcel != undefined && (newSet == undefined || newSet.indexOf(newCode) === -1)) {
      newCode = FeaturesHelpers.generateCode("P");
      duplicatedParcel = await parcelModel.getParcelByCode(newCode);
    }

    return newCode;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.generateCode", { newSet }, error);
    return "ERROR";
  }
};
