
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";
import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";

export default defineComponent({
  name: "KoruTextOverlay",
  setup() {
    let eventBusIdSetEvent: string | undefined = undefined;
    let eventBusIdShow: string | undefined = undefined;

    const event: Ref<MouseEvent | undefined> = ref(undefined);
    const overlayPanel = ref();
    const textRef: Ref<string> = ref("");

    onMounted(() => {
      eventBusIdSetEvent = eventBus.on("textOverlaySetEvent", async (e?: MouseEvent): Promise<void> => {
        if (e === undefined) return;
        event.value = e;
      });

      eventBusIdShow = eventBus.on("textOverlayShow", async (text?: string): Promise<void> => {
        if (text === undefined || event.value === undefined) return Promise.resolve();

        textRef.value = text;
        overlayPanel.value.toggle(event.value);
      });
    });

    onUnmounted(() => {
      if (eventBusIdSetEvent !== undefined) eventBus.off(eventBusIdSetEvent);
      if (eventBusIdShow !== undefined) eventBus.off(eventBusIdShow);
    });

    return {
      FormatHelpers,
      overlayPanel,
      textRef,
    };
  },
});
