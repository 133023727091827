import { BranchDpi } from "@/features/modules/branchDpi/objects/BranchDpi";
import { branchDpiModel } from "@/features/modules/branchDpi/models/BranchDpiModel";
import { DpiHandling } from "../objects/DpiHandling";
import { EmployeeDpi } from "@/features/modules/employeeDpi/objects/EmployeeDpi";
import { employeeDpiModel } from "@/features/modules/employeeDpi/models/EmployeeDpiModel";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { storedDpiModel } from "@/features/modules/storedDpi/models/StoredDpiModel";

export class DpiHandlingHelpers {
  public static async checkDpisAvailabilityInBranch(dpiHandling: DpiHandling, branchId: string): Promise<string> {
    for (const handledDpi of dpiHandling.getHandledDpis()) {
      if (handledDpi.dpi === undefined) throw new Error("handledDpi dpi is undefined");
      if (handledDpi.size === undefined) throw new Error("handledDpi size is undefined");

      const branchDpi: BranchDpi | undefined = await branchDpiModel.getBranchDpiByDpiAndSize(handledDpi.dpi.id, handledDpi.size, branchId);
      if (branchDpi === undefined) return "dpiNotAvailable";
      if (branchDpi.quantity < handledDpi.quantity) return "dpiNotAvailable";
    }
    return "OK";
  }

  public static async checkDpisAvailabilityInEmployee(dpiHandling: DpiHandling, employeeId: string): Promise<string> {
    for (const handledDpi of dpiHandling.getHandledDpis()) {
      if (handledDpi.dpi === undefined) throw new Error("handledDpi dpi is undefined");
      if (handledDpi.size === undefined) throw new Error("handledDpi size is undefined");

      const employeeDpi: EmployeeDpi | undefined = await employeeDpiModel.getEmployeeDpiByDpiAndSize(handledDpi.dpi.id, handledDpi.size, employeeId);
      if (employeeDpi === undefined) return "dpiNotAvailable";
      if (employeeDpi.quantity < handledDpi.quantity) return "dpiNotAvailable";
    }
    return "OK";
  }

  public static async checkDpisAvailabilityInWarehouse(dpiHandling: DpiHandling): Promise<string> {
    for (const handledDpi of dpiHandling.getHandledDpis()) {
      if (handledDpi.dpi === undefined) throw new Error("handledDpi dpi is undefined");
      if (handledDpi.size === undefined) throw new Error("handledDpi size is undefined");

      const storedDpi: StoredDpi | undefined = await storedDpiModel.getStoredDpiByDpiAndSize(handledDpi.dpi.id, handledDpi.size);
      if (storedDpi === undefined) return "dpiNotAvailable";
      if (storedDpi.quantity < handledDpi.quantity) return "dpiNotAvailable";
    }
    return "OK";
  }
}
