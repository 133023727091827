import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocDownload } from "./DocDownload";
import { DocOwner } from "./DocOwner";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedDocType } from "@/features/modules/docType/objects/LinkedDocType";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";

import { ArrayField, DateField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class Doc extends FirestoreDocument {
  public date: Date | undefined = new Date();
  public name: string | undefined = undefined;
  public type: LinkedDocType | undefined = undefined;
  public expiration: Date | undefined = undefined;
  public expirationSort = "9999-99-99";
  public path: string | undefined = undefined;
  public files: StorageFile[] = [];
  public owners: DocOwner[] = [];
  public notes: string | undefined = undefined;
  public downloads: DocDownload[] = [];
  public downloadSources: DocTypeSource[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Doc {
    super.fromFirestore(data, id, firestoreRef);

    this.date = DateField.fromFirestore(data.date);
    this.name = StringField.fromFirestore(data.name);
    this.type = ObjectField.fromFirestore<LinkedDocType>(data.type, (value) => new LinkedDocType(value));
    this.expiration = DateField.fromFirestore(data.expiration);
    this.expirationSort = StringStrictField.fromFirestore(data.expirationSort, "9999-99-99");
    this.path = StringField.fromFirestore(data.path);
    this.files = ArrayField.fromFirestore<StorageFile>(data.files, (value) => new StorageFile(value));
    this.owners = ArrayField.fromFirestore<DocOwner>(data.owners, (value) => new DocOwner(value));
    this.notes = StringField.fromFirestore(data.notes);
    this.downloads = ArrayField.fromFirestore<DocDownload>(data.downloads, (value) => new DocDownload(value));
    this.downloadSources = ArrayField.fromFirestore<DocTypeSource>(data.downloadSources, (value) => value as unknown as DocTypeSource);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.type = ObjectField.toFirestore<LinkedDocType>(this.type, (value) => value.toFirestore());
    firestoreData.expiration = DateField.toFirestore(this.expiration);
    firestoreData.expirationSort = DateField.toStringValue(this.expiration, "9999-99-99");
    firestoreData.path = StringField.toFirestore(this.path);
    firestoreData.files = ArrayField.toFirestore<StorageFile>(this.files, (value) => value.toFirestore());
    firestoreData.owners = ArrayField.toFirestore<DocOwner>(this.owners, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.downloads = ArrayField.toFirestore<DocDownload>(this.downloads, (value) => value.toFirestore());
    firestoreData.downloadSources = ArrayField.toFirestore<DocTypeSource>(this.downloadSources, (value) => value as string);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
  }
}
