import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";
import { ParcelWithAction } from "../objects/ParcelWithAction";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { ParcelState } from "@/features/modules/parcel/objects/ParcelState";

export class HandleCodeModel {
  public async calcHandleCode(companyId: string, itemCode: string, startDate: Date, endDate: Date): Promise<ParcelWithAction[]> {
    try {
      if (companyId === undefined) throw new Error("companyIdUndefined");
      if (itemCode === undefined) throw new Error("itemCodeUndefined");
      if (startDate === undefined) throw new Error("startDateUndefined");
      if (endDate === undefined) throw new Error("endDateUndefined");

      const parcels: Parcel[] = await parcelModel.getParcelsByCompanyAndPeriodAndItemCode(companyId, startDate, endDate, itemCode);

      const results: ParcelWithAction[] = [];

      for (const parcel of parcels) {
        if (parcel.state === ParcelState.Deleted) continue;

        for (const parcelAction of parcel.actions) {
          if (parcelAction.action == ParcelActionType.Created || parcelAction.action == ParcelActionType.Sent) {
            for (const parcelItem of parcel.items) {
              if (parcelItem.code == itemCode) {
                results.push({
                  inboundShipment: parcel.inboundShipment,
                  outboundShipment: parcel.outboundShipment,
                  type: parcel.type,
                  code: parcel.code,
                  quantity: parcelItem.quantity,
                  action: parcelAction,
                });
              }
            }
          }
        }
      }

      return results;
    } catch (error: unknown) {
      appFaultModel.catchAppError("HandleCodeModel.calcHandleCode", { companyId, itemCode, startDate, endDate }, error);
      return [];
    }
  }
}

export const handleCodeModel: HandleCodeModel = new HandleCodeModel();
