export const it = {
  medicalExamType: {
    isMandatory: "Obbligatoria",
    isMandatoryError: "Scegliere un valore valido",
    medicalExamType: "Tipo di idoneità",
    medicalExamTypes: "Tipi di idoneità",
    months: "1 mese | {count} mesi",
    name: "Nome",
    nameError: "Inserire un nome valido",
    validity: "Validità",
    validityError: "Scegliere un valore valido",
  },
  navigation: {
    medicalExamType: "Tipi di idoneità",
  },
};
