import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "../ParcelModel";

export const getParcelsByWarehouseLocation = async (warehouseLocationId: string): Promise<Parcel[]> => {
  try {
    const snapshotQuery: Query<Parcel> = query(
      parcelModel.getPathReference().withConverter(parcelModel.firestoreConverter),
      where("warehouseLocation.id", "==", warehouseLocationId)
    );

    const snapshot: QuerySnapshot<Parcel> = await getDocs(parcelModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.getParcelsByWarehouseLocation", { warehouseLocationId }, error);
    return [];
  }
};
