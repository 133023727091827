import { activityRoutes } from "@/features/modules/activity/router/routes";
import { aedCompanyRoutes } from "@/features/modules/aedCompany/router/routes";
import { aedProductRoutes } from "@/features/modules/aedProduct/router/routes";
import { aedPurchaseRoutes } from "@/features/modules/aedPurchase/router/routes";
import { aedSaleRoutes } from "@/features/modules/aedSale/router/routes";
import { assignmentRoutes } from "@/features/modules/assignment/router/routes";
import { bankRoutes } from "@/features/modules/bank/router/routes";
import { branchPositionRoutes } from "@/features/modules/branchPosition/router/routes";
import { branchRoutes } from "@/features/modules/branch/router/routes";
import { branchShiftTypesRoutes } from "@/features/modules/branchShiftType/router/routes";
import { companyRoutes } from "@/features/modules/company/router/routes";
import { configurationRoutes } from "@/features/modules/configuration/router/routes";
import { courseTypeRoutes } from "@/features/modules/courseType/router/routes";
import { dashboardRoutes } from "@/features/modules/dashboard/router/routes";
import { docFolderRoutes } from "@/features/modules/docFolder/router/routes";
import { docRoutes } from "@/features/modules/doc/router/routes";
import { docTypeRoutes } from "@/features/modules/docType/router/routes";
import { dpiAlertRoutes } from "@/features/modules/dpiAlert/router/routes";
import { dpiHandlingRoutes } from "@/features/modules/dpiHandling/router/routes";
import { dpiPurchaseRoutes } from "@/features/modules/dpiPurchase/router/routes";
import { dpiRoutes } from "@/features/modules/dpi/router/routes";
import { employeeContractRoutes } from "@/features/modules/employeeContract/router/routes";
import { employeeCourseRoutes } from "@/features/modules/employeeCourse/router/routes";
import { employeeLeaveRoutes } from "@/features/modules/employeeLeave/router/routes";
import { employeeMedicalExamRoutes } from "@/features/modules/employeeMedicalExam/router/routes";
import { employeePositionRoutes } from "@/features/modules/employeePosition/router/routes";
import { employeeRosterRoutes } from "@/features/modules/employeeRoster/router/routes";
import { employeeRoutes } from "@/features/modules/employee/router/routes";
import { employeeTransactionRoutes } from "@/features/modules/employeeTransaction/router/routes";
import { employeeWarningRoutes } from "@/features/modules/employeeWarning/router/routes";
import { expenseTypeRoutes } from "@/features/modules/expenseType/router/routes";
import { forkliftRoutes } from "@/features/modules/forklift/router/routes";
import { inboundShipmentRoutes } from "@/features/modules/inboundShipment/router/routes";
import { invoiceRoutes } from "@/features/modules/invoice/router/routes";
import { leaveTypeRoutes } from "@/features/modules/leaveType/router/routes";
import { medicalExamTypeRoutes } from "@/features/modules/medicalExamType/router/routes";
import { outboundShipmentRoutes } from "@/features/modules/outboundShipment/router/routes";
import { parcelDetailRoutes } from "@/features/modules/parcelDetail/router/routes";
import { parcelRoutes } from "@/features/modules/parcel/router/routes";
import { parcelTypeRoutes } from "@/features/modules/parcelType/router/routes";
import { penaltyTypeRoutes } from "@/features/modules/penaltyType/router/routes";
import { priceListRoutes } from "@/features/modules/priceList/router/routes";
import { rawClockingRoutes } from "@/features/modules/rawClocking/router/routes";
import { storedDpiRoutes } from "@/features/modules/storedDpi/router/routes";
import { timekeeperRoutes } from "@/features/modules/timekeeper/router/routes";
import { transactionTypeRoutes } from "@/features/modules/transactionType/router/routes";
import { transportTypeRoutes } from "@/features/modules/transportType/router/routes";
import { vehicleExpenseRoutes } from "@/features/modules/vehicleExpense/router/routes";
import { vehiclePositionRoutes } from "@/features/modules/vehiclePosition/router/routes";
import { vehicleRoutes } from "@/features/modules/vehicle/router/routes";
import { vehicleTypeRoutes } from "@/features/modules/vehicleType/router/routes";
import { warehouseLocationRoutes } from "@/features/modules/warehouseLocation/router/routes";
import { warningTypeRoutes } from "@/features/modules/warningType/router/routes";

export const featuresRoutes = [
  ...activityRoutes,
  ...aedCompanyRoutes,
  ...aedProductRoutes,
  ...aedPurchaseRoutes,
  ...aedSaleRoutes,
  ...assignmentRoutes,
  ...bankRoutes,
  ...branchPositionRoutes,
  ...branchShiftTypesRoutes,
  ...branchRoutes,
  ...companyRoutes,
  ...configurationRoutes,
  ...courseTypeRoutes,
  ...dashboardRoutes,
  ...docFolderRoutes,
  ...docRoutes,
  ...docTypeRoutes,
  ...dpiAlertRoutes,
  ...dpiHandlingRoutes,
  ...dpiPurchaseRoutes,
  ...dpiRoutes,
  ...employeeContractRoutes,
  ...employeeCourseRoutes,
  ...employeeLeaveRoutes,
  ...employeeMedicalExamRoutes,
  ...employeePositionRoutes,
  ...employeeRosterRoutes,
  ...employeeRoutes,
  ...employeeTransactionRoutes,
  ...employeeWarningRoutes,
  ...expenseTypeRoutes,
  ...forkliftRoutes,
  ...inboundShipmentRoutes,
  ...leaveTypeRoutes,
  ...medicalExamTypeRoutes,
  ...outboundShipmentRoutes,
  ...parcelDetailRoutes,
  ...parcelRoutes,
  ...parcelTypeRoutes,
  ...penaltyTypeRoutes,
  ...priceListRoutes,
  ...rawClockingRoutes,
  ...storedDpiRoutes,
  ...timekeeperRoutes,
  ...transactionTypeRoutes,
  ...transportTypeRoutes,
  ...vehicleExpenseRoutes,
  ...vehiclePositionRoutes,
  ...vehicleRoutes,
  ...vehicleTypeRoutes,
  ...invoiceRoutes,
  ...warehouseLocationRoutes,
  ...warningTypeRoutes,
];
