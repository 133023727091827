import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";

import { EnumField, StringField } from "@/core/fields";

export class DocOwner {
  public source: DocTypeSource = DocTypeSource.Company;
  public sourceId: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): DocOwner {
    this.source = EnumField.fromFirestore<DocTypeSource>(data.source, Object.values(DocTypeSource), DocTypeSource.Company);
    this.sourceId = StringField.fromFirestore(data.sourceId);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.source = EnumField.toFirestore<DocTypeSource>(this.source, DocTypeSource.Company);
    firestoreData.sourceId = StringField.toFirestore(this.sourceId);

    return firestoreData;
  }
}
