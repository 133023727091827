import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class EmployeeHelpers {
  public static getDocFolderPath(employeeId: string | undefined): string {
    try {
      if (employeeId === undefined) throw new Error("employeeId is undefined");
      return `employees/${employeeId}/docs/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeHelpers.getDocFolderPath", { employeeId }, error);
      throw error;
    }
  }
}
