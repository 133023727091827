import { addDays, compareAsc, endOfMonth, getDate, getMonth, getYear, startOfMonth } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { EmployeeLeave } from "../objects/EmployeeLeave";
import { EmployeeRoster } from "@/features/modules/employeeRoster/objects/EmployeeRoster";
import { employeeRosterModel } from "@/features/modules/employeeRoster/models/EmployeeRosterModel";
import { LinkedEmployeeLeave } from "../objects/LinkedEmployeeLeave";
import { Shift } from "@/features/modules/employeeRoster/objects/Shift";

export class EmployeeLeaveHelpers {
  public static async addRosterLeave(employeeLeave: EmployeeLeave, employeeId: string): Promise<void> {
    // TODOCOOP: calc warnings for updated shifts

    if (employeeLeave.from === undefined || employeeLeave.to === undefined) throw new Error("dateUndefined");
    if (employeeId === undefined) throw new Error("employeeIdUndefined");

    let loopDate: Date = employeeLeave.from;
    const to: Date = employeeLeave.to;

    const linkedEmployeeLeave: LinkedEmployeeLeave = LinkedEmployeeLeave.createFromEmployeeLeave(employeeLeave);

    const employeeRosters: EmployeeRoster[] = await employeeRosterModel.getDocuments([], employeeId);

    while (compareAsc(loopDate, to) <= 0) {
      const loopDay = String(getDate(loopDate));
      const loopMonth: number = getMonth(loopDate) + 1;
      const loopYear: number = getYear(loopDate);

      const employeeRoster: EmployeeRoster | undefined = employeeRosters.find(
        (employeeRoster: EmployeeRoster) => employeeRoster.month === loopMonth && employeeRoster.year === loopYear
      );

      if (employeeRoster === undefined) {
        // create a new roster for the employee
        const employeeRoster = new EmployeeRoster();
        employeeRoster.month = loopMonth;
        employeeRoster.year = loopYear;
        let from: Date = startOfMonth(new Date(loopYear, loopMonth - 1, 1));
        const to: Date = endOfMonth(from);
        while (compareAsc(from, to) < 0) {
          employeeRoster.shifts[getDate(from).toFixed()] = new Shift();
          from = addDays(from, 1);
        }

        employeeRoster.id = await employeeRosterModel.createDocument(employeeRoster, employeeId);
        employeeRoster.firestoreRef = employeeRosterModel.getDocumentReference(employeeRoster.id, employeeId);

        const shift: Shift = new Shift();
        shift.leave = linkedEmployeeLeave;

        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}`]: shift.toFirestore() });

        employeeRosters.push(employeeRoster);
      } else if (loopDay in employeeRoster.shifts === false) {
        // create a new roster shift for the employee
        const shift: Shift = new Shift();
        shift.leave = linkedEmployeeLeave;

        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}`]: shift.toFirestore() });
      } else {
        // update the roster shift
        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}.leave`]: linkedEmployeeLeave.toFirestore() });
      }

      loopDate = addDays(loopDate, 1);
    }

    return batch.commit();
  }

  public static async deleteRosterLeave(employeeLeave: EmployeeLeave, employeeId: string): Promise<void> {
    // TODOCOOP: calc warnings for updated shifts

    if (employeeLeave.from === undefined || employeeLeave.to === undefined) throw new Error("dateUndefined");
    if (employeeId === undefined) throw new Error("employeeIdUndefined");

    let loopDate: Date = employeeLeave.from;
    const to: Date = employeeLeave.to;

    const employeeRosters: EmployeeRoster[] = await employeeRosterModel.getDocuments([], employeeId);

    while (compareAsc(loopDate, to) <= 0) {
      const loopDay = String(getDate(loopDate));
      const loopMonth: number = getMonth(loopDate) + 1;
      const loopYear: number = getYear(loopDate);

      const employeeRoster: EmployeeRoster | undefined = employeeRosters.find(
        (employeeRoster: EmployeeRoster) => employeeRoster.month === loopMonth && employeeRoster.year === loopYear
      );

      if (employeeRoster !== undefined && loopDay in employeeRoster.shifts) {
        // update the roster shift
        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}.leave`]: null });
      }

      loopDate = addDays(loopDate, 1);
    }

    return batch.commit();
  }

  public static getFolderPath(employeeId: string | undefined): string {
    try {
      if (employeeId === undefined) throw new Error("employeeId is undefined");
      return `employees/${employeeId}/leaves/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeLeaveHelpers.getFolderPath", { employeeId }, error);
      throw error;
    }
  }

  public static async updateRosterLeave(employeeLeave: EmployeeLeave, employeeId: string, oldEmployeeLeave: EmployeeLeave): Promise<void> {
    // TODOCOOP: calc warnings for updated shifts

    if (employeeLeave.from === undefined || employeeLeave.to === undefined) throw new Error("dateUndefined");
    if (employeeId === undefined) throw new Error("employeeIdUndefined");

    let loopDate: Date = employeeLeave.from;
    let to: Date = employeeLeave.to;

    if (oldEmployeeLeave.from === undefined || oldEmployeeLeave.to === undefined) throw new Error("dateUndefined");
    if (oldEmployeeLeave.from.getTime() === employeeLeave.from.getTime() && oldEmployeeLeave.to.getTime() === employeeLeave.to.getTime()) {
      return Promise.resolve();
    }

    const newDates: Date[] = [];
    while (compareAsc(loopDate, to) <= 0) {
      newDates.push(loopDate);
      loopDate = addDays(loopDate, 1);
    }

    loopDate = oldEmployeeLeave.from;
    to = oldEmployeeLeave.to;
    const oldDates: Date[] = [];
    while (compareAsc(loopDate, to) <= 0) {
      oldDates.push(loopDate);
      loopDate = addDays(loopDate, 1);
    }

    const linkedEmployeeLeave: LinkedEmployeeLeave = LinkedEmployeeLeave.createFromEmployeeLeave(employeeLeave);

    const employeeRosters: EmployeeRoster[] = await employeeRosterModel.getDocuments([], employeeId);

    // process old dates
    for (const oldDate of oldDates) {
      const loopDay = String(getDate(oldDate));
      const loopMonth: number = getMonth(oldDate) + 1;
      const loopYear: number = getYear(oldDate);

      const employeeRoster: EmployeeRoster | undefined = employeeRosters.find(
        (employeeRoster: EmployeeRoster) => employeeRoster.month === loopMonth && employeeRoster.year === loopYear
      );

      if (employeeRoster !== undefined && loopDay in employeeRoster.shifts) {
        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}.leave`]: null });
      }
    }
    await batch.commit();

    // process new dates
    for (const newDate of newDates) {
      const loopDay = String(getDate(newDate));
      const loopMonth: number = getMonth(newDate) + 1;
      const loopYear: number = getYear(newDate);

      const employeeRoster: EmployeeRoster | undefined = employeeRosters.find(
        (employeeRoster: EmployeeRoster) => employeeRoster.month === loopMonth && employeeRoster.year === loopYear
      );

      if (employeeRoster === undefined) {
        // create a new roster for the employee
        const employeeRoster = new EmployeeRoster();
        employeeRoster.month = loopMonth;
        employeeRoster.year = loopYear;
        let from: Date = startOfMonth(new Date(loopYear, loopMonth - 1, 1));
        const to: Date = endOfMonth(from);
        while (compareAsc(from, to) < 0) {
          employeeRoster.shifts[getDate(from).toFixed()] = new Shift();
          from = addDays(from, 1);
        }

        employeeRoster.id = await employeeRosterModel.createDocument(employeeRoster, employeeId);
        employeeRoster.firestoreRef = employeeRosterModel.getDocumentReference(employeeRoster.id, employeeId);

        const shift: Shift = new Shift();
        shift.leave = linkedEmployeeLeave;

        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}`]: shift.toFirestore() });

        employeeRosters.push(employeeRoster);
      } else if (loopDay in employeeRoster.shifts === false) {
        // create a new roster shift for the employee
        const shift: Shift = new Shift();
        shift.leave = linkedEmployeeLeave;

        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}`]: shift.toFirestore() });
      } else {
        // update the roster shift
        if (employeeRoster.firestoreRef === undefined) throw new Error("firestoreRefUndefined");
        batch.update(employeeRoster.firestoreRef, { [`shifts.${loopDay}.leave`]: linkedEmployeeLeave.toFirestore() });
      }
    }

    return batch.commit();
  }
}
