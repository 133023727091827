import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "../ParcelModel";

export const getParcelsByInboundShipment = async (inboundShipmentId: string): Promise<Parcel[]> => {
  try {
    const snapshotQuery: Query<Parcel> = query(
      parcelModel.getPathReference().withConverter(parcelModel.firestoreConverter),
      where("inboundShipment.id", "==", inboundShipmentId)
    );

    const snapshot: QuerySnapshot<Parcel> = await getDocs(parcelModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.getParcelsByInboundShipment", { inboundShipmentId }, error);
    return [];
  }
};

function sortDocuments(documents: Parcel[]): Parcel[] {
  const sorter: FirestoreSorter<Parcel> = new FirestoreSorter(documents);
  sorter.addSortCriteria("createdAt", "asc", "date");
  return sorter.sort();
}
