import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BadgeScanner = _resolveComponent("BadgeScanner")!
  const _component_KoruQrCode = _resolveComponent("KoruQrCode")!
  const _component_KoruPdfDialog = _resolveComponent("KoruPdfDialog")!
  const _component_KoruTextOverlay = _resolveComponent("KoruTextOverlay")!
  const _component_KoruWarehouseLocation = _resolveComponent("KoruWarehouseLocation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BadgeScanner),
    _createVNode(_component_KoruQrCode),
    _createVNode(_component_KoruPdfDialog),
    _createVNode(_component_KoruTextOverlay),
    _createVNode(_component_KoruWarehouseLocation)
  ], 64))
}