/*
import { compareAsc } from "date-fns";
import Holidays from "date-holidays";

import { localeModel } from "@/core/modules/locale/models/LocaleModel";
import { setting } from "@/core/modules/setting/Setting";
import { Shift } from "./Shift";
*/

import { ArrayByKeyField, NumberField } from "@/core/fields";

export class EmployeeRosterCounter {
  public balanceHours = 0;
  public branchHours: Record<string, number> = {};
  public compensatedOvertimeHours = 0;
  public dayHours = 0;
  public holidayDayHours = 0;
  public holidayNightHours = 0;
  public nightHours = 0;
  public overtimeHours = 0;
  public positionHours: Record<string, Record<string, number>> = {};
  public saturdayDayHours = 0;
  public saturdayNightHours = 0;
  public sundayDayHours = 0;
  public sundayNightHours = 0;
  public totalHours = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: unknown): void {
    if (data === undefined || data === null) return;

    const dataObject: Record<string, unknown> = data as Record<string, unknown>;

    this.balanceHours = NumberField.fromFirestore(dataObject.balanceHours);
    this.branchHours = ArrayByKeyField.fromFirestore<number>(dataObject.branchHours, (value) => NumberField.fromFirestore(value));
    this.compensatedOvertimeHours = NumberField.fromFirestore(dataObject.compensatedOvertimeHours);
    this.dayHours = NumberField.fromFirestore(dataObject.dayHours);
    this.holidayDayHours = NumberField.fromFirestore(dataObject.holidayDayHours);
    this.holidayNightHours = NumberField.fromFirestore(dataObject.holidayNightHours);
    this.nightHours = NumberField.fromFirestore(dataObject.nightHours);
    this.overtimeHours = NumberField.fromFirestore(dataObject.overtimeHours);
    this.positionHours = ArrayByKeyField.fromFirestore<Record<string, number>>(dataObject.positionHours, (value) =>
      ArrayByKeyField.fromFirestore<number>(value, (subValue) => NumberField.fromFirestore(subValue))
    );
    this.saturdayDayHours = NumberField.fromFirestore(dataObject.saturdayDayHours);
    this.saturdayNightHours = NumberField.fromFirestore(dataObject.saturdayNightHours);
    this.sundayDayHours = NumberField.fromFirestore(dataObject.sundayDayHours);
    this.sundayNightHours = NumberField.fromFirestore(dataObject.sundayNightHours);
    this.totalHours = NumberField.fromFirestore(dataObject.totalHours);
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.balanceHours = NumberField.toFirestore(this.balanceHours);
    firestoreData.branchHours = this.branchHours ?? {};
    firestoreData.compensatedOvertimeHours = NumberField.toFirestore(this.compensatedOvertimeHours);
    firestoreData.dayHours = NumberField.toFirestore(this.dayHours);
    firestoreData.holidayDayHours = NumberField.toFirestore(this.holidayDayHours);
    firestoreData.holidayNightHours = NumberField.toFirestore(this.holidayNightHours);
    firestoreData.nightHours = NumberField.toFirestore(this.nightHours);
    firestoreData.overtimeHours = NumberField.toFirestore(this.overtimeHours);
    firestoreData.positionHours = this.positionHours ?? {};
    firestoreData.saturdayDayHours = NumberField.toFirestore(this.saturdayDayHours);
    firestoreData.saturdayNightHours = NumberField.toFirestore(this.saturdayNightHours);
    firestoreData.sundayDayHours = NumberField.toFirestore(this.sundayDayHours);
    firestoreData.sundayNightHours = NumberField.toFirestore(this.sundayNightHours);
    firestoreData.totalHours = NumberField.toFirestore(this.totalHours);

    return firestoreData;
  }

  /*
  public calculateCounters(year: number, month: number, shifts: Record<string, Shift>): void {
    this.balanceHours = 0;
    this.branchHours = {};
    this.compensatedOvertimeHours = 0;
    this.dayHours = 0;
    this.holidayDayHours = 0;
    this.holidayNightHours = 0;
    this.nightHours = 0;
    this.overtimeHours = 0;
    this.positionHours = {};
    this.saturdayDayHours = 0;
    this.saturdayNightHours = 0;
    this.sundayDayHours = 0;
    this.sundayNightHours = 0;
    this.totalHours = 0;

    for (const [day, shift] of Object.entries(shifts)) {
      this.totalHours += shift.counters.totalHours;
      this.dayHours += shift.counters.dayHours;
      this.nightHours += shift.counters.nightHours;
      this.overtimeHours += shift.counters.overtimeHours;

      const shiftDate = new Date(year, month - 1, Number(day), 0, 0, 0, 0);
      if (shiftDate.getDay() === 6) {
        this.saturdayDayHours += shift.counters.dayHours;
        this.saturdayNightHours += shift.counters.nightHours;
      }
      if (shiftDate.getDay() === 0) {
        this.sundayDayHours += shift.counters.dayHours;
        this.sundayNightHours += shift.counters.nightHours;
      }

      const dateHolidays = new Holidays(localeModel.getSelectedLocale().name.toUpperCase());
      if (dateHolidays.isHoliday(shiftDate)) {
        this.holidayDayHours += shift.counters.dayHours;
        this.holidayNightHours += shift.counters.nightHours;
      }

      this.balanceHours += this.calculateBalanceHours(shift.counters.totalHours, shift, shiftDate);

      for (const branchId of Object.keys(shift.counters.branchHours)) {
        this.addBranchHours(branchId, shift.counters.branchHours[branchId]);
      }

      for (const branchId of Object.keys(shift.counters.positionHours)) {
        for (const positionId of Object.keys(shift.counters.positionHours[branchId])) {
          this.addPositionHours(branchId, positionId, shift.counters.positionHours[branchId][positionId]);
        }
      }
    }

    this.compensatedOvertimeHours = Math.max(this.balanceHours, 0);
  }

  public calculateBalanceHours(dayTotalHours: number, shift: Shift, shiftDate: Date): number {
    const regularHours = setting.getSetting<number>("shiftRegularHours");
    const dateHolidays = new Holidays(localeModel.getSelectedLocale().name.toUpperCase());
    // if a leave is found, simply disregard the day
    if (shift.leave !== undefined) return 0;
    // if the day is today or in the future, disregard the day
    const now: Date = new Date();
    now.setHours(0, 0, 0, 0);
    if (compareAsc(shiftDate, now) >= 0) return 0;

    // check if the day is a holiday, saturday or sunday
    let isHoliday = false;
    if (dateHolidays.isHoliday(shiftDate) !== false) isHoliday = true;
    if (shiftDate.getDay() === 6 || shiftDate.getDay() === 0) isHoliday = true;

    // disregard negative balance on holidays
    if (dayTotalHours < regularHours && isHoliday === true) return 0;

    return dayTotalHours - regularHours;
  }

  public addBranchHours(branchId: string | undefined, hours: number): void {
    if (branchId === undefined) return;
    if (this.branchHours[branchId] === undefined) {
      this.branchHours[branchId] = hours;
    } else {
      this.branchHours[branchId] += hours;
    }
  }

  public addPositionHours(branchId: string | undefined, positionId: string | undefined, hours: number): void {
    if (branchId === undefined) return;
    if (positionId === undefined) return;
    if (this.positionHours[branchId] === undefined) {
      this.positionHours[branchId] = { positionId: hours };
    } else {
      if (this.positionHours[branchId][positionId] === undefined) {
        this.positionHours[branchId][positionId] = hours;
      } else {
        this.positionHours[branchId][positionId] += hours;
      }
    }
  }
  */
}
