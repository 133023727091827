import { RouteRecordRaw } from "vue-router";

export const bankRoutes: Array<RouteRecordRaw> = [
  {
    path: "/banks/:id/edit",
    name: "BankEdit",
    component: () => import("../views/BankEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "bank",
      requiredRight: "viewUI",
    },
  },
];
