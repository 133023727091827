import { DpiCategory } from "./DpiCategory";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedDpiCategory extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromDpiCategory(dpiCategory: DpiCategory): LinkedDpiCategory {
    const linkedDpiCategory: LinkedDpiCategory = new LinkedDpiCategory();
    linkedDpiCategory.id = dpiCategory.id;
    linkedDpiCategory.name = dpiCategory.name;

    return linkedDpiCategory;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedDpiCategory {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
