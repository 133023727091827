import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { OutboundShipment } from "../objects/OutboundShipment";
import { outboundShipmentModel } from "../models/OutboundShipmentModel";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const outboundShipmentSearch: SearchModule = new SearchModule(
  "outboundShipment.outboundShipment",
  (firestoreDocument: FirestoreDocument) => Promise.resolve(`${(firestoreDocument as OutboundShipment).code}`),
  (firestoreDocument: FirestoreDocument) => `/outbound-shipments/${firestoreDocument.id}/edit`,
  "download",
  outboundShipmentModel,
  [new SortCriteria("date", "desc", "date")]
);
