import { DocumentReference } from "firebase/firestore";

import { EmployeeRosterCounter } from "./EmployeeRosterCounter";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Shift } from "./Shift";

import { ArrayByKeyField, NumberField } from "@/core/fields";

export class EmployeeRoster extends FirestoreDocument {
  public year = 0;
  public month = 0;
  public shifts: Record<string, Shift> = {};
  public counters: EmployeeRosterCounter = new EmployeeRosterCounter();

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): EmployeeRoster {
    super.fromFirestore(data, id, firestoreRef);

    this.year = NumberField.fromFirestore(data.year);
    this.month = NumberField.fromFirestore(data.month);
    this.shifts = ArrayByKeyField.fromFirestore<Shift>(data.shifts, (value) => new Shift(value));
    this.counters.fromFirestore(data.counters);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.month = NumberField.toFirestore(this.month);
    firestoreData.shifts = ArrayByKeyField.toFirestore<Shift>(this.shifts, (value) => value.toFirestore());
    firestoreData.counters = this.counters.toFirestore();

    return firestoreData;
  }
}
