import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";

export const chartStockByType = (parcels: Parcel[], date: Date, t: (entry: string) => string): Record<string, unknown> => {
  try {
    if (parcels.length == 0) return {};

    const sets: Record<string, number> = {};
    for (const parcel of parcels) {
      const type: string = parcel.type?.name ?? t("statHandleChart.noType");

      let isStored = false;
      for (const action of parcel.actions) {
        action.date.setHours(0, 0, 0, 0);
        if (compareAsc(action.date, date) > 0) continue;

        if (!isStored) {
          if (action.action == ParcelActionType.Created) isStored = true;
        } else {
          if ([ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) isStored = false;
        }
      }

      if (isStored) {
        if (Object.keys(sets).includes(type)) {
          sets[type] += 1;
        } else {
          sets[type] = 1;
        }
      }
    }

    const orderedSets = DataHelpers.sortObjectKeys(sets);

    const chartData: Record<string, unknown> = {
      labels: [t("statHandleChart.parcel")],
      datasets: Object.keys(orderedSets).map((type: string, index: number) => {
        return {
          backgroundColor: ChartHelpers.getChartBackgroundColor(index),
          borderColor: ChartHelpers.getChartBorderColor(index),
          borderWidth: 2,
          data: [orderedSets[type]],
          label: type,
          maxBarThickness: 160,
        };
      }),
    };

    return chartData;
  } catch (error: unknown) {
    appFaultModel.catchAppError("HandleChartModel.chartStockByType", { parcels, date, t }, error);
    return {};
  }
};
