export const it = {
  dpiCategory: {
    dpiCategories: "Categorie DPI",
    dpiCategory: "Categoria DPI",
    name: "Nome",
    nameError: "Inserire un nome valido",
    newDpiCategory: "Nuova categoria DPI",
  },
  navigation: {
    dpiCategory: "DPI - Categorie",
  },
  buttons: {
    addDpiCategory: "Aggiungi categoria DPI",
  },
};
