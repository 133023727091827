import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { ParcelWithAction } from "../objects/ParcelWithAction";

export class HandleCodePaper extends DocumentPaper {
  private companyName: string | undefined;
  private itemCode: string | undefined;
  private period: Date[];
  private results: ParcelWithAction[];
  private handleCodeCount: number;

  constructor(
    companyName: string | undefined,
    itemCode: string | undefined,
    period: Date[],
    results: ParcelWithAction[],
    handleCodeCount: number,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.companyName = companyName;
    this.itemCode = itemCode;
    this.period = period;
    this.results = results;
    this.handleCodeCount = handleCodeCount;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      handleCodeDetail: { fontSize: 10 },
      handleCodeParcelCell: { alignment: "center", fontSize: 8 },
      handleCodeParcelCellLeft: { alignment: "left", fontSize: 8 },
      handleCodeParcelFoot: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 8 },
      handleCodeParcelFootRight: { alignment: "right", bold: true, color: "white", fillColor: "gray", fontSize: 8 },
      handleCodeParcelHead: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(): Promise<Record<string, unknown>[]> {
    await this.setStructure(this.t("statHandleCode.handleCodePaper").toLocaleUpperCase());

    this.writeDetails();

    this.writeHandleCodeTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeDetails(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: DataHelpers.mmToPoints(40),
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "handleCodeDetail",
              text: `${this.t("statHandleCode.company").toLocaleUpperCase()}:`,
            },
            {
              margin: [0, DataHelpers.mmToPoints(2), 0, 0],
              style: "handleCodeDetail",
              text: `${this.t("statHandleCode.itemCode").toLocaleUpperCase()}:`,
            },
            {
              margin: [0, DataHelpers.mmToPoints(2), 0, 0],
              style: "handleCodeDetail",
              text: `${this.t("statHandleCode.periodShort").toLocaleUpperCase()}:`,
            },
          ],
        },
        {
          width: "*",
          stack: [
            { margin: [0, DataHelpers.mmToPoints(4), 0, 0], style: "handleCodeDetail", text: this.companyName ?? "-" },
            { margin: [0, DataHelpers.mmToPoints(2), 0, 0], style: "handleCodeDetail", text: this.itemCode?.toLocaleUpperCase() ?? "-" },
            {
              margin: [0, DataHelpers.mmToPoints(2), 0, 0],
              style: "handleCodeDetail",
              text: `${this.d(this.period[0], "mediumDate")} - ${this.d(this.period[1], "mediumDate")}`,
            },
          ],
        },
      ],
    });
  }

  private writeHandleCodeTable(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "handleCodeParcelHead", text: this.t("statHandleCode.parcel").toLocaleUpperCase() },
        { style: "handleCodeParcelHead", text: this.t("statHandleCode.inboundShipment").toLocaleUpperCase() },
        { style: "handleCodeParcelHead", text: this.t("statHandleCode.outboundShipment").toLocaleUpperCase() },
        { style: "handleCodeParcelHead", text: this.t("statHandleCode.action").toLocaleUpperCase() },
        { style: "handleCodeParcelHead", text: this.t("statHandleCode.quantity").toLocaleUpperCase() },
      ],
    ];

    for (const result of this.results) {
      dataTableBody.push([
        {
          style: "handleCodeParcelCellLeft",
          text: result.type ? `${result.type.name} ${result.code}` : `${this.t("statHandleCode.parcel")} ${result.code}`,
        },
        {
          style: "handleCodeParcelCellLeft",
          text: result.inboundShipment
            ? this.t("statHandleCode.shipmentDetail", {
                code: result.inboundShipment.code,
                date: this.d(result.inboundShipment.date || new Date(), "shortDate"),
                companyName: result.inboundShipment.company?.name ?? "-",
              })
            : "-",
        },
        {
          style: "handleCodeParcelCellLeft",
          text: result.outboundShipment
            ? this.t("statHandleCode.shipmentDetail", {
                code: result.outboundShipment.code,
                date: this.d(result.outboundShipment.date || new Date(), "shortDate"),
                companyName: result.outboundShipment.company?.name ?? "-",
              })
            : "-",
        },
        {
          style: "handleCodeParcelCell",
          text:
            result.action?.action == "created"
              ? this.t("statHandleCode.parcelStates.created").toLocaleUpperCase()
              : result.action?.action == "sent"
              ? this.t("statHandleCode.parcelStates.sent").toLocaleUpperCase()
              : this.t("statHandleCode.parcelStates.unknown").toLocaleUpperCase(),
        },
        { style: "handleCodeParcelCell", text: result.quantity ? this.n(result.quantity, "number0") : "0" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 5, style: "handleCodeParcelCell", text: this.t("gen.noResults") }]);
    } else {
      dataTableBody.push([
        { style: "handleCodeParcelFootRight", colSpan: 4, text: this.t("statHandleCode.handleCodeCount").toLocaleUpperCase() },
        { style: "handleCodeParcelFoot", text: "" },
        { style: "handleCodeParcelFoot", text: "" },
        { style: "handleCodeParcelFoot", text: "" },
        { style: "handleCodeParcelFoot", text: this.n(this.handleCodeCount, "number0") },
      ]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(50), DataHelpers.mmToPoints(50), "*", DataHelpers.mmToPoints(18)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
