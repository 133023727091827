export const it = {
  employeeRoster: {
    clocking: {
      branch: "Cantiere",
      branchError: "Selezionare un cantiere",
      clockings: "Timbrature",
      position: "Ruolo",
      positionError: "Selezionare un ruolo",
      side: "Direzione",
      sideError: "Selezionare una direzione",
      sides: {
        in: "Ingresso",
        out: "Uscita",
      },
      timestamp: "Data e ora",
      timestampError: "Inserire una data e ora",
    },
    counter: {
      balanceHours: "Saldo ore",
      compensatedOvertimeHours: "Ore straordinarie compensate",
      counters: "Contatori",
      dayHours: "Ore diurne",
      holidayDayHours: "Ore diurne festivo",
      holidayNightHours: "Ore notturne festivo",
      label: "Contatore",
      nightHours: "Ore notturne",
      overtimeHours: "Ore straordinarie",
      saturdayDayHours: "Ore diurne sabato",
      saturdayNightHours: "Ore notturne sabato",
      sundayDayHours: "Ore diurne domenica",
      sundayNightHours: "Ore notturne domenica",
      totalHours: "Ore totali",
      value: "Valore",
    },
    employeeRoster: "Turni",
    employeeRosters: "Turni",
    expectedShiftType: "Turno previsto",
    inRole: "In",
    leaveFound: "Assenza presente sul giorno",
    month: "Mese",
    notes: "Note",
    shiftViewTitle: "Turno di {date} di {employee}",
    totalHours: "Totale ore",
    totalPrefix: "Tot",
    warningFound: "Sospensione presente sul giorno",
    warningFoundOf: "del",
    warnings: {
      clockingWithoutBranch: "Timbratura in ingresso senza cantiere",
      clockingsWithoutHours: "Timbrature non producono ore",
      clockingWithoutPosition: "Timbratura in ingresso senza ruolo",
      incorrectClockingOrder: "Sequenza timbrature errata",
      invalidClockings: "Timbrature non valide",
      leaveWithClockings: "Assenza con timbrature",
      warningWithClockings: "Sospensione con timbrature",
    },
  },
  navigation: {
    employeeRoster: "Turni",
  },
  buttons: {
    addClocking: "Aggiungi",
    setClockingsFromBranchShiftType: "Assegna timbrature",
    showCounters: "Contatori",
  },
  toast: {
    error: {
      employeeRosterSaveShift: "Errore durante il salvataggio del turno",
    },
    success: {
      employeeRosterSaveShift: "Turno salvato con successo",
    },
    warning: {},
  },
};
