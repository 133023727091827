import { addDays, compareAsc, endOfMonth, startOfMonth } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";

export const calcMonthlyStockPeakValue = (parcels: Parcel[], monthDate: Date): number => {
  try {
    if (parcels.length == 0) return 0;

    let startDate: Date = startOfMonth(monthDate);
    const endDate: Date = endOfMonth(monthDate);

    let peakValue = 0;
    while (compareAsc(startDate, endDate) <= 0) {
      let parcelStored = 0;

      for (const parcel of parcels) {
        let isStored = false;
        for (const action of parcel.actions) {
          action.date.setHours(0, 0, 0, 0);
          if (compareAsc(action.date, startDate) > 0) continue;

          if (!isStored) {
            if (action.action == ParcelActionType.Created) isStored = true;
          } else {
            if ([ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) isStored = false;
          }
        }
        if (isStored) parcelStored++;
      }

      if (parcelStored > peakValue) peakValue = parcelStored;
      startDate = addDays(startDate, 1);
    }

    return peakValue;
  } catch (error: unknown) {
    appFaultModel.catchAppError("StockChartModel.calcMonthlyStockPeakValue", { parcels, monthDate }, error);
    return 0;
  }
};
