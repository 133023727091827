import { RouteRecordRaw } from "vue-router";

export const parcelRoutes: Array<RouteRecordRaw> = [
  {
    path: "/parcels",
    name: "ParcelList",
    component: () => import("../views/ParcelList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "parcel",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/parcels/:id/edit",
    name: "ParcelEdit",
    component: () => import("../views/ParcelEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "parcel",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/parcels/:id/:section?",
    name: "ParcelView",
    component: () => import("../views/ParcelView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "parcel",
      requiredRight: "viewUI",
    },
  },
];
