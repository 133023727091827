import { EmployeePosition } from "../objects/EmployeePosition";
import { EmployeePositionHelpers } from "../helpers/EmployeePositionHelpers";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import {
  getEmployeePositionsByBranch,
  getEmployeePositionsByDate,
  getEmployeePositionsByEmployeeAndBranch,
  getExpiringEmployeePositions,
} from "./methods";

export class EmployeePositionModel extends FirestoreModel<EmployeePosition> {
  public constructor() {
    super(() => new EmployeePosition(), "employeePositions", LockPolicy.DiscardUnsyncedChanges, "employeePosition");
    this.parentCollectionName = "employees";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<EmployeePosition[]> {
    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(employeePositionId: string, parentId?: string): Promise<EmployeePosition> {
    return super.getDocument(employeePositionId, parentId);
  }

  public async createDocument(employeePosition: EmployeePosition, parentId?: string): Promise<string> {
    const employeePositionId: string = await super.createDocument(employeePosition, parentId);

    await EmployeePositionHelpers.processEmployeePositionsForEmployee(parentId as string);

    return employeePositionId;
  }

  public async updateDocument(employeePosition: EmployeePosition, parentId?: string): Promise<void> {
    await super.updateDocument(employeePosition, parentId);

    return EmployeePositionHelpers.processEmployeePositionsForEmployee(parentId as string);
  }

  public async deleteDocument(employeePosition: EmployeePosition, parentId?: string): Promise<boolean> {
    const deleteResult: boolean = await super.deleteDocument(employeePosition, parentId);
    if (deleteResult === false) return false;

    await EmployeePositionHelpers.processEmployeePositionsForEmployee(parentId as string);
    return true;
  }

  public async getEmployeePositionsByBranch(branchId: string): Promise<EmployeePosition[]> {
    return getEmployeePositionsByBranch(branchId);
  }

  public async getEmployeePositionsByDate(date: Date): Promise<EmployeePosition[]> {
    return getEmployeePositionsByDate(date);
  }

  public async getEmployeePositionsByEmployeeAndBranch(employeeId: string, branchId: string): Promise<EmployeePosition[]> {
    return getEmployeePositionsByEmployeeAndBranch(employeeId, branchId);
  }

  public async getExpiringEmployeePositions(): Promise<EmployeePosition[]> {
    return getExpiringEmployeePositions();
  }
}

export const employeePositionModel: EmployeePositionModel = new EmployeePositionModel();
