import { Company } from "../objects/Company";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getActiveCompanies, isEmailUnique, isVatCodeUnique } from "./methods";

export class CompanyModel extends FirestoreModel<Company> {
  public constructor() {
    super(() => new Company(), "companies", LockPolicy.DiscardUnsyncedChanges, "company");
    this.beforeDeleteFunction = "featuresCompanyBeforeDelete";
  }

  public async getDocuments(): Promise<Company[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(companyId: string): Promise<Company> {
    return super.getDocument(companyId);
  }

  public async createDocument(company: Company): Promise<string> {
    return super.createDocument(company);
  }

  public async updateDocument(company: Company): Promise<void> {
    return super.updateDocument(company);
  }

  public async deleteDocument(company: Company): Promise<boolean> {
    return super.deleteDocument(company);
  }

  /* custom methods */

  public async getActiveCompanies(): Promise<Company[]> {
    return getActiveCompanies();
  }

  public async isEmailUnique(email: string, companyId?: string, companyUserId?: string): Promise<boolean> {
    return isEmailUnique(email, companyId, companyUserId);
  }

  public async isVatCodeUnique(vatCode: string, companyId?: string): Promise<boolean> {
    return isVatCodeUnique(vatCode, companyId);
  }
}

export const companyModel: CompanyModel = new CompanyModel();
