import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { DpiPurchase } from "@/features/modules/dpiPurchase/objects/DpiPurchase";
import { dpiPurchaseModel } from "@/features/modules/dpiPurchase/models/DpiPurchaseModel";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { storedDpiModel } from "../StoredDpiModel";

export const storeFromDpiPurchase = async (dpiPurchase: DpiPurchase): Promise<void> => {
  try {
    for (const boughtDpi of dpiPurchase.getBoughtDpis()) {
      if (boughtDpi.dpi === undefined) throw new Error("BoughtDpi has no dpi");
      if (boughtDpi.size === undefined) throw new Error("BoughtDpi has no selected size");

      const storedDpi: StoredDpi | undefined = await storedDpiModel.getStoredDpiByDpiAndSize(boughtDpi.dpi.id, boughtDpi.size);

      if (storedDpi === undefined) {
        const storedDpi: StoredDpi = new StoredDpi();
        storedDpi.dpi = boughtDpi.dpi;
        storedDpi.size = boughtDpi.size;
        storedDpi.quantity = boughtDpi.quantity;
        storedDpi.setTimestampFields("create");
        batch.set(storedDpiModel.getDocumentReference(), storedDpi.toFirestore());
      } else {
        storedDpi.quantity += boughtDpi.quantity;
        storedDpi.setTimestampFields("update");
        batch.set(storedDpiModel.getDocumentReference(storedDpi.id), storedDpi.toFirestore());
      }
    }

    batch.update(dpiPurchaseModel.getDocumentReference(dpiPurchase.id), { isStored: true });

    await batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("StoredDpiModel.storeFromDpiPurchase", { dpiPurchase }, error);
  }
};
