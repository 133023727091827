import { UtilityModule } from "@/core/modules/utility/objects/UtilityModule";

import MoveWarehouseLocationView from "./views/MoveWarehouseLocationView.vue";

export const moveWarehouseLocation: UtilityModule = new UtilityModule(
  "warehouse",
  "MoveWarehouseLocation",
  "warehouseUtility",
  MoveWarehouseLocationView
);
