import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { batch } from "@/core/modules/batch/objects/Batch";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "@/features/modules/inboundShipment/models/InboundShipmentModel";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "@/features/modules/outboundShipment/models/OutboundShipmentModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";

export class ItemCodeModel {
  public async replaceItemCode(oldCode: string, newCode: string): Promise<void> {
    try {
      if (oldCode === undefined || oldCode === "") throw new Error("oldCodeUndefined");
      if (newCode === undefined || newCode === "") throw new Error("newCodeUndefined");

      // inboundShipment
      const inboundShipments: InboundShipment[] = await inboundShipmentModel.getDocuments();
      for (const inboundShipment of inboundShipments) {
        let edited = false;
        for (const parcelData of inboundShipment.parcelsData) {
          for (const item of parcelData.items) {
            if (item.code === oldCode) {
              item.code = newCode;
              edited = true;
            }
          }
        }
        if (edited) batch.set(inboundShipmentModel.getDocumentReference(inboundShipment.id), inboundShipment.toFirestore());
      }

      // outboundShipment
      const outboundShipments: OutboundShipment[] = await outboundShipmentModel.getDocuments();
      for (const outboundShipment of outboundShipments) {
        let edited = false;
        for (const item of outboundShipment.items) {
          if (item.code === oldCode) {
            item.code = newCode;
            edited = true;
          }
        }
        if (edited) batch.set(outboundShipmentModel.getDocumentReference(outboundShipment.id), outboundShipment.toFirestore());
      }

      await batch.commit();
      console.log("inboundShipments and outboundShipments updated");
      await AppHelpers.delay(20000);

      // parcel
      const parcels: Parcel[] = await parcelModel.getParcelsByItemCode(oldCode);
      for (const parcel of parcels) {
        const index: number = parcel.itemsCodes.indexOf(oldCode);
        if (index !== -1) parcel.itemsCodes[index] = newCode;

        for (const item of parcel.items) {
          if (item.code === oldCode) item.code = newCode;
        }
        batch.set(parcelModel.getDocumentReference(parcel.id), parcel.toFirestore());
      }

      console.log("parcels updated");
      return batch.commit();
    } catch (error: unknown) {
      appFaultModel.catchAppError("ItemCodeModel.replaceItemCode", { oldCode, newCode }, error);
    }
  }
}

export const itemCodeModel: ItemCodeModel = new ItemCodeModel();
