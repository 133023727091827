import { addDays, compareAsc, endOfMonth, format, getDate, startOfMonth } from "date-fns";

import { Clocking } from "@/features/modules/employeeRoster/objects/Clocking";
import { ClockingSide } from "@/features/modules/employeeRoster/objects/ClockingSide";
import { EmployeeDayReport } from "../objects/EmployeeDayReport";
import { EmployeeRoster } from "@/features/modules/employeeRoster/objects/EmployeeRoster";
import { EmployeeRosterCounter } from "@/features/modules/employeeRoster/objects/EmployeeRosterCounter";
import { employeeRosterModel } from "@/features/modules/employeeRoster/models/EmployeeRosterModel";
import { EmployeeTransaction } from "@/features/modules/employeeTransaction/objects/EmployeeTransaction";
import { employeeTransactionModel } from "@/features/modules/employeeTransaction/models/EmployeeTransactionModel";
import { Shift } from "@/features/modules/employeeRoster/objects/Shift";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";

export class EmployeeMonthReportModel {
  public async createReport(
    employeeId: string,
    monthDate: Date,
    n: (number: number, format: string) => string,
    t: (text: string) => string
  ): Promise<{ report: EmployeeDayReport[]; counters: EmployeeRosterCounter }> {
    try {
      if (employeeId === undefined) throw new Error("employeeUndefined");
      if (monthDate === undefined) throw new Error("monthUndefined");

      const employeeRoster: EmployeeRoster | undefined = await employeeRosterModel.getEmployeeRosterByEmployeeAndMonthAndYear(
        employeeId,
        monthDate.getMonth() + 1,
        monthDate.getFullYear()
      );

      const branches: Branch[] = await branchModel.getDocuments();

      const employeeDayReports: EmployeeDayReport[] = [];

      let startDate: Date = startOfMonth(monthDate);
      const endDate: Date = endOfMonth(monthDate);

      while (compareAsc(startDate, endDate) <= 0) {
        // branches
        const branchDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        branchDetails.label = "branches";
        // clockings
        const clockingDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        clockingDetails.label = "clockings";
        // counters
        const counterDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        counterDetails.label = "counters";
        // leaves
        const leaveDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        leaveDetails.label = "leaves";
        // suspensions
        const suspensionDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        suspensionDetails.label = "suspensions";
        // notes
        const notesDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        notesDetails.label = "notes";

        if (employeeRoster !== undefined) {
          // recalc roster counters
          // TODOM3: farlo sempre nella funzione, qui è superfluo
          /*
          employeeRoster.counters.calculateCounters(employeeRoster.year, employeeRoster.month, employeeRoster.shifts);
          batch.update(employeeRosterModel.getDocumentReference(employeeRoster.id, employeeId), {
            counters: employeeRoster.counters.toFirestore(),
          });
          */

          const day: string = getDate(startDate).toFixed();
          if (day in employeeRoster.shifts) {
            const shift: Shift = employeeRoster.shifts[day];
            if (shift.notes !== undefined) notesDetails.value = shift.notes;
            if (shift.getClockings().length > 0) {
              clockingDetails.value = shift
                .getClockings()
                .map((clocking: Clocking) => `${clocking.side === ClockingSide.In ? "E: " : "U: "} ${format(clocking.timestamp, "HH:mm")}`)
                .join(" - ");

              // group all the clockings branches in a single string
              const branchesIds: string[] = [];
              for (const clocking of shift.getClockings()) {
                if (clocking.branchId !== undefined && branchesIds.includes(clocking.branchId) === false) {
                  branchesIds.push(clocking.branchId);
                }
              }
              branchDetails.value = branchesIds
                .map((branchId: string) => branches.find((branch: Branch) => branch.id === branchId)?.name ?? "-")
                .join(" - ");
            }
            if (shift.counters !== undefined && shift.getClockings().length > 0) {
              counterDetails.value = `${t("employeeRoster.counter.dayHours")}: `;
              counterDetails.value += n(shift.counters.dayHours, "number1");
              counterDetails.value += ` - ${t("employeeRoster.counter.nightHours")}: `;
              counterDetails.value += n(shift.counters.nightHours, "number1");
            }
            if (shift.leave !== undefined) {
              leaveDetails.value = shift.leave.type?.name ?? "-";
            }
            if (shift.warning !== undefined) {
              suspensionDetails.value = shift.warning.type?.name ?? "-";
            }
          }
        }
        employeeDayReports.push(branchDetails);
        employeeDayReports.push(clockingDetails);
        employeeDayReports.push(counterDetails);
        if (leaveDetails.value !== undefined) employeeDayReports.push(leaveDetails);
        if (suspensionDetails.value !== undefined) employeeDayReports.push(suspensionDetails);

        // transactions
        const transactionDetails: EmployeeDayReport = new EmployeeDayReport(startDate);
        transactionDetails.label = "transactions";
        const employeeTransactions: EmployeeTransaction[] = await employeeTransactionModel.getEmployeeTransactionsByEmployeeAndDate(
          employeeId,
          startDate
        );
        if (employeeTransactions.length > 0) {
          transactionDetails.value = employeeTransactions
            .map((employeeTransaction) => `${employeeTransaction.type?.name ?? "-"}: ${n(employeeTransaction.amount, "currencyEUR")}`)
            .join(" - ");
        }
        if (transactionDetails.value !== undefined) employeeDayReports.push(transactionDetails);
        if (notesDetails.value !== undefined) employeeDayReports.push(notesDetails);

        startDate = addDays(startDate, 1);
      }

      return { report: employeeDayReports, counters: employeeRoster?.counters ?? new EmployeeRosterCounter() };
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}

export const employeeMonthReportModel: EmployeeMonthReportModel = new EmployeeMonthReportModel();
