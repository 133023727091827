import { format } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doc } from "@/features/modules/doc/objects/Doc";
import { docModel } from "../DocModel";
import { DocOwner } from "@/features/modules/doc/objects/DocOwner";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";

export const getDocsInForceByOwner = async (owner: DocOwner): Promise<Doc[]> => {
  try {
    const today: Date = new Date();

    const snapshotQuery: Query<Doc> = query(
      docModel.getPathReference().withConverter(docModel.firestoreConverter),
      where("expirationSort", ">=", format(today, "yyyy-MM-dd")),
      where("owners", "array-contains", owner.toFirestore())
    );

    const snapshot: QuerySnapshot<Doc> = await getDocs(docModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("DocModel.getDocsInForceByOwner", { owner }, error);
    return [];
  }
};

function sortDocuments(documents: Doc[]): Doc[] {
  const sorter: FirestoreSorter<Doc> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "desc", "date");
  return sorter.sort();
}
