export const it = {
  outboundShipment: {
    addItem: "Aggiungi codice",
    availableQuantity: "Quantità rimanente",
    calcParcels: "Selezione automatica",
    code: "Codice",
    codeError: "Inserire un codice valido",
    company: "Azienda",
    companyError: "Selezionare una azienda",
    content: "Contenuto",
    date: "Data",
    dateError: "Inserire una data valida",
    details: "Dettagli",
    general: "Generale",
    importCompanyParcel: "Aggiungi UDC",
    inboundShipmentDescription: "Spedizione {code} del {date}",
    inboundShipmentDescriptionFrom: "Ricevuto da spedizione {code} del {date}",
    noLocation: "Nessuna informazione di posizione",
    detail: "Dettagli",
    outboundShipment: "Spedizione in partenza",
    outboundShipments: "Spedizioni in partenza",
    outboundShipmentState: "Stato",
    outboundShipmentStates: {
      draft: "Bozza",
      ready: "Pronta",
      sent: "Spedita",
      unknown: "Sconosciuto",
    },
    parcelDescription: "{code} - Spedizione {shipmentCode} del {date}",
    parcelGuide: "Lista di prelievo",
    parcels: "UDC",
    parcelSelection: "Seleziona UDC",
    parcelSelectionTitle: "UDC e materiale selezionato",
    quantity: "Quantità",
    recipient: "Destinatario",
    requestedItems: "Materiale richiesto",
    requestedQuantity: "Quantità richiesta",
    requestedQuantityError: "Inserire una quantità valida",
    selectedQuantity: "Quantità selezionata",
    shipmentStates: {
      loaded: "Caricato",
      selected: "Da caricare",
      sent: "Spedito",
      unknown: "Sconosciuto",
    },
    sourceParcels: "UDC disponibili",
    state: "Stato",
    states: {
      deleted: "Eliminato",
      loaded: "Caricato",
      loosened: "Libero",
      sent: "Spedito",
      stored: "Immagazzinato",
      unknown: "Sconosciuto",
    },
    targetParcels: "UDC selezionati",
    transportType: "Tipo di trasporto",
    transportTypeError: "Selezionare un tipo di trasporto",
    type: "Tipo",
    unknownFormat: "Formato sconosciuto",
    workingOutboundShipments: "Spedizioni in partenza in lavorazione",
  },
  navigation: {
    outboundShipment: "Spedizioni in partenza",
  },
};
