import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";

export const getInvoicesByYear = async (year: number): Promise<Invoice[]> => {
  try {
    const snapshotQuery: Query<Invoice> = query(
      invoiceModel.getPathReference().withConverter(invoiceModel.firestoreConverter),
      where("year", "==", year)
    );

    const snapshot: QuerySnapshot<Invoice> = await getDocs(invoiceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data())).sort((a, b) => {
      if (a.company === undefined || b.company === undefined) return 0;
      if (a.company.name === undefined && b.company.name === undefined) return 0;
      if (a.company.name === undefined) return 1;
      if (b.company.name === undefined) return -1;
      if (a.company.name === b.company.name) return 0;
      return a.company.name > b.company.name ? 1 : -1;
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getInvoicesByYear", { year }, error);
    return [];
  }
};

function sortDocuments(documents: Invoice[]): Invoice[] {
  const sorter: FirestoreSorter<Invoice> = new FirestoreSorter(documents);
  sorter.addSortCriteria("company.name", "asc", "string");
  sorter.addSortCriteria("month", "asc", "number");
  return sorter.sort();
}
