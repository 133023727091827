import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.warehouseLocationCode,
    visible: _ctx.isDialogVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDialogVisible) = $event)),
    modal: true,
    dismissableMask: true,
    style: {"width":"60vw"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        class: "text-center",
        style: {"font-size":"3rem"},
        innerHTML: _ctx.warehouseLocationDecoded
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}