
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import QRCode from "qrcode";

import { eventBus } from "@/core/modules/eventBus/EventBus";

export default defineComponent({
  name: "KoruQrCode",
  setup() {
    let eventBusId: string | undefined = undefined;

    const isDialogVisible: Ref<boolean> = ref(false);
    const qrCodeDataUrl: Ref<string> = ref("");
    const qrCodeValue: Ref<string> = ref("");

    onMounted(() => {
      eventBusId = eventBus.on("showQrCode", async (code?: string): Promise<void> => {
        if (code === undefined) return Promise.resolve();

        qrCodeValue.value = code;
        qrCodeDataUrl.value = await QRCode.toDataURL(qrCodeValue.value || "0", { width: 600 });
        isDialogVisible.value = true;
      });
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      isDialogVisible,
      qrCodeDataUrl,
      qrCodeValue,
    };
  },
});
