import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { LinkedDpi } from "@/features/modules/dpi/objects/LinkedDpi";

import { NumberField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class HandledDpi {
  public id: string = DataHelpers.uniqueId();
  public dpi: LinkedDpi | undefined = undefined;
  public size: string | undefined = undefined;
  public quantity = 1;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): HandledDpi {
    this.id = StringStrictField.fromFirestore(data.id, DataHelpers.uniqueId());
    this.dpi = ObjectField.fromFirestore<LinkedDpi>(data.dpi, (value) => new LinkedDpi(value));
    this.size = StringField.fromFirestore(data.size);
    this.quantity = NumberField.fromFirestore(data.quantity);
    this.order = NumberField.fromFirestore(data.order);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.id = StringStrictField.toFirestore(this.id);
    firestoreData.dpi = ObjectField.toFirestore<LinkedDpi>(this.dpi, (value) => value.toFirestore());
    firestoreData.size = StringField.toFirestore(this.size);
    firestoreData.quantity = NumberField.toFirestore(this.quantity);
    firestoreData.order = NumberField.toFirestore(this.order);

    return firestoreData;
  }
}
