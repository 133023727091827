
import { defineComponent } from "vue";

import BadgeScanner from "@/features/components/BadgeScanner.vue";
import KoruQrCode from "@/features/components/KoruQrCode.vue";
import KoruPdfDialog from "@/features/components/KoruPdfDialog.vue";
import KoruTextOverlay from "@/features/components/KoruTextOverlay.vue";
import KoruWarehouseLocation from "@/features/components/KoruWarehouseLocation.vue";

export default defineComponent({
  name: "BedrockHolder",
  components: {
    BadgeScanner,
    KoruQrCode,
    KoruPdfDialog,
    KoruTextOverlay,
    KoruWarehouseLocation,
  },
});
