import { EmployeeExpiration } from "@/features/modules/employee/objects/EmployeeExpiration";
import { EmployeeLeave } from "../objects/EmployeeLeave";
import { EmployeeLeaveHelpers } from "../helpers/EmployeeLeaveHelpers";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getEmployeeLeavesByDate, getEmployeeLeavesByEmployeeAndDate, getOpenEmployeeLeaves, isPeriodAvailable } from "./methods";

export class EmployeeLeaveModel extends FirestoreModel<EmployeeLeave> {
  public constructor() {
    super(() => new EmployeeLeave(), "employeeLeaves", LockPolicy.DiscardUnsyncedChanges, "employeeLeave");
    this.parentCollectionName = "employees";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<EmployeeLeave[]> {
    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(employeeLeaveId: string, parentId?: string): Promise<EmployeeLeave> {
    return super.getDocument(employeeLeaveId, parentId);
  }

  public async createDocument(employeeLeave: EmployeeLeave, parentId?: string): Promise<string> {
    const newEmployeeLeaveId: string = await super.createDocument(employeeLeave, parentId);

    employeeLeave.id = newEmployeeLeaveId;
    await EmployeeLeaveHelpers.addRosterLeave(employeeLeave, parentId as string);

    return newEmployeeLeaveId;
  }

  public async updateDocument(employeeLeave: EmployeeLeave, parentId?: string): Promise<void> {
    const oldEmployeeLeave: EmployeeLeave = await employeeLeaveModel.getDocument(employeeLeave.id, parentId);

    await super.updateDocument(employeeLeave, parentId);

    return EmployeeLeaveHelpers.updateRosterLeave(employeeLeave, parentId as string, oldEmployeeLeave);
  }

  public async deleteDocument(employeeLeave: EmployeeLeave, parentId?: string): Promise<boolean> {
    const result: boolean = await super.deleteDocument(employeeLeave, parentId);
    if (result === false) return false;

    await EmployeeLeaveHelpers.deleteRosterLeave(employeeLeave, parentId as string);
    return true;
  }

  public async getEmployeeLeavesByDate(date: Date): Promise<EmployeeLeave[]> {
    return getEmployeeLeavesByDate(date);
  }

  public async getEmployeeLeavesByEmployeeAndDate(employeeId: string, date: Date): Promise<EmployeeLeave[]> {
    return getEmployeeLeavesByEmployeeAndDate(employeeId, date);
  }

  public async getOpenEmployeeLeaves(): Promise<EmployeeExpiration[]> {
    return getOpenEmployeeLeaves();
  }

  public async isPeriodAvailable(employeeId: string, from: Date, to: Date, employeeLeaveId?: string): Promise<boolean> {
    return isPeriodAvailable(employeeId, from, to, employeeLeaveId);
  }
}

export const employeeLeaveModel: EmployeeLeaveModel = new EmployeeLeaveModel();
