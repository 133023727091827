export const it = {
  statItemCode: {
    code: "Codice",
    company: "Azienda",
    inboundShipment: "Spedizione in arrivo",
    itemCode: "Codice materiale",
    itemCodeCount: "Quantità totale materiale",
    itemCodePaper: "Resoconto su codice materiale",
    items: "Contenuto",
    name: "Cerca codice materiale",
    parcelState: "Stato",
    parcelStates: {
      created: "Creato",
      deleted: "Eliminato",
      loaded: "Caricato",
      loosened: "Libero",
      sent: "Spedito",
      stored: "Immagazzinato",
      unknown: "Sconosciuto",
    },
    shipmentDetail: "Spedizione {code} del {date} da {companyName}",
    warehouseLocation: "Posizione",
  },
};
