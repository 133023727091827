export const it = {
  utilityMoveWarehouseLocation: {
    companies: "Aziende (vuoto per tutte)",
    name: "Trasloca UDC fra posizioni magazzino",
    sourceWarehouseLocationCode: "Posizione magazzino di partenza",
    targetWarehouseLocationCode: "Posizione magazzino di destinazione",
  },
  toast: {
    error: {
      sourceWarehouseLocationUndefined: "La posizione magazzino di partenza non è valida",
      targetWarehouseLocationUndefined: "La posizione magazzino di destinazione non è valida",
    },
    success: {},
    warning: {},
  },
};
