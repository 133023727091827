import { RouteRecordRaw } from "vue-router";

export const leaveTypeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/leave-types/:id/edit",
    name: "LeaveTypeEdit",
    component: () => import("../views/LeaveTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "leaveType",
      requiredRight: "viewUI",
    },
  },
];
