export const it = {
  utilityItemCode: {
    oldCode: "Codice da sostituire",
    newCode: "Nuovo codice",
    name: "Sostituzione codice materiale",
  },
  toast: {
    error: {
      newCodeUndefined: "Nuovo codice non valido",
      oldCodeUndefined: "Codice da sostituire non valido",
    },
    success: {},
    warning: {},
  },
};
