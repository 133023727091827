import { RouteRecordRaw } from "vue-router";

export const aedCompanyRoutes: Array<RouteRecordRaw> = [
  {
    path: "/aed-companies",
    name: "AedCompanyList",
    component: () => import("../views/AedCompanyList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedCompany",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-companies/:id/edit",
    name: "AedCompanyEdit",
    component: () => import("../views/AedCompanyEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedCompany",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-companies/:id/:section?",
    name: "AedCompanyView",
    component: () => import("../views/AedCompanyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedCompany",
      requiredRight: "viewUI",
    },
  },
];
