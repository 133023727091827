import { compareAsc } from "date-fns";
import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedBranchPosition } from "@/features/modules/branchPosition/objects/LinkedBranchPosition";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";

import { ArrayField, DateField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class EmployeePosition extends FirestoreDocument {
  public branch: LinkedBranch | undefined = undefined;
  public position: LinkedBranchPosition | undefined = undefined;
  public from: Date | undefined = undefined;
  public fromSort = "0000-00-00";
  public to: Date | undefined = undefined;
  public toSort = "9999-99-99";
  public files: StorageFile[] = [];
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): EmployeePosition {
    super.fromFirestore(data, id, firestoreRef);

    this.branch = ObjectField.fromFirestore<LinkedBranch>(data.branch, (value) => new LinkedBranch(value));
    this.position = ObjectField.fromFirestore<LinkedBranchPosition>(data.position, (value) => new LinkedBranchPosition(value));
    this.from = DateField.fromFirestore(data.from);
    this.fromSort = StringStrictField.fromFirestore(data.fromSort, "0000-00-00");
    this.to = DateField.fromFirestore(data.to);
    this.toSort = StringStrictField.fromFirestore(data.toSort, "9999-99-99");
    this.files = ArrayField.fromFirestore<StorageFile>(data.files, (value) => new StorageFile(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.branch = ObjectField.toFirestore<LinkedBranch>(this.branch, (value) => value.toFirestore());
    firestoreData.position = ObjectField.toFirestore<LinkedBranchPosition>(this.position, (value) => value.toFirestore());
    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.files = ArrayField.toFirestore<StorageFile>(this.files, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public isCurrentlyActive(): boolean {
    const now: Date = new Date();
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, now) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(now, to) > 0) return false;
    }
    return true;
  }
}
