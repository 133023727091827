export const it = {
  vehiclePosition: {
    addVehiclePosition: "Aggiungi impiego",
    branch: "Cantiere",
    branchError: "Selezionare un cantiere valido",
    vehiclePosition: "Impiego",
    vehiclePositionOf: "Impiego di {parent}",
    vehiclePositions: "Impieghi",
    vehiclePositionsOf: "Impieghi di {parent}",
    from: "Inizio",
    fromError: "Selezionare una data di inizio valida",
    to: "Fine",
    toError: "Selezionare una data di fine valida",
  },
};
