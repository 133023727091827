import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { dpiHandlingModel } from "../DpiHandlingModel";

export const getDpiHandlingByCode = async (code: string): Promise<DpiHandling | undefined> => {
  try {
    const snapshotQuery: Query<DpiHandling> = query(
      dpiHandlingModel.getPathReference().withConverter(dpiHandlingModel.firestoreConverter),
      where("code", "==", code)
    );

    const snapshot: QuerySnapshot<DpiHandling> = await getDocs(dpiHandlingModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.getDpiHandlingByCode", { code }, error);
    return new DpiHandling();
  }
};
