import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { HandledDpi } from "./HandledDpi";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { SourceType } from "./SourceType";
import { TargetType } from "./TargetType";

import { ArrayByKeyField, DateStrictField, EnumField, ObjectField, StringField } from "@/core/fields";

export class DpiHandling extends FirestoreDocument {
  public date: Date = new Date();
  public code: string | undefined = undefined;
  public sourceType: SourceType = SourceType.Warehouse;
  public sourceBranch: LinkedBranch | undefined = undefined;
  public sourceEmployee: LinkedEmployee | undefined = undefined;
  public targetType: TargetType = TargetType.Employee;
  public targetBranch: LinkedBranch | undefined = undefined;
  public targetEmployee: LinkedEmployee | undefined = undefined;
  public dpis: Record<string, HandledDpi> = {};
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): DpiHandling {
    super.fromFirestore(data, id, firestoreRef);

    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.code = StringField.fromFirestore(data.code);
    this.sourceType = EnumField.fromFirestore<SourceType>(data.sourceType, Object.values(SourceType), SourceType.Warehouse);
    this.sourceBranch = ObjectField.fromFirestore<LinkedBranch>(data.sourceBranch, (value) => new LinkedBranch(value));
    this.sourceEmployee = ObjectField.fromFirestore<LinkedEmployee>(data.sourceEmployee, (value) => new LinkedEmployee(value));
    this.targetType = EnumField.fromFirestore<TargetType>(data.targetType, Object.values(TargetType), TargetType.Employee);
    this.targetBranch = ObjectField.fromFirestore<LinkedBranch>(data.targetBranch, (value) => new LinkedBranch(value));
    this.targetEmployee = ObjectField.fromFirestore<LinkedEmployee>(data.targetEmployee, (value) => new LinkedEmployee(value));
    this.dpis = ArrayByKeyField.fromFirestore<HandledDpi>(data.dpis, (value) => new HandledDpi(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.sourceType = EnumField.toFirestore<SourceType>(this.sourceType, SourceType.Warehouse);
    firestoreData.sourceBranch = ObjectField.toFirestore<LinkedBranch>(this.sourceBranch, (value) => value.toFirestore());
    firestoreData.sourceEmployee = ObjectField.toFirestore<LinkedEmployee>(this.sourceEmployee, (value) => value.toFirestore());
    firestoreData.targetType = EnumField.toFirestore<TargetType>(this.targetType, TargetType.Employee);
    firestoreData.targetBranch = ObjectField.toFirestore<LinkedBranch>(this.targetBranch, (value) => value.toFirestore());
    firestoreData.targetEmployee = ObjectField.toFirestore<LinkedEmployee>(this.targetEmployee, (value) => value.toFirestore());
    firestoreData.dpis = ArrayByKeyField.toFirestore<HandledDpi>(this.dpis, (value) => value.toFirestore());
    firestoreData.dpisIds = this.getHandledDpis().map((handledDpi) => handledDpi.dpi?.id ?? null);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public getHandledDpis(): HandledDpi[] {
    return DataHelpers.objectToSortedArray<HandledDpi>(this.dpis);
  }

  public setHandledDpis(handledDpis: HandledDpi[]): void {
    this.dpis = DataHelpers.sortedArrayToObject<HandledDpi>(handledDpis);
  }

  public addHandledDpi(handledDpi: HandledDpi): void {
    this.dpis[handledDpi.id] = handledDpi;
  }

  public removeHandledDpi(handledDpi: HandledDpi): void {
    delete this.dpis[handledDpi.id];
  }

  public emptyHandledDpis(): void {
    this.dpis = {};
  }
}
