import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Parcel } from "../objects/Parcel";
import { parcelModel } from "../models/ParcelModel";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const parcelSearch: SearchModule = new SearchModule(
  "parcel.parcel",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Parcel).code as string),
  (firestoreDocument: FirestoreDocument) => `/parcels/${firestoreDocument.id}`,
  "box",
  parcelModel,
  [new SortCriteria("code", "asc", "string")]
);
