import { compareAsc } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "../InboundShipmentModel";

export const getInboundShipmentsByPeriod = async (startDate: Date, endDate: Date): Promise<InboundShipment[]> => {
  try {
    const snapshotQuery: Query<InboundShipment> = query(
      inboundShipmentModel.getPathReference().withConverter(inboundShipmentModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate))
    );

    const snapshot: QuerySnapshot<InboundShipment> = await getDocs(inboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    const inboundShipments: InboundShipment[] = snapshot.docs
      .map((doc) => doc.data())
      .filter((inboundShipment: InboundShipment) => compareAsc(inboundShipment.date, endDate) <= 0);

    return inboundShipments;
  } catch (error: unknown) {
    appFaultModel.catchAppError("InboundShipmentModel.getInboundShipmentsByPeriod", { startDate, endDate }, error);
    return [];
  }
};
