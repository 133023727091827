import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { dpiHandlingModel } from "../DpiHandlingModel";

export const generateCode = async (): Promise<string> => {
  try {
    let newCode = "ERROR";
    let duplicatedDpiHandling: DpiHandling | undefined = new DpiHandling();

    while (duplicatedDpiHandling !== undefined) {
      newCode = FeaturesHelpers.generateCode("H");
      duplicatedDpiHandling = await dpiHandlingModel.getDpiHandlingByCode(newCode);
    }

    return newCode;
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.generateCode", {}, error);
    return "ERROR";
  }
};
