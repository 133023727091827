export const it = {
  employeeTransaction: {
    amount: "Importo",
    amountError: "Inserire un importo numerico positivo",
    date: "Data",
    dateError: "Inserire una data valida",
    direction: "Direzione",
    directionError: "Selezioanre una direzione valida",
    directions: {
      credit: "Entrata",
      debit: "Uscita",
    },
    employeeTransaction: "Transazione",
    employeeTransactionOf: "Transazione di {parent}",
    employeeTransactions: "Transazioni",
    employeeTransactionsOf: "Transazioni di {parent}",
    notes: "Note",
    preview: "Anteprima",
    title: "Titolo",
    titleError: "Inserire un titolo valido",
    type: "Tipo transazione",
    typeError: "Selezionare un tipo transazione valido",
  },
};
