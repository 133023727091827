import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";
import { LinkedWarehouseLocation } from "@/features/modules/warehouseLocation/objects/LinkedWarehouseLocation";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";
import { WarehouseLocation } from "@/features/modules/warehouseLocation/objects/WarehouseLocation";
import { warehouseLocationModel } from "@/features/modules/warehouseLocation/models/WarehouseLocationModel";

export class MoveWarehouseLocationModel {
  public async move(sourceWarehouseLocationCode: string, targetWarehouseLocationCode: string, companiesIds: string[]): Promise<void> {
    try {
      // check codes
      if (sourceWarehouseLocationCode === undefined) throw new Error("moveWarehouseLocationMissingSourceWarehouseLocationCode");
      if (targetWarehouseLocationCode === undefined) throw new Error("moveWarehouseLocationMissingTargetWarehouseLocationCode");

      // find warehouse locations
      const sourceWarehouseLocation: WarehouseLocation | undefined =
        await warehouseLocationModel.getWarehouseLocationByCode(sourceWarehouseLocationCode);
      const targetWarehouseLocation: WarehouseLocation | undefined =
        await warehouseLocationModel.getWarehouseLocationByCode(targetWarehouseLocationCode);

      // check warehouse locations
      if (sourceWarehouseLocation === undefined) throw new Error("moveWarehouseLocationUnknownSourceWarehouseLocation");
      if (targetWarehouseLocation === undefined) throw new Error("moveWarehouseLocationUnknownTargetWarehouseLocation");

      // check capacity
      let sourceCount = 0;
      if (companiesIds === undefined || companiesIds.length === 0) {
        sourceCount = sourceWarehouseLocation.getLinkedParcels().length;
      } else {
        const sourceParcels: Parcel[] = await parcelModel.getParcelsByWarehouseLocation(sourceWarehouseLocation.id);
        sourceCount = sourceParcels.filter((parcel: Parcel) => {
          if (parcel.inboundShipment?.company?.id === undefined) return false;
          return companiesIds.includes(parcel.inboundShipment.company.id);
        }).length;
      }
      if (targetWarehouseLocation.capacity !== 0) {
        const targetAvailableCapacity: number = targetWarehouseLocation.capacity - targetWarehouseLocation.getLinkedParcels().length;
        if (targetAvailableCapacity < sourceCount) {
          throw new Error("moveWarehouseLocationTargetWarehouseLocationCapacity");
        }
      }

      // move parcels
      const linkedTargetWarehouseLocation: LinkedWarehouseLocation = LinkedWarehouseLocation.createFromWarehouseLocation(targetWarehouseLocation);
      if (companiesIds === undefined || companiesIds.length === 0) {
        // just move all parcels
        const sourceParcels: Parcel[] = await parcelModel.getParcelsByWarehouseLocation(sourceWarehouseLocation.id);
        for (const sourceParcel of sourceParcels) {
          sourceParcel.warehouseLocation = linkedTargetWarehouseLocation;
          sourceParcel.addAction(ParcelActionType.Stored, targetWarehouseLocation.code);
          batch.update(parcelModel.getDocumentReference(sourceParcel.id), sourceParcel.toFirestore());

          const linkedParcel: LinkedParcel = LinkedParcel.createFromParcel(sourceParcel);
          linkedParcel.order = targetWarehouseLocation.getLinkedParcels().length + 1;

          targetWarehouseLocation.addLinkedParcel(linkedParcel);
        }

        sourceWarehouseLocation.emptyLinkedParcels();
        batch.update(warehouseLocationModel.getDocumentReference(sourceWarehouseLocation.id), sourceWarehouseLocation.toFirestore());
        batch.update(warehouseLocationModel.getDocumentReference(targetWarehouseLocation.id), targetWarehouseLocation.toFirestore());
      } else {
        // move only parcels from companiesIds
        const sourceParcels: Parcel[] = await parcelModel.getParcelsByWarehouseLocation(sourceWarehouseLocation.id);
        for (const sourceParcel of sourceParcels) {
          if (sourceParcel.inboundShipment?.company?.id === undefined) continue;
          if (companiesIds.includes(sourceParcel.inboundShipment.company.id) === false) continue;

          sourceParcel.warehouseLocation = linkedTargetWarehouseLocation;
          sourceParcel.addAction(ParcelActionType.Stored, targetWarehouseLocation.code);
          batch.update(parcelModel.getDocumentReference(sourceParcel.id), sourceParcel.toFirestore());

          sourceWarehouseLocation.removeLinkedParcel(LinkedParcel.createFromParcel(sourceParcel));

          const linkedParcel: LinkedParcel = LinkedParcel.createFromParcel(sourceParcel);
          linkedParcel.order = targetWarehouseLocation.getLinkedParcels().length + 1;

          targetWarehouseLocation.addLinkedParcel(linkedParcel);
        }

        batch.update(warehouseLocationModel.getDocumentReference(sourceWarehouseLocation.id), sourceWarehouseLocation.toFirestore());
        batch.update(warehouseLocationModel.getDocumentReference(targetWarehouseLocation.id), targetWarehouseLocation.toFirestore());
      }

      return batch.commit();
    } catch (error: unknown) {
      appFaultModel.catchAppError(
        "MoveWarehouseLocationModel.move",
        { sourceWarehouseLocationCode, targetWarehouseLocationCode, companiesIds },
        error
      );
    }
  }
}

export const moveWarehouseLocationModel: MoveWarehouseLocationModel = new MoveWarehouseLocationModel();
