export const it = {
  vehicle: {
    docFolder: "Fascicolo",
    filters: {
      archived: "Veicoli archiviati",
      archivedExcluded: "Archiviati esclusi",
      archivedIncluded: "Archiviati inclusi",
    },
    info: "Informazioni",
    model: "Modello",
    modelError: "Inserire un modello valido",
    registrationNumber: "Matricola",
    registrationNumberError: "Inserire una matricola valida e univoca",
    vehicle: "Veicolo",
    vehicles: "Veicoli",
    notes: "Note",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attivo",
      archived: "Archiviato",
    },
    type: "Tipo",
    typeError: "Selezionare un tipo valido",
  },
  navigation: {
    vehicle: "Veicoli",
  },
  buttons: {
    archiveVehicle: "Metti in archivio",
    restoreVehicle: "Togli dall'archivio",
  },
};
