export const it = {
  statEmployeeMonthReport: {
    date: "Data",
    from: "da",
    label: "Dettaglio",
    labels: {
      branches: "Cantieri",
      clockings: "Timbrature",
      counters: "Conteggio ore",
      leaves: "Assenze",
      notes: "Note",
      suspensions: "Sospensioni",
      transactions: "Transazioni",
    },
    month: "Mese",
    name: "Riepilogo mensile per operatore",
    to: "a",
    value: "Valore",
  },
  toast: {
    error: {
      statEmployeeMonthReport: {
        employeeUndefined: "Operatore non valido",
        monthUndefined: "Mese non valido",
      },
    },
    success: {},
    warning: {},
  },
};
