import { OutboundShipment } from "./OutboundShipment";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { DateStrictField, ObjectField, StringField } from "@/core/fields";

export class LinkedOutboundShipment extends LinkedFirestoreDocument {
  public date: Date = new Date();
  public company: LinkedCompany | undefined = undefined;
  public code: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromOutboundShipment(outboundShipment: OutboundShipment): LinkedOutboundShipment {
    const linkedOutboundShipment: LinkedOutboundShipment = new LinkedOutboundShipment();
    linkedOutboundShipment.id = outboundShipment.id;
    linkedOutboundShipment.date = outboundShipment.date;
    linkedOutboundShipment.company = outboundShipment.company;
    linkedOutboundShipment.code = outboundShipment.code;

    return linkedOutboundShipment;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedOutboundShipment {
    super.fromFirestore(data);

    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.code = StringField.fromFirestore(data.code);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.code = StringField.toFirestore(this.code);

    return firestoreData;
  }
}
