import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isPeriodAvailable = async (employeeId: string, from: Date, to: Date, employeeLeaveId = "empty"): Promise<boolean> => {
  try {
    if (employeeId === undefined) return false;
    if (from === undefined) return false;
    if (to === undefined) return false;

    return CloudHelpers.runValidatorFunction(
      "featuresEmployeeLeavePeriodValidator",
      { employeeId: employeeId, from: from.getTime(), to: to.getTime() },
      employeeLeaveId
    );
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeLeaveModel.isPeriodAvailable", { employeeId, from, to, employeeLeaveId }, error);
    return false;
  }
};
