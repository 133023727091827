
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { dpiStockModel } from "../models/DpiStockModel";
import { DpiStockPaper } from "../paper/DpiStockPaper";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "DpiStockView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<StoredDpi[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const branches: Ref<Branch[]> = ref([]);
    const branchesIds: Ref<string[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      branches.value = await branchModel.getActiveBranches();
    };

    const submitAction = async () => {
      showResults.value = false;

      results.value = await dpiStockModel.calcDpiStock(branchesIds.value);

      showResults.value = true;
    };

    const printReport = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const dpiStockPaper: DpiStockPaper = new DpiStockPaper(
            results.value,
            branches.value.filter((branch) => branchesIds.value.includes(branch.id)),
            d,
            n,
            t
          );
          await dpiStockPaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      branches,
      branchesIds,
      dataChanged,
      handleKeydown,
      handleSubmit,
      n,
      printReport,
      results,
      showResults,
      t,
    };
  },
});
