
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { CompanyStorage } from "../objects/CompanyStorage";
import { companyStorageModel } from "../models/CompanyStorageModel";
import { CompanyStoragePaper } from "../paper/CompanyStoragePaper";
import { ParcelType } from "@/features/modules/parcelType/objects/ParcelType";
import { parcelTypeModel } from "@/features/modules/parcelType/models/ParcelTypeModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "CompanyStorageView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<CompanyStorage[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId = ref();
    const companies: Ref<Company[]> = ref([]);
    const date: Ref<Date> = ref(new Date());

    const parcelTypes: Ref<ParcelType[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
      parcelTypes.value = await parcelTypeModel.getDocuments();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value == undefined) throw new Error("companyIdUndefined");
      if (date.value == undefined) throw new Error("dateUndefined");

      results.value = await companyStorageModel.calcCompanyStorage(companyId.value, date.value);

      showResults.value = true;
    };

    const printReport = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const company: Company = companies.value.find((c) => c.id == companyId.value) as Company;

          results.value = results.value.sort((a, b) => (a.code && b.code ? (a.code > b.code ? 1 : a.code < b.code ? -1 : 0) : 0));

          const companyStoragePaper: CompanyStoragePaper = new CompanyStoragePaper(
            company.name,
            date.value,
            results.value,
            parcelTypes.value,
            d,
            n,
            t
          );
          await companyStoragePaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      companies,
      companyId,
      dataChanged,
      date,
      handleKeydown,
      handleSubmit,
      n,
      parcelTypes,
      printReport,
      results,
      showResults,
      t,
    };
  },
});
