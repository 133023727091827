import { EmployeeDpi } from "../objects/EmployeeDpi";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getEmployeeDpiByDpiAndSize } from "./methods";

export class EmployeeDpiModel extends FirestoreModel<EmployeeDpi> {
  public constructor() {
    super(() => new EmployeeDpi(), "employeeDpis", LockPolicy.DiscardUnsyncedChanges, "employeeDpi");
    this.parentCollectionName = "employees";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<EmployeeDpi[]> {
    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(employeeDpiId: string, parentId?: string): Promise<EmployeeDpi> {
    return super.getDocument(employeeDpiId, parentId);
  }

  public async createDocument(employeeDpi: EmployeeDpi, parentId?: string): Promise<string> {
    return super.createDocument(employeeDpi, parentId);
  }

  public async updateDocument(employeeDpi: EmployeeDpi, parentId?: string): Promise<void> {
    return super.updateDocument(employeeDpi, parentId);
  }

  public async deleteDocument(employeeDpi: EmployeeDpi, parentId?: string): Promise<boolean> {
    return super.deleteDocument(employeeDpi, parentId);
  }

  public getEmployeeDpiByDpiAndSize(dpiId: string, size: string, parentId: string): Promise<EmployeeDpi | undefined> {
    return getEmployeeDpiByDpiAndSize(dpiId, size, parentId);
  }
}

export const employeeDpiModel: EmployeeDpiModel = new EmployeeDpiModel();
