import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { WarehouseLocation } from "./WarehouseLocation";

import { StringField } from "@/core/fields";

export class LinkedWarehouseLocation extends LinkedFirestoreDocument {
  public warehouse: string | undefined = undefined;
  public code: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromWarehouseLocation(warehouseLocation: WarehouseLocation): LinkedWarehouseLocation {
    const linkedWarehouseLocation: LinkedWarehouseLocation = new LinkedWarehouseLocation();
    linkedWarehouseLocation.id = warehouseLocation.id;
    linkedWarehouseLocation.warehouse = warehouseLocation.warehouse;
    linkedWarehouseLocation.code = warehouseLocation.code;

    return linkedWarehouseLocation;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedWarehouseLocation {
    super.fromFirestore(data);

    this.warehouse = StringField.fromFirestore(data.warehouse);
    this.code = StringField.fromFirestore(data.code);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.warehouse = StringField.toFirestore(this.warehouse);
    firestoreData.code = StringField.toFirestore(this.code);

    return firestoreData;
  }
}
