import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeExpiration } from "@/features/modules/employee/objects/EmployeeExpiration";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getOpenEmployeeLeaves = async (): Promise<EmployeeExpiration[]> => {
  try {
    const employeesExpirations: EmployeeExpiration[] = [];

    const employees: Employee[] = await employeeModel.getActiveEmployees();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("openLeaves", "employee");

    for (const employee of employees) {
      if (employee.trackExpirations === false) continue;

      const employeeWidget: Widget | undefined = widgets.find((widget) => widget.sourceId === employee.id);
      if (employeeWidget === undefined) continue;
      if (employeeWidget.values.count === 0) continue;
      if (employeeWidget.values.expirations == undefined) continue;

      const expirations: Record<string, Timestamp | null> = employeeWidget.values.expirations as Record<string, Timestamp | null>;
      for (const leaveTypeId of Object.keys(expirations)) {
        employeesExpirations.push(new EmployeeExpiration(employee, expirations[leaveTypeId]?.toDate() ?? undefined, leaveTypeId));
      }
    }

    return employeesExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeLeaveModel.getOpenEmployeeLeaves", {}, error);
    return [];
  }
};
