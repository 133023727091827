import BrowserPrint, { Device } from "zebra-print";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { zebraPrintModel } from "../ZebraPrintModel";

export const connect = (): void => {
  try {
    BrowserPrint.getDefaultDevice(
      "printer",
      function (device: Device) {
        zebraPrintModel.device = device;
        zebraPrintModel.setState("connected");
        console.log("Zebra printer connected", device);
      },
      function (error: unknown) {
        console.log(error);
      }
    );
  } catch (error: unknown) {
    appFaultModel.catchAppError("ZebraPrintModel.connect", {}, error);
  }
};
