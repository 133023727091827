import { RouteRecordRaw } from "vue-router";

export const vehiclePositionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/vehicles/:vehicleId/positions/:vehiclePositionId/edit",
    name: "VehiclePositionEdit",
    component: () => import("../views/VehiclePositionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vehiclePosition",
      requiredRight: "viewUI",
    },
  },
  // doc edit
  {
    path: "/vehicles/:sourceId/docs/:docId/edit",
    name: "VehicleDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: { source: "vehicle" },
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
];
