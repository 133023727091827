import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { InvoiceState } from "@/features/modules/invoice/objects/InvoiceState";

export const markAsCompleted = async (invoice: Invoice): Promise<void> => {
  try {
    for (const invoiceItem of invoice.items) {
      if (invoiceItem.isConfirmed === false) throw new Error("unconfirmedItems");
    }

    invoice.setTimestampFields("update");
    batch.update(invoiceModel.getDocumentReference(invoice.id), {
      state: InvoiceState.Completed,
    });

    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.markAsCompleted", { invoice }, error);
  }
};
