import { RouteRecordRaw } from "vue-router";

export const rawClockingRoutes: Array<RouteRecordRaw> = [
  {
    path: "/raw-clockings",
    name: "RawClockingList",
    component: () => import("../views/RawClockingList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "rawClocking",
      requiredRight: "viewUI",
    },
  },
];
