import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { LinkedParcelType } from "@/features/modules/parcelType/objects/LinkedParcelType";
import { ParcelItem } from "./ParcelItem";

import { ArrayField, ObjectField } from "@/core/fields";

export class ParcelData {
  public id: string = DataHelpers.uniqueId();
  public type: LinkedParcelType | undefined = undefined;
  public items: ParcelItem[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): ParcelData {
    this.id = DataHelpers.uniqueId();

    this.type = ObjectField.fromFirestore<LinkedParcelType>(data.type, (value) => new LinkedParcelType(value));
    this.items = ArrayField.fromFirestore<ParcelItem>(data.items, (value) => new ParcelItem(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.type = ObjectField.toFirestore<LinkedParcelType>(this.type, (value) => value.toFirestore());
    firestoreData.items = ArrayField.toFirestore<ParcelItem>(this.items, (value) => value.toFirestore());

    return firestoreData;
  }
}
