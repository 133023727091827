import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeeRoster } from "@/features/modules/employeeRoster/objects/EmployeeRoster";
import { employeeRosterModel } from "../EmployeeRosterModel";

export const getEmployeeRosterByEmployeeAndMonthAndYear = async (
  employeeId: string,
  month: number,
  year: number
): Promise<EmployeeRoster | undefined> => {
  try {
    const snapshotQuery: Query<EmployeeRoster> = query(
      employeeRosterModel.getPathReference(employeeId).withConverter(employeeRosterModel.firestoreConverter),
      where("year", "==", year),
      where("month", "==", month)
    );

    const snapshot: QuerySnapshot<EmployeeRoster> = await getDocs(employeeRosterModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeRosterModel.getEmployeeRosterByEmployeeAndMonthAndYear", { employeeId, month, year }, error);
    return undefined;
  }
};
