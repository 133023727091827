import { compareAsc } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "../InboundShipmentModel";

export const getInboundShipmentsByCompanyAndPeriod = async (companyId: string, startDate: Date, endDate: Date): Promise<InboundShipment[]> => {
  try {
    const snapshotQuery: Query<InboundShipment> = query(
      inboundShipmentModel.getPathReference().withConverter(inboundShipmentModel.firestoreConverter),
      where("company.id", "==", companyId),
      where("date", ">=", Timestamp.fromDate(startDate))
    );

    const snapshot: QuerySnapshot<InboundShipment> = await getDocs(inboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    const inboundShipments: InboundShipment[] = sortDocuments(
      snapshot.docs.map((doc) => doc.data()).filter((inboundShipment: InboundShipment) => compareAsc(inboundShipment.date, endDate) <= 0)
    );

    return inboundShipments;
  } catch (error: unknown) {
    appFaultModel.catchAppError("InboundShipmentModel.getInboundShipmentsByCompanyAndPeriod", { companyId, startDate, endDate }, error);
    return [];
  }
};

function sortDocuments(documents: InboundShipment[]): InboundShipment[] {
  const sorter: FirestoreSorter<InboundShipment> = new FirestoreSorter(documents);
  sorter.addSortCriteria("createdAt", "asc", "date");
  return sorter.sort();
}
