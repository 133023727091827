import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { zebraPrintModel } from "../ZebraPrintModel";

export const getState = (): "connected" | "disconnected" => {
  try {
    return zebraPrintModel.device !== undefined ? "connected" : "disconnected";
  } catch (error: unknown) {
    appFaultModel.catchAppError("ZebraPrintModel.getState", {}, error);
    return "disconnected";
  }
};
