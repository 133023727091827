export const itFeaturesLabels = {
  app: {
    warehouseLocationNotFound: "Dati non disponibili",
    warehouseLocationShelfDetail: "Magazzino {warehouse} - Corsia {lane} - Campata {span} - Posizione {position} - Piano {floor}",
    warehouseLocationZoneDetail: "Magazzino {warehouse} - Zona {zone}",
  },
  buttons: {
    calc: "Calcola",
    clone: "Duplica",
    generateParcels: "Genera UDC",
    goToObject: "Vai all'oggetto",
    goToWarehouseLocation: "Vai alla posizione",
    markAsSent: "Segna come spedita",
    openFile: "Apri allegato",
    openFiles: "Apri allegati",
    printDetails: "Stampa dettagli",
    printLabel: "Stampa etichetta",
    printLabels: "Stampa etichette",
    printParcelGuide: "Stampa lista di prelievo",
    printZebraLabel: "Stampa etichetta Zebra",
    printZebraLabels: "Stampa etichette Zebra",
    showQrCode: "Visualizza codice QR",
  },
  gen: {
    expiration: "Scadenza",
    noExpiration: "Non prevista",
    noResults: "Nessun risultato",
    weekDays: {
      0: "Domenica",
      1: "Lunedì",
      2: "Martedì",
      3: "Mercoledì",
      4: "Giovedì",
      5: "Venerdì",
      6: "Sabato",
    },
  },
  navigation: {
    accounting: "Fatture",
    aed: "DAE",
    dpi: "DPI",
    register: "Registro",
    tools: "Strumenti",
    warehouse: "Magazzino",
  },
  sexs: {
    female: "Femmina",
    male: "Maschio",
  },
  toast: {
    error: {
      calcParcelsError: "Impossibile selezionare UDC",
      calcParcelsNoSolutions: "Nessuna soluzione trovata",
      calcParcelsNotEnoughItems: "Il magazzino non contiene il materiale richiesto",
      cameraNotFound: "Fotocamera non trovata",
      codeError: "Codice non valido",
      companyFindDuplicated: "La partita IVA è già presente",
      companyFindError: "Impossibile ottenere i dati",
      companyParcelAlreadyPresent: "UDC già inserita",
      companyParcelAlreadySelected: "UDC già selezionata per spedizione",
      companyParcelNotAvailable: "UDC non disponibile",
      companyParcelNotFound: "UDC non trovata",
      companyParcelUndefined: "Selezionare un UDC",
      duplicatedCode: "Il codice è già presente",
      forkliftCompanyMismatch: "Aziende non corrispondenti",
      forkliftInvalidAction: "Azione non valida",
      forkliftInvalidOutboundShipment: "Spedizione in partenza non valida",
      forkliftInvalidScan: "Codice non valido",
      forkliftInvalidScanType: "Codice non corrispondente",
      forkliftLocationAtCapacity: "Posizione magazzino piena",
      forkliftMissingLocationCode: "Codice posizione magazzino mancante",
      forkliftMissingOutboundCode: "Codice spedizione in partenza mancante",
      forkliftMissingParcelCode: "Codice UDC mancante",
      forkliftMissingParcelsCodes: "Nessun UDC da caricare",
      forkliftParcelAlreadyInWarehouseLocation: "UDC già presente nella posizione magazzino",
      forkliftParcelAlreadyLoosened: "UDC già libero",
      forkliftParcelAlreadyStored: "UDC già immagazzinato",
      forkliftParcelInvalidState: "Stato UDC non valido",
      forkliftParcelNotInWarehouseLocation: "UDC non presente nella posizione magazzino",
      forkliftParcelNotLoosened: "UDC non libero",
      forkliftParcelNotValid: "UDC non valido",
      forkliftScanError: "Impossibile riconoscere il codice",
      forkliftUnknownParcel: "UDC non riconosciuto",
      forkliftUnknownLocation: "Posizione magazzino non riconosciuta",
      forkliftUnknownOutboundShipment: "Spedizione in partenza non riconosciuta",
      generateParcels: "Impossibile generare UDC",
      getPecFromVatError: "Impossibile recuperare il dato",
      objectNotFound: "Oggetto non trovato",
      parcelError: "Stato non valido per uno o più UDC",
    },
    success: {
      calc: "Calcolo eseguito con successo",
      forkliftScan: "Codice acquisito",
      generateParcels: "UDC generati correttamente",
    },
    warning: {
      companyFindEmpty: "Inserire la partita IVA",
      companyFindNotFound: "Nessuna corrispondenza trovata",
      forkliftMissingData: "Inserire i dati richiesti",
      getPecFromVatEmpty: "Inserire la partita IVA",
      getPecFromVatNotFound: "Indirizzo non trovato",
      parcelsAlreadyGenerated: "UDC già generati",
    },
  },
  widgets: {
    expiredCount: "Scaduti",
    expiringCount: "In scadenza",
    missingCount: "Mancanti",
    openCount: "Aperti",
  },
};
