
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { moveWarehouseLocationModel } from "../models/MoveWarehouseLocationModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "MoveWarehouseLocationView",
  setup() {
    let eventBusId: string | undefined;
    const { t } = useLocale();

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      eventBus.emit("showConfirmDialog");
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const sourceWarehouseLocationCode: Ref<string> = ref("");
    const targetWarehouseLocationCode: Ref<string> = ref("");

    const companies: Ref<Company[]> = ref([]);
    const companiesIds: Ref<string[]> = ref([]);

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    eventBus.on("executeUtilityModuleAction", () => {
      return Promise.resolve(submitAction());
    });

    const submitAction = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await moveWarehouseLocationModel.move(sourceWarehouseLocationCode.value, targetWarehouseLocationCode.value, companiesIds.value);
        },
        "utilityDone",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => {
      handleLoad();
      eventBusId = eventBus.on("executeUtilityModuleAction", () => Promise.resolve(submitAction()));
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      companies,
      companiesIds,
      handleKeydown,
      handleSubmit,
      sourceWarehouseLocationCode,
      t,
      targetWarehouseLocationCode,
    };
  },
});
