import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { WarehouseLocation } from "../objects/WarehouseLocation";

import { getWarehouseLocationByCode } from "./methods";

export class WarehouseLocationModel extends FirestoreModel<WarehouseLocation> {
  public constructor() {
    super(() => new WarehouseLocation(), "warehouseLocations", LockPolicy.DiscardUnsyncedChanges, "warehouseLocation");
    this.beforeDeleteFunction = "featuresWarehouseLocationBeforeDelete";
  }

  public async getDocuments(): Promise<WarehouseLocation[]> {
    return super.getDocuments();
  }

  public async getDocument(warehouseLocationId: string): Promise<WarehouseLocation> {
    return super.getDocument(warehouseLocationId);
  }

  public async createDocument(warehouseLocation: WarehouseLocation): Promise<string> {
    const duplicatedWarehouseLocation: WarehouseLocation | undefined = await this.getWarehouseLocationByCode(warehouseLocation.code as string);
    if (duplicatedWarehouseLocation != undefined) throw new Error("duplicatedCode");

    return super.createDocument(warehouseLocation);
  }

  public async updateDocument(warehouseLocation: WarehouseLocation): Promise<void> {
    const duplicatedWarehouseLocation: WarehouseLocation | undefined = await this.getWarehouseLocationByCode(warehouseLocation.code as string);
    if (duplicatedWarehouseLocation != undefined && warehouseLocation.id !== duplicatedWarehouseLocation.id) throw new Error("duplicatedCode");

    return super.updateDocument(warehouseLocation);
  }

  public async deleteDocument(warehouseLocation: WarehouseLocation): Promise<boolean> {
    return super.deleteDocument(warehouseLocation);
  }

  /* custom methods */

  public async getWarehouseLocationByCode(code: string): Promise<WarehouseLocation | undefined> {
    return getWarehouseLocationByCode(code);
  }
}

export const warehouseLocationModel: WarehouseLocationModel = new WarehouseLocationModel();
