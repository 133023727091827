import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { branchDpiModel } from "@/features/modules/branchDpi/models/BranchDpiModel";
import { BranchDpi } from "@/features/modules/branchDpi/objects/BranchDpi";
import { storedDpiModel } from "@/features/modules/storedDpi/models/StoredDpiModel";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";

export class DpiStockModel {
  public async calcDpiStock(branchesIds: string[]): Promise<StoredDpi[]> {
    try {
      if (branchesIds === undefined) branchesIds = [];

      const storedDpis: StoredDpi[] = await storedDpiModel.getDocuments();

      for (const branchId of branchesIds) {
        const branchStoredDpis: BranchDpi[] = await branchDpiModel.getDocuments([], branchId);

        for (const branchStoredDpi of branchStoredDpis) {
          const storedDpi: StoredDpi | undefined = storedDpis.find(
            (storedDpi: StoredDpi) =>
              storedDpi.dpi?.id === branchStoredDpi.dpi?.id && storedDpi.size === branchStoredDpi.size && branchStoredDpi.size !== undefined
          );

          if (storedDpi === undefined) {
            const newStoredDpi: StoredDpi = new StoredDpi();
            newStoredDpi.dpi = branchStoredDpi.dpi;
            newStoredDpi.quantity = branchStoredDpi.quantity;
            newStoredDpi.size = branchStoredDpi.size;
            storedDpis.push(newStoredDpi);
          } else {
            storedDpi.quantity += branchStoredDpi.quantity;
          }
        }
      }

      return storedDpis;
    } catch (error: unknown) {
      appFaultModel.catchAppError("DpiStockModel.calcDpiStock", { branchesIds }, error);
      return [];
    }
  }
}

export const dpiStockModel: DpiStockModel = new DpiStockModel();
