import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { SourceType } from "@/features/modules/dpiHandling/objects/SourceType";
import { TargetType } from "@/features/modules/dpiHandling/objects/TargetType";

import { deliverDpisFromBranchToEmployee } from "./deliverDpisFromBranchToEmployee";
import { deliverDpisFromBranchToTrash } from "./deliverDpisFromBranchToTrash";
import { deliverDpisFromBranchToWarehouse } from "./deliverDpisFromBranchToWarehouse";
import { deliverDpisFromEmployeeToBranch } from "./deliverDpisFromEmployeeToBranch";
import { deliverDpisFromEmployeeToTrash } from "./deliverDpisFromEmployeeToTrash";
import { deliverDpisFromEmployeeToWarehouse } from "./deliverDpisFromEmployeeToWarehouse";
import { deliverDpisFromWarehouseToBranch } from "./deliverDpisFromWarehouseToBranch";
import { deliverDpisFromWarehouseToEmployee } from "./deliverDpisFromWarehouseToEmployee";
import { deliverDpisFromWarehouseToTrash } from "./deliverDpisFromWarehouseToTrash";

export const deliverDpis = async (dpiHandling: DpiHandling): Promise<string> => {
  try {
    if (dpiHandling === undefined) throw new Error("dpiHandling is undefined");
    if (dpiHandling.getHandledDpis().length === 0) return "noDpisToDeliver";

    if (dpiHandling.sourceType === SourceType.Warehouse) {
      if (dpiHandling.targetType === TargetType.Branch) {
        if (dpiHandling.targetBranch === undefined) throw new Error("targetBranch is undefined");
        return await deliverDpisFromWarehouseToBranch(dpiHandling);
      } else if (dpiHandling.targetType === TargetType.Employee) {
        if (dpiHandling.targetEmployee === undefined) throw new Error("targetEmployee is undefined");
        return await deliverDpisFromWarehouseToEmployee(dpiHandling);
      } else if (dpiHandling.targetType === TargetType.Trash) {
        return await deliverDpisFromWarehouseToTrash(dpiHandling);
      }
    } else if (dpiHandling.sourceType === SourceType.Branch) {
      if (dpiHandling.sourceBranch === undefined) throw new Error("sourceBranch is undefined");
      if (dpiHandling.targetType === TargetType.Warehouse) {
        return await deliverDpisFromBranchToWarehouse(dpiHandling);
      } else if (dpiHandling.targetType === TargetType.Employee) {
        if (dpiHandling.targetEmployee === undefined) throw new Error("targetEmployee is undefined");
        return await deliverDpisFromBranchToEmployee(dpiHandling);
      } else if (dpiHandling.targetType === TargetType.Trash) {
        return await deliverDpisFromBranchToTrash(dpiHandling);
      }
    } else if (dpiHandling.sourceType === SourceType.Employee) {
      if (dpiHandling.sourceEmployee === undefined) throw new Error("sourceEmployee is undefined");
      if (dpiHandling.targetType === TargetType.Warehouse) {
        return await deliverDpisFromEmployeeToWarehouse(dpiHandling);
      } else if (dpiHandling.targetType === TargetType.Branch) {
        if (dpiHandling.targetBranch === undefined) throw new Error("targetBranch is undefined");
        return await deliverDpisFromEmployeeToBranch(dpiHandling);
      } else if (dpiHandling.targetType === TargetType.Trash) {
        return await deliverDpisFromEmployeeToTrash(dpiHandling);
      }
    }

    return "dpiDeliveryNotImplemented";
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.deliverDpis", { dpiHandling }, error);
    return "ERROR";
  }
};
