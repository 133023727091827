export const it = {
  branchShiftType: {
    branchShiftType: "Tipo di turno",
    branchShiftTypeOf: "Tipo di turno di {parent}",
    branchShiftTypes: "Tipi di turno",
    branchShiftTypesOf: "Tipi di turno di {parent}",
    code: "Sigla",
    codeError: "Inserire una sigla valida",
    name: "Nome",
    nameError: "Inserire un nome valido",
    times: "Orari",
    timesError: "Inserire almeno due orari validi",
  },
  navigation: {
    branchShiftType: "Tipi di turno",
  },
};
