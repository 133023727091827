import { RouteRecordRaw } from "vue-router";

export const inboundShipmentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/inbound-shipments",
    name: "InboundShipmentList",
    component: () => import("../views/InboundShipmentList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "inboundShipment",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/inbound-shipments/:id/edit",
    name: "InboundShipmentEdit",
    component: () => import("../views/InboundShipmentEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "inboundShipment",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/inbound-shipments/:id/:section?",
    name: "InboundShipmentView",
    component: () => import("../views/InboundShipmentView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "inboundShipment",
      requiredRight: "viewUI",
    },
  },
];
