export class ChartHelpers {
  public static getChartBackgroundColor(index: number): string {
    const backgroundColors: string[] = [
      "rgba(66, 165, 245, 0.5)", // blue
      "rgba(255, 167, 38, 0.5)", // orange
      "rgba(236, 64, 122, 0.5)", // pink
      "rgba(102, 187, 106, 0.5)", // green
      "rgba(170, 71, 188, 0.5)", // purple
      "rgba(255, 202, 40, 0.5)", // yellow
      "rgba(38, 166, 154, 0.5)", // teal
      "rgba(126, 87, 194, 0.5)", // dark purple
    ];
    return index < backgroundColors.length ? backgroundColors[index] : backgroundColors[0];
  }

  public static getChartBorderColor(index: number): string {
    const borderColors: string[] = [
      "rgb(66, 165, 245)", // blue
      "rgb(255, 167, 38)", // orange
      "rgb(236, 64, 122)", // pink
      "rgb(102, 187, 106)", // green
      "rgb(170, 71, 188)", // purple
      "rgb(255, 202, 40)", // yellow
      "rgb(38, 166, 154)", // teal
      "rgb(126, 87, 194)", // dark purple
    ];
    return index < borderColors.length ? borderColors[index] : borderColors[0];
  }

  public static getChartOptions(): Record<string, unknown> {
    return {
      plugins: {
        legend: {
          display: true,
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
  }

  public static getChartOptionsLandscape(): Record<string, unknown> {
    return { ...this.getChartOptions(), aspectRatio: 3 };
  }
}
