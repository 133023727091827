import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedDpi } from "@/features/modules/dpi/objects/LinkedDpi";

import { NumberField, ObjectField, StringField } from "@/core/fields";

export class BranchDpi extends FirestoreDocument {
  public dpi: LinkedDpi | undefined = undefined;
  public size: string | undefined = undefined;
  public quantity = 0;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): BranchDpi {
    super.fromFirestore(data, id, firestoreRef);

    this.dpi = ObjectField.fromFirestore<LinkedDpi>(data.dpi, (value) => new LinkedDpi(value));
    this.size = StringField.fromFirestore(data.size);
    this.quantity = NumberField.fromFirestore(data.quantity);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.dpi = ObjectField.toFirestore<LinkedDpi>(this.dpi, (value) => value.toFirestore());
    firestoreData.size = StringField.toFirestore(this.size);
    firestoreData.quantity = NumberField.toFirestore(this.quantity);

    return firestoreData;
  }
}
