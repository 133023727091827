export const it = {
  vehicleExpense: {
    addVehicleExpense: "Aggiungi costo",
    amount: "Importo",
    amountError: "Inserire un importo valido",
    branch: "Cantiere",
    branchError: "Selezionare una data dove il veicolo risulta impiegato in un cantiere",
    date: "Data",
    dateError: "Selezionare una data valida",
    description: "Descrizione",
    notes: "Note",
    vehicleExpense: "Costo",
    vehicleExpenseOf: "Costo di {parent}",
    vehicleExpenses: "Costi",
    vehicleExpensesOf: "Costi di {parent}",
  },
};
