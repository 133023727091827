import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";

import { NumberField, StringField } from "@/core/fields";

export class BranchPosition extends FirestoreDocument {
  public name: string | undefined = undefined;
  public hourlyCost = 0;
  public overtimePercentIncrease = 0;
  public nightPercentIncrease = 0;
  public beforeHolidayPercentIncrease = 0;
  public holidayPercentIncrease = 0;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): BranchPosition {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.hourlyCost = NumberField.fromFirestore(data.hourlyCost);
    this.overtimePercentIncrease = NumberField.fromFirestore(data.overtimePercentIncrease);
    this.nightPercentIncrease = NumberField.fromFirestore(data.nightPercentIncrease);
    this.beforeHolidayPercentIncrease = NumberField.fromFirestore(data.beforeHolidayPercentIncrease);
    this.holidayPercentIncrease = NumberField.fromFirestore(data.holidayPercentIncrease);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.hourlyCost = NumberField.toFirestore(this.hourlyCost);
    firestoreData.overtimePercentIncrease = NumberField.toFirestore(this.overtimePercentIncrease);
    firestoreData.nightPercentIncrease = NumberField.toFirestore(this.nightPercentIncrease);
    firestoreData.beforeHolidayPercentIncrease = NumberField.toFirestore(this.beforeHolidayPercentIncrease);
    firestoreData.holidayPercentIncrease = NumberField.toFirestore(this.holidayPercentIncrease);

    return firestoreData;
  }
}
