import { Dpi } from "./Dpi";
import { LinkedDpiCategory } from "@/features/modules/dpiCategory/objects/LinkedDpiCategory";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { ObjectField, StringArrayField, StringField } from "@/core/fields";

export class LinkedDpi extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;
  public category: LinkedDpiCategory | undefined = undefined;
  public sizes: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromDpi(dpi: Dpi): LinkedDpi {
    const linkedDpi: LinkedDpi = new LinkedDpi();
    linkedDpi.id = dpi.id;
    linkedDpi.name = dpi.name;
    linkedDpi.category = dpi.category;
    linkedDpi.sizes = dpi.sizes;

    return linkedDpi;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedDpi {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.category = ObjectField.fromFirestore<LinkedDpiCategory>(data.category, (value) => new LinkedDpiCategory(value));
    this.sizes = StringArrayField.fromFirestore(data.sizes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.category = ObjectField.toFirestore<LinkedDpiCategory>(this.category, (value) => value.toFirestore());
    firestoreData.sizes = StringArrayField.toFirestore(this.sizes);

    return firestoreData;
  }
}
