import { compareAsc, differenceInSeconds } from "date-fns";

import { Clocking } from "../objects/Clocking";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export class EmployeeRosterHelpers {
  public static calcClockingHours(startTimestamp: Date, endTimestamp: Date): number {
    if (compareAsc(startTimestamp, endTimestamp) >= 0) return 0;

    const secondsDiff = differenceInSeconds(endTimestamp, startTimestamp);
    const hours: number = Math.floor(secondsDiff / 3600);
    return hours + Math.floor(((secondsDiff % 3600) / 3600) * 2) / 2;
  }

  public static async calculateEmployeeRosterCounters(employeeId: string, employeeRosterId: string): Promise<void> {
    await CloudHelpers.runGenericFunction("featuresEmployeeRosterCalculateCounters", {
      employeeId: employeeId,
      employeeRosterId: employeeRosterId,
    });
  }

  public static clockingsToSortedArray(object: Record<string, Clocking>): Clocking[] {
    return Object.values(object).sort((a, b) => {
      if ((a as unknown as Record<string, number>).order < (b as unknown as Record<string, number>).order) return -1;
      if ((a as unknown as Record<string, number>).order > (b as unknown as Record<string, number>).order) return 1;
      return 0;
    });
  }

  public static sortedArrayToClockings(clockings: Clocking[]): Record<string, Clocking> {
    const object: Record<string, Clocking> = {};
    for (const clocking of clockings) {
      object[clocking.id] = clocking;
    }
    return object;
  }
}
