import { DocumentReference } from "firebase/firestore";

import { BoughtDpi } from "./BoughtDpi";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";

import { ArrayByKeyField, BooleanField, DateStrictField, NumberField, StringField } from "@/core/fields";

export class DpiPurchase extends FirestoreDocument {
  public code: string | undefined = undefined;
  public date: Date = new Date();
  public dpis: Record<string, BoughtDpi> = {};
  public totalPrice = 0;
  public notes: string | undefined = undefined;
  public isStored = false;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): DpiPurchase {
    super.fromFirestore(data, id, firestoreRef);

    this.code = StringField.fromFirestore(data.code);
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.dpis = ArrayByKeyField.fromFirestore<BoughtDpi>(data.dpis, (value) => new BoughtDpi(value));
    this.totalPrice = NumberField.fromFirestore(data.totalPrice);
    this.notes = StringField.fromFirestore(data.notes);
    this.isStored = BooleanField.fromFirestore(data.isStored);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.dpis = ArrayByKeyField.toFirestore<BoughtDpi>(this.dpis, (value) => value.toFirestore());
    firestoreData.dpisIds = this.getBoughtDpis().map((boughtDpi) => boughtDpi.dpi?.id ?? null);
    firestoreData.totalPrice = NumberField.toFirestore(this.totalPrice);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.isStored = BooleanField.toFirestore(this.isStored);

    return firestoreData;
  }

  public getBoughtDpis(): BoughtDpi[] {
    return DataHelpers.objectToSortedArray<BoughtDpi>(this.dpis);
  }

  public setBoughtDpis(boughtDpis: BoughtDpi[]): void {
    this.dpis = DataHelpers.sortedArrayToObject<BoughtDpi>(boughtDpis);
  }

  public addBoughtDpi(boughtDpi: BoughtDpi): void {
    this.dpis[boughtDpi.id] = boughtDpi;
  }

  public removeBoughtDpi(boughtDpi: BoughtDpi): void {
    delete this.dpis[boughtDpi.id];
  }

  public emptyBoughtDpis(): void {
    this.dpis = {};
  }
}
