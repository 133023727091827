export const it = {
  employeePosition: {
    addEmployeePosition: "Aggiungi impiego",
    branch: "Cantiere",
    branchError: "Selezionare un cantiere valido",
    employeePosition: "Impiego",
    employeePositionOf: "Impiego di {parent}",
    employeePositions: "Impieghi",
    employeePositionsOf: "Impieghi di {parent}",
    from: "Inizio",
    fromError: "Selezionare una data di inizio valida",
    notes: "Note",
    position: "Ruolo",
    positionError: "Selezionare un ruolo valido",
    to: "Fine",
    toError: "Selezionare una data di fine valida",
  },
};
