
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { BranchWithEmployee } from "../objects/BranchWithEmployee";
import { branchWithEmployeeModel } from "../models/BranchWithEmployeeModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "BranchWithEmployeeView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<BranchWithEmployee[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const date: Ref<Date> = ref(new Date());
    const expandedRows: Ref<unknown[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      // nothing to do here
    };

    const submitAction = async () => {
      showResults.value = false;
      if (date.value === undefined) throw new Error("statBranchWithEmployee.dateUndefined");

      results.value = await branchWithEmployeeModel.countForDate(date.value);

      showResults.value = true;
    };

    const printPaper = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          /*
          const serviceCounterPaper: ServiceCounterPaper = new ServiceCounterPaper(
            companyId.value as string,
            doctorId.value as string,
            period.value[0],
            period.value[1],
            results.value,
            d,
            n,
            t
          );
          serviceCounterPaper.open();
          */
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      d,
      dataChanged,
      date,
      expandedRows,
      handleKeydown,
      handleSubmit,
      n,
      printPaper,
      results,
      showResults,
      t,
    };
  },
});
