export const it = {
  forklift: {
    action: "Azione",
    actionError: "Selezionare una azione valida",
    actions: {
      delete: "Elimina",
      load: "Carica",
      loose: "Togli",
      store: "Immagazzina",
      unload: "Scarica",
    },
    error: "Errore",
    forklift: "Movimentazione UDC",
    inboundShipment: "Spedizione in arrivo",
    inboundShipmentCode: "Codice spedizione in arrivo",
    inboundShipmentDescription: "{code} - Spedizione in arrivo del {date} per {companyName}",
    inboundShipmentDetail: "Spedizione in arrivo del {date} per {companyName}",
    outboundShipment: "Spedizione in partenza",
    outboundShipmentCode: "Codice spedizione in partenza",
    outboundShipmentDescription: "{code} - Spedizione in partenza del {date} per {companyName}",
    outboundShipmentDetail: "Spedizione in partenza del {date} per {companyName}",
    parcel: "UDC",
    parcelCode: "Codice UDC",
    parcelCodeError: "Inserire un codice UDC valido",
    parcelDescription: "{code} - UDC da spedizione di {companyName} del {date}",
    parcelInboundShipmentDetail: "Spedizione in arrivo { code } di {companyName} del {date}",
    parcelOutboundShipmentDetail: "Spedizione in partenza { code } di {companyName} del {date}",
    scan: "Scansiona",
    scanCode: "Scansiona un codice",
    scanner: "Scanner codice",
    states: {
      deleted: "Eliminato",
      loaded: "Caricato",
      loosened: "Libero",
      selected: "Da caricare",
      sent: "Spedito",
      stored: "Immagazzinato",
      unknown: "Sconosciuto",
    },
    success: "Completato",
    successMessage: "Operazione completata",
    visualActions: {
      check: "Cerca codice",
      load: "Carica su spedizione",
      loose: "Togli dal magazzino",
      shipmentList: "Lista di prelievo",
      store: "Posiziona in magazzino",
      unload: "Scarica da spedizione",
    },
    warehouseLocation: "Posizione magazzino",
    warehouseLocationCapacity: "Capacità {capacity} UDC",
    warehouseLocationCapacityUnlimited: "Capacità illimitata",
    warehouseLocationCode: "Codice posizione magazzino",
    warehouseLocationEmpty: "Nessun UDC contenuto",
    warehouseLocationParcelsContained: "{parcels} UDC contenuti | {parcels} UDC contenuto",
    warning: "Attenzione",
    warnings: {
      emptyCode: "Codice non valido",
      invalidCode: "Codice non valido",
      outboundShipmentNotFound: "Spedizione non trovata",
      parcelNotFound: "UDC non trovata",
      warehouseLocationNotFound: "Posizione magazzino non trovata",
    },
  },
  navigation: {
    forklift: "Movimentazione UDC",
  },
};
