export enum ParcelActionType {
  Created = "created",
  Deleted = "deleted",
  Loaded = "loaded",
  Loosened = "loosened",
  Selected = "selected",
  Sent = "sent",
  Stored = "stored",
  Unselected = "unselected",
}
