import { RouteRecordRaw } from "vue-router";

export const employeeRosterRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employee-roster/:branchId?/:year?/:month?",
    name: "RosterView",
    component: () => import("../views/RosterView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeRoster",
      requiredRight: "viewUI",
    },
  },
];
