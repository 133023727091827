import { EmployeeLeave } from "./EmployeeLeave";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedLeaveType } from "@/features/modules/leaveType/objects/LinkedLeaveType";

import { DateField, ObjectField } from "@/core/fields";

export class LinkedEmployeeLeave extends LinkedFirestoreDocument {
  public from: Date | undefined = undefined;
  public to: Date | undefined = undefined;
  public type: LinkedLeaveType | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromEmployeeLeave(employeeLeave: EmployeeLeave): LinkedEmployeeLeave {
    const linkedEmployeeLeave: LinkedEmployeeLeave = new LinkedEmployeeLeave();
    linkedEmployeeLeave.id = employeeLeave.id;
    linkedEmployeeLeave.from = employeeLeave.from;
    linkedEmployeeLeave.to = employeeLeave.to;
    linkedEmployeeLeave.type = employeeLeave.type;

    return linkedEmployeeLeave;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedEmployeeLeave {
    super.fromFirestore(data);

    this.from = DateField.fromFirestore(data.from);
    this.to = DateField.fromFirestore(data.to);
    this.type = ObjectField.fromFirestore<LinkedLeaveType>(data.type, (value) => new LinkedLeaveType(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.type = ObjectField.toFirestore<LinkedLeaveType>(this.type, (value) => value.toFirestore());

    return firestoreData;
  }
}
