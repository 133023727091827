import { addDays, compareAsc, endOfMonth, startOfMonth } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ChartHelpers } from "@/features/modules/stat/helpers/ChartHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";

export const chartMonthlyStock = (parcels: Parcel[], monthDate: Date, t: (entry: string) => string): Record<string, unknown> => {
  try {
    let startDate: Date = startOfMonth(monthDate);
    const endDate: Date = endOfMonth(monthDate);

    const sets: Record<number, number> = {};
    while (compareAsc(startDate, endDate) <= 0) {
      let parcelStored = 0;

      for (const parcel of parcels) {
        let isStored = false;
        for (const action of parcel.actions) {
          action.date.setHours(0, 0, 0, 0);
          if (compareAsc(action.date, startDate) > 0) continue;

          if (!isStored) {
            if (action.action == ParcelActionType.Created) isStored = true;
          } else {
            if ([ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) isStored = false;
          }
        }
        if (isStored) parcelStored++;
      }

      sets[startDate.getDate()] = parcelStored;
      startDate = addDays(startDate, 1);
    }

    const orderedSets = DataHelpers.sortObjectKeys(sets);

    const chartData: Record<string, unknown> = {
      labels: Object.keys(orderedSets),
      datasets: [
        {
          backgroundColor: ChartHelpers.getChartBackgroundColor(0),
          borderColor: ChartHelpers.getChartBorderColor(0),
          borderWidth: 2,
          data: Object.values(orderedSets),
          label: t("statStockChart.parcel"),
        },
      ],
    };

    return chartData;
  } catch (error: unknown) {
    appFaultModel.catchAppError("StockChartModel.chartMonthlyStock", { parcels, monthDate, t }, error);
    return {};
  }
};
