export const it = {
  parcelType: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    parcelType: "Formato UDC",
    parcelTypes: "Formati UDC",
  },
  navigation: {
    parcelType: "Formati UDC",
  },
};
