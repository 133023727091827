export const it = {
  bank: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    bank: "Conto corrente",
    banks: "Conti correnti",
  },
  navigation: {
    bank: "Conti correnti",
  },
};
