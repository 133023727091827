export const it = {
  rawClocking: {
    badgeNumber: "Numero di badge",
    details: "Dettagli",
    employee: "Operatore",
    noDetails: "Nessun dettaglio presente",
    rawClocking: "Timbratura grezza",
    rawClockings: "Timbrature grezze",
    side: "Tipo",
    state: "Stato",
    states: {
      error: "Errore",
      raw: "Grezza",
      validated: "Validata",
    },
    timekeeper: "Marcatempo",
    timestamp: "Data e ora",
  },
  navigation: {
    rawClocking: "Timbrature grezze",
  },
};
