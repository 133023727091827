export const it = {
  courseType: {
    courseType: "Tipo di corso",
    courseTypes: "Tipi di corso",
    isMandatory: "Obbligatorio",
    isMandatoryError: "Scegliere un valore valido",
    months: "1 mese | {count} mesi",
    name: "Nome",
    nameError: "Inserire un nome valido",
    validity: "Validità",
    validityError: "Scegliere un valore valido",
  },
  navigation: {
    courseType: "Tipi di corso",
  },
};
