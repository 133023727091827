export const it = {
  inboundShipment: {
    addParcel: "Aggiungi carico",
    code: "Codice",
    codeDetails: "Dettagli Codice",
    codeError: "Inserire un codice valido",
    company: "Azienda",
    companyError: "Selezionare una azienda",
    content: "Contenuto",
    count: "Conteggio",
    creation: "Creazione",
    date: "Data",
    dateError: "Inserire una data valida",
    detail: "Dettaglio",
    detailError: "Selezionare un dettaglio valido",
    details: "Dettagli",
    general: "Generale",
    groupedContent: "Contenuto raggruppato",
    inboundShipment: "Spedizione in arrivo",
    inboundShipments: "Spedizioni in arrivo",
    items: "Codici",
    lastInboundShipments: "Ultime spedizione in arrivo",
    of: "del",
    parcels: "UDC",
    position: "Posizione",
    quantity: "Quantità",
    quantityError: "Inserire una quantità valida",
    reorder: "Ordina",
    sender: "Mittente",
    state: "Stato",
    transportType: "Tipo di trasporto",
    transportTypeError: "Selezionare un tipo di trasporto",
    type: "Tipo",
    typeError: "Selezionare il tipo",
    unknownFormat: "Formato sconosciuto",
    warehouseLocation: "Posizione magazzino",
  },
  navigation: {
    inboundShipment: "Spedizioni in arrivo",
  },
  buttons: {
    printParcelsDataDetails: "Stampa lista contenuto",
    printParcelsDetails: "Stampa lista UDC",
  },
};
