import { Employee } from "../objects/Employee";
import { employeeModel } from "../models/EmployeeModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const employeeSearch: SearchModule = new SearchModule(
  "employee.employee",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Employee).fullName ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/employees/${firestoreDocument.id}`,
  "users",
  employeeModel,
  [new SortCriteria("fullName", "asc", "string")]
);
