import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeeTransaction } from "@/features/modules/employeeTransaction/objects/EmployeeTransaction";
import { employeeTransactionModel } from "../EmployeeTransactionModel";

export const getEmployeeTransactionsByEmployeeAndDate = async (employeeId: string, date: Date): Promise<EmployeeTransaction[]> => {
  try {
    const snapshotQuery: Query<EmployeeTransaction> = query(
      employeeTransactionModel.getPathReference(employeeId).withConverter(employeeTransactionModel.firestoreConverter),
      where("date", "==", Timestamp.fromDate(date))
    );

    const snapshot: QuerySnapshot<EmployeeTransaction> = await getDocs(employeeTransactionModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeTransactionModel.getEmployeeTransactionsByEmployeeAndDate", { employeeId, date }, error);
    return [];
  }
};
