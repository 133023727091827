import { RouteRecordRaw } from "vue-router";

export const docRoutes: Array<RouteRecordRaw> = [
  {
    path: "/docs",
    name: "DocList",
    component: () => import("../views/DocList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/docs/:id/edit",
    name: "DocEdit",
    component: () => import("../views/DocEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/docs/:id",
    name: "DocView",
    component: () => import("../views/DocView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
];
