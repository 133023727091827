import { addDays, compareAsc, endOfMonth, getDate, startOfMonth } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { employeePositionModel } from "@/features/modules/employeePosition/models/EmployeePositionModel";
import { EmployeeRoster } from "@/features/modules/employeeRoster/objects/EmployeeRoster";
import { employeeRosterModel } from "../EmployeeRosterModel";
import { EmployeeWithRoster } from "@/features/modules/employeeRoster/objects/EmployeeWithRoster";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { Shift } from "@/features/modules/employeeRoster/objects/Shift";

export const getEmployeesWithRosterByBranchAndMonthAndYear = async (branchId: string, month: number, year: number): Promise<EmployeeWithRoster[]> => {
  try {
    const employeesWithRoster: EmployeeWithRoster[] = [];

    // get all employees for the branch
    const employees: Employee[] = await employeeModel.getEmployeesByBranch(branchId);

    for (const employee of employees) {
      // check for allowed days
      const employeePositions: EmployeePosition[] = await employeePositionModel.getEmployeePositionsByEmployeeAndBranch(employee.id, branchId);
      const allowedDays: number[] = getAllowedDays(employeePositions, month, year);
      if (allowedDays.length === 0) continue;

      // check if employee has a roster for the month and year
      let employeeRoster: EmployeeRoster | undefined = await employeeRosterModel.getEmployeeRosterByEmployeeAndMonthAndYear(employee.id, month, year);
      if (employeeRoster === undefined) {
        // create a new roster for the employee
        employeeRoster = new EmployeeRoster();
        employeeRoster.month = month;
        employeeRoster.year = year;
        let startDate: Date = startOfMonth(new Date(year, month - 1, 1));
        const endDate: Date = endOfMonth(startDate);
        while (compareAsc(startDate, endDate) < 0) {
          employeeRoster.shifts[getDate(startDate).toFixed()] = new Shift();
          startDate = addDays(startDate, 1);
        }

        employeeRoster.id = await employeeRosterModel.createDocument(employeeRoster, employee.id);
      }
      employeesWithRoster.push(new EmployeeWithRoster(employee, employeeRoster, allowedDays));
    }

    return employeesWithRoster;
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeRosterModel.getEmployeesWithRosterByBranchAndMonthAndYear", { branchId, month, year }, error);
    return [];
  }
};

function getAllowedDays(employeePositions: EmployeePosition[], month: number, year: number): number[] {
  const startDate: Date = new Date(year, month - 1, 1);
  const endDate: Date = endOfMonth(startDate);

  const allowedDays: number[] = [];

  for (const loopDate of DataHelpers.createDateRange(startDate, endDate)) {
    for (const employeePosition of employeePositions) {
      if (FeaturesHelpers.checkIfDateIsInWindow(loopDate, employeePosition.from, employeePosition.to) === true) {
        allowedDays.push(getDate(loopDate));
        break;
      }
    }
  }

  return allowedDays;
}
