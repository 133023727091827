import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "../InboundShipmentModel";

import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";

export const generateCode = async (): Promise<string> => {
  try {
    let newCode = "ERROR";
    let duplicatedInboundShipment: InboundShipment | undefined = new InboundShipment();

    while (duplicatedInboundShipment != undefined) {
      newCode = FeaturesHelpers.generateCode("A");
      duplicatedInboundShipment = await inboundShipmentModel.getInboundShipmentByCode(newCode);
    }

    return newCode;
  } catch (error: unknown) {
    appFaultModel.catchAppError("InboundShipmentModel.generateCode", {}, error);
    return "ERROR";
  }
};
