export const it = {
  vehicleType: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    vehicleType: "Tipo veicolo",
    vehicleTypes: "Tipi veicolo",
  },
  navigation: {
    vehicleType: "Tipi veicolo",
  },
};
