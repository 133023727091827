import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { employeePositionModel } from "../EmployeePositionModel";

export const getEmployeePositionsByEmployeeAndBranch = async (employeeId: string, branchId: string): Promise<EmployeePosition[]> => {
  try {
    const snapshotQuery: Query<EmployeePosition> = query(
      employeePositionModel.getPathReference(employeeId).withConverter(employeePositionModel.firestoreConverter),
      where("branch.id", "==", branchId)
    );

    const snapshot: QuerySnapshot<EmployeePosition> = await getDocs(employeePositionModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeePositionModel.getEmployeePositionsByEmployeeAndBranch", { employeeId, branchId }, error);
    return [];
  }
};
