import { BranchPosition } from "./BranchPosition";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedBranchPosition extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromBranchPosition(branchPosition: BranchPosition): LinkedBranchPosition {
    const linkedBranchPosition: LinkedBranchPosition = new LinkedBranchPosition();
    linkedBranchPosition.id = branchPosition.id;
    linkedBranchPosition.name = branchPosition.name;

    return linkedBranchPosition;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedBranchPosition {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
