import { RouteRecordRaw } from "vue-router";

export const forkliftRoutes: Array<RouteRecordRaw> = [
  {
    path: "/forklifts",
    name: "ForkliftView",
    component: () => import("../views/ForkliftView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "forklift",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/forklifts/vehicle",
    name: "ForkliftVehicleView",
    component: () => import("../viewsVehicle/ForkliftVehicleView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "forklift",
      requiredRight: "viewUI",
    },
  },
];
