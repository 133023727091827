import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "../CompanyModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getActiveCompanies = async (): Promise<Company[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    let snapshotQuery: Query<Company> = query(
      companyModel.getPathReference().withConverter(companyModel.firestoreConverter),
      where("archived", "==", false)
    );

    if (user.role?.name === "Azienda") {
      snapshotQuery = query(snapshotQuery, where("userId", "==", user.id));
    }

    const snapshot: QuerySnapshot<Company> = await getDocs(companyModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyModel.getActiveCompanies", {}, error);
    return [];
  }
};

function sortDocuments(documents: Company[]): Company[] {
  const sorter: FirestoreSorter<Company> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
