import { compareAsc } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "../OutboundShipmentModel";

export const getOutboundShipmentsByPeriod = async (startDate: Date, endDate: Date): Promise<OutboundShipment[]> => {
  try {
    const snapshotQuery: Query<OutboundShipment> = query(
      outboundShipmentModel.getPathReference().withConverter(outboundShipmentModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate))
    );

    const snapshot: QuerySnapshot<OutboundShipment> = await getDocs(outboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    const outboundShipments: OutboundShipment[] = snapshot.docs
      .map((doc) => doc.data())
      .filter((outboundShipment: OutboundShipment) => compareAsc(outboundShipment.date, endDate) <= 0);

    return outboundShipments;
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.getOutboundShipmentsByPeriod", { startDate, endDate }, error);
    return [];
  }
};
