import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { storedDpiModel } from "../StoredDpiModel";

export const getStoredDpiByDpiAndSize = async (dpiId: string, size: string): Promise<StoredDpi | undefined> => {
  try {
    const snapshotQuery: Query<StoredDpi> = query(
      storedDpiModel.getPathReference().withConverter(storedDpiModel.firestoreConverter),
      where("dpi.id", "==", dpiId),
      where("size", "==", size)
    );

    const snapshot: QuerySnapshot<StoredDpi> = await getDocs(storedDpiModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("StoredDpiModel.getStoredDpiByDpiAndSize", { dpiId, size }, error);
    return undefined;
  }
};
