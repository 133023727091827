import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";

import { AddressField, ObjectField, NumberArrayField, StringField, NumberField, BooleanField } from "@/core/fields";

export class Branch extends FirestoreDocument {
  public company: LinkedCompany | undefined = undefined;
  public name: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public weekDays: number[] = [];
  public editablePastDays = 0;
  public timemotoId: string | undefined = undefined;
  public notes: string | undefined = undefined;
  public archived = false;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Branch {
    super.fromFirestore(data, id, firestoreRef);

    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.name = StringField.fromFirestore(data.name);
    this.address.fromFirestore(data.address);
    this.weekDays = NumberArrayField.fromFirestore(data.weekDays);
    this.editablePastDays = NumberField.fromFirestore(data.editablePastDays);
    this.timemotoId = StringField.fromFirestore(data.timemotoId);
    this.notes = StringField.fromFirestore(data.notes);
    this.archived = BooleanField.fromFirestore(data.archived);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.address = this.address.toFirestore();
    firestoreData.weekDays = NumberArrayField.toFirestore(this.weekDays);
    firestoreData.editablePastDays = NumberField.toFirestore(this.editablePastDays);
    firestoreData.timemotoId = StringField.toFirestore(this.timemotoId);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.archived = BooleanField.toFirestore(this.archived);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
  }
}
