import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";
import { LinkedTransportType } from "@/features/modules/transportType/objects/LinkedTransportType";
import { OutboundShipmentItem } from "./OutboundShipmentItem";
import { OutboundShipmentState } from "./OutboundShipmentState";
import { ParcelState } from "@/features/modules/parcel/objects/ParcelState";

import { ArrayByKeyField, ArrayField, DateStrictField, EnumField, ObjectField, StringArrayField, StringField } from "@/core/fields";

export class OutboundShipment extends FirestoreDocument {
  public date: Date = new Date();
  public company: LinkedCompany | undefined = undefined;
  public code: string | undefined = undefined;
  public items: OutboundShipmentItem[] = [];
  public parcels: Record<string, LinkedParcel> = {};
  public parcelTypesIds: string[] = [];
  public state: OutboundShipmentState = OutboundShipmentState.Draft;
  public detail: string | undefined;
  public transportType: LinkedTransportType | undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): OutboundShipment {
    super.fromFirestore(data, id, firestoreRef);

    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.code = StringField.fromFirestore(data.code);
    this.items = ArrayField.fromFirestore<OutboundShipmentItem>(data.items, (value) => new OutboundShipmentItem(value));
    this.parcels = ArrayByKeyField.fromFirestore<LinkedParcel>(data.parcels, (value) => new LinkedParcel(value));
    this.parcelTypesIds = StringArrayField.fromFirestore(data.parcelTypesIds);
    this.state = EnumField.fromFirestore<OutboundShipmentState>(data.state, Object.values(OutboundShipmentState), OutboundShipmentState.Draft);
    this.detail = StringField.fromFirestore(data.detail);
    this.transportType = ObjectField.fromFirestore<LinkedTransportType>(data.transportType, (value) => new LinkedTransportType(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.items = ArrayField.toFirestore<OutboundShipmentItem>(this.items, (value) => value.toFirestore());
    firestoreData.parcels = ArrayByKeyField.toFirestore<LinkedParcel>(this.parcels, (value) => value.toFirestore());
    firestoreData.parcelTypesIds = StringArrayField.toFirestore(this.parcelTypesIds);
    firestoreData.state = EnumField.toFirestore<OutboundShipmentState>(this.state, OutboundShipmentState.Draft);
    firestoreData.detail = StringField.toFirestore(this.detail);
    firestoreData.transportType = ObjectField.toFirestore<LinkedTransportType>(this.transportType, (value) => value.toFirestore());

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.code !== undefined) this.searchKeys.push(this.code.toLowerCase());
  }

  public getLinkedParcels(): LinkedParcel[] {
    return DataHelpers.objectToSortedArray<LinkedParcel>(this.parcels);
  }

  public setLinkedParcels(linkedParcels: LinkedParcel[]): void {
    this.parcels = DataHelpers.sortedArrayToObject<LinkedParcel>(linkedParcels);
    const parcelTypes: string[] = Object.values(this.parcels).map((linkedParcel) => linkedParcel.type?.id ?? "EMPTY");
    this.parcelTypesIds = parcelTypes.filter(function (linkedParcelTypeId: string, index: number) {
      return linkedParcelTypeId !== "EMPTY" && parcelTypes.indexOf(linkedParcelTypeId) === index;
    });
  }

  public addLinkedParcel(linkedParcel: LinkedParcel): void {
    this.parcels[linkedParcel.id] = linkedParcel;
    const parcelTypes: string[] = Object.values(this.parcels).map((linkedParcel) => linkedParcel.type?.id ?? "EMPTY");
    this.parcelTypesIds = parcelTypes.filter(function (linkedParcelTypeId: string, index: number) {
      return linkedParcelTypeId !== "EMPTY" && parcelTypes.indexOf(linkedParcelTypeId) === index;
    });
  }

  public removeLinkedParcel(linkedParcel: LinkedParcel): void {
    delete this.parcels[linkedParcel.id];
    if (linkedParcel.type === undefined) return;
    const parcelTypes: string[] = Object.values(this.parcels).map((linkedParcel) => linkedParcel.type?.id ?? "EMPTY");
    this.parcelTypesIds = parcelTypes.filter(function (linkedParcelTypeId: string, index: number) {
      return linkedParcelTypeId !== "EMPTY" && parcelTypes.indexOf(linkedParcelTypeId) === index;
    });
  }

  public emptyLinkedParcels(): void {
    this.parcels = {};
    this.parcelTypesIds = [];
  }

  public setState(): void {
    if (this.state === OutboundShipmentState.Sent) return;

    if (this.getLinkedParcels().length <= 0) {
      this.state = OutboundShipmentState.Draft;
      return;
    }
    this.state = OutboundShipmentState.Ready;
    for (const parcel of this.getLinkedParcels()) {
      if (parcel.state !== ParcelState.Loaded) {
        this.state = OutboundShipmentState.Draft;
        return;
      }
    }
  }
}
