import { addDays, format } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { employeePositionModel } from "../EmployeePositionModel";
import { setting } from "@/core/modules/setting/Setting";

export const getExpiringEmployeePositions = async (): Promise<EmployeePosition[]> => {
  try {
    const todaySort: string = format(new Date(), "yyyy-MM-dd");
    const noticeSort: string = format(addDays(new Date(), setting.getSetting<number>("expiringEmployeePositionDaysNotice")), "yyyy-MM-dd");

    const snapshotQuery: Query<EmployeePosition> = query(
      employeePositionModel.getGroupPathReference().withConverter(employeePositionModel.firestoreConverter),
      where("toSort", ">=", todaySort),
      where("toSort", "<", noticeSort)
    );

    const snapshot: QuerySnapshot<EmployeePosition> = await getDocs(employeePositionModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeePositionModel.getExpiringEmployeePositions", {}, error);
    return [];
  }
};
