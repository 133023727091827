import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doc } from "@/features/modules/doc/objects/Doc";
import { docModel } from "../DocModel";
import { DocOwner } from "@/features/modules/doc/objects/DocOwner";

export const getDocsByOwner = async (owner: DocOwner): Promise<Doc[]> => {
  try {
    const snapshotQuery: Query<Doc> = query(
      docModel.getPathReference().withConverter(docModel.firestoreConverter),
      where("owners", "array-contains", owner.toFirestore())
    );

    const snapshot: QuerySnapshot<Doc> = await getDocs(docModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("DocModel.getDocsByOwner", { owner }, error);
    return [];
  }
};
