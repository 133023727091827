import { branchMonthReport } from "@/features/modules/stat/modules/branchMonthReport";
import { branchWithEmployee } from "@/features/modules/stat/modules/branchWithEmployee";
import { companyStock } from "@/features/modules/stat/modules/companyStock";
import { companyStorage } from "@/features/modules/stat/modules/companyStorage";
import { dpiDelivered } from "@/features/modules/stat/modules/dpiDelivered";
import { dpiStock } from "@/features/modules/stat/modules/dpiStock";
import { employeeMonthReport } from "@/features/modules/stat/modules/employeeMonthReport";
import { handleChart } from "@/features/modules/stat/modules/handleChart";
import { handleCode } from "@/features/modules/stat/modules/handleCode";
import { invoiceByYear } from "@/features/modules/stat/modules/invoiceByYear";
import { itemCode } from "@/features/modules/stat/modules/itemCode";
import { stockChart } from "@/features/modules/stat/modules/stockChart";

export const featuresStatModules = [
  dpiStock,
  dpiDelivered,
  companyStock,
  companyStorage,
  branchMonthReport,
  branchWithEmployee,
  employeeMonthReport,
  handleChart,
  handleCode,
  itemCode,
  stockChart,
  invoiceByYear,
];
