import { CompanyStorage } from "../objects/CompanyStorage";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { ParcelType } from "@/features/modules/parcelType/objects/ParcelType";

export class CompanyStoragePaper extends DocumentPaper {
  private companyName: string | undefined;
  private date: Date;
  private results: CompanyStorage[];
  private parcelTypes: ParcelType[];

  constructor(
    companyName: string | undefined,
    date: Date,
    results: CompanyStorage[],
    parcelTypes: ParcelType[],
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.companyName = companyName;
    this.date = date;
    this.results = results;
    this.parcelTypes = parcelTypes;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      companyStorageDetail: { fontSize: 10 },
      companyStorageParcelCell: { alignment: "center", fontSize: 8 },
      companyStorageParcelHead: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(): Promise<Record<string, unknown>[]> {
    await this.setStructure(this.t("statCompanyStorage.companyStoragePaper").toLocaleUpperCase());

    this.writeDetails();

    this.writeStorageTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeDetails(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: DataHelpers.mmToPoints(40),
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "companyStorageDetail",
              text: `${this.t("statCompanyStorage.company").toLocaleUpperCase()}:`,
            },
            {
              margin: [0, DataHelpers.mmToPoints(2), 0, 0],
              style: "companyStorageDetail",
              text: `${this.t("statCompanyStorage.date").toLocaleUpperCase()}:`,
            },
          ],
        },
        {
          width: "*",
          stack: [
            { margin: [0, DataHelpers.mmToPoints(4), 0, 0], style: "companyStorageDetail", text: this.companyName ?? "-" },
            { margin: [0, DataHelpers.mmToPoints(2), 0, 0], style: "companyStorageDetail", text: this.d(this.date, "hugeDate") },
          ],
        },
      ],
    });
  }

  private writeStorageTable(): void {
    const widths: unknown[] = [DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(25)];
    const dataTableBody: unknown[] = [
      [
        { style: "companyStorageParcelHead", text: this.t("statCompanyStorage.code").toLocaleUpperCase() },
        { style: "companyStorageParcelHead", text: this.t("statCompanyStorage.totalQuantity").toLocaleUpperCase() },
      ],
    ];

    for (const parcelType of this.parcelTypes) {
      (dataTableBody[0] as unknown[]).push({ style: "companyStorageParcelHead", text: parcelType.name?.toLocaleUpperCase() ?? "-" });
      widths.push("*");
    }

    for (const result of this.results) {
      const row: unknown[] = [
        { style: "companyStorageParcelCell", text: result.code ?? "-" },
        { style: "companyStorageParcelCell", text: result.quantity ? this.n(result.quantity, "number0") : "-" },
      ];

      for (const parcelType of this.parcelTypes) {
        row.push({
          style: "companyStorageParcelCell",
          text: result.parcelTypesQuantity[parcelType.id] ? this.n(result.parcelTypesQuantity[parcelType.id], "number0") : "0",
        });
      }

      dataTableBody.push(row);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: widths.length, style: "companyStorageParcelCell", text: this.t("gen.noResults") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: widths,
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
