export const it = {
  timekeeper: {
    branch: "Cantiere",
    branchError: "Selezionare un cantiere valido",
    direction: "Direzione",
    directions: {
      enterLeft: "Entrata a sinistra",
      enterRight: "Entrata a destra",
    },
    directionError: "Selezionare una direzione valida",
    isActive: "Attiva",
    lastSeen: "Ultimo accesso",
    macAddress: "Indirizzo MAC",
    macAddressError: "Inserire un indirizzo MAC valido",
    name: "Nome",
    nameError: "Inserire un nome valido",
    never: "Mai",
    timekeeper: "Marcatempo",
    timekeepers: "Marcatempo",
    timeZone: "Fuso orario",
    timeZoneError: "Inserire un fuso orario valido",
    firmwareVersion: "Versione",
  },
  navigation: {
    timekeeper: "Marcatempo",
  },
  buttons: {
    setActive: "Imposta come attiva",
    setNotActive: "Imposta come non attiva",
  },
};
