import { distanceBetween, geohashQueryBounds, GeohashRange, Geopoint } from "geofire-common";
import { endAt, getDocs, orderBy, Query, query, QuerySnapshot, startAt, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "../EmployeeModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";

export const getActiveEmployeesByLocation = async (
  latitude: number,
  longitude: number,
  radius: number
): Promise<{ employee: Employee; distance: number }[]> => {
  try {
    const employeesWithDistance: { employee: Employee; distance: number }[] = [];

    const locationCenter: Geopoint = [latitude, longitude];
    const bounds: GeohashRange[] = geohashQueryBounds(locationCenter, radius);

    const promises: Promise<QuerySnapshot<Employee>>[] = [];
    for (const b of bounds) {
      const snapshotQuery: Query<Employee> = query(
        employeeModel.getPathReference().withConverter(employeeModel.firestoreConverter),
        orderBy("locationGeoHash"),
        startAt(b[0]),
        endAt(b[1]),
        where("archived", "==", false)
      );
      promises.push(getDocs(snapshotQuery));
    }

    const snapshots: QuerySnapshot<Employee>[] = await Promise.all(promises);

    for (const snapshot of snapshots) {
      const employees: Employee[] = snapshot.docs.map((doc) => doc.data());
      for (const employee of employees) {
        const lat: number =
          employee.onlyResidentialAddress === true ? employee.residentialAddress?.latitude ?? 0 : employee.livingAddress?.latitude ?? 0;
        const lng: number =
          employee.onlyResidentialAddress === true ? employee.residentialAddress?.longitude ?? 0 : employee.livingAddress?.longitude ?? 0;
        // filter out a few false positives due to GeoHash accuracy, but most will match
        const distanceInKm = distanceBetween([lat, lng], locationCenter);
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radius) {
          employeesWithDistance.push({ employee: employee, distance: distanceInM });
        }
      }
    }

    return sortDocuments(employeesWithDistance);
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.getActiveEmployeesByLocation", { latitude, longitude, radius }, error);
    return [];
  }
};

function sortDocuments(documents: { employee: Employee; distance: number }[]): { employee: Employee; distance: number }[] {
  const sorter: FirestoreSorter<{ employee: Employee; distance: number }> = new FirestoreSorter(documents);
  sorter.addSortCriteria("distance", "asc", "number");
  return sorter.sort();
}
