import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { Vehicle } from "../objects/Vehicle";

import { isRegistrationNumberUnique } from "./methods";

export class VehicleModel extends FirestoreModel<Vehicle> {
  public constructor() {
    super(() => new Vehicle(), "vehicles", LockPolicy.DiscardUnsyncedChanges, "vehicle");
    this.beforeDeleteFunction = "featuresVehicleBeforeDelete";
  }

  public async getDocuments(): Promise<Vehicle[]> {
    return super.getDocuments([new SortCriteria("registrationNumber", "asc", "string")]);
  }

  public async getDocument(vehicleId: string): Promise<Vehicle> {
    return super.getDocument(vehicleId);
  }

  public async createDocument(vehicle: Vehicle): Promise<string> {
    return super.createDocument(vehicle);
  }

  public async updateDocument(vehicle: Vehicle): Promise<void> {
    return super.updateDocument(vehicle);
  }

  public async deleteDocument(vehicle: Vehicle): Promise<boolean> {
    return super.deleteDocument(vehicle);
  }

  public async isRegistrationNumberUnique(registrationNumber: string, vehicleId?: string): Promise<boolean> {
    return isRegistrationNumberUnique(registrationNumber, vehicleId);
  }
}

export const vehicleModel: VehicleModel = new VehicleModel();
