import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeExpiration } from "@/features/modules/employee/objects/EmployeeExpiration";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getMissingSourceDocs = async (source: DocTypeSource): Promise<EmployeeExpiration[]> => {
  try {
    const missingEmployees: EmployeeExpiration[] = [];

    const employees: Employee[] = await employeeModel.getActiveEmployees();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("missingDocs", source);

    for (const employee of employees) {
      if (employee.trackExpirations === false) continue;

      const employeeWidget: Widget | undefined = widgets.find((widget) => widget.sourceId === employee.id);
      if (employeeWidget === undefined) continue;
      if (employeeWidget.values.count === 0) continue;
      if (employeeWidget.values.missings == undefined) continue;

      const missings: string[] = employeeWidget.values.missings as string[];
      for (const docTypeId of missings) {
        missingEmployees.push(new EmployeeExpiration(employee, new Date("2000-01-01"), docTypeId));
      }
    }

    return missingEmployees;
  } catch (error: unknown) {
    appFaultModel.catchAppError("DocModel.getMissingSourceDocs", { source }, error);
    return [];
  }
};
