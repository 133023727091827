export const it = {
  statBranchWithEmployee: {
    date: "Data",
    name: "Riepilogo turni operatori per cantiere",
  },
  toast: {
    error: {
      statBranchWithEmployee: {
        dateUndefined: "Data non valida",
      },
    },
    success: {},
    warning: {},
  },
};
