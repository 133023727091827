export const it = {
  warningType: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    warningType: "Tipo di richiamo",
    warningTypes: "Tipi di richiamo",
  },
  navigation: {
    warningType: "Tipi di richiamo",
  },
};
