import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { LinkedOutboundShipment } from "@/features/modules/outboundShipment/objects/LinkedOutboundShipment";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { OutboundShipmentHelpers } from "@/features/modules/outboundShipment/helpers/OutboundShipmentHelpers";
import { outboundShipmentModel } from "../OutboundShipmentModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";
import { ParcelState } from "@/features/modules/parcel/objects/ParcelState";

export const updateLinkedParcels = async (outboundShipment: OutboundShipment): Promise<void> => {
  try {
    const duplicatedOutboundShipment: OutboundShipment | undefined = await outboundShipmentModel.getOutboundShipmentByCode(
      outboundShipment.code as string
    );
    if (duplicatedOutboundShipment !== undefined && duplicatedOutboundShipment.id !== outboundShipment.id) throw new Error("duplicatedCode");

    const checkResult: boolean = await OutboundShipmentHelpers.checkParcels(outboundShipment);
    if (checkResult === false) throw new Error("checkParcels failed");

    const parcels: Parcel[] = await parcelModel.getParcelsByOutboundShipment(outboundShipment.id);

    const updatedParcelsIds: string[] = [];
    // for every parcel already in the outbound shipment
    for (const parcel of parcels) {
      const checkParcel: LinkedParcel | undefined = outboundShipment.getLinkedParcels().find((linkedParcel) => linkedParcel.id === parcel.id);
      // if parcel is not in the outbound shipment parcels anymore, delete reference to it
      if (checkParcel === undefined) {
        console.log("handleDeletedParcel", parcel.code);
        handleDeletedParcel(parcel, outboundShipment);
      } else {
        // parcel is still there, don't need to update because the function will do
        updatedParcelsIds.push(parcel.id);
      }
    }

    // for every parcel in outbound shipment
    for (const linkedParcel of outboundShipment.getLinkedParcels()) {
      if (updatedParcelsIds.includes(linkedParcel.id)) continue;
      const parcel: Parcel = await parcelModel.getDocument(linkedParcel.id);
      console.log("handleSelectedParcel", parcel.code);
      handleSelectedParcel(parcel, outboundShipment);
    }

    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.updateLinkedParcels", { outboundShipment }, error);
  }
};

const handleSelectedParcel = (parcel: Parcel, outboundShipment: OutboundShipment): void => {
  try {
    parcel.addAction(ParcelActionType.Selected, outboundShipment.code);
    parcel.outboundShipment = LinkedOutboundShipment.createFromOutboundShipment(outboundShipment);

    batch.update(parcelModel.getDocumentReference(parcel.id), parcel.toFirestore());
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.handleSelectedParcel", { parcel, outboundShipment }, error);
  }
};

const handleDeletedParcel = (parcel: Parcel, outboundShipment: OutboundShipment): void => {
  try {
    parcel.addAction(ParcelActionType.Unselected, outboundShipment.code);
    if (parcel.warehouseLocation !== undefined) {
      parcel.addAction(ParcelActionType.Stored, parcel.warehouseLocation.code);
      parcel.outboundShipment = undefined;
      parcel.state = ParcelState.Stored;
    } else {
      parcel.addAction(ParcelActionType.Loosened);
      parcel.outboundShipment = undefined;
      parcel.warehouseLocation = undefined;
      parcel.state = ParcelState.Loosened;
    }
    batch.update(parcelModel.getDocumentReference(parcel.id), parcel.toFirestore());
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.handleDeletedParcel", { parcel, outboundShipment }, error);
  }
};
