import { RouteRecordRaw } from "vue-router";

export const medicalExamTypeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/medical-exam-types/:id/edit",
    name: "MedicalExamTypeEdit",
    component: () => import("../views/MedicalExamTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "medicalExamType",
      requiredRight: "viewUI",
    },
  },
];
