import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "../ParcelModel";

export const getParcelByCode = async (code: string): Promise<Parcel | undefined> => {
  try {
    const snapshotQuery: Query<Parcel> = query(
      parcelModel.getPathReference().withConverter(parcelModel.firestoreConverter),
      where("code", "==", code)
    );

    const snapshot: QuerySnapshot<Parcel> = await getDocs(parcelModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.getParcelByCode", { code }, error);
    return undefined;
  }
};
