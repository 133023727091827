
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "BadgeScanner",
  setup() {
    let eventBusId: string | undefined = undefined;
    const { t } = useLocale();

    const isDialogVisible: Ref<boolean> = ref(false);

    const closeDialog = () => {
      isDialogVisible.value = false;
    };

    const dataBuffer: Ref<string> = ref("");
    const scannedCode: Ref<string> = ref("");

    const initDataBuffer = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        scannedCode.value = dataBuffer.value;
        dataBuffer.value = "";

        eventBus.emit("badgeScanned", Number(scannedCode.value));
        closeDialog();
        return;
      }
      scannedCode.value = "INIT";
      dataBuffer.value = dataBuffer.value + e.key;
    };

    onMounted(() => {
      eventBusId = eventBus.on("showBadgeScanner", async (): Promise<void> => {
        window.addEventListener("keypress", initDataBuffer);
        isDialogVisible.value = true;
      });
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
      window.removeEventListener("keypress", initDataBuffer);
    });

    return {
      closeDialog,
      isDialogVisible,
      t,
    };
  },
});
