import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { WarehouseLocation } from "@/features/modules/warehouseLocation/objects/WarehouseLocation";
import { warehouseLocationModel } from "../WarehouseLocationModel";

export const getWarehouseLocationByCode = async (code: string): Promise<WarehouseLocation | undefined> => {
  try {
    const snapshotQuery: Query<WarehouseLocation> = query(
      warehouseLocationModel.getPathReference().withConverter(warehouseLocationModel.firestoreConverter),
      where("code", "==", code)
    );

    const snapshot: QuerySnapshot<WarehouseLocation> = await getDocs(warehouseLocationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("WarehouseLocationModel.getWarehouseLocationByCode", { code }, error);
    return undefined;
  }
};
