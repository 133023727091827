
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { itemCodeModel } from "../models/ItemCodeModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "ItemCodeView",
  setup() {
    let eventBusId: string | undefined;
    const { t } = useLocale();

    const store = useStore();

    const handleSubmit = async () => {
      eventBus.emit("showConfirmDialog");
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const oldCode: Ref<string> = ref("");
    const newCode: Ref<string> = ref("");

    const submitAction = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await itemCodeModel.replaceItemCode(oldCode.value, newCode.value);
        },
        "utilityDone",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => {
      store.commit(storeTypes.mutations.loadingStop);
      eventBusId = eventBus.on("executeUtilityModuleAction", () => Promise.resolve(submitAction()));
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      handleKeydown,
      handleSubmit,
      newCode,
      oldCode,
      t,
    };
  },
});
