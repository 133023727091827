import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isRegistrationNumberUnique = async (registrationNumber: string, vehicleId = "empty"): Promise<boolean> => {
  try {
    if (registrationNumber === undefined) return false;

    return CloudHelpers.runValidatorFunction("featuresVehicleRegistrationNumberValidator", registrationNumber, vehicleId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("VehicleModel.isRegistrationNumberUnique", { registrationNumber, vehicleId }, error);
    return false;
  }
};
