import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { WarningType } from "./WarningType";

import { StringField } from "@/core/fields";

export class LinkedWarningType extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromWarningType(warningType: WarningType): LinkedWarningType {
    const linkedWarningType: LinkedWarningType = new LinkedWarningType();
    linkedWarningType.id = warningType.id;
    linkedWarningType.name = warningType.name;

    return linkedWarningType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedWarningType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
