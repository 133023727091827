import { RouteRecordRaw } from "vue-router";

export const outboundShipmentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/outbound-shipments",
    name: "OutboundShipmentList",
    component: () => import("../views/OutboundShipmentList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "outboundShipment",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/outbound-shipments/:id/edit",
    name: "OutboundShipmentEdit",
    component: () => import("../views/OutboundShipmentEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "outboundShipment",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/outbound-shipments/:id/:section?",
    name: "OutboundShipmentView",
    component: () => import("../views/OutboundShipmentView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "outboundShipment",
      requiredRight: "viewUI",
    },
  },
];
