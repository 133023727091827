export const it = {
  statBranchMonthReport: {
    counters: {
      overtimeHours: "Ore straordinarie",
      totalHours: "Ore totali",
    },
    date: "Data",
    month: "Mese",
    name: "Riepilogo mensile per cantiere",
    to: "a",
    value: "Valore",
  },
  toast: {
    error: {
      statBranchMonthReport: {
        branchUndefined: "Cantiere non valido",
        monthUndefined: "Mese non valido",
      },
    },
    success: {},
    warning: {},
  },
};
