import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { DpiHandlingHelpers } from "@/features/modules/dpiHandling/helpers/DpiHandlingHelpers";
import { EmployeeDpi } from "@/features/modules/employeeDpi/objects/EmployeeDpi";
import { employeeDpiModel } from "@/features/modules/employeeDpi/models/EmployeeDpiModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";
import { storedDpiModel } from "@/features/modules/storedDpi/models/StoredDpiModel";

export const deliverDpisFromWarehouseToEmployee = async (dpiHandling: DpiHandling): Promise<string> => {
  try {
    // check if dpis are available
    const result: string = await DpiHandlingHelpers.checkDpisAvailabilityInWarehouse(dpiHandling);
    if (result !== "OK") return result;

    await FirestoreModel.runTransaction(async () => {
      for (const handledDpi of dpiHandling.getHandledDpis()) {
        if (dpiHandling.targetEmployee === undefined) throw new Error("targetEmployee is undefined");
        if (handledDpi.dpi === undefined) throw new Error("handledDpi dpi is undefined");
        if (handledDpi.size === undefined) throw new Error("handledDpi size is undefined");

        // check if stored dpi is available and if quantity is enough
        const storedDpi: StoredDpi | undefined = await storedDpiModel.getStoredDpiByDpiAndSize(handledDpi.dpi.id, handledDpi.size);
        if (storedDpi === undefined) throw new Error(`Dpi #${handledDpi.dpi.id} with size ${handledDpi.size} not available`);
        if (storedDpi.quantity < handledDpi.quantity) {
          throw new Error(
            `Dpi #${handledDpi.dpi.id} with size ${handledDpi.size} not available (requested quantity: ${handledDpi.quantity}, available quantity: ${storedDpi.quantity})`
          );
        }

        // update the stored dpi quantity or delete it if quantity is 0
        if (storedDpi.firestoreRef === undefined) throw new Error("storedDpi firestoreRef is undefined");
        if (storedDpi.quantity - handledDpi.quantity > 0) {
          batch.update(storedDpi.firestoreRef, { quantity: storedDpi.quantity - handledDpi.quantity });
        } else {
          batch.delete(storedDpi.firestoreRef);
        }

        // check if employee dpi already exists and update the quantity or create a new one
        const employeeDpi: EmployeeDpi | undefined = await employeeDpiModel.getEmployeeDpiByDpiAndSize(
          handledDpi.dpi.id,
          handledDpi.size,
          dpiHandling.targetEmployee.id
        );
        if (employeeDpi === undefined) {
          const newEmployeeDpi: EmployeeDpi = new EmployeeDpi();
          newEmployeeDpi.dpi = handledDpi.dpi;
          newEmployeeDpi.size = handledDpi.size;
          newEmployeeDpi.quantity = handledDpi.quantity;
          batch.set(employeeDpiModel.getDocumentReference(undefined, dpiHandling.targetEmployee.id), newEmployeeDpi.toFirestore());
        } else {
          if (employeeDpi.firestoreRef === undefined) throw new Error("employeeDpi firestoreRef is undefined");
          batch.update(employeeDpi.firestoreRef, { quantity: employeeDpi.quantity + handledDpi.quantity });
        }
      }
      await batch.commit();
    });

    return "OK";
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.deliverDpisFromWarehouseToEmployee", { dpiHandling }, error);
    return "ERROR";
  }
};
