import { RouteRecordRaw } from "vue-router";

export const employeeTransactionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/transactions/:employeeTransactionId/edit",
    name: "EmployeeTransactionEdit",
    component: () => import("../views/EmployeeTransactionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeTransaction",
      requiredRight: "viewUI",
    },
  },
];
