import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { inboundShipmentModel } from "@/features/modules/inboundShipment/models/InboundShipmentModel";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "../OutboundShipmentModel";
import { OutboundShipmentState } from "@/features/modules/outboundShipment/objects/OutboundShipmentState";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";
import { ParcelState } from "@/features/modules/parcel/objects/ParcelState";

export const markAsSent = async (outboundShipment: OutboundShipment): Promise<void> => {
  try {
    for (const linkedParcel of outboundShipment.getLinkedParcels()) {
      linkedParcel.state = ParcelState.Sent;

      const parcel: Parcel = await parcelModel.getDocument(linkedParcel.id);
      parcel.state = ParcelState.Sent;
      parcel.addAction(ParcelActionType.Sent, outboundShipment.code);

      parcel.setTimestampFields("update");
      batch.set(parcelModel.getDocumentReference(parcel.id), parcel.toFirestore());

      if (parcel.inboundShipment !== undefined) {
        batch.update(inboundShipmentModel.getDocumentReference(parcel.inboundShipment.id), {
          [`parcels.${parcel.id}.state`]: ParcelState.Sent,
        });
      }
    }

    outboundShipment.state = OutboundShipmentState.Sent;

    outboundShipment.setTimestampFields("update");
    batch.update(outboundShipmentModel.getDocumentReference(outboundShipment.id), outboundShipment.toFirestore());

    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.markAsSent", { outboundShipment }, error);
  }
};
