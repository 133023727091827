import { RouteRecordRaw } from "vue-router";

export const aedSaleRoutes: Array<RouteRecordRaw> = [
  {
    path: "/aed-sales",
    name: "AedSaleList",
    component: () => import("../views/AedSaleList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedSale",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-sales/:id/edit",
    name: "AedSaleEdit",
    component: () => import("../views/AedSaleEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedSale",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-sales/:id",
    name: "AedSaleView",
    component: () => import("../views/AedSaleView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedSale",
      requiredRight: "viewUI",
    },
  },
];
