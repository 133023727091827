import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Paper } from "@/core/modules/paper/objects/Paper";
import { PaperHelpers } from "../helpers/PaperHelpers";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";

export class DocumentPaper extends Paper {
  public constructor(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      documentTitle: { alignment: "center", bold: true, color: this.highlightColor, fontSize: 14, margin: [0, 0, 0, 0] },
      documentSubtitle: {
        alignment: "center",
        bold: true,
        color: this.highlightColor,
        fontSize: 12,
        margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
      },
      employeeDataField: { fontSize: 6, margin: [0, 0, 0, 2] },
      employeeDataTable: { alignment: "center" },
      employeeDataValue: { border: false, color: this.highlightColor, fontSize: 10 },
      footerTitle: { color: this.footerColor, fontSize: 8, italics: true },
      headerText: { color: this.textColor, fontSize: 10 },
      referenceText: { alignment: "right", color: this.highlightColor, fontSize: 8 },
      signaturesDateField: { border: false, color: this.highlightColor, fontSize: 10, bold: true, margin: [0, 0, 0, 20] },
      signaturesDateValue: { border: false, color: this.textColor, fontSize: 10 },
      signaturesField: { fontSize: 6, margin: [0, 0, 0, 2] },
      signaturesTable: { alignment: "center" },
      signaturesValue: { border: false, color: this.highlightColor, fontSize: 10, bold: true, margin: [0, 0, 0, 25] },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  protected async setStructure(title: string, isContinuos = false): Promise<void> {
    this.setTitle(title, isContinuos);
    this.setAuthor(store.getters[storeTypes.getters.getUser]);
    this.setHighlightColor("#000000");

    const customHeader = await PaperHelpers.getCustomDocumentHeader(this.d, this.n, this.t);
    this.setHeader(customHeader);

    const customFooter = PaperHelpers.getCustomDocumentFooter(this.d, this.n, this.t);
    this.setFooter(customFooter);
  }

  protected writeSubtitle(text: string): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: text,
      style: "documentSubtitle",
    });
  }
}
