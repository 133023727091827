import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedActivity } from "@/features/modules/activity/objects/LinkedActivity";
import { LinkedPriceList } from "@/features/modules/priceList/objects/LinkedPriceList";
import { Referent } from "@/features/modules/referent/objects/Referent";

import { AddressField, ArrayByKeyField, ArrayField, BooleanField, ObjectField, StringField } from "@/core/fields";

export class Company extends FirestoreDocument {
  public name: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public phone: string | undefined = undefined;
  public fax: string | undefined = undefined;
  public email: string | undefined = undefined;
  public invoiceEmail: string | undefined = undefined;
  public certifiedEmail: string | undefined = undefined;
  public vatCode: string | undefined = undefined;
  public fiscalCode: string | undefined = undefined;
  public recipientCode: string | undefined = undefined;
  public referents: Referent[] = [];
  public activities: Record<string, LinkedActivity> = {};
  public notes: string | undefined = undefined;
  public priceList: LinkedPriceList | undefined = undefined;
  public archived = false;
  public userId: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Company {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.fax = StringField.fromFirestore(data.fax);
    this.email = StringField.fromFirestore(data.email);
    this.invoiceEmail = StringField.fromFirestore(data.invoiceEmail);
    this.certifiedEmail = StringField.fromFirestore(data.certifiedEmail);
    this.vatCode = StringField.fromFirestore(data.vatCode);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.recipientCode = StringField.fromFirestore(data.recipientCode);
    this.referents = ArrayField.fromFirestore<Referent>(data.referents, (value) => new Referent(value));
    this.activities = ArrayByKeyField.fromFirestore<LinkedActivity>(data.activities, (value) => new LinkedActivity(value));
    this.notes = StringField.fromFirestore(data.notes);
    this.priceList = ObjectField.fromFirestore<LinkedPriceList>(data.priceList, (value) => new LinkedPriceList(value));
    this.archived = BooleanField.fromFirestore(data.archived);
    this.userId = StringField.fromFirestore(data.userId);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.fax = StringField.toFirestore(this.fax);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.invoiceEmail = StringField.toFirestore(this.invoiceEmail);
    firestoreData.certifiedEmail = StringField.toFirestore(this.certifiedEmail);
    firestoreData.vatCode = StringField.toFirestore(this.vatCode);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.recipientCode = StringField.toFirestore(this.recipientCode);
    firestoreData.referents = ArrayField.toFirestore<Referent>(this.referents, (value) => value.toFirestore());
    firestoreData.activities = ArrayByKeyField.toFirestore<LinkedActivity>(this.activities, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.priceList = ObjectField.toFirestore<LinkedPriceList>(this.priceList, (value) => value.toFirestore());
    firestoreData.archived = BooleanField.toFirestore(this.archived);
    firestoreData.userId = StringField.toFirestore(this.userId);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
  }
}
