import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedLeaveType } from "@/features/modules/leaveType/objects/LinkedLeaveType";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";

import { ArrayField, DateField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class EmployeeLeave extends FirestoreDocument {
  public from: Date | undefined = undefined;
  public fromSort = "0000-00-00";
  public to: Date | undefined = undefined;
  public toSort = "9999-99-99";
  public type: LinkedLeaveType | undefined = undefined;
  public files: StorageFile[] = [];
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): EmployeeLeave {
    super.fromFirestore(data, id, firestoreRef);

    this.from = DateField.fromFirestore(data.from);
    this.fromSort = StringStrictField.fromFirestore(data.fromSort, "0000-00-00");
    this.to = DateField.fromFirestore(data.to);
    this.toSort = StringStrictField.fromFirestore(data.toSort, "9999-99-99");
    this.type = ObjectField.fromFirestore<LinkedLeaveType>(data.type, (value) => new LinkedLeaveType(value));
    this.files = ArrayField.fromFirestore<StorageFile>(data.files, (value) => new StorageFile(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.type = ObjectField.toFirestore<LinkedLeaveType>(this.type, (value) => value.toFirestore());
    firestoreData.files = ArrayField.toFirestore<StorageFile>(this.files, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }
}
