import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { zebraPrintModel } from "../ZebraPrintModel";

export const setState = (state: "connected" | "disconnected"): void => {
  try {
    eventBus.emit("zebraPrintToggleStateComponent", state);
    eventBus.emit("zebraPrintToggleStateView", state);

    if (state === "disconnected") zebraPrintModel.device = undefined;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ZebraPrintModel.setState", { state }, error);
  }
};
