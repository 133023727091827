export const it = {
  employeeLeave: {
    employeeLeave: "Assenza",
    employeeLeaveOf: "Assenza di {parent}",
    employeeLeaves: "Assenze",
    employeeLeavesOf: "Assenze di {parent}",
    from: "Data iniziale",
    fromError: "Periodo non valido o assenza già presente",
    notes: "Note",
    openEmployeeLeaves: "Assenze aperte",
    preview: "Anteprima",
    to: "Data finale",
    toError: "Periodo non valido o assenza già presente",
    type: "Tipo assenza",
    typeError: "Selezionare un tipo assenza valido",
  },
};
