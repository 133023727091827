import { BranchDpi } from "../objects/BranchDpi";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getBranchDpiByDpiAndSize } from "./methods";

export class BranchDpiModel extends FirestoreModel<BranchDpi> {
  public constructor() {
    super(() => new BranchDpi(), "branchDpis", LockPolicy.DiscardUnsyncedChanges, "branchDpi");
    this.parentCollectionName = "branches";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<BranchDpi[]> {
    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(branchDpiId: string, parentId?: string): Promise<BranchDpi> {
    return super.getDocument(branchDpiId, parentId);
  }

  public async createDocument(branchDpi: BranchDpi, parentId?: string): Promise<string> {
    return super.createDocument(branchDpi, parentId);
  }

  public async updateDocument(branchDpi: BranchDpi, parentId?: string): Promise<void> {
    return super.updateDocument(branchDpi, parentId);
  }

  public async deleteDocument(branchDpi: BranchDpi, parentId?: string): Promise<boolean> {
    return super.deleteDocument(branchDpi, parentId);
  }

  public getBranchDpiByDpiAndSize(dpiId: string, size: string, parentId: string): Promise<BranchDpi | undefined> {
    return getBranchDpiByDpiAndSize(dpiId, size, parentId);
  }
}

export const branchDpiModel: BranchDpiModel = new BranchDpiModel();
