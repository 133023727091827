import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedInboundShipment } from "@/features/modules/inboundShipment/objects/LinkedInboundShipment";
import { LinkedOutboundShipment } from "@/features/modules/outboundShipment/objects/LinkedOutboundShipment";
import { LinkedParcelType } from "@/features/modules/parcelType/objects/LinkedParcelType";
import { LinkedWarehouseLocation } from "@/features/modules/warehouseLocation/objects/LinkedWarehouseLocation";
import { ParcelAction } from "./ParcelAction";
import { ParcelActionType } from "./ParcelActionType";
import { ParcelItem } from "./ParcelItem";
import { ParcelState } from "./ParcelState";

import { ArrayField, DateField, EnumField, ObjectField, StringArrayField, StringField, StringStrictField } from "@/core/fields";

export class Parcel extends FirestoreDocument {
  public inboundShipment: LinkedInboundShipment | undefined = undefined;
  public outboundShipment: LinkedOutboundShipment | undefined = undefined;
  public type: LinkedParcelType | undefined = undefined;
  public warehouseLocation: LinkedWarehouseLocation | undefined = undefined;
  public lastWarehouseLocationCode: string | undefined = undefined;
  public code: string | undefined = undefined;
  public items: ParcelItem[] = [];
  public itemsCodes: string[] = [];
  public state: ParcelState = ParcelState.Loosened;
  public detail: string | undefined = undefined;
  public lifeStart: Date | undefined = undefined;
  public lifeStartSort = "0000-00-00";
  public lifeEnd: Date | undefined = undefined;
  public lifeEndSort = "9999-99-99";
  public actions: ParcelAction[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Parcel {
    super.fromFirestore(data, id, firestoreRef);

    this.inboundShipment = ObjectField.fromFirestore<LinkedInboundShipment>(data.inboundShipment, (value) => new LinkedInboundShipment(value));
    this.outboundShipment = ObjectField.fromFirestore<LinkedOutboundShipment>(data.outboundShipment, (value) => new LinkedOutboundShipment(value));
    this.type = ObjectField.fromFirestore<LinkedParcelType>(data.type, (value) => new LinkedParcelType(value));
    this.warehouseLocation = ObjectField.fromFirestore<LinkedWarehouseLocation>(
      data.warehouseLocation,
      (value) => new LinkedWarehouseLocation(value)
    );
    this.lastWarehouseLocationCode = StringField.fromFirestore(data.lastWarehouseLocationCode);
    this.code = StringField.fromFirestore(data.code);
    this.items = ArrayField.fromFirestore<ParcelItem>(data.items, (value) => new ParcelItem(value));
    this.itemsCodes = StringArrayField.fromFirestore(data.itemsCodes);
    this.state = EnumField.fromFirestore<ParcelState>(data.state, Object.values(ParcelState), ParcelState.Loosened);
    this.detail = StringField.fromFirestore(data.detail);
    this.lifeStart = DateField.fromFirestore(data.lifeStart);
    this.lifeStartSort = StringStrictField.fromFirestore(data.lifeStartSort, "0000-00-00");
    this.lifeEnd = DateField.fromFirestore(data.lifeEnd);
    this.lifeEndSort = StringStrictField.fromFirestore(data.lifeEndSort, "9999-99-99");
    this.actions = ArrayField.fromFirestore<ParcelAction>(data.actions, (value) => new ParcelAction(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.inboundShipment = ObjectField.toFirestore<LinkedInboundShipment>(this.inboundShipment, (value) => value.toFirestore());
    firestoreData.outboundShipment = ObjectField.toFirestore<LinkedOutboundShipment>(this.outboundShipment, (value) => value.toFirestore());
    firestoreData.type = ObjectField.toFirestore<LinkedParcelType>(this.type, (value) => value.toFirestore());
    firestoreData.warehouseLocation = ObjectField.toFirestore<LinkedWarehouseLocation>(this.warehouseLocation, (value) => value.toFirestore());
    firestoreData.lastWarehouseLocationCode = StringField.toFirestore(this.lastWarehouseLocationCode);
    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.items = ArrayField.toFirestore<ParcelItem>(this.items, (value) => value.toFirestore());
    firestoreData.itemsCodes = StringArrayField.toFirestore(this.itemsCodes);
    firestoreData.state = EnumField.toFirestore<ParcelState>(this.state, ParcelState.Loosened);
    firestoreData.detail = StringField.toFirestore(this.detail);
    firestoreData.lifeStart = DateField.toFirestore(this.lifeStart);
    firestoreData.lifeStartSort = DateField.toStringValue(this.lifeStart, "0000-00-00");
    firestoreData.lifeEnd = DateField.toFirestore(this.lifeEnd);
    firestoreData.lifeEndSort = DateField.toStringValue(this.lifeEnd, "9999-99-99");
    firestoreData.actions = ArrayField.toFirestore<ParcelAction>(this.actions, (value) => value.toFirestore());

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.code !== undefined) this.searchKeys.push(this.code.toLowerCase());
  }

  public setItemsCodes(): void {
    this.itemsCodes = [];
    this.items.forEach((item) => {
      if (item.code !== undefined && !this.itemsCodes.includes(item.code)) this.itemsCodes.push(item.code);
    });
  }

  public addAction(action: ParcelActionType, reference?: string): void {
    const now: Date = new Date();

    const parcelAction: ParcelAction = new ParcelAction();
    parcelAction.order = this.actions.length + 1 ?? 1;
    parcelAction.date = now;
    parcelAction.action = action;
    parcelAction.reference = reference;
    this.actions.push(parcelAction);

    if (action === ParcelActionType.Created) {
      this.lifeStart = new Date(now.getTime());
    } else if (action === ParcelActionType.Deleted || action === ParcelActionType.Sent) {
      this.lifeEnd = new Date(now.getTime());
    }
  }
}
