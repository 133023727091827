import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12 text-center" }
const _hoisted_3 = { class: "mb-0 text-italic" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.isDialogVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event)),
    modal: true,
    closable: false,
    style: { width: '30vw' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.t("employee.badgeScannerText")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Divider)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            label: _ctx.t('buttons.close'),
            class: "p-button-warning p-uppercase",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog())),
            autofocus: ""
          }, null, 8, ["label"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}