import { Employee } from "../objects/Employee";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import {
  getActiveEmployees,
  getActiveEmployeesByLocation,
  getEmployeeByBadgeNumber,
  getEmployeesByBranch,
  isBadgeNumberUnique,
  isEmailUnique,
  isFiscalCodeUnique,
  isRegistrationNumberUnique,
} from "./methods";

export class EmployeeModel extends FirestoreModel<Employee> {
  public constructor() {
    super(() => new Employee(), "employees", LockPolicy.DiscardUnsyncedChanges, "employee");
    this.beforeDeleteFunction = "featuresEmployeeBeforeDelete";
  }

  public async getDocuments(): Promise<Employee[]> {
    return super.getDocuments([new SortCriteria("fullName", "asc", "string")]);
  }

  public async getDocument(employeeId: string): Promise<Employee> {
    return super.getDocument(employeeId);
  }

  public async createDocument(employee: Employee): Promise<string> {
    employee.setFullNames();
    return super.createDocument(employee);
  }

  public async superCreateDocument(employee: Employee): Promise<string> {
    employee.setFullNames();
    return super.createDocument(employee);
  }

  public async updateDocument(employee: Employee): Promise<void> {
    employee.setFullNames();
    return super.updateDocument(employee);
  }

  public async deleteDocument(employee: Employee): Promise<boolean> {
    return super.deleteDocument(employee);
  }

  public async getActiveEmployees(): Promise<Employee[]> {
    return getActiveEmployees();
  }

  public async getActiveEmployeesByLocation(
    latitude: number,
    longitude: number,
    radius: number
  ): Promise<{ employee: Employee; distance: number }[]> {
    return getActiveEmployeesByLocation(latitude, longitude, radius);
  }

  public async getEmployeeByBadgeNumber(badgeNumber: number): Promise<Employee | undefined> {
    return getEmployeeByBadgeNumber(badgeNumber);
  }

  public async getEmployeesByBranch(branchId: string): Promise<Employee[]> {
    return getEmployeesByBranch(branchId);
  }

  public async isBadgeNumberUnique(badgeNumber: number, employeeId?: string): Promise<boolean> {
    return isBadgeNumberUnique(badgeNumber, employeeId);
  }

  public async isEmailUnique(email: string, employeeId?: string, employeeUserId?: string): Promise<boolean> {
    return isEmailUnique(email, employeeId, employeeUserId);
  }

  public async isFiscalCodeUnique(fiscalCode: string, employeeId?: string): Promise<boolean> {
    return isFiscalCodeUnique(fiscalCode, employeeId);
  }

  public async isRegistrationNumberUnique(registrationNumber: number, employeeId?: string): Promise<boolean> {
    return isRegistrationNumberUnique(registrationNumber, employeeId);
  }
}

export const employeeModel: EmployeeModel = new EmployeeModel();
