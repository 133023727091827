import { format } from "date-fns";
import { getDocs, Query, query, QueryDocumentSnapshot, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { EmployeeLeave } from "@/features/modules/employeeLeave/objects/EmployeeLeave";
import { employeeLeaveModel } from "../EmployeeLeaveModel";

export const getEmployeeLeavesByEmployeeAndDate = async (employeeId: string, date: Date): Promise<EmployeeLeave[]> => {
  try {
    const toSnapshotQuery: Query<EmployeeLeave> = query(
      employeeLeaveModel.getPathReference(employeeId).withConverter(employeeLeaveModel.firestoreConverter),
      where("toSort", ">=", format(date, "yyyy-MM-dd"))
    );

    const toSnapshot: QuerySnapshot<EmployeeLeave> = await getDocs(employeeLeaveModel.addReadConditionToQuery(toSnapshotQuery));

    if (toSnapshot == undefined || toSnapshot.empty) return [];

    return toSnapshot.docs.reduce((result: EmployeeLeave[], doc: QueryDocumentSnapshot<EmployeeLeave>) => {
      if (doc.data().fromSort <= format(date, "yyyy-MM-dd")) result.push(doc.data());
      return result;
    }, []);
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeLeaveModel.getEmployeeLeavesByEmployeeAndDate", { employeeId, date }, error);
    return [];
  }
};
