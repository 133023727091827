export const it = {
  employee: {
    activeBranches: "Cantieri attivi",
    addressFields: {
      country: "Paese",
      province: "Provincia",
      street: "Indirizzo",
      town: "Comune",
      zipCode: "CAP",
    },
    age: "Età",
    assignments: "Incarichi",
    badgeNumber: "Numero di badge",
    badgeNumberError: "Inserire un numero di badge valido e univoco",
    badgeScannerText: "Scansiona il badge",
    birthDate: "Data di nascita",
    birthDateError: "Selezionare una data di nascita valida",
    birthPlace: "Luogo di nascita",
    date: "Data",
    dateError: "Selezionare una data valida",
    details: "Dettagli",
    docFolder: "Fascicolo",
    email: "Indirizzo e-mail",
    emailError: "Indirizzo e-mail non valido o già presente",
    employee: "Operatore",
    employees: "Operatori",
    employeesOf: "Operatori di {parent}",
    expiredEmployeeDocs: "Documenti operatori scaduti",
    expiringEmployeeDocs: "Documenti operatori in scadenza",
    filters: {
      archived: "Operatori archiviati",
      archivedExcluded: "Archiviati esclusi",
      archivedIncluded: "Archiviati inclusi",
    },
    fiscalCode: "Codice fiscale",
    fiscalCodeError: "Inserire un codice fiscale valido e univoco",
    firstName: "Nome",
    firstNameError: "Inserire un nome valido",
    fullName: "Nome",
    hasUser: "Utente",
    iban: "IBAN",
    ibanError: "Inserire un IBAN valido",
    info: "Informazioni",
    lastName: "Cognome",
    lastNameError: "Inserire un cognome valido",
    livingAddress: "Domicilio",
    locationSearch: "Cerca da località",
    locationSearchFields: {
      address: "Indirizzo",
      distance: "Distanza",
      maxDistance: "Distanza massima",
      noResults: "Nessun risultato trovato con i parametri di ricerca inseriti",
      submitToSearch: "Inserire una posizione ed avviare la ricerca per visualizzare i risultati",
    },
    map: "Mappa operatori",
    missingEmployeeDocs: "Documenti operatori mancanti",
    name: "Nome",
    nationality: "Nazionalità",
    notes: "Note",
    onlyResidentialAddress: "L'indirizzo di residenza coincide con quello di domicilio",
    phone: "Telefono",
    residentialAddress: "Residenza",
    sex: "Sesso",
    sexError: "Selezionare il sesso",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attivo",
      archived: "Archiviato",
    },
    userId: "Utente",
    years: "anni",
  },
  navigation: {
    employee: "Operatori",
  },
  buttons: {
    archiveEmployee: "Metti in archivio",
    employeeDisableTrackExpirations: "Disabilita controllo scadenze",
    employeeEnableTrackExpirations: "Abilita controllo scadenze",
    employeeScanBadge: "Scansiona badge",
    goToEmployeeMap: "Mappa",
    restoreEmployee: "Togli dall'archivio",
  },
  toast: {
    error: {
      getEmployeeByBadgeNumber: "Operatore non trovato",
      locationSearch: "Errore durante la ricerca",
    },
    success: {},
    warning: {
      locationSearchNoLocation: "Nessuna posizione selezionata per la ricerca",
    },
  },
};
