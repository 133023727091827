import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";

export class ItemCodeModel {
  public async calcItemCode(companyId: string, itemCode: string): Promise<Parcel[]> {
    try {
      if (companyId === undefined) throw new Error("companyIdUndefined");
      if (itemCode === undefined) throw new Error("itemCodeUndefined");

      const parcels: Parcel[] = await parcelModel.getParcelsByCompanyAndItemCode(companyId, itemCode);

      return parcels;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ItemCodeModel.calcItemCode", { companyId, itemCode }, error);
      return [];
    }
  }
}

export const itemCodeModel: ItemCodeModel = new ItemCodeModel();
