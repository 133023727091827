export const it = {
  expenseType: {
    expenseType: "Tipo di spesa",
    expenseTypes: "Tipi di spesa",
    name: "Nome",
    nameError: "Inserire un nome valido",
  },
  navigation: {
    expenseType: "Tipi di spesa",
  },
};
