import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { Vehicle } from "../objects/Vehicle";
import { vehicleModel } from "../models/VehicleModel";

export const vehicleSearch: SearchModule = new SearchModule(
  "vehicle.vehicle",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Vehicle).registrationNumber ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/vehicles/${firestoreDocument.id}`,
  "truck",
  vehicleModel,
  [new SortCriteria("registrationNumber", "asc", "string")]
);
