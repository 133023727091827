import { RouteRecordRaw } from "vue-router";

export const employeeMedicalExamRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/medical-exams/:employeeMedicalExamId/edit",
    name: "EmployeeMedicalExamEdit",
    component: () => import("../views/EmployeeMedicalExamEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeMedicalExam",
      requiredRight: "viewUI",
    },
  },
];
