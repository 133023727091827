import { EmployeeRoster } from "../objects/EmployeeRoster";
import { EmployeeRosterHelpers } from "../helpers/EmployeeRosterHelpers";
import { EmployeeWithRoster } from "../objects/EmployeeWithRoster";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getEmployeeRosterByEmployeeAndMonthAndYear, getEmployeesWithRosterByBranchAndMonthAndYear } from "./methods";

export class EmployeeRosterModel extends FirestoreModel<EmployeeRoster> {
  public constructor() {
    super(() => new EmployeeRoster(), "employeeRosters", LockPolicy.DiscardUnsyncedChanges, "employeeRoster");
    this.parentCollectionName = "employees";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<EmployeeRoster[]> {
    return super.getDocuments([new SortCriteria("year", "asc", "number"), new SortCriteria("month", "asc", "number")], parentId);
  }

  public async getDocument(employeeRosterId: string, parentId?: string): Promise<EmployeeRoster> {
    return super.getDocument(employeeRosterId, parentId);
  }

  public async createDocument(employeeRoster: EmployeeRoster, parentId?: string): Promise<string> {
    const newEmployeeRosterId: string = await super.createDocument(employeeRoster, parentId);

    EmployeeRosterHelpers.calculateEmployeeRosterCounters(parentId as string, newEmployeeRosterId);

    return newEmployeeRosterId;
  }

  public async updateDocument(employeeRoster: EmployeeRoster, parentId?: string): Promise<void> {
    await super.updateDocument(employeeRoster, parentId);

    EmployeeRosterHelpers.calculateEmployeeRosterCounters(parentId as string, employeeRoster.id);
  }

  public async deleteDocument(employeeRoster: EmployeeRoster, parentId?: string): Promise<boolean> {
    return super.deleteDocument(employeeRoster, parentId);
  }

  public async getEmployeeRosterByEmployeeAndMonthAndYear(employeeId: string, month: number, year: number): Promise<EmployeeRoster | undefined> {
    return getEmployeeRosterByEmployeeAndMonthAndYear(employeeId, month, year);
  }

  public async getEmployeesWithRosterByBranchAndMonthAndYear(branchId: string, month: number, year: number): Promise<EmployeeWithRoster[]> {
    return getEmployeesWithRosterByBranchAndMonthAndYear(branchId, month, year);
  }
}

export const employeeRosterModel: EmployeeRosterModel = new EmployeeRosterModel();
