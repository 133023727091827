import { RouteRecordRaw } from "vue-router";

export const timekeeperRoutes: Array<RouteRecordRaw> = [
  {
    path: "/timekeepers/:id/edit",
    name: "TimekeeperEdit",
    component: () => import("../views/TimekeeperEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "timekeeper",
      requiredRight: "viewUI",
    },
  },
];
