import { RouteRecordRaw } from "vue-router";

export const parcelDetailRoutes: Array<RouteRecordRaw> = [
  {
    path: "/parcel-details/:id/edit",
    name: "ParcelDetailEdit",
    component: () => import("../views/ParcelDetailEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "parcelDetail",
      requiredRight: "viewUI",
    },
  },
];
