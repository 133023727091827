import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";

export const getPreviousInvoiceByCompany = async (companyId: string, monthDate: Date): Promise<Invoice | undefined> => {
  try {
    const snapshotQuery: Query<Invoice> = query(
      invoiceModel.getPathReference().withConverter(invoiceModel.firestoreConverter),
      where("company.id", "==", companyId),
      where("monthDate", "<", Timestamp.fromDate(monthDate))
    );

    const snapshot: QuerySnapshot<Invoice> = await getDocs(invoiceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return sortDocuments(snapshot.docs.map((doc) => doc.data()))[0];
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getPreviousInvoiceByCompany", { companyId, monthDate }, error);
    return undefined;
  }
};

function sortDocuments(documents: Invoice[]): Invoice[] {
  const sorter: FirestoreSorter<Invoice> = new FirestoreSorter(documents);
  sorter.addSortCriteria("monthDate", "desc", "date");
  return sorter.sort();
}
