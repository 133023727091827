import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { StoredDpi } from "@/features/modules/storedDpi/objects/StoredDpi";

export class DpiDeliveredPaper extends DocumentPaper {
  private startDate: Date;
  private endDate: Date;
  private results: StoredDpi[];

  constructor(
    startDate: Date,
    endDate: Date,
    results: StoredDpi[],
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.startDate = startDate;
    this.endDate = endDate;
    this.results = results;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      dpiDeliveredDetail: { fontSize: 10 },
      dpiDeliveredDpiCell: { alignment: "center", fontSize: 8 },
      dpiDeliveredDpiCellLeft: { alignment: "left", fontSize: 8 },
      dpiDeliveredDpiHead: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(): Promise<Record<string, unknown>[]> {
    await this.setStructure(this.t("statDpiDelivered.name").toLocaleUpperCase());

    this.writeDetails();

    this.writeDpiTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeDetails(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: DataHelpers.mmToPoints(30),
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "dpiDeliveredDetail",
              text: `${this.t("statDpiDelivered.period").toLocaleUpperCase()}:`,
            },
          ],
        },
        {
          width: "*",
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "dpiDeliveredDetail",
              text: `${this.d(this.startDate, "mediumDate")}  -  ${this.d(this.endDate, "mediumDate")}`,
            },
          ],
        },
      ],
    });
  }

  private writeDpiTable(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "dpiDeliveredDpiHead", text: this.t("dpi.name").toLocaleUpperCase() },
        { style: "dpiDeliveredDpiHead", text: this.t("storedDpi.size").toLocaleUpperCase() },
        { style: "dpiDeliveredDpiHead", text: this.t("storedDpi.quantity").toLocaleUpperCase() },
      ],
    ];

    for (const result of this.results) {
      const row: unknown[] = [
        { style: "dpiDeliveredDpiCellLeft", text: result.dpi?.name ?? "-" },
        { style: "dpiDeliveredDpiCell", text: result.size ?? "-" },
        { style: "dpiDeliveredDpiCell", text: result.quantity ? this.n(result.quantity, "number0") : "-" },
      ];

      dataTableBody.push(row);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "dpiDeliveredDpiCell", text: this.t("gen.noResults") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(25)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
