export const it = {
  priceList: {
    item: {
      add: "Aggiungi elemento",
      highThreshold: "Soglia superiore",
      highThresholdError: "Inserire un valore di soglia valido",
      items: "Elementi",
      lowThreshold: "Soglia inferiore",
      lowThresholdError: "Inserire un valore di soglia valido",
      name: "Testo",
      nameError: "Inserire il testo",
      price: "Prezzo",
      priceError: "Inserire un prezzo valido",
      reference: "Riferimento",
      referenceError: "Selezionare un riferimento",
      type: "Tipo",
      typeError: "Selezionare il tipo",
      types: {
        fixed: "Costo fisso",
        forfait: "Forfait",
        rentSpace: "Affitto spazio",
        employeeTime: "Ore di lavoro",
        handleParcel: "Movimentazione UDC",
        stockParcel: "Giacenza UDC",
        transport: "Trasporto",
      },
    },
    name: "Nome",
    nameError: "Inserire il nome",
    priceList: "Listino prezzi",
    priceLists: "Listini prezzi",
  },
  navigation: {
    priceList: "Listini prezzi",
  },
};
