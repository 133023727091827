export const it = {
  zebraPrint: {
    connect: "Connetti",
    disconnect: "Disconnetti",
    title: "Stampante Zebra",
  },
  buttons: {
    connect: "Connetti",
    disconnect: "Disconnetti",
  },
  toast: {
    error: {
      zebraPrintConnection: "Stampante non connessa",
    },
    success: {
      zebraPrintConnection: "Stampante connessa",
    },
    warning: {},
  },
};
