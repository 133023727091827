import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { InboundShipment } from "../objects/InboundShipment";
import { inboundShipmentModel } from "../models/InboundShipmentModel";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const inboundShipmentSearch: SearchModule = new SearchModule(
  "inboundShipment.inboundShipment",
  (firestoreDocument: FirestoreDocument) => Promise.resolve(`${(firestoreDocument as InboundShipment).code}`),
  (firestoreDocument: FirestoreDocument) => `/inbound-shipments/${firestoreDocument.id}/edit`,
  "download",
  inboundShipmentModel,
  [new SortCriteria("date", "desc", "date")]
);
