import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isEmailUnique = async (email: string, companyId = "empty", companyUserId = "empty"): Promise<boolean> => {
  try {
    if (email === undefined) return false;

    const companyResult: boolean = await CloudHelpers.runValidatorFunction("featuresCompanyEmailValidator", email, companyId);
    const userResult: boolean = await CloudHelpers.runValidatorFunction("coreUserEmailValidator", email, companyUserId);

    return companyResult && userResult;
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyModel.isEmailUnique", { email, companyId, companyUserId }, error);
    return false;
  }
};
