import { EmployeeTransaction } from "../objects/EmployeeTransaction";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getEmployeeTransactionsByEmployeeAndDate } from "./methods";

export class EmployeeTransactionModel extends FirestoreModel<EmployeeTransaction> {
  public constructor() {
    super(() => new EmployeeTransaction(), "employeeTransactions", LockPolicy.DiscardUnsyncedChanges, "employeeTransaction");
    this.parentCollectionName = "employees";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<EmployeeTransaction[]> {
    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(employeeTransactionId: string, parentId?: string): Promise<EmployeeTransaction> {
    return super.getDocument(employeeTransactionId, parentId);
  }

  public async createDocument(employeeTransaction: EmployeeTransaction, parentId?: string): Promise<string> {
    return super.createDocument(employeeTransaction, parentId);
  }

  public async updateDocument(employeeTransaction: EmployeeTransaction, parentId?: string): Promise<void> {
    return super.updateDocument(employeeTransaction, parentId);
  }

  public async deleteDocument(employeeTransaction: EmployeeTransaction, parentId?: string): Promise<boolean> {
    return super.deleteDocument(employeeTransaction, parentId);
  }

  /* custom methods */

  public async getEmployeeTransactionsByEmployeeAndDate(employeeId: string, date: Date): Promise<EmployeeTransaction[]> {
    return getEmployeeTransactionsByEmployeeAndDate(employeeId, date);
  }
}

export const employeeTransactionModel: EmployeeTransactionModel = new EmployeeTransactionModel();
