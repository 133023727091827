
import { defineComponent, onMounted, Ref, ref } from "vue";

import { addMonths, compareAsc, startOfMonth } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { BranchCounter } from "../objects/BranchCounter";
import { BranchEmployeeReport } from "../objects/BranchEmployeeReport";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { branchMonthReportModel } from "../models/BranchMonthReportModel";
import { BranchPosition } from "@/features/modules/branchPosition/objects/BranchPosition";
import { branchPositionModel } from "@/features/modules/branchPosition/models/BranchPositionModel";
import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";
import { storeTypes } from "@/core/modules/store/types";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "BranchMonthReportView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<BranchEmployeeReport[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const branchCounters: Ref<BranchCounter> = ref(new BranchCounter());
    const branches: Ref<Branch[]> = ref([]);
    const branchId: Ref<string | undefined> = ref(undefined);
    const positions: Ref<BranchPosition[]> = ref([]);

    const monthDate: Ref<Date> = ref(startOfMonth(new Date()));
    monthDate.value.setHours(0, 0, 0, 0);
    const months: Ref<{ label: string; date: Date }[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      await loadBranches();

      let startDate: Date = new Date("2023-01-01");
      startDate.setHours(0, 0, 0, 0);
      const endDate: Date = startOfMonth(new Date());
      endDate.setHours(0, 0, 0, 0);

      while (compareAsc(startDate, endDate) <= 0) {
        months.value.push({
          label: FormatHelpers.capitalizeFirstLetter(d(startDate, "longMonth")),
          date: startDate,
        });
        startDate = addMonths(startDate, 1);
      }
    };

    const loadBranches = async (): Promise<void> => {
      branches.value = await branchModel.getDocuments();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (branchId.value == undefined) throw new Error("statBranchMonthReport.branchUndefined");
      if (monthDate.value == undefined) throw new Error("statBranchMonthReport.monthUndefined");
      monthDate.value.setHours(0, 0, 0, 0);

      positions.value = await branchPositionModel.getDocuments([new SortCriteria("name", "asc", "string")], branchId.value);

      const output: { report: BranchEmployeeReport[]; counters: BranchCounter } = await branchMonthReportModel.createReport(
        branchId.value,
        monthDate.value
      );

      results.value = output.report;
      branchCounters.value = output.counters;

      showResults.value = true;
    };

    const printPaper = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          /*
          const serviceCounterPaper: ServiceCounterPaper = new ServiceCounterPaper(
            companyId.value as string,
            doctorId.value as string,
            period.value[0],
            period.value[1],
            results.value,
            d,
            n,
            t
          );
          serviceCounterPaper.open();
          */
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      branchCounters,
      branches,
      branchId,
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      monthDate,
      months,
      n,
      printPaper,
      results,
      showResults,
      t,
    };
  },
});
