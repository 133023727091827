export const it = {
  parcel: {
    action: "Azione",
    actionError: "Selezionare una azione valida",
    actions: "Azioni",
    actionTypes: {
      created: "Creato",
      deleted: "Eliminato",
      loaded: "Caricato",
      loosened: "Libero",
      selected: "Selezionato",
      sent: "Spedito",
      stored: "Immagazzinato",
      unknown: "Sconosciuto",
      unselected: "Deselezionato",
    },
    addItem: "Aggiungi codice",
    code: "Codice",
    codeError: "Inserire un codice valido",
    company: "Azienda",
    content: "Contenuto",
    createdAt: "Creato il",
    creation: "Creazione",
    date: "Data",
    detail: "Dettaglio",
    detailError: "Selezionare un dettaglio valido",
    images: "Immagini",
    inboundShipment: "Spedizione in arrivo",
    inboundShipmentError: "Selezionare la spedizione in arrivo",
    of: "del",
    outboundShipment: "Spedizione in partenza",
    parcel: "UDC",
    parcelError: "Selezionare UDC",
    parcels: "UDC",
    printAll: "Stampa tutti",
    quantity: "Quantità",
    quantityError: "Inserire una quantità valida",
    reference: "Riferimento",
    referenceDate: "Data di riferimento",
    sender: "Mittente",
    shipmentDetail: "Spedizione {code} del {date} da {companyName}",
    state: "Stato",
    states: {
      deleted: "Eliminato",
      loaded: "Caricato",
      loosened: "Libero",
      sent: "Spedito",
      stored: "Immagazzinato",
      unknown: "Sconosciuto",
    },
    type: "Tipo",
    typeError: "Selezionare il tipo",
    warehouse: "Magazzino",
    warehouseLocation: "Posizione",
    warehouseLocationCode: "Codice Posizione Magazzino",
  },
  navigation: {
    parcel: "UDC",
  },
};
