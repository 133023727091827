import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyInvoiceYear } from "../objects/CompanyInvoiceYear";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "@/features/modules/invoice/models/InvoiceModel";

export class InvoiceByYearModel {
  public async calcInvoices(year: number): Promise<CompanyInvoiceYear[]> {
    try {
      if (year === undefined) return [];

      const invoices: Invoice[] = await invoiceModel.getInvoicesByYear(year);

      const groupInvoices: Record<string, Invoice[]> = {};
      const companies: LinkedCompany[] = [];

      for (const invoice of invoices) {
        const company: LinkedCompany | undefined = invoice.company;
        if (company === undefined) continue;

        if (groupInvoices[company.id] === undefined) {
          groupInvoices[company.id] = [];
          companies.push(company);
        }
        groupInvoices[company.id].push(invoice);
      }

      const companyInvoiceYears: CompanyInvoiceYear[] = [];
      for (const company of companies) {
        const companyInvoiceYear: CompanyInvoiceYear = new CompanyInvoiceYear();
        companyInvoiceYear.company = company;
        companyInvoiceYear.year = year;
        companyInvoiceYear.invoices = groupInvoices[company.id];
        companyInvoiceYear.total = companyInvoiceYear.invoices.reduce((total, invoice) => total + invoice.total, 0);
        companyInvoiceYears.push(companyInvoiceYear);
      }

      return companyInvoiceYears;
    } catch (error: unknown) {
      appFaultModel.catchAppError("InvoiceByYearModel.calcInvoices", { year }, error);
      return [];
    }
  }
}

export const invoiceByYearModel: InvoiceByYearModel = new InvoiceByYearModel();
