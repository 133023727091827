export const it = {
  transactionType: {
    direction: "Direzione",
    directionError: "Selezioanre una direzione valida",
    directions: {
      credit: "Entrata",
      debit: "Uscita",
    },
    name: "Nome",
    nameError: "Inserire un nome valido",
    transactionType: "Tipo di transazione",
    transactionTypes: "Tipi di transazione",
  },
  navigation: {
    transactionType: "Tipi di transazione",
  },
};
