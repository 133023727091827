import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "../OutboundShipmentModel";

export const getOutboundShipmentByCode = async (code: string): Promise<OutboundShipment | undefined> => {
  try {
    const snapshotQuery: Query<OutboundShipment> = query(
      outboundShipmentModel.getPathReference().withConverter(outboundShipmentModel.firestoreConverter),
      where("code", "==", code)
    );

    const snapshot: QuerySnapshot<OutboundShipment> = await getDocs(outboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.getOutboundShipmentByCode", { code }, error);
    return undefined;
  }
};
