import { getDocs, limit, orderBy, Query, query, QuerySnapshot } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "../InboundShipmentModel";

export const getLastInboundShipments = async (): Promise<InboundShipment[]> => {
  try {
    const snapshotQuery: Query<InboundShipment> = query(
      inboundShipmentModel.getPathReference().withConverter(inboundShipmentModel.firestoreConverter),
      orderBy("createdAt", "desc"),
      limit(5)
    );

    const snapshot: QuerySnapshot<InboundShipment> = await getDocs(inboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("InboundShipmentModel.getLastInboundShipments", {}, error);
    return [];
  }
};
