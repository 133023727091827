import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { zebraPrintModel } from "../ZebraPrintModel";

export const printLabel = (zplLabel: string): void => {
  try {
    if (zebraPrintModel.device === undefined) return;
    zebraPrintModel.device.send(zplLabel, successCallback, errorCallback);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ZebraPrintModel.printLabel", { zplLabel }, error);
  }
};

function successCallback(): void {
  console.log("Printed successfully");
}

function errorCallback(errorMessage: string): void {
  alert("Error: " + errorMessage);
}
