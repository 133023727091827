export const it = {
  dpiAlert: {
    dpi: "DPI",
    dpiAlert: "Soglia DPI",
    dpiAlerts: "Soglie DPI",
    dpiError: "Selezionare un DPI",
    isActive: "Attiva",
    notifications: {
      groupName: "Soglie DPI",
      thresholdMessage: "Il DPI {name} taglia {size} ha infranto la soglia {threshold} con {quantity} pezzi disponibili", // TODO
    },
    quantity: "Quantità",
    quantityError: "Inserire una quantità maggiore di zero",
    sizes: "Taglie",
    sizesError: "Selezionare almeno una taglia",
  },
  navigation: {
    dpiAlert: "Soglie DPI",
  },
  buttons: {
    disableDpiAlert: "Disattiva soglia",
    enableDpiAlert: "Attiva soglia",
  },
};
