import { RouteRecordRaw } from "vue-router";

export const branchShiftTypesRoutes: Array<RouteRecordRaw> = [
  {
    path: "/branches/:branchId/shift-types/:branchShiftTypeId/edit",
    name: "BranchShiftTypeEdit",
    component: () => import("../views/BranchShiftTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branchShiftType",
      requiredRight: "viewUI",
    },
  },
];
