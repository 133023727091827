
import { defineComponent, onMounted, Ref, ref } from "vue";

import { addMonths, compareAsc, startOfMonth } from "date-fns";
import Holidays from "date-holidays";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { BranchPosition } from "@/features/modules/branchPosition/objects/BranchPosition";
import { branchPositionModel } from "@/features/modules/branchPosition/models/BranchPositionModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDayReport } from "../objects/EmployeeDayReport";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { employeeMonthReportModel } from "../models/EmployeeMonthReportModel";
import { EmployeeRosterCounter } from "@/features/modules/employeeRoster/objects/EmployeeRosterCounter";
import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";
import { localeModel } from "@/core/modules/locale/models/LocaleModel";
import { storeTypes } from "@/core/modules/store/types";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "EmployeeMonthReportView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<EmployeeDayReport[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const dateHolidays = new Holidays(localeModel.getSelectedLocale().name.toUpperCase());

    const counterResults: Ref<Record<string, unknown>[]> = ref([]);

    const branches: Ref<Branch[]> = ref([]);
    const positionsByBranchId: Ref<Record<string, BranchPosition[]>> = ref({});

    const employees: Ref<Employee[]> = ref([]);
    const employeeId: Ref<string | undefined> = ref(undefined);
    const monthDate: Ref<Date> = ref(startOfMonth(new Date()));
    monthDate.value.setHours(0, 0, 0, 0);
    const months: Ref<{ label: string; date: Date }[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      await loadBranches();
      employees.value = await employeeModel.getActiveEmployees();

      let startDate: Date = new Date("2023-01-01");
      startDate.setHours(0, 0, 0, 0);
      const endDate: Date = startOfMonth(new Date());
      endDate.setHours(0, 0, 0, 0);

      while (compareAsc(startDate, endDate) <= 0) {
        months.value.push({
          label: FormatHelpers.capitalizeFirstLetter(d(startDate, "longMonth")),
          date: startDate,
        });
        startDate = addMonths(startDate, 1);
      }
    };

    const loadBranches = async (): Promise<void> => {
      branches.value = await branchModel.getDocuments();

      positionsByBranchId.value = {};
      for (const branch of branches.value) {
        positionsByBranchId.value[branch.id] = await branchPositionModel.getDocuments([new SortCriteria("name", "asc", "string")], branch.id);
      }
    };

    const submitAction = async () => {
      showResults.value = false;
      if (employeeId.value == undefined) throw new Error("statEmployeeMonthReport.employeeUndefined");
      if (monthDate.value == undefined) throw new Error("statEmployeeMonthReport.monthUndefined");
      monthDate.value.setHours(0, 0, 0, 0);

      const output: { report: EmployeeDayReport[]; counters: EmployeeRosterCounter } = await employeeMonthReportModel.createReport(
        employeeId.value,
        monthDate.value,
        n,
        t
      );

      results.value = output.report;
      renderCounters(output.counters);

      showResults.value = true;
    };

    const renderCounters = (counters: EmployeeRosterCounter) => {
      counterResults.value = [
        {
          label: t("employeeRoster.counter.totalHours"),
          value: counters.totalHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.dayHours"),
          value: counters.dayHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.nightHours"),
          value: counters.nightHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.saturdayDayHours"),
          value: counters.saturdayDayHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.saturdayNightHours"),
          value: counters.saturdayNightHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.sundayDayHours"),
          value: counters.sundayDayHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.sundayNightHours"),
          value: counters.sundayNightHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.holidayDayHours"),
          value: counters.holidayDayHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.holidayNightHours"),
          value: counters.holidayNightHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.overtimeHours"),
          value: counters.overtimeHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.compensatedOvertimeHours"),
          value: counters.compensatedOvertimeHours ?? 0,
        },
        {
          label: t("employeeRoster.counter.balanceHours"),
          value: counters.balanceHours ?? 0,
        },
      ];

      for (const branchId of Object.keys(counters.branchHours)) {
        const branch: Branch | undefined = branches.value.find((branch) => branch.id === branchId);
        if (branch === undefined) continue;

        counterResults.value.push({
          label: `${t("branch.branch")} ${branch.name}`,
          value: counters.branchHours[branchId],
        });

        if (branchId in counters.positionHours === false) continue;

        for (const positionId of Object.keys(counters.positionHours[branchId])) {
          const branchPositions: BranchPosition[] = positionsByBranchId.value[branchId] ?? [];
          const branchPosition: BranchPosition | undefined = branchPositions.find((position) => position.id === positionId);
          if (branchPosition === undefined) continue;

          counterResults.value.push({
            label: `${t("employeeRoster.inRole")} ${t("branchPosition.branchPosition")} ${branchPosition.name}`,
            value: counters.branchHours[branchId],
          });
        }
      }
    };

    const dayHasLeave = (date: Date): boolean => {
      const days: EmployeeDayReport[] = results.value.filter((day) => day.date.getTime() === date.getTime() && day.label === "leaves");
      return days.length > 0;
    };

    const dayHasSuspension = (date: Date): boolean => {
      const days: EmployeeDayReport[] = results.value.filter((day) => day.date.getTime() === date.getTime() && day.label === "suspensions");
      return days.length > 0;
    };

    const printPaper = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);
          /*
          TODO
          const serviceCounterPaper: ServiceCounterPaper = new ServiceCounterPaper(
            companyId.value as string,
            doctorId.value as string,
            period.value[0],
            period.value[1],
            results.value,
            d,
            n,
            t
          );
          serviceCounterPaper.open();
          */
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      counterResults,
      d,
      dataChanged,
      dateHolidays,
      dayHasLeave,
      dayHasSuspension,
      employeeId,
      employees,
      handleKeydown,
      handleSubmit,
      monthDate,
      months,
      n,
      printPaper,
      results,
      showResults,
      t,
    };
  },
});
