import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { InboundShipment } from "@/features/modules/inboundShipment/objects/InboundShipment";
import { inboundShipmentModel } from "@/features/modules/inboundShipment/models/InboundShipmentModel";
import { LinkedParcel } from "@/features/modules/parcel/objects/LinkedParcel";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";

export const linkToInboundShipment = async (parcel: Parcel): Promise<void> => {
  try {
    if (parcel.inboundShipment === undefined) throw new Error("inboundShipmentUndefined");
    const inboundShipment: InboundShipment = await inboundShipmentModel.getDocument(parcel.inboundShipment.id);

    // update inboundShipment
    const linkedParcel: LinkedParcel = LinkedParcel.createFromParcel(parcel);
    linkedParcel.order = inboundShipment.getLinkedParcels().length + 1;
    inboundShipment.addLinkedParcel(linkedParcel);

    batch.update(inboundShipmentModel.getDocumentReference(inboundShipment.id), inboundShipment.toFirestore());

    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("ParcelModel.linkToInboundShipment", { parcel }, error);
  }
};
