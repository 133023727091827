import { RouteRecordRaw } from "vue-router";

export const branchPositionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/branches/:branchId/positions/:branchPositionId/edit",
    name: "BranchPositionEdit",
    component: () => import("../views/BranchPositionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branchPosition",
      requiredRight: "viewUI",
    },
  },
];
