export const it = {
  employeeMedicalExam: {
    date: "Data",
    dateError: "Inserire una data valida",
    employeeMedicalExam: "Idoneità",
    employeeMedicalExamOf: "Idoneità di {parent}",
    employeeMedicalExams: "Idoneità",
    employeeMedicalExamsOf: "Idoneità di {parent}",
    expiredEmployeeMedicalExams: "Idoneità scadute",
    expiringEmployeeMedicalExams: "Idoneità in scadenza",
    expiration: "Data scadenza",
    expirationError: "Inserire una data valida",
    isMandatory: "Obbligatoria",
    missingEmployeeMedicalExams: "Idoneità mancanti",
    notes: "Note",
    preview: "Anteprima",
    type: "Tipo idoneità",
    typeError: "Selezionare un tipo idoneità valido",
  },
  buttons: {
    goToEmployeeMedicalExams: "Vai alle idoneità dell'operatore",
  },
};
