import { DpiHandling } from "../objects/DpiHandling";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import {
  deliverDpis,
  generateCode,
  getDpiHandlingByCode,
  getDpiHandlingsByBranch,
  getDpiHandlingsByEmployee,
  getDpiHandlingsByPeriod,
} from "./methods";

export class DpiHandlingModel extends FirestoreModel<DpiHandling> {
  public constructor() {
    super(() => new DpiHandling(), "dpiHandlings", LockPolicy.DiscardUnsyncedChanges, "dpiHandling");
  }

  public async getDocuments(): Promise<DpiHandling[]> {
    return super.getDocuments();
  }

  public async getDocument(dpiHandlingId: string): Promise<DpiHandling> {
    return super.getDocument(dpiHandlingId);
  }

  public async createDocument(dpiHandling: DpiHandling): Promise<string> {
    dpiHandling.id = await super.createDocument(dpiHandling);

    return deliverDpis(dpiHandling);
  }

  public async updateDocument(dpiHandling: DpiHandling): Promise<void> {
    return super.updateDocument(dpiHandling);
  }

  public async deleteDocument(dpiHandling: DpiHandling): Promise<boolean> {
    return super.deleteDocument(dpiHandling);
  }

  public async deliverDpis(dpiHandling: DpiHandling): Promise<string> {
    return deliverDpis(dpiHandling);
  }

  public async generateCode(): Promise<string> {
    return generateCode();
  }

  public async getDpiHandlingByCode(code: string): Promise<DpiHandling | undefined> {
    return getDpiHandlingByCode(code);
  }

  public async getDpiHandlingsByBranch(branchId: string): Promise<DpiHandling[]> {
    return getDpiHandlingsByBranch(branchId);
  }

  public async getDpiHandlingsByEmployee(employeeId: string): Promise<DpiHandling[]> {
    return getDpiHandlingsByEmployee(employeeId);
  }

  public async getDpiHandlingsByPeriod(startDate: Date, endDate: Date): Promise<DpiHandling[]> {
    return getDpiHandlingsByPeriod(startDate, endDate);
  }
}

export const dpiHandlingModel: DpiHandlingModel = new DpiHandlingModel();
