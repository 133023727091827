export const it = {
  transportType: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    transportType: "Trasporto",
    transportTypes: "Trasporti",
  },
  navigation: {
    transportType: "Trasporti",
  },
};
