import { Clocking } from "./Clocking";
import { EmployeeRosterHelpers } from "../helpers/EmployeeRosterHelpers";
import { LinkedBranchShiftType } from "@/features/modules/branchShiftType/objects/LinkedBranchShiftType";
import { LinkedEmployeeLeave } from "@/features/modules/employeeLeave/objects/LinkedEmployeeLeave";
import { LinkedEmployeeWarning } from "@/features/modules/employeeWarning/objects/LinkedEmployeeWarning";
import { ShiftCounter } from "./ShiftCounter";

import { ArrayByKeyField, ObjectField, StringField } from "@/core/fields";

export class Shift {
  public branchShiftType: LinkedBranchShiftType | undefined = undefined;
  public clockings: Record<string, Clocking> = {};
  public counters: ShiftCounter = new ShiftCounter();
  public leave: LinkedEmployeeLeave | undefined;
  public warning: LinkedEmployeeWarning | undefined;
  public notes: string | undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): Shift {
    this.branchShiftType = ObjectField.fromFirestore<LinkedBranchShiftType>(data.branchShiftType, (value) => new LinkedBranchShiftType(value));
    this.clockings = ArrayByKeyField.fromFirestore<Clocking>(data.clockings, (value) => new Clocking(value));
    this.counters.fromFirestore(data.counters);
    this.leave = ObjectField.fromFirestore<LinkedEmployeeLeave>(data.leave, (value) => new LinkedEmployeeLeave(value));
    this.warning = ObjectField.fromFirestore<LinkedEmployeeWarning>(data.warning, (value) => new LinkedEmployeeWarning(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.branchShiftType = ObjectField.toFirestore(this.branchShiftType, (value) => value.toFirestore());
    firestoreData.clockings = ArrayByKeyField.toFirestore(this.clockings, (value) => value.toFirestore());
    firestoreData.counters = this.counters.toFirestore();
    firestoreData.leave = ObjectField.toFirestore(this.leave, (value) => value.toFirestore());
    firestoreData.warning = ObjectField.toFirestore(this.warning, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public getClockings(): Clocking[] {
    return EmployeeRosterHelpers.clockingsToSortedArray(this.clockings);
  }

  public setClockings(clockings: Clocking[]): void {
    this.clockings = EmployeeRosterHelpers.sortedArrayToClockings(clockings);
  }

  public addClocking(clocking: Clocking): void {
    this.clockings[clocking.id] = clocking;
  }

  public removeClocking(clocking: Clocking): void {
    delete this.clockings[clocking.id];
  }
}
