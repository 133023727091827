export const it = {
  dpiHandling: {
    addDpi: "Aggiungi DPI",
    branchError: "Selezionare un cantiere valido",
    code: "Codice",
    date: "Data",
    dateError: "Inserire una data valida",
    dpiHandling: "Movimentazione DPI",
    dpiHandlings: "Movimentazioni DPI",
    dpiHandlingsOf: "Movimentazioni DPI di {parent}",
    employeeError: "Selezionare un operatore valido",
    notes: "Note",
    source: "Provenienza",
    sourceBranch: "Cantiere di provenienza",
    sourceEmployee: "Operatore di provenienza",
    sourceType: "Tipo provenienza",
    sourceTypeError: "Selezionare un tipo di provenienza",
    sourceTypes: {
      branch: "Cantiere",
      employee: "Operatore",
      warehouse: "Magazzino",
    },
    target: "Destinazione",
    targetBranch: "Cantiere di destinazione",
    targetEmployee: "Operatore di destinazione",
    targetType: "Tipo destinazione",
    targetTypeError: "Selezionare un tipo di destinazione",
    targetTypes: {
      branch: "Cantiere",
      employee: "Operatore",
      trash: "Cestino",
      warehouse: "Magazzino",
    },
  },
  navigation: {
    dpiHandling: "Movimentazioni DPI",
  },
  toast: {
    error: {
      dpiNotAvailable: "Uno o più DPI non sono disponibili",
    },
    success: {},
    warning: {},
  },
};
