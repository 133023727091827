export const it = {
  statStockChart: {
    chartMonthlyStock: "Giacenza mensile",
    chartMonthlyStockByDetail: "Giacenza mensile per dettagli",
    chartMonthlyStockByType: "Giacenza mensile per formato UDC",
    company: "Azienda",
    month: "Mese",
    name: "Grafici di giacenza",
    noDetail: "Nessun dettaglio",
    noType: "Sconosciuto",
    parcel: "UDC",
    stockPeakValueLabel: "Valore massimo di giacenza registrato",
  },
};
