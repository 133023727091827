import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { ParcelType } from "../objects/ParcelType";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class ParcelTypeModel extends FirestoreModel<ParcelType> {
  public constructor() {
    super(() => new ParcelType(), "parcelTypes", LockPolicy.DiscardUnsyncedChanges, "parcelType");
    this.beforeDeleteFunction = "featuresParcelTypeBeforeDelete";
  }

  public async getDocuments(): Promise<ParcelType[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(parcelTypeId: string): Promise<ParcelType> {
    return super.getDocument(parcelTypeId);
  }

  public async createDocument(parcelType: ParcelType): Promise<string> {
    return super.createDocument(parcelType);
  }

  public async updateDocument(parcelType: ParcelType): Promise<void> {
    return super.updateDocument(parcelType);
  }

  public async deleteDocument(parcelType: ParcelType): Promise<boolean> {
    return super.deleteDocument(parcelType);
  }
}

export const parcelTypeModel: ParcelTypeModel = new ParcelTypeModel();
