import { RouteRecordRaw } from "vue-router";

import { BranchHelpers } from "../helpers/BranchHelpers";

export const branchRoutes: Array<RouteRecordRaw> = [
  {
    path: "/branches",
    name: "BranchList",
    component: () => import("../views/BranchList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branch",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/branches/:branchId/edit",
    name: "BranchEdit",
    component: () => import("../views/BranchEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branch",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/branches/:id/:section?",
    name: "BranchView",
    component: () => import("../views/BranchView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branch",
      requiredRight: "viewUI",
    },
  },
  // doc edit
  {
    path: "/branches/:sourceId/docs/:docId/edit",
    name: "BranchDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: BranchHelpers.getDocFolderPath(route.params.sourceId as string),
      source: "branch",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
  // doc view
  {
    path: "/branches/:sourceId/docs/:docId",
    name: "BranchDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      source: "branch",
      sourceId: route.params.sourceId,
      sourcePath: "branches",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
];
