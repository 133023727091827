import { RouteRecordRaw } from "vue-router";

export const employeePositionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees/:employeeId/positions/:employeePositionId/edit",
    name: "EmployeePositionEdit",
    component: () => import("../views/EmployeePositionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeePosition",
      requiredRight: "viewUI",
    },
  },
];
