import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "../OutboundShipmentModel";
import { OutboundShipmentState } from "@/features/modules/outboundShipment/objects/OutboundShipmentState";

export const getWorkingOutboundShipments = async (): Promise<OutboundShipment[]> => {
  try {
    const snapshotQuery: Query<OutboundShipment> = query(
      outboundShipmentModel.getPathReference().withConverter(outboundShipmentModel.firestoreConverter),
      where("state", "!=", OutboundShipmentState.Sent)
    );

    const snapshot: QuerySnapshot<OutboundShipment> = await getDocs(outboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.getWorkingOutboundShipment", {}, error);
    return [];
  }
};
