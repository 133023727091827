export const it = {
  assignment: {
    assignment: "Incarico",
    assignments: "Incarichi",
    name: "Nome",
    nameError: "Inserire un nome valido",
  },
  navigation: {
    assignment: "Incarichi",
  },
};
