import { Branch } from "./Branch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { ObjectField, StringField } from "@/core/fields";

export class LinkedBranch extends LinkedFirestoreDocument {
  public company: LinkedCompany | undefined = undefined;
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromBranch(branch: Branch): LinkedBranch {
    const linkedBranch: LinkedBranch = new LinkedBranch();
    linkedBranch.id = branch.id;
    linkedBranch.company = branch.company;
    linkedBranch.name = branch.name;

    return linkedBranch;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedBranch {
    super.fromFirestore(data);

    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
