import { RouteRecordRaw } from "vue-router";

export const dpiAlertRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dpi-alerts",
    name: "DpiAlertList",
    component: () => import("../views/DpiAlertList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiAlert",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/dpi-alerts/:id/edit",
    name: "DpiAlertEdit",
    component: () => import("../views/DpiAlertEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dpiAlert",
      requiredRight: "viewUI",
    },
  },
];
