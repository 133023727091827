import { RouteRecordRaw } from "vue-router";

import { EmployeeHelpers } from "../helpers/EmployeeHelpers";

export const employeeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees",
    name: "EmployeeList",
    component: () => import("../views/EmployeeList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/:id/:section?",
    name: "EmployeeView",
    component: () => import("../views/EmployeeView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/:id/edit",
    name: "EmployeeEdit",
    component: () => import("../views/EmployeeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/map",
    name: "EmployeeMapView",
    component: () => import("../views/EmployeeMapView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/location-search",
    name: "EmployeeLocationSearch",
    component: () => import("../views/EmployeeLocationSearch.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  // doc edit
  {
    path: "/employees/:sourceId/docs/:docId/edit",
    name: "EmployeeDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: EmployeeHelpers.getDocFolderPath(route.params.sourceId as string),
      source: "employee",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
  // doc view
  {
    path: "/employees/:sourceId/docs/:docId",
    name: "EmployeeDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      source: "employee",
      sourceId: route.params.sourceId,
      sourcePath: "employees",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
];
