import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Parcel } from "../objects/Parcel";
import { ParcelActionType } from "../objects/ParcelActionType";

import {
  generateCode,
  getAvailableParcelsByInboundCompany,
  getAvailableParcelsByInboundCompanyAndItemCode,
  getParcelByCode,
  getParcelsByCompanyAndItemCode,
  getParcelsByCompanyAndPeriod,
  getParcelsByCompanyAndPeriodAndItemCode,
  getParcelsByInboundShipment,
  getParcelsByItemCode,
  getParcelsByOutboundShipment,
  getParcelsByWarehouseLocation,
  getParcelsWithFilters,
  linkToInboundShipment,
} from "./methods";

export class ParcelModel extends FirestoreModel<Parcel> {
  public constructor() {
    super(() => new Parcel(), "parcels", LockPolicy.DiscardUnsyncedChanges, "parcel");
    this.beforeDeleteFunction = "featuresParcelBeforeDelete";
  }

  public async getDocuments(): Promise<Parcel[]> {
    return super.getDocuments();
  }

  public async getDocument(parcelId: string): Promise<Parcel> {
    return super.getDocument(parcelId);
  }

  public async createDocument(parcel: Parcel): Promise<string> {
    const duplicatedParcel: Parcel | undefined = await this.getParcelByCode(parcel.code as string);
    if (duplicatedParcel != undefined) throw new Error("duplicatedCode");

    parcel.addAction(ParcelActionType.Created, parcel.inboundShipment?.code);
    parcel.setItemsCodes();

    parcel.id = await super.createDocument(parcel);

    await linkToInboundShipment(parcel);

    return parcel.id;
  }

  public async updateDocument(parcel: Parcel): Promise<void> {
    const duplicatedParcel: Parcel | undefined = await this.getParcelByCode(parcel.code as string);
    if (duplicatedParcel != undefined && parcel.id !== duplicatedParcel.id) throw new Error("duplicatedCode");

    parcel.setItemsCodes();

    super.updateDocument(parcel);
  }

  public async deleteDocument(parcel: Parcel): Promise<boolean> {
    return super.deleteDocument(parcel);
  }

  /* custom methods */

  public async getAvailableParcelsByInboundCompany(companyId: string): Promise<Parcel[]> {
    return getAvailableParcelsByInboundCompany(companyId);
  }

  public async getAvailableParcelsByInboundCompanyAndItemCode(companyId: string, itemCode: string): Promise<Parcel[]> {
    return getAvailableParcelsByInboundCompanyAndItemCode(companyId, itemCode);
  }

  public async getParcelByCode(code: string): Promise<Parcel | undefined> {
    return getParcelByCode(code);
  }

  public async getParcelsByCompanyAndItemCode(companyId: string, itemCode: string): Promise<Parcel[]> {
    return getParcelsByCompanyAndItemCode(companyId, itemCode);
  }

  public async getParcelsByCompanyAndPeriod(companyId: string, startDate: Date, endDate: Date): Promise<Parcel[]> {
    return getParcelsByCompanyAndPeriod(companyId, startDate, endDate);
  }

  public async getParcelsByCompanyAndPeriodAndItemCode(companyId: string, startDate: Date, endDate: Date, itemCode: string): Promise<Parcel[]> {
    return getParcelsByCompanyAndPeriodAndItemCode(companyId, startDate, endDate, itemCode);
  }

  public async getParcelsByInboundShipment(inboundShipmentId: string): Promise<Parcel[]> {
    return getParcelsByInboundShipment(inboundShipmentId);
  }

  public async getParcelsByItemCode(itemCode: string): Promise<Parcel[]> {
    return getParcelsByItemCode(itemCode);
  }

  public async getParcelsByOutboundShipment(outboundShipmentId: string): Promise<Parcel[]> {
    return getParcelsByOutboundShipment(outboundShipmentId);
  }

  public async getParcelsByWarehouseLocation(warehouseLocationId: string): Promise<Parcel[]> {
    return getParcelsByWarehouseLocation(warehouseLocationId);
  }

  public async getParcelsWithFilters(companyId: string, date: Date, state: string): Promise<Parcel[]> {
    return getParcelsWithFilters(companyId, date, state);
  }

  public async generateCode(newSet?: string[]): Promise<string> {
    return generateCode(newSet);
  }
}

export const parcelModel: ParcelModel = new ParcelModel();
