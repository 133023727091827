import { compareAsc } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "../OutboundShipmentModel";
import { OutboundShipmentState } from "@/features/modules/outboundShipment/objects/OutboundShipmentState";

export const getOutboundShipmentsByCompanyAndPeriod = async (companyId: string, startDate: Date, endDate: Date): Promise<OutboundShipment[]> => {
  try {
    const snapshotQuery: Query<OutboundShipment> = query(
      outboundShipmentModel.getPathReference().withConverter(outboundShipmentModel.firestoreConverter),
      where("company.id", "==", companyId),
      where("date", ">=", Timestamp.fromDate(startDate))
    );

    const snapshot: QuerySnapshot<OutboundShipment> = await getDocs(outboundShipmentModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    const outboundShipments: OutboundShipment[] = sortDocuments(
      snapshot.docs
        .map((doc) => doc.data())
        .filter(
          (outboundShipment: OutboundShipment) =>
            compareAsc(outboundShipment.date, endDate) <= 0 && outboundShipment.state === OutboundShipmentState.Sent
        )
    );

    return outboundShipments;
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.getOutboundShipmentsByCompanyAndPeriod", { companyId, startDate, endDate }, error);
    return [];
  }
};

function sortDocuments(documents: OutboundShipment[]): OutboundShipment[] {
  const sorter: FirestoreSorter<OutboundShipment> = new FirestoreSorter(documents);
  sorter.addSortCriteria("createdAt", "asc", "date");
  return sorter.sort();
}
