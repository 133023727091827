import { Branch } from "@/features/modules/branch/objects/Branch";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { LinkedBranchPosition } from "@/features/modules/branchPosition/objects/LinkedBranchPosition";
import { LinkedLeaveType } from "@/features/modules/leaveType/objects/LinkedLeaveType";

export class BranchWithEmployee {
  public branch: Branch;
  public employeesWithPosition: { employee: Employee; position: LinkedBranchPosition; leave: LinkedLeaveType | undefined }[];

  public constructor(branch: Branch) {
    this.branch = branch;
    this.employeesWithPosition = [];
  }
}
