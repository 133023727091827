import { DocumentReference } from "firebase/firestore";

import { differenceInYears } from "date-fns";

import { ActiveLinkedBranch } from "@/features/modules/branch/objects/ActiveLinkedBranch";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { EmployeePosition } from "@/features/modules/employeePosition/objects/EmployeePosition";
import { EmployeeSex } from "./EmployeeSex";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedAssignment } from "@/features/modules/assignment/objects/LinkedAssignment";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { AddressField, ArrayByKeyField, BooleanField, DateField, EnumField, NumberField, StringField } from "@/core/fields";

export class Employee extends FirestoreDocument {
  public branches: Record<string, LinkedBranch> = {};
  public lastName: string | undefined = undefined;
  public firstName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public birthDate: Date | undefined = undefined;
  public age: number | undefined = undefined;
  public birthMonth: number | undefined = undefined;
  public birthPlace: string | undefined = undefined;
  public sex: EmployeeSex = EmployeeSex.Male;
  public fiscalCode: string | undefined = undefined;
  public nationality: string | undefined = "Italiana";
  public residentialAddress: AddressField = new AddressField();
  public onlyResidentialAddress = true;
  public livingAddress: AddressField = new AddressField();
  public locationGeoHash: string | undefined = undefined;
  public phone: string | undefined = undefined;
  public email: string | undefined = undefined;
  public badgeNumber = 0;
  public assignments: Record<string, LinkedAssignment> = {};
  public iban: string | undefined = undefined;
  public notes: string | undefined = undefined;
  public activeBranches: Record<string, ActiveLinkedBranch> = {};
  public trackExpirations = true;
  public archived = false;
  public userId: string | undefined = undefined;

  public oldId: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Employee {
    super.fromFirestore(data, id, firestoreRef);

    this.branches = ArrayByKeyField.fromFirestore<LinkedBranch>(data.branches, (value) => new LinkedBranch(value));
    this.lastName = StringField.fromFirestore(data.lastName);
    this.firstName = StringField.fromFirestore(data.firstName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.birthDate = DateField.fromFirestore(data.birthDate);
    this.age = this.birthDate !== undefined ? differenceInYears(new Date(), this.birthDate) : undefined;
    this.birthMonth = NumberField.fromFirestore(data.birthMonth);
    this.birthPlace = StringField.fromFirestore(data.birthPlace);
    this.sex = EnumField.fromFirestore<EmployeeSex>(data.sex, Object.values(EmployeeSex), EmployeeSex.Male);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.nationality = StringField.fromFirestore(data.nationality);
    this.residentialAddress.fromFirestore(data.residentialAddress);
    this.onlyResidentialAddress = BooleanField.fromFirestore(data.onlyResidentialAddress);
    this.livingAddress.fromFirestore(data.livingAddress);
    this.locationGeoHash = StringField.fromFirestore(data.locationGeoHash);
    this.phone = StringField.fromFirestore(data.phone);
    this.email = StringField.fromFirestore(data.email);
    this.badgeNumber = NumberField.fromFirestore(data.badgeNumber);
    this.assignments = ArrayByKeyField.fromFirestore<LinkedAssignment>(data.assignments, (value) => new LinkedAssignment(value));
    this.iban = StringField.fromFirestore(data.iban);
    this.notes = StringField.fromFirestore(data.notes);
    this.activeBranches = ArrayByKeyField.fromFirestore<ActiveLinkedBranch>(data.activeBranches, (value) => new ActiveLinkedBranch(value));
    this.trackExpirations = BooleanField.fromFirestore(data.trackExpirations);
    this.archived = BooleanField.fromFirestore(data.archived);
    this.userId = StringField.fromFirestore(data.userId);
    this.oldId = StringField.fromFirestore(data.oldId);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.branches = ArrayByKeyField.toFirestore(this.branches, (value) => value.toFirestore());
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.birthDate = DateField.toFirestore(this.birthDate);
    firestoreData.birthMonth = this.birthDate !== undefined ? this.birthDate.getMonth() + 1 : null;
    firestoreData.birthPlace = StringField.toFirestore(this.birthPlace);
    firestoreData.sex = EnumField.toFirestore<EmployeeSex>(this.sex, EmployeeSex.Male);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.nationality = StringField.toFirestore(this.nationality);
    firestoreData.residentialAddress = this.residentialAddress.toFirestore();
    firestoreData.onlyResidentialAddress = BooleanField.toFirestore(this.onlyResidentialAddress);
    firestoreData.livingAddress = this.livingAddress.toFirestore();
    firestoreData.locationGeoHash = StringField.toFirestore(this.locationGeoHash);
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.badgeNumber = NumberField.toFirestore(this.badgeNumber);
    firestoreData.assignments = ArrayByKeyField.toFirestore(this.assignments, (value) => value.toFirestore());
    firestoreData.iban = StringField.toFirestore(this.iban);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.activeBranches = ArrayByKeyField.toFirestore(this.activeBranches, (value) => value.toFirestore());
    firestoreData.trackExpirations = BooleanField.toFirestore(this.trackExpirations);
    firestoreData.archived = BooleanField.toFirestore(this.archived);
    firestoreData.userId = StringField.toFirestore(this.userId);
    firestoreData.oldId = StringField.toFirestore(this.oldId);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.lastName)];
    this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.firstName)];
    if (this.email !== undefined) this.searchKeys.push(this.email.toLowerCase());
    if (this.fiscalCode !== undefined) this.searchKeys.push(this.fiscalCode.toLowerCase());
  }

  public setFullNames(): void {
    if (this.lastName != undefined && this.firstName != undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
    } else {
      this.fullName = undefined;
    }
  }

  public processEmployeePositions(employeePositions: EmployeePosition[]): void {
    this.branches = {};

    for (const employeePosition of employeePositions) {
      if (employeePosition.branch == undefined) continue;

      if (Object.keys(this.branches).includes(employeePosition.branch.id) === false) {
        this.branches[employeePosition.branch.id] = employeePosition.branch;
      }
    }
  }

  public getLinkedBranches(): LinkedBranch[] {
    return DataHelpers.objectToSortedArray<LinkedBranch>(this.branches);
  }

  public setLinkedBranches(linkedBranches: LinkedBranch[]): void {
    this.branches = DataHelpers.sortedArrayToObject<LinkedBranch>(linkedBranches);
  }

  public addLinkedBranch(linkedBranch: LinkedBranch): void {
    this.branches[linkedBranch.id] = linkedBranch;
  }

  public removeLinkedBranch(linkedBranch: LinkedBranch): void {
    delete this.branches[linkedBranch.id];
  }

  public getLinkedAssignments(): LinkedAssignment[] {
    return DataHelpers.objectToSortedArray<LinkedAssignment>(this.assignments, new SortCriteria("name", "asc", "string"));
  }

  public setLinkedAssignments(linkedAssignments: LinkedAssignment[]): void {
    this.assignments = DataHelpers.sortedArrayToObject<LinkedAssignment>(linkedAssignments);
  }

  public addLinkedAssignment(linkedAssignment: LinkedAssignment): void {
    this.assignments[linkedAssignment.id] = linkedAssignment;
  }

  public removeLinkedAssignment(linkedAssignment: LinkedAssignment): void {
    delete this.assignments[linkedAssignment.id];
  }

  public getActiveBranches(): ActiveLinkedBranch[] {
    return DataHelpers.objectToSortedArray<ActiveLinkedBranch>(this.activeBranches, new SortCriteria("to", "asc", "date"));
  }
}
