import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isVatCodeUnique = async (vatCode: string, companyId = "empty"): Promise<boolean> => {
  try {
    if (vatCode === undefined) return false;

    return CloudHelpers.runValidatorFunction("featuresCompanyVatCodeValidator", vatCode, companyId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyModel.isVatCodeUnique", { vatCode, companyId }, error);
    return false;
  }
};
