import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyStockRow } from "../objects/CompanyStockRow";
import { LinkedParcelType } from "@/features/modules/parcelType/objects/LinkedParcelType";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";

export class CompanyStockModel {
  public async calcCompanyStock(companyId: string, date: Date): Promise<CompanyStockRow[]> {
    try {
      if (companyId === undefined) throw new Error("companyIdUndefined");
      if (date === undefined) throw new Error("dateUndefined");

      // find parcels
      const parcels: Parcel[] = await parcelModel.getParcelsByCompanyAndPeriod(companyId, date, date);

      // calc company stock
      const results: CompanyStockRow[] = [];
      for (const parcel of parcels) {
        const parcelType: LinkedParcelType | undefined = parcel.type;
        if (parcelType === undefined) continue;

        let isStored = false;
        for (const action of parcel.actions) {
          action.date.setHours(0, 0, 0, 0);
          if (compareAsc(action.date, date) > 0) break;

          if (!isStored) {
            if (action.action == ParcelActionType.Stored) isStored = true;
          } else {
            if ([ParcelActionType.Loosened, ParcelActionType.Loaded, ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) {
              isStored = false;
            }
          }
        }

        if (isStored === false) continue;

        let found = false;
        for (const result of results) {
          if (result.type !== undefined && result.type.id === parcelType.id && result.detail === parcel.detail) {
            result.quantity += 1;
            found = true;
            break;
          }
        }

        if (!found) {
          const companyStockRow: CompanyStockRow = {
            type: parcelType,
            detail: parcel.detail || "-",
            quantity: 1,
          };
          results.push(companyStockRow);
        }
      }

      return results;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyStockModel.calcCompanyStock", { companyId, date }, error);
      return [];
    }
  }
}

export const companyStockModel: CompanyStockModel = new CompanyStockModel();
