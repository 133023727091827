export const it = {
  employeeCourse: {
    certifier: "Ente certificatore",
    duration: "Durata",
    durationError: "Selezionare una durata valida",
    employeeCourse: "Corso",
    employeeCourseOf: "Corso di {parent}",
    employeeCourses: "Corsi",
    employeeCoursesOf: "Corsi di {parent}",
    endDate: "Data fine",
    endDateError: "Inserire una data valida",
    expiration: "Data scadenza",
    expirationError: "Inserire una data valida",
    expiredEmployeeCourses: "Corsi operatori scaduti",
    expiringEmployeeCourses: "Corsi operatori in scadenza",
    hours: "ore",
    isMandatory: "Obbligatorio",
    missingEmployeeCourses: "Corsi operatori mancanti",
    notes: "Note",
    preview: "Anteprima",
    registrationDate: "Data registrazione",
    registrationDateError: "Inserire una data valida",
    result: "Esito",
    resultError: "Selezionare un esito valido",
    results: {
      failed: "Non superato",
      passedTheory: "Superato teoria",
      passedTheoryAndPractice: "Superato teoria e pratica",
    },
    startDate: "Data inizio",
    startDateError: "Inserire una data valida",
    type: "Tipo corso",
    typeError: "Selezionare un tipo corso valido",
  },
  buttons: {
    goToEmployeeCourses: "Vai ai corsi dell'operatore",
  },
};
