import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { BooleanField, NumberField, StringField, StringStrictField } from "@/core/fields";

export class InvoiceItem {
  public id: string = DataHelpers.uniqueId();
  public priceListItemId: string = DataHelpers.uniqueId();
  public name: string | undefined = undefined;
  public price = 0;
  public quantity = 0;
  public total = 0;
  public isConfirmed = false;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): InvoiceItem {
    this.id = DataHelpers.uniqueId();

    this.priceListItemId = StringStrictField.fromFirestore(data.priceListItemId, DataHelpers.uniqueId());
    this.name = StringField.fromFirestore(data.name);
    this.price = NumberField.fromFirestore(data.price);
    this.quantity = NumberField.fromFirestore(data.quantity);
    this.total = NumberField.fromFirestore(data.total);
    this.isConfirmed = BooleanField.fromFirestore(data.isConfirmed);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.priceListItemId = StringStrictField.toFirestore(this.priceListItemId);
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.price = NumberField.toFirestore(this.price);
    firestoreData.quantity = NumberField.toFirestore(this.quantity);
    firestoreData.total = NumberField.toFirestore(this.total);
    firestoreData.isConfirmed = BooleanField.toFirestore(this.isConfirmed);

    return firestoreData;
  }
}
