import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isEmailUnique = async (email: string, employeeId = "empty", employeeUserId = "empty"): Promise<boolean> => {
  try {
    if (email === undefined) return false;

    const employeeResult: boolean = await CloudHelpers.runValidatorFunction("featuresEmployeeEmailValidator", email, employeeId);
    const userResult: boolean = await CloudHelpers.runValidatorFunction("coreUserEmailValidator", email, employeeUserId);

    return employeeResult && userResult;
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.isEmailUnique", { email, employeeId, employeeUserId }, error);
    return false;
  }
};
