export const it = {
  employeeContract: {
    contractType: "Tipo",
    contractTypeError: "Selezionare un tipo di contratto",
    contractTypes: {
      extension: "Proroga",
      fixedTerm: "Determinato",
      levelUp: "Passaggio di livello",
      permanent: "Indeterminato",
      seasonal: "Stagionale",
      transformation: "Trasformazione",
    },
    details: "Dettagli",
    earlyClosingDate: "Data di chiusura anticipata",
    earlyClosingDateError: "Selezionare una data di chiusura anticipata valida",
    earlyClosingReason: "Motivo di chiusura anticipata",
    earlyClosingReasonError: "Selezionare un motivo di chiusura anticipata",
    earlyClosingReasons: {
      dismissalByConsensualAgreement: "Licenziamento con accordo consensuale",
      dismissalForFailureToPassProbationaryPeriod: "Licenziamento per mancato superamento del periodo di prova",
      dismissalForJustCause: "Licenziamento per giusta causa",
      resignationDuringProbationaryPeriod: "Dimissioni durante periodo di prova",
      voluntaryResignation: "Dimissioni volontarie",
    },
    employerType: "Assunzione",
    employerTypeError: "Selezionare una assunzione",
    employerTypes: {
      direct: "Diretta",
      interim: "Interinale",
    },
    employeeContract: "Contratto",
    employeeContractOf: "Contratto di {parent}",
    employeeContracts: "Contratti",
    employeeContractsOf: "Contratti di {parent}",
    expiredEmployeeContracts: "Contratti operatori scaduti",
    expiringEmployeeContracts: "Contratti operatori in scadenza",
    from: "Inizio",
    fromError: "Selezionare una data di inizio valida",
    isEarlyClosed: "Chiusura anticipata",
    level: "Livello",
    levelError: "Selezionare un livello",
    missingEmployeeContracts: "Contratti operatori mancanti",
    notes: "Note",
    preview: "Anteprima",
    to: "Fine",
    toError: "Selezionare una data di fine valida",
    workingHour: "Orario",
    workingHourError: "Selezionare un orario",
    workingHours: {
      fullTime: "Full-time",
      partTime: "Part-time",
    },
  },
  buttons: {
    goToEmployeeContracts: "Vai ai contratti dell'operatore",
  },
};
