import { RouteRecordRaw } from "vue-router";

export const invoiceRoutes: Array<RouteRecordRaw> = [
  {
    path: "/invoices",
    name: "InvoiceList",
    component: () => import("../views/InvoiceList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/invoices/:id/edit",
    name: "InvoiceEdit",
    component: () => import("../views/InvoiceEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/invoices/:id",
    name: "InvoiceView",
    component: () => import("../views/InvoiceView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "viewUI",
    },
  },
];
