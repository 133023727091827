import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedVehicleType } from "@/features/modules/vehicleType/objects/LinkedVehicleType";
import { VehiclePosition } from "@/features/modules/vehiclePosition/objects/VehiclePosition";

import { ArrayByKeyField, BooleanField, ObjectField, StringField } from "@/core/fields";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

export class Vehicle extends FirestoreDocument {
  public branches: Record<string, LinkedBranch> = {};
  public registrationNumber: string | undefined = undefined;
  public type: LinkedVehicleType | undefined = undefined;
  public model: string | undefined = undefined;
  public notes: string | undefined = undefined;
  public archived = false;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Vehicle {
    super.fromFirestore(data, id, firestoreRef);

    this.branches = ArrayByKeyField.fromFirestore<LinkedBranch>(data.branches, (value) => new LinkedBranch(value));
    this.registrationNumber = StringField.fromFirestore(data.registrationNumber);
    this.type = ObjectField.fromFirestore<LinkedVehicleType>(data.type, (value) => new LinkedVehicleType(value));
    this.model = StringField.fromFirestore(data.model);
    this.notes = StringField.fromFirestore(data.notes);
    this.archived = BooleanField.fromFirestore(data.archived);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.branches = ArrayByKeyField.toFirestore(this.branches, (value) => value.toFirestore());
    firestoreData.registrationNumber = StringField.toFirestore(this.registrationNumber);
    firestoreData.type = ObjectField.toFirestore<LinkedVehicleType>(this.type, (value) => value.toFirestore());
    firestoreData.model = StringField.toFirestore(this.model);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.archived = BooleanField.toFirestore(this.archived);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.registrationNumber !== undefined) this.searchKeys.push(this.registrationNumber.toLowerCase());
  }

  public processVehiclePositions(vehiclePositions: VehiclePosition[]): void {
    this.branches = {};

    for (const vehiclePosition of vehiclePositions) {
      if (vehiclePosition.branch == undefined) continue;

      if (Object.keys(this.branches).includes(vehiclePosition.branch.id) === false) {
        this.branches[vehiclePosition.branch.id] = vehiclePosition.branch;
      }
    }
  }

  public getLinkedBranches(): LinkedBranch[] {
    return DataHelpers.objectToSortedArray<LinkedBranch>(this.branches);
  }

  public setLinkedBranches(linkedBranches: LinkedBranch[]): void {
    this.branches = DataHelpers.sortedArrayToObject<LinkedBranch>(linkedBranches);
  }

  public addLinkedBranch(linkedBranch: LinkedBranch): void {
    this.branches[linkedBranch.id] = linkedBranch;
  }

  public removeLinkedBranch(linkedBranch: LinkedBranch): void {
    delete this.branches[linkedBranch.id];
  }
}
