import { RouteRecordRaw } from "vue-router";

export const expenseTypeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/expense-types/:id/edit",
    name: "ExpenseTypeEdit",
    component: () => import("../views/ExpenseTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "expenseType",
      requiredRight: "viewUI",
    },
  },
];
