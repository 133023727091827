export const it = {
  company: {
    activities: "Attività svolte",
    addReferent: "Aggiungi referente",
    address: "Indirizzo",
    addressFields: {
      country: "Paese",
      province: "Provincia",
      street: "Indirizzo",
      town: "Comune",
      zipCode: "CAP",
    },
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserisci un indirizzo e-mail valido",
    company: "Azienda",
    companies: "Aziende",
    createFromVatCode: "Crea Azienda da partita IVA",
    docFolder: "Fascicolo",
    email: "Indirizzo e-mail",
    emailError: "Indirizzo e-mail non valido o già presente",
    fax: "Fax",
    filters: {
      archived: "Aziende archiviate",
      archivedExcluded: "Archiviate escluse",
      archivedIncluded: "Archiviate incluse",
    },
    fiscalCode: "Codice fiscale",
    fiscalData: "Dati fiscali",
    general: "Generali",
    info: "Informazioni",
    invoiceEmail: "E-mail fatturazione",
    invoiceEmailError: "Inserisci un indirizzo e-mail valido",
    mobile: "Cellulare",
    name: "Ragione sociale",
    nameError: "Inserisci la ragione sociale",
    notes: "Note",
    phone: "Telefono",
    position: "Posizione",
    positionError: "Inserire una posizione valida",
    priceList: "Listino prezzi",
    province: "Provincia",
    recipientCode: "Codice destinatario",
    referentName: "Nome",
    referentNameError: "Inserire un nome valido",
    referents: "Referenti",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attiva",
      archived: "Archiviata",
    },
    town: "Comune",
    userId: "Utente",
    vatCode: "Partita IVA",
    vatCodeEmpty: "Inserisci la partita IVA",
    vatCodeError: "La partita IVA inserita è già presente",
    vatCodePlaceholder: "Inserisci una partita IVA",
    zipCode: "CAP",
  },
  navigation: {
    company: "Aziende",
  },
  buttons: {
    archiveCompany: "Metti in archivio",
    restoreCompany: "Togli dall'archivio",
  },
};
