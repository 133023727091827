import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedTransactionType } from "@/features/modules/transactionType/objects/LinkedTransactionType";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { TransactionDirection } from "@/features/modules/transactionType/objects/TransactionDirection";

import { ArrayField, DateField, EnumField, NumberField, ObjectField, StringField } from "@/core/fields";

export class EmployeeTransaction extends FirestoreDocument {
  public date: Date | undefined = new Date();
  public title: string | undefined = undefined;
  public type: LinkedTransactionType | undefined = undefined;
  public direction: TransactionDirection = TransactionDirection.Credit;
  public amount = 0;
  public files: StorageFile[] = [];
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): EmployeeTransaction {
    super.fromFirestore(data, id, firestoreRef);

    this.date = DateField.fromFirestore(data.date);
    this.title = StringField.fromFirestore(data.title);
    this.type = ObjectField.fromFirestore<LinkedTransactionType>(data.type, (value) => new LinkedTransactionType(value));
    this.direction = EnumField.fromFirestore<TransactionDirection>(data.direction, Object.values(TransactionDirection), TransactionDirection.Credit);
    this.amount = NumberField.fromFirestore(data.amount);
    this.files = ArrayField.fromFirestore<StorageFile>(data.files, (value) => new StorageFile(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.title = StringField.toFirestore(this.title);
    firestoreData.type = ObjectField.toFirestore<LinkedTransactionType>(this.type, (value) => value.toFirestore());
    firestoreData.direction = EnumField.toFirestore<TransactionDirection>(this.direction, TransactionDirection.Credit);
    firestoreData.amount = NumberField.toFirestore(this.amount);
    firestoreData.files = ArrayField.toFirestore<StorageFile>(this.files, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }
}
