import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { dpiHandlingModel } from "../DpiHandlingModel";
import { SourceType } from "@/features/modules/dpiHandling/objects/SourceType";
import { TargetType } from "@/features/modules/dpiHandling/objects/TargetType";

export const getDpiHandlingsByBranch = async (branchId: string): Promise<DpiHandling[]> => {
  try {
    if (branchId === undefined) throw new Error("branchId is undefined");

    let sourceDocuments: DpiHandling[] = [];
    let targetDocuments: DpiHandling[] = [];

    const sourceSnapshotQuery: Query<DpiHandling> = query(
      dpiHandlingModel.getPathReference().withConverter(dpiHandlingModel.firestoreConverter),
      where("sourceType", "==", SourceType.Branch),
      where("sourceBranch.id", "==", branchId)
    );

    const sourceSnapshot: QuerySnapshot<DpiHandling> = await getDocs(dpiHandlingModel.addReadConditionToQuery(sourceSnapshotQuery));
    if (sourceSnapshot == undefined || sourceSnapshot.empty) {
      sourceDocuments = [];
    } else {
      sourceDocuments = sourceSnapshot.docs.map((doc) => doc.data());
    }

    const targetSnapshotQuery: Query<DpiHandling> = query(
      dpiHandlingModel.getPathReference().withConverter(dpiHandlingModel.firestoreConverter),
      where("targetType", "==", TargetType.Branch),
      where("targetBranch.id", "==", branchId)
    );

    const targetSnapshot: QuerySnapshot<DpiHandling> = await getDocs(dpiHandlingModel.addReadConditionToQuery(targetSnapshotQuery));
    if (targetSnapshot == undefined || targetSnapshot.empty) {
      targetDocuments = [];
    } else {
      targetDocuments = targetSnapshot.docs.map((doc) => doc.data());
    }

    return [...sourceDocuments, ...targetDocuments];
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.getDpiHandlingsByBranch", { branchId }, error);
    return [];
  }
};
