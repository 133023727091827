import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { EmployeePosition } from "../objects/EmployeePosition";
import { employeePositionModel } from "../models/EmployeePositionModel";

export class EmployeePositionHelpers {
  public static getFolderPath(employeeId: string | undefined): string {
    try {
      if (employeeId === undefined) throw new Error("employeeId is undefined");
      return `employees/${employeeId}/positions/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeePositionHelpers.getFolderPath", { employeeId }, error);
      throw error;
    }
  }

  public static async processEmployeePositionsForEmployee(employeeId: string): Promise<void> {
    try {
      const employee: Employee = await employeeModel.getDocument(employeeId);
      const employeePositions: EmployeePosition[] = await employeePositionModel.getDocuments([], employeeId);

      employee.processEmployeePositions(employeePositions);

      return employeeModel.updateDocument(employee);
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeePositionHelpers.processEmployeePositionsForEmployee", { employeeId }, error);
    }
  }
}
