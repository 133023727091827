import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { BranchDpi } from "@/features/modules/branchDpi/objects/BranchDpi";
import { branchDpiModel } from "@/features/modules/branchDpi/models/BranchDpiModel";
import { DpiHandling } from "@/features/modules/dpiHandling/objects/DpiHandling";
import { DpiHandlingHelpers } from "@/features/modules/dpiHandling/helpers/DpiHandlingHelpers";
import { EmployeeDpi } from "@/features/modules/employeeDpi/objects/EmployeeDpi";
import { employeeDpiModel } from "@/features/modules/employeeDpi/models/EmployeeDpiModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";

export const deliverDpisFromBranchToEmployee = async (dpiHandling: DpiHandling): Promise<string> => {
  try {
    // check if dpis are available
    if (dpiHandling.sourceBranch === undefined) throw new Error("sourceBranch is undefined");
    const result: string = await DpiHandlingHelpers.checkDpisAvailabilityInBranch(dpiHandling, dpiHandling.sourceBranch.id);
    if (result !== "OK") return result;

    await FirestoreModel.runTransaction(async () => {
      if (dpiHandling.sourceBranch === undefined) throw new Error("sourceBranch is undefined");

      for (const handledDpi of dpiHandling.getHandledDpis()) {
        if (dpiHandling.targetEmployee === undefined) throw new Error("targetEmployee is undefined");
        if (handledDpi.dpi === undefined) throw new Error("handledDpi dpi is undefined");
        if (handledDpi.size === undefined) throw new Error("handledDpi size is undefined");

        // check if branch dpi is available and if quantity is enough
        const branchDpi: BranchDpi | undefined = await branchDpiModel.getBranchDpiByDpiAndSize(
          handledDpi.dpi.id,
          handledDpi.size,
          dpiHandling.sourceBranch.id
        );
        if (branchDpi === undefined) throw new Error(`Dpi #${handledDpi.dpi.id} with size ${handledDpi.size} not available`);
        if (branchDpi.quantity < handledDpi.quantity) {
          throw new Error(
            `Dpi #${handledDpi.dpi.id} with size ${handledDpi.size} not available (requested quantity: ${handledDpi.quantity}, available quantity: ${branchDpi.quantity})`
          );
        }

        // update the branch dpi quantity or delete it if quantity is 0
        if (branchDpi.firestoreRef === undefined) throw new Error("branchDpi firestoreRef is undefined");
        if (branchDpi.quantity - handledDpi.quantity > 0) {
          batch.update(branchDpi.firestoreRef, { quantity: branchDpi.quantity - handledDpi.quantity });
        } else {
          batch.delete(branchDpi.firestoreRef);
        }

        // check if employee dpi already exists and update the quantity or create a new one
        const employeeDpi: EmployeeDpi | undefined = await employeeDpiModel.getEmployeeDpiByDpiAndSize(
          handledDpi.dpi.id,
          handledDpi.size,
          dpiHandling.targetEmployee.id
        );
        if (employeeDpi === undefined) {
          const newEmployeeDpi: EmployeeDpi = new EmployeeDpi();
          newEmployeeDpi.dpi = handledDpi.dpi;
          newEmployeeDpi.size = handledDpi.size;
          newEmployeeDpi.quantity = handledDpi.quantity;
          batch.set(employeeDpiModel.getDocumentReference(undefined, dpiHandling.targetEmployee.id), newEmployeeDpi.toFirestore());
        } else {
          if (employeeDpi.firestoreRef === undefined) throw new Error("employeeDpi firestoreRef is undefined");
          batch.update(employeeDpi.firestoreRef, { quantity: employeeDpi.quantity + handledDpi.quantity });
        }
      }
      await batch.commit();
    });

    return "OK";
  } catch (error: unknown) {
    appFaultModel.catchAppError("DpiHandlingModel.deliverDpisFromBranchToEmployee", { dpiHandling }, error);
    return "ERROR";
  }
};
