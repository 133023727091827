import { RouteRecordRaw } from "vue-router";

export const aedPurchaseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/aed-purchases",
    name: "AedPurchaseList",
    component: () => import("../views/AedPurchaseList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedPurchase",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-purchases/:id/edit",
    name: "AedPurchaseEdit",
    component: () => import("../views/AedPurchaseEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedPurchase",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/aed-purchases/:id",
    name: "AedPurchaseView",
    component: () => import("../views/AedPurchaseView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "aedPurchase",
      requiredRight: "viewUI",
    },
  },
];
