export const it = {
  storedDpi: {
    dpi: "DPI",
    dpiError: "Selezionare un DPI",
    quantity: "Quantità",
    quantityError: "Inserire una quantità maggiore di zero",
    size: "Taglia",
    sizeError: "Selezionare una taglia valida",
    storedDpi: "DPI in magazzino",
    storedDpis: "DPI in magazzino",
  },
  navigation: {
    storedDpi: "DPI in magazzino",
  },
  buttons: {
    storeDpis: "Immagazzina DPI",
  },
};
