export const it = {
  branchPosition: {
    beforeHolidayPercentIncrease: "Maggiorazione prefestivo",
    beforeHolidayPercentIncreaseError: "Inserire una maggiorazione valida",
    beforeHolidayPercentIncreaseShort: "Prefestivo",
    branchPosition: "Ruolo",
    branchPositionOf: "Ruolo di {parent}",
    branchPositions: "Ruoli",
    branchPositionsOf: "Ruoli di {parent}",
    holidayPercentIncrease: "Maggiorazione festivo",
    holidayPercentIncreaseError: "Inserire una maggiorazione valida",
    holidayPercentIncreaseShort: "Festivo",
    hourlyCost: "Costo orario",
    hourlyCostError: "Inserire un costo orario valido",
    name: "Nome",
    nameError: "Inserire un nome valido",
    nightPercentIncrease: "Maggiorazione notturno",
    nightPercentIncreaseError: "Inserire una maggiorazione valida",
    nightPercentIncreaseShort: "Notturno",
    overtimePercentIncrease: "Maggiorazione straordinario",
    overtimePercentIncreaseError: "Inserire una maggiorazione valida",
    overtimePercentIncreaseShort: "Straordinario",
  },
};
