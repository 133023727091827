export class EmployeeDayReport {
  public date: Date;
  public label: string | undefined;
  public value: string | undefined;

  public constructor(date: Date) {
    this.date = date;
    this.label = undefined;
    this.value = undefined;
  }
}
