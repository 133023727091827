import { Branch } from "../objects/Branch";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getActiveBranches, getBranchesByCompany } from "./methods";

export class BranchModel extends FirestoreModel<Branch> {
  public constructor() {
    super(() => new Branch(), "branches", LockPolicy.DiscardUnsyncedChanges, "branch");
    this.beforeDeleteFunction = "featuresBranchBeforeDelete";
  }

  public async getDocuments(): Promise<Branch[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(branchId: string): Promise<Branch> {
    return super.getDocument(branchId);
  }

  public async createDocument(branch: Branch): Promise<string> {
    return super.createDocument(branch);
  }

  public async updateDocument(branch: Branch): Promise<void> {
    return super.updateDocument(branch);
  }

  public async deleteDocument(branch: Branch): Promise<boolean> {
    return super.deleteDocument(branch);
  }

  /* custom methods */

  public async getActiveBranches(): Promise<Branch[]> {
    return getActiveBranches();
  }

  public async getBranchesByCompany(companyId: string): Promise<Branch[]> {
    return getBranchesByCompany(companyId);
  }
}

export const branchModel: BranchModel = new BranchModel();
