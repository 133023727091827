export const it = {
  warehouseLocation: {
    capacity: "Capienza",
    capacityParcel: "UDC | UDC",
    capacityUnlimited: "Illimitata",
    code: "Codice",
    codeError: "Inserire un codice valido",
    content: "Contenuto",
    details: "Dettagli",
    empty: "Libero",
    floor: "Piano",
    floorError: "Selezionare un piano",
    general: "Generale",
    init: "Inizializza",
    lane: "Corsia",
    laneError: "Selezionare una corsia",
    parcelsContained: "{parcels} UDC | {parcels} UDC",
    parcels: "UDC",
    position: "Posizione",
    positionError: "Selezionare una posizione",
    shipmentDetail: "Spedizione {code} del {date} da {companyName}",
    span: "Campata",
    spanError: "Selezionare una campata",
    state: "Stato",
    stockParcels: "UDC immagazzinate",
    type: "Tipo",
    typeError: "Inserire un tipo valido",
    types: {
      shelves: "Scaffali",
      zones: "Zone",
    },
    unknownFormat: "Formato sconosciuto",
    warehouse: "Magazzino",
    warehouseError: "Selezionare un magazzino",
    warehouseLocation: "Posizione magazzino",
    warehouseLocations: "Posizioni magazzino",
    zone: "Zona",
    zoneError: "Selezionare una zona",
  },
  navigation: {
    warehouseLocation: "Posizioni magazzino",
  },
};
