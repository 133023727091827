import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "../BranchModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";

export const getBranchesByCompany = async (companyId: string): Promise<Branch[]> => {
  try {
    const snapshotQuery: Query<Branch> = query(
      branchModel.getPathReference().withConverter(branchModel.firestoreConverter),
      where("company.id", "==", companyId)
    );

    const snapshot: QuerySnapshot<Branch> = await getDocs(branchModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("BranchModel.getBranchesByCompany", { companyId }, error);
    return [];
  }
};

function sortDocuments(documents: Branch[]): Branch[] {
  const sorter: FirestoreSorter<Branch> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
