import { branchSearch } from "@/features/modules/branch/search/branchSearch";
import { companySearch } from "@/features/modules/company/search/companySearch";
import { docSearch } from "@/features/modules/doc/search/docSearch";
import { employeeSearch } from "@/features/modules/employee/search/employeeSearch";
import { inboundShipmentSearch } from "@/features/modules/inboundShipment/search/inboundShipmentSearch";
import { outboundShipmentSearch } from "@/features/modules/outboundShipment/search/outboundShipmentSearch";
import { parcelSearch } from "@/features/modules/parcel/search/parcelSearch";
import { vehicleSearch } from "@/features/modules/vehicle/search/vehicleSearch";
import { warehouseLocationSearch } from "@/features/modules/warehouseLocation/search/warehouseLocationSearch";

export const featuresSearchModules = [
  branchSearch,
  companySearch,
  docSearch,
  employeeSearch,
  inboundShipmentSearch,
  outboundShipmentSearch,
  parcelSearch,
  vehicleSearch,
  warehouseLocationSearch,
];
