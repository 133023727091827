import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";

export class PaperHelpers {
  public static async getAedCustomDocumentHeader(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    reference?: string
  ): Promise<() => Record<string, unknown>> {
    const logoData: string | undefined = await StorageHelpers.blobToBase64(
      await StorageHelpers.fetchAsBlob("/assets/features/images/paper-logo-aed.jpg")
    );
    if (logoData === undefined) throw new Error("Unable to read logo");

    const columns: Record<string, unknown>[] = [
      {
        stack: [
          { text: "", style: "headerText" },
          { text: "Ruffini S.a.s.", style: "headerText", bold: true },
          { text: "Via Enrico Berlinguer, 6 - 44027 Migliarino - Fiscaglia (FE)", style: "headerText" },
          { text: "P.IVA 01826560383 - C.F. 01826560383", style: "headerText" },
          { text: "E-mail: vendite@defibrillatori.info", style: "headerText" },
        ],
        width: "*",
      },
      {
        fit: [DataHelpers.mmToPoints(30), DataHelpers.mmToPoints(30)],
        height: DataHelpers.mmToPoints(30),
        image: `data:image/png;base64,${logoData}`,
        width: DataHelpers.mmToPoints(40),
      },
    ];

    if (reference !== undefined) {
      const referenceTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          { text: reference, style: "referenceText", border: [true, true, true, true] },
        ],
      ];

      columns.push({
        table: { body: referenceTableBody, headerRows: 0, widths: ["*", "auto"] },
        layout: { hLineColor: () => "black", hLineWidth: () => 0.5 },
        width: DataHelpers.mmToPoints(30),
      });
    }

    return () => {
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(15), 0],
        columns: columns,
      };
    };
  }

  public static getCustomDocumentFooter(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    leftText?: string,
    rightText?: string
  ): (currentPage: number, pageCount: number) => Record<string, unknown> {
    return (currentPage: number, pageCount: number) => {
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(15), 0],
        columns: [
          {
            style: "footerTitle",
            text: leftText ?? "",
            width: DataHelpers.mmToPoints(120),
          },
          {
            alignment: "right",
            style: "footerTitle",
            text:
              rightText !== undefined
                ? `${rightText} - ${t("paper.pagination", { current: currentPage, count: pageCount })}`
                : t("paper.pagination", { current: currentPage, count: pageCount }),
            width: "*",
          },
        ],
      };
    };
  }

  public static async getCustomDocumentHeader(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    reference?: string
  ): Promise<() => Record<string, unknown>> {
    const logoData: string | undefined = await StorageHelpers.blobToBase64(
      await StorageHelpers.fetchAsBlob("/assets/features/images/paper-logo.jpg")
    );
    if (logoData === undefined) throw new Error("Unable to read logo");

    const columns: Record<string, unknown>[] = [
      {
        fit: [DataHelpers.mmToPoints(30), DataHelpers.mmToPoints(30)],
        height: DataHelpers.mmToPoints(30),
        image: `data:image/png;base64,${logoData}`,
        width: DataHelpers.mmToPoints(40),
      },
      {
        stack: [
          { text: "M3 COOP SERVIZI a.r.l.", style: "headerText", bold: true },
          { text: "Piazza del Teatro, 4 - 44027 Migliarino - Fiscaglia (FE)", style: "headerText" },
          { text: "P.IVA 01536490384 - C.F. 01536490384", style: "headerText" },
          { text: "Tel: +39 05 33 52 796 - email: info@coopm3.it", style: "headerText" },
        ],
        width: "*",
      },
    ];

    if (reference !== undefined) {
      const referenceTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          { text: reference, style: "referenceText", border: [true, true, true, true] },
        ],
      ];

      columns.push({
        table: { body: referenceTableBody, headerRows: 0, widths: ["*", "auto"] },
        layout: { hLineColor: () => "black", hLineWidth: () => 0.5 },
        width: DataHelpers.mmToPoints(30),
      });
    }

    return () => {
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(15), 0],
        columns: columns,
      };
    };
  }
}
