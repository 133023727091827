
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { WarehouseLocation } from "../modules/warehouseLocation/objects/WarehouseLocation";
import { warehouseLocationModel } from "../modules/warehouseLocation/models/WarehouseLocationModel";

export default defineComponent({
  name: "KoruWarehouseLocation",
  setup() {
    let eventBusId: string | undefined = undefined;
    const { t } = useLocale();

    const isDialogVisible: Ref<boolean> = ref(false);
    const warehouseLocationCode: Ref<string> = ref("");
    const warehouseLocationDecoded: Ref<string> = ref("");

    onMounted(() => {
      eventBusId = eventBus.on("showWarehouseLocationDetail", async (code?: string): Promise<void> => {
        if (code === undefined) return Promise.resolve();

        const warehouseLocation: WarehouseLocation | undefined = await warehouseLocationModel.getWarehouseLocationByCode(code);
        if (warehouseLocation === undefined) return Promise.resolve();

        warehouseLocationCode.value = warehouseLocation.code ?? "";
        warehouseLocationDecoded.value = FeaturesHelpers.decodeWarehouseLocationCode(warehouseLocationCode.value, t);
        isDialogVisible.value = true;
      });
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      isDialogVisible,
      warehouseLocationCode,
      warehouseLocationDecoded,
    };
  },
});
