import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { VehicleType } from "./VehicleType";

import { StringField } from "@/core/fields";

export class LinkedVehicleType extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromVehicleType(vehicleType: VehicleType): LinkedVehicleType {
    const linkedVehicleType: LinkedVehicleType = new LinkedVehicleType();
    linkedVehicleType.id = vehicleType.id;
    linkedVehicleType.name = vehicleType.name;

    return linkedVehicleType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedVehicleType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
