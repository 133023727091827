export const it = {
  penaltyType: {
    isSuspendable: "Sospendibile",
    isSuspendableError: "Selezionare un valore valido",
    name: "Nome",
    nameError: "Inserire un nome valido",
    penaltyType: "Tipo di sanzione",
    penaltyTypes: "Tipi di sanzione",
  },
  navigation: {
    penaltyType: "Tipi di sanzione",
  },
};
