import { RouteRecordRaw } from "vue-router";

export const transactionTypeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/transaction-types/:id/edit",
    name: "TransactionTypeEdit",
    component: () => import("../views/TransactionTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "transactionType",
      requiredRight: "viewUI",
    },
  },
];
