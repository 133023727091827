import { addDays, addHours, compareAsc, getMinutes, setMinutes } from "date-fns";

import { ClockingSide } from "./ClockingSide";
import { EmployeeRosterHelpers } from "../helpers/EmployeeRosterHelpers";
import { Shift } from "./Shift";
import { setting } from "@/core/modules/setting/Setting";

import { ArrayByKeyField, NumberField, StringArrayField } from "@/core/fields";

export class ShiftCounter {
  public branchHours: Record<string, number> = {};
  public dayHours = 0;
  public nightHours = 0;
  public overtimeHours = 0;
  public positionHours: Record<string, Record<string, number>> = {};
  public totalHours = 0;
  public warnings: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: unknown): void {
    if (data === undefined || data === null) return;

    const dataObject: Record<string, unknown> = data as Record<string, unknown>;

    this.branchHours = ArrayByKeyField.fromFirestore<number>(dataObject.branchHours, (value) => NumberField.fromFirestore(value));
    this.dayHours = NumberField.fromFirestore(dataObject.dayHours);
    this.nightHours = NumberField.fromFirestore(dataObject.nightHours);
    this.overtimeHours = NumberField.fromFirestore(dataObject.overtimeHours);
    this.positionHours = ArrayByKeyField.fromFirestore<Record<string, number>>(dataObject.positionHours, (value) =>
      ArrayByKeyField.fromFirestore<number>(value, (subValue) => NumberField.fromFirestore(subValue))
    );
    this.totalHours = NumberField.fromFirestore(dataObject.totalHours);
    this.warnings = StringArrayField.fromFirestore(dataObject.warnings);
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.branchHours = this.branchHours ?? {};
    firestoreData.dayHours = NumberField.toFirestore(this.dayHours);
    firestoreData.nightHours = NumberField.toFirestore(this.nightHours);
    firestoreData.overtimeHours = NumberField.toFirestore(this.overtimeHours);
    firestoreData.positionHours = this.positionHours ?? {};
    firestoreData.totalHours = NumberField.toFirestore(this.totalHours);
    firestoreData.warnings = StringArrayField.toFirestore(this.warnings);

    return firestoreData;
  }

  public calculateCounters(shift: Shift, date: Date, onlyBranchId?: string): void {
    const nightStartHour = setting.getSetting<number>("shiftNightStartHour");
    const nightEndHour = setting.getSetting<number>("shiftNightEndHour");
    const regularHours = setting.getSetting<number>("shiftRegularHours");

    const nightStartTimestamp: Date = new Date(date.getTime());
    nightStartTimestamp.setHours(nightStartHour, 0, 0, 0);
    const nightEndTimestamp: Date = addDays(new Date(date.getTime()), 1);
    nightEndTimestamp.setHours(nightEndHour, 0, 0, 0);

    this.branchHours = {};
    this.dayHours = 0;
    this.nightHours = 0;
    this.overtimeHours = 0;
    this.positionHours = {};
    this.totalHours = 0;
    this.warnings = [];

    let startTimestamp: Date | undefined;
    let startBranchId: string | undefined;
    let startPositionId: string | undefined;
    let endTimestamp: Date | undefined;
    let lastSide: string | undefined = undefined;

    if (shift.leave !== undefined && shift.getClockings().length > 0) {
      this.warnings.push("leaveWithClockings");
      return;
    }
    if (shift.warning !== undefined && shift.getClockings().length > 0) {
      this.warnings.push("warningWithClockings");
      return;
    }

    for (const clocking of shift.getClockings()) {
      lastSide = clocking.side;
      const clockingTimestampExact: Date = new Date(clocking.timestamp.getTime());
      const clockingTimestampRounded: Date = this.roundToHalfHour(clockingTimestampExact, clocking.side);
      if (clocking.side === ClockingSide.In) {
        // if side is in and there is already a startTimestamp, then the clockings are in the wrong order
        if (startTimestamp !== undefined) {
          if (this.warnings.includes("incorrectClockingOrder") === false) this.warnings.push("incorrectClockingOrder");
          break;
        }
        // if side is in and there is already an endTimestamp that is after, then the clockings are in the wrong order
        if (endTimestamp !== undefined && compareAsc(endTimestamp, clockingTimestampExact) > 0) {
          if (this.warnings.includes("incorrectClockingOrder") === false) this.warnings.push("incorrectClockingOrder");
          break;
        }
        // if clocking is after nightEndTimestamp, then it is too late to enter
        if (compareAsc(clockingTimestampExact, nightEndTimestamp) > 0) {
          if (this.warnings.includes("incorrectClockingOrder") === false) this.warnings.push("incorrectClockingOrder");
          break;
        }
        // clocking with no branch
        if (clocking.branchId === undefined) {
          if (this.warnings.includes("clockingWithoutBranch") === false) this.warnings.push("clockingWithoutBranch");
          break;
        }
        // clocking with no position
        if (clocking.positionId === undefined) {
          if (this.warnings.includes("clockingWithoutPosition") === false) this.warnings.push("clockingWithoutPosition");
          break;
        }

        startTimestamp = clockingTimestampRounded;
        startTimestamp.setSeconds(0, 0);
        startBranchId = clocking.branchId;
        startPositionId = clocking.positionId;
      } else {
        // if side is out and startTimestamp is undefined or startTimestamp is after the out clocking,
        // then the clockings are in the wrong order
        if (startTimestamp === undefined || compareAsc(startTimestamp, clockingTimestampExact) > 0) {
          if (this.warnings.includes("incorrectClockingOrder") === false) this.warnings.push("incorrectClockingOrder");
          break;
        }
        endTimestamp = clockingTimestampRounded;
        endTimestamp.setSeconds(0, 0);

        // calculate hours
        if (onlyBranchId === undefined || onlyBranchId === startBranchId) {
          if (compareAsc(startTimestamp, nightStartTimestamp) < 0) {
            if (compareAsc(endTimestamp, nightStartTimestamp) <= 0) {
              // shift is completely before night
              const clockingHours: number = EmployeeRosterHelpers.calcClockingHours(startTimestamp, endTimestamp);
              this.dayHours += clockingHours;
              this.totalHours += clockingHours;
              this.addBranchHours(startBranchId, clockingHours);
              this.addPositionHours(startBranchId, startPositionId, clockingHours);
            } else {
              if (compareAsc(endTimestamp, nightEndTimestamp) <= 0) {
                // shift start before night start and end during night
                const dayClockingHours: number = EmployeeRosterHelpers.calcClockingHours(startTimestamp, nightStartTimestamp);
                this.dayHours += dayClockingHours;
                this.totalHours += dayClockingHours;
                this.addBranchHours(startBranchId, dayClockingHours);
                this.addPositionHours(startBranchId, startPositionId, dayClockingHours);

                const nightClockingHours: number = EmployeeRosterHelpers.calcClockingHours(nightStartTimestamp, endTimestamp);
                this.nightHours += nightClockingHours;
                this.totalHours += nightClockingHours;
                this.addBranchHours(startBranchId, nightClockingHours);
                this.addPositionHours(startBranchId, startPositionId, nightClockingHours);
              } else {
                // shift start before night start and end after night end
                const dayClockingHours: number = EmployeeRosterHelpers.calcClockingHours(startTimestamp, nightStartTimestamp);
                this.dayHours += dayClockingHours;
                this.totalHours += dayClockingHours;
                this.addBranchHours(startBranchId, dayClockingHours);
                this.addPositionHours(startBranchId, startPositionId, dayClockingHours);

                const nightClockingHours: number = EmployeeRosterHelpers.calcClockingHours(nightStartTimestamp, nightEndTimestamp);
                this.nightHours += nightClockingHours;
                this.totalHours += nightClockingHours;
                this.addBranchHours(startBranchId, nightClockingHours);
                this.addPositionHours(startBranchId, startPositionId, nightClockingHours);

                const afterNightClockingHours: number = EmployeeRosterHelpers.calcClockingHours(nightEndTimestamp, endTimestamp);
                this.dayHours += afterNightClockingHours;
                this.totalHours += afterNightClockingHours;
                this.addBranchHours(startBranchId, afterNightClockingHours);
                this.addPositionHours(startBranchId, startPositionId, afterNightClockingHours);
              }
            }
          } else {
            if (compareAsc(endTimestamp, nightEndTimestamp) <= 0) {
              // shift start during night and end during night
              const clockingHours: number = EmployeeRosterHelpers.calcClockingHours(startTimestamp, endTimestamp);
              this.nightHours += clockingHours;
              this.totalHours += clockingHours;
              this.addBranchHours(startBranchId, clockingHours);
              this.addPositionHours(startBranchId, startPositionId, clockingHours);
            } else {
              // shift start during night and end after night end
              const nightClockingHours: number = EmployeeRosterHelpers.calcClockingHours(startTimestamp, nightEndTimestamp);
              this.nightHours += nightClockingHours;
              this.totalHours += nightClockingHours;
              this.addBranchHours(startBranchId, nightClockingHours);
              this.addPositionHours(startBranchId, startPositionId, nightClockingHours);

              const afterNightClockingHours: number = EmployeeRosterHelpers.calcClockingHours(nightEndTimestamp, endTimestamp);
              this.dayHours += afterNightClockingHours;
              this.totalHours += afterNightClockingHours;
              this.addBranchHours(startBranchId, afterNightClockingHours);
              this.addPositionHours(startBranchId, startPositionId, afterNightClockingHours);
            }
          }
        }
        startTimestamp = undefined;
      }
    }

    if (lastSide !== undefined && lastSide === ClockingSide.In && shift.getClockings().length > 1) {
      if (this.warnings.includes("incorrectClockingOrder") === false) this.warnings.push("incorrectClockingOrder");
    }

    if (this.totalHours > regularHours) {
      this.overtimeHours = this.totalHours - regularHours;
    }

    if (this.totalHours < 0) this.warnings.push("invalidClockings");
    if (shift.getClockings().length > 0 && this.totalHours === 0) {
      if (this.warnings.includes("clockingsWithoutHours") === false) this.warnings.push("clockingsWithoutHours");
    }

    if (this.warnings.length > 0) {
      this.branchHours = {};
      this.dayHours = 0;
      this.nightHours = 0;
      this.overtimeHours = 0;
      this.positionHours = {};
      this.totalHours = 0;
    }
  }

  public addBranchHours(branchId: string | undefined, hours: number): void {
    if (branchId === undefined) return;
    if (this.branchHours[branchId] === undefined) {
      this.branchHours[branchId] = hours;
    } else {
      this.branchHours[branchId] += hours;
    }
  }

  public addPositionHours(branchId: string | undefined, positionId: string | undefined, hours: number): void {
    if (branchId === undefined) return;
    if (positionId === undefined) return;
    if (this.positionHours[branchId] === undefined) {
      this.positionHours[branchId] = { [positionId]: hours };
    } else {
      if (this.positionHours[branchId][positionId] === undefined) {
        this.positionHours[branchId][positionId] = hours;
      } else {
        this.positionHours[branchId][positionId] += hours;
      }
    }
  }

  public roundToHalfHour(timestamp: Date, clockingSide: ClockingSide): Date {
    let roundedTimestamp: Date = new Date(timestamp.getTime());

    const minutes: number = getMinutes(timestamp);
    if (clockingSide === ClockingSide.In) {
      if (minutes > 30) {
        roundedTimestamp = addHours(setMinutes(timestamp, 0), 1);
      } else if (minutes > 0) {
        roundedTimestamp = setMinutes(timestamp, 30);
      } else {
        roundedTimestamp = setMinutes(timestamp, 0);
      }
    } else {
      if (minutes >= 30) {
        roundedTimestamp = setMinutes(timestamp, 30);
      } else if (minutes > 0) {
        roundedTimestamp = setMinutes(timestamp, 0);
      } else {
        roundedTimestamp = setMinutes(timestamp, 0);
      }
    }
    return roundedTimestamp;
  }
}
