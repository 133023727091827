
import { defineComponent, onMounted, Ref, ref } from "vue";

import { subDays } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { handleCodeModel } from "../models/HandleCodeModel";
import { HandleCodePaper } from "../paper/HandleCodePaper";
import { LinkedWarehouseLocation } from "@/features/modules/warehouseLocation/objects/LinkedWarehouseLocation";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { ParcelWithAction } from "../objects/ParcelWithAction";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "HandleCodeView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<ParcelWithAction[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "calc",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId = ref();
    const itemCode = ref();
    const handleCodeCount = ref(0);
    const companies: Ref<Company[]> = ref([]);
    const period: Ref<Date[]> = ref([subDays(new Date(), 30), new Date()]);

    const showResults: Ref<boolean> = ref(false);

    const warehouseLocationValue: Ref<string | undefined> = ref(undefined);
    const warehouseLocationVisible: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value == undefined) throw new Error("companyIdUndefined");
      if (itemCode.value == undefined) throw new Error("itemCodeUndefined");
      if (period.value == undefined || period.value.length != 2) throw new Error("periodUndefined");

      const startDate: Date = period.value[0];
      const endDate: Date = period.value[1];
      if (startDate == undefined || endDate == undefined) throw new Error("periodUndefined");

      results.value = await handleCodeModel.calcHandleCode(companyId.value, itemCode.value, startDate, endDate);

      handleCodeCount.value = 0;
      for (const parcelWithAction of results.value) {
        if (parcelWithAction.action == undefined) continue;
        if (parcelWithAction.action.action == ParcelActionType.Created) handleCodeCount.value += parcelWithAction.quantity;
        if (parcelWithAction.action.action == ParcelActionType.Sent) handleCodeCount.value -= parcelWithAction.quantity;
      }

      showResults.value = true;
    };

    const showWarehouseLocationDetails = (warehouseLocation: LinkedWarehouseLocation) => {
      warehouseLocationValue.value = warehouseLocation.code;
      warehouseLocationVisible.value = true;
    };

    const printReport = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const company: Company = companies.value.find((c) => c.id == companyId.value) as Company;

          const handleCodePaper: HandleCodePaper = new HandleCodePaper(
            company.name,
            itemCode.value,
            period.value,
            results.value,
            handleCodeCount.value,
            d,
            n,
            t
          );
          await handleCodePaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companyId,
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      itemCode,
      handleCodeCount,
      n,
      period,
      printReport,
      results,
      showResults,
      showWarehouseLocationDetails,
      t,
      warehouseLocationValue,
      warehouseLocationVisible,
    };
  },
});
