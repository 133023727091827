import { CompanyStockRow } from "../objects/CompanyStockRow";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";

export class CompanyStockPaper extends DocumentPaper {
  private companyName: string | undefined;
  private date: Date;
  private results: CompanyStockRow[];

  constructor(
    companyName: string | undefined,
    date: Date,
    results: CompanyStockRow[],
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.companyName = companyName;
    this.date = date;
    this.results = results;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      companyStockDetail: { fontSize: 10 },
      companyStockParcelCell: { alignment: "center", fontSize: 8 },
      companyStockParcelFoot: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 8 },
      companyStockParcelHead: { alignment: "center", bold: true, color: "white", fillColor: "gray", fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(): Promise<Record<string, unknown>[]> {
    await this.setStructure(this.t("statCompanyStock.companyStockPaper").toLocaleUpperCase());

    this.writeDetails();

    this.writeStockTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeDetails(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: DataHelpers.mmToPoints(40),
          stack: [
            {
              margin: [0, DataHelpers.mmToPoints(4), 0, 0],
              style: "companyStockDetail",
              text: `${this.t("statCompanyStock.company").toLocaleUpperCase()}:`,
            },
            {
              margin: [0, DataHelpers.mmToPoints(2), 0, 0],
              style: "companyStockDetail",
              text: `${this.t("statCompanyStock.date").toLocaleUpperCase()}:`,
            },
          ],
        },
        {
          width: "*",
          stack: [
            { margin: [0, DataHelpers.mmToPoints(4), 0, 0], style: "companyStockDetail", text: this.companyName ?? "-" },
            { margin: [0, DataHelpers.mmToPoints(2), 0, 0], style: "companyStockDetail", text: this.d(this.date, "hugeDate") },
          ],
        },
      ],
    });
  }

  private writeStockTable(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "companyStockParcelHead", text: this.t("statCompanyStock.parcelType").toLocaleUpperCase() },
        { style: "companyStockParcelHead", text: this.t("statCompanyStock.detail").toLocaleUpperCase() },
        { style: "companyStockParcelHead", text: this.t("statCompanyStock.quantity").toLocaleUpperCase() },
      ],
    ];

    for (const result of this.results) {
      dataTableBody.push([
        { style: "companyStockParcelCell", text: result.type?.name ?? "-" },
        { style: "companyStockParcelCell", text: result.detail ?? "-" },
        { style: "companyStockParcelCell", text: result.quantity ? this.n(result.quantity, "number0") : "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "companyStockParcelCell", text: this.t("gen.noResults") }]);
    } else {
      let stockCount = 0;
      for (const stock of this.results) {
        stockCount += stock.quantity;
      }

      dataTableBody.push([
        { colSpan: 2, style: "companyStockParcelFoot", text: this.t("statCompanyStock.stockCount").toLocaleUpperCase() },
        { style: "companyStockParcelFoot", text: "" },
        { style: "companyStockParcelFoot", text: this.n(stockCount, "number0") },
      ]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", "*", DataHelpers.mmToPoints(30)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
