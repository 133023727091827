import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyStorage } from "../objects/CompanyStorage";
import { Parcel } from "@/features/modules/parcel/objects/Parcel";
import { ParcelActionType } from "@/features/modules/parcel/objects/ParcelActionType";
import { parcelModel } from "@/features/modules/parcel/models/ParcelModel";

export class CompanyStorageModel {
  public async calcCompanyStorage(companyId: string, date: Date): Promise<CompanyStorage[]> {
    try {
      if (companyId === undefined) throw new Error("companyIdUndefined");
      if (date === undefined) throw new Error("dateUndefined");

      const parcels: Parcel[] = await parcelModel.getParcelsByCompanyAndPeriod(companyId, date, date);

      // find stored parcels at date
      const storedParcels: Parcel[] = [];
      for (const parcel of parcels) {
        let isStored = false;
        for (const action of parcel.actions) {
          action.date.setHours(0, 0, 0, 0);
          if (compareAsc(action.date, date) > 0) break;

          if (!isStored) {
            if (action.action == ParcelActionType.Stored) isStored = true;
          } else {
            if ([ParcelActionType.Loosened, ParcelActionType.Loaded, ParcelActionType.Sent, ParcelActionType.Deleted].includes(action.action)) {
              isStored = false;
            }
          }
        }

        if (isStored) storedParcels.push(parcel);
      }

      // calc company storage
      const results: CompanyStorage[] = [];
      for (const parcel of storedParcels) {
        for (const item of parcel.items) {
          const result: CompanyStorage | undefined = results.find((result) => result.code == item.code);
          if (result != undefined) {
            result.quantity += item.quantity;

            if (parcel.type == undefined) continue;
            if (Object.keys(result.parcelTypesQuantity).includes(parcel.type.id)) {
              result.parcelTypesQuantity[parcel.type.id] += item.quantity;
            } else {
              result.parcelTypesQuantity[parcel.type.id] = item.quantity;
            }
          } else {
            const result: CompanyStorage = new CompanyStorage();
            result.code = item.code;
            result.quantity = item.quantity;

            if (parcel.type == undefined) continue;
            result.parcelTypesQuantity[parcel.type.id] = item.quantity;

            results.push(result);
          }
        }
      }

      return results;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyStorageModel.calcCompanyStorage", { companyId, date }, error);
      return [];
    }
  }
}

export const companyStorageModel: CompanyStorageModel = new CompanyStorageModel();
