import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { NumberField, NumberWithDefaultField, StringField } from "@/core/fields";

export class OutboundShipmentItem {
  public id: string = DataHelpers.uniqueId();
  public code: string | undefined;
  public requestedQuantity = 1;
  public selectedQuantity = 0;
  public availableQuantity = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): OutboundShipmentItem {
    this.id = DataHelpers.uniqueId();

    this.code = StringField.fromFirestore(data.code);
    this.requestedQuantity = NumberWithDefaultField.fromFirestore(data.quantity, 1);
    this.selectedQuantity = NumberField.fromFirestore(data.selectedQuantity);
    this.availableQuantity = NumberField.fromFirestore(data.availableQuantity);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.code = StringField.toFirestore(this.code);
    firestoreData.quantity = NumberWithDefaultField.toFirestore(this.requestedQuantity, 1);
    firestoreData.selectedQuantity = NumberField.toFirestore(this.selectedQuantity);
    firestoreData.availableQuantity = NumberField.toFirestore(this.availableQuantity);

    return firestoreData;
  }
}
