
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";
import { zebraPrintModel } from "../models/ZebraPrintModel";

import ZebraPrintView from "../views/ZebraPrintView.vue";

export default defineComponent({
  name: "ZebraPrintSelector",
  components: {
    ZebraPrintView,
  },
  setup() {
    let eventBusId: string | undefined = undefined;

    const zebraPrintViewVisible: Ref<boolean> = ref(false);
    const linkClass: Ref<string> = ref("");

    onMounted(() => {
      zebraPrintModel.connect();

      eventBusId = eventBus.on("zebraPrintToggleStateComponent", async (state: "connected" | "disconnected" | undefined) => {
        if (state === undefined) return;
        linkClass.value = state === "connected" ? "text-primary" : "text-danger";
      });

      const state: "connected" | "disconnected" = zebraPrintModel.getState();
      linkClass.value = state === "connected" ? "text-primary" : "text-danger";
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      linkClass,
      zebraPrintViewVisible,
    };
  },
});
