
import { defineComponent } from "vue";

import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

import ZebraPrintSelector from "@/features/modules/zebraPrint/components/ZebraPrintSelector.vue";

export default defineComponent({
  name: "TopBarRightHolder",
  components: {
    ZebraPrintSelector,
  },
  setup() {
    const store = useStore();
    const user: User = store.getters[storeTypes.getters.getUser];

    return {
      user,
    };
  },
});
