import { RouteRecordRaw } from "vue-router";

import { CompanyHelpers } from "../helpers/CompanyHelpers";

export const companyRoutes: Array<RouteRecordRaw> = [
  {
    path: "/companies",
    name: "CompanyList",
    component: () => import("../views/CompanyList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/find",
    name: "CompanyFind",
    component: () => import("../views/CompanyFind.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "create",
    },
  },
  {
    path: "/companies/:id/edit",
    name: "CompanyEdit",
    component: () => import("../views/CompanyEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:id/:section?",
    name: "CompanyView",
    component: () => import("../views/CompanyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "viewUI",
    },
  },
  // doc edit
  {
    path: "/companies/:sourceId/docs/:docId/edit",
    name: "CompanyDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: CompanyHelpers.getDocFolderPath(route.params.sourceId as string),
      source: "company",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
  // doc view
  {
    path: "/companies/:sourceId/docs/:docId",
    name: "CompanyDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      source: "company",
      sourceId: route.params.sourceId,
      sourcePath: "companies",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doc",
      requiredRight: "viewUI",
    },
  },
];
