import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { OutboundShipment } from "@/features/modules/outboundShipment/objects/OutboundShipment";
import { outboundShipmentModel } from "../OutboundShipmentModel";

export const generateCode = async (): Promise<string> => {
  try {
    let newCode = "ERROR";
    let duplicatedOutboundShipment: OutboundShipment | undefined = new OutboundShipment();

    while (duplicatedOutboundShipment != undefined) {
      newCode = FeaturesHelpers.generateCode("D");
      duplicatedOutboundShipment = await outboundShipmentModel.getOutboundShipmentByCode(newCode);
    }

    return newCode;
  } catch (error: unknown) {
    appFaultModel.catchAppError("OutboundShipmentModel.generateCode", {}, error);
    return "ERROR";
  }
};
