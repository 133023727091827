import { Company } from "./Company";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedCompany extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromCompany(company: Company): LinkedCompany {
    const linkedCompany: LinkedCompany = new LinkedCompany();
    linkedCompany.id = company.id;
    linkedCompany.name = company.name;

    return linkedCompany;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedCompany {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
