import CodiceFiscale from "codice-fiscale-js";
import { compareAsc } from "date-fns";
import { customAlphabet } from "nanoid";
import { HttpsCallable, httpsCallable, HttpsCallableResult } from "firebase/functions";

import { firebase } from "@/core/modules/firebase/objects/Firebase";

export class FeaturesHelpers {
  /**
   * calculate fiscal code
   * @param firstName the first name
   * @param lastName the last name
   * @param sex the sex
   * @param birthDate the birth date
   * @param birthPlace the birth place
   * @returns the calculated fiscal code
   */
  public static calculateFiscalCode(
    firstName: string | undefined,
    lastName: string | undefined,
    sex: string | undefined,
    birthDate: Date | undefined,
    birthPlace: string | undefined
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (firstName == undefined || lastName == undefined || sex == undefined || birthDate == undefined || birthPlace == undefined) {
        reject();
        return;
      }
      const cf = new CodiceFiscale({
        name: firstName,
        surname: lastName,
        gender: sex,
        day: birthDate.getDate(),
        month: birthDate.getMonth() == 12 ? 0 : birthDate.getMonth() + 1,
        year: birthDate.getFullYear(),
        birthplace: birthPlace,
      });
      resolve(cf.cf);
    });
  }

  public static checkIfDateIsInWindow(date: Date, fromDate: Date | undefined, toDate: Date | undefined): boolean {
    if (fromDate !== undefined) {
      const from: Date = new Date(fromDate.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, date) > 0) return false;
    }
    if (toDate !== undefined) {
      const to: Date = new Date(toDate.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(date, to) > 0) return false;
    }
    return true;
  }

  public static decodeWarehouseLocationCode(code: string, t: (entry: string, params?: Record<string, unknown> | undefined) => string): string {
    if (code == undefined || code.trim().length == 0) return t("app.warehouseLocationNotFound");

    const parts = code.split("-");
    if (parts[0] == "S") {
      // shelf
      if (parts.length != 6) return t("app.warehouseLocationNotFound");
      return t("app.warehouseLocationShelfDetail", {
        warehouse: parts[1],
        lane: parts[2],
        span: parts[3],
        position: parts[4],
        floor: parts[5],
      });
    } else if (parts[0] == "Z") {
      // zone
      if (parts.length != 3) return t("app.warehouseLocationNotFound");
      return t("app.warehouseLocationZoneDetail", { warehouse: parts[1], zone: parts[2] });
    }
    return t("app.warehouseLocationNotFound");
  }

  public static generateCode(prefix: string, length = 5): string {
    const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ", length);
    return `${prefix}-${nanoid()}`;
  }

  public static async runNextCodeFunction(functionName: string, currentYear: number): Promise<number> {
    const validatorFunction: HttpsCallable = httpsCallable(firebase.functions, functionName);
    const functionResult: HttpsCallableResult = await validatorFunction({ currentYear: currentYear });
    const response: { result: number } = functionResult.data as { result: number };

    if (response === undefined || response.result === undefined) throw new Error("next code is undefined");
    return Promise.resolve(response.result);
  }
}
