import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12 flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.t('zebraPrint.title'),
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    style: { width: '300px' },
    modal: true,
    closable: true,
    dismissableMask: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.zebraPrintState === 'connected')
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "flex flex-grow-1",
                label: _ctx.t('buttons.disconnect'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disconnect()))
              }, null, 8, ["label"]))
            : (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                class: "flex flex-grow-1",
                label: _ctx.t('buttons.connect'),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connect()))
              }, null, 8, ["label"]))
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}